import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const CommentModal = ({
  showCommentModal,
  handleCloseCommentModal,
  formValues,
  handleFormChange,
  noConsent,
  isLoading
}) => {
  const history = useHistory();
  return (
    <div>
      <Modal
        show={showCommentModal}
        onHide={handleCloseCommentModal}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <div className="mb-3">
              <label>Please give reason(s) for declining.</label>
              <textarea
                class="form-control"
                id="exampleFormControlTextarea1"
                rows="5"
                value={formValues.comment}
                onChange={handleFormChange("comment")}
              ></textarea>
            </div>

            <button
              type="button"
              className="btn btn-outline-danger px-5 mb-3"
              disabled={isLoading}
              onClick={() => {
                noConsent();
              }}
            >
              {isLoading ? "Submit ...": "Submit"}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CommentModal;
