import React, { useState, useEffect } from "react";
import Header from "./components/Header/Header";
import { Steps, message } from "antd";
import * as EmailValidator from "email-validator";
import moment from "moment";
import Bvn from "./components/bvn";
import OTP from "./components/otp";
import BvnValidation from "./components/bvnVal";
import DocumentUpload from "./components/document";
import CompletedForm from "./components/completed";
import InvalidBVN from "./components/bvn/errors/invalid";
import useFiles from "./hooks/useFiles";
import InvalidOTP from "./components/otp/errors/invalid";
import useGoogle from "./hooks/useGoogle";
import ExistingOTP from "./components/otp/errors/existing";
import { useDispatch } from "react-redux";
import { set_bvn_info } from "../../../../redux/actions/bvnAction";
import useModal from "./hooks/useModal";

import { useSelector } from "react-redux";
import { encrypt2 } from "../../../../encr";
import { decryptHandler2 } from "../../../../dec";
import TermsAndConditions from "./components/modal/tc";
import NewCompletedForm from "./components/newCompleted";
import { Spinner } from "react-bootstrap";
import PendingModal from "../../../ExistingCustomer/components/PendingModal";
import { appUrls } from "../../../../service/urls";
// import { apiAccount } from "../../../../service/apiAccount";
import { authOApi } from '../../../../service/authOApi';


const { Step } = Steps;

const KidsExistingAcc = () => {
  const dispatch = useDispatch();

  const { bvnInfo } = useSelector(({ bvn }) => bvn);
  const [
    // showOtp,
    // handleCloseOtp,
    // handleShowOtp,
    showInvalid,
    handleCloseInvalid,
    handleShowInvalid,
    showCompleted,
    handleCloseCompleted,
    handleShowCompleted,
    showInvalidOTP,
    handleCloseInvalidOTP,
    handleShowInvalidOTP,
    showExistingOTP,
    handleCloseExistingOTP,
    handleShowExistingOTP,

    showTC,
    handleCloseTC,
    handleShowTC,
  ] = useModal();

  const [showOtp, setShowOtp] = useState(false);
  const handleCloseOtp = () => setShowOtp(false);
  const handleShowOtp = () => setShowOtp(true);

  const [isOpen, setIsOpen] = useState(false);
  const handleClosePendingModal = () => setIsOpen(false);
  const handleShowPendingModal = () => setIsOpen(true);

  //state to hold bvn details
  const [identificationType, setIdentificationType] = useState(
    "International Passport"
  );

  const [isVerified, setIsVerified] = useState(false);

  const [disableBtn, setDisableBtn] = useState(true);

  const [loadingID, setLoadingID] = useState(false);

  const [supposedBvn, setSupposedBvn] = useState("");

  //state to hold account info
  const [accountInfo, setAccountInfo] = useState("");

  const [accNum, setAccNum] = useState("");

  const [customerNumber, setCustomerNumber] = useState("");

  const [accountCount, setAccountCount] = useState("");

  const [mob, setMob] = useState("");

  const [address, setAddress, handleSelect] = useGoogle();

  const [otp, setOtp] = useState("");

  const [branchCode, setBranchCode] = useState([]);

  const [isTCClicked, setIsTCClicked] = React.useState(false);
  const [emailInfo, setEmailInfo] = useState("");

  const [isRestriction, setIsRestriction] = useState(false)

  const [hpvImage, setHpvImage] = useState("");
  const [valid, setValid] = useState(false);
  const [isLoadingHpvSdk, setIsLoadingHpvSdk] = useState(false);

  const tcHandler = (value) => {
  
    setIsTCClicked(value);
  };

  //stepper
  const [current, setCurrent] = React.useState(0);
  const prev = () => {
    setCurrent(current - 1);
  };

  const next = () => {
    setCurrent(current + 1);
    handleClosePendingModal();
  };

  // const validate = () => {
  //   handleShowOtp();
  // };

  const confirmOtp = () => {
    handleCloseOtp();
    setCurrent(current + 1);
    message.success("BVN verification was successful");
  };

  const complete = () => {
    handleShowCompleted();
  };

  const confirmCompleted = () => {
    handleCloseCompleted();
    setCurrent(0);
  };

  //forms state
  const [formValues, setFormValues] = useState({
    bvn: "",
    otp: "",
    dobirth: "",
    branch: "",
    ChildFirstName: "",
    ChildMiddleName: "",
    ChildLastName: "",
    ChildDob: "",
    ChildGender: "",
    firstName: "",
    secondName: "",
    lastName: "",
    phone: "",
    email: "",
    houseNo: "",
    address: "",
    landmark: "",
    state: "",
    addressSummary: "",
    minorImage: "",
    livePhoto: "",
    accountNo: "",
    productCode: "I.CAN.SAVE1",
    employment: "",
    idCardNo: "",
    issueDate: "",
    expiryDate: "",
    refCode: "",
    nok: "",
    id: "",
    gender: "",
  });

  // state to continue your account opening process
  const [savedProcessValues, setSavedProcessValues] = useState([]);

  //handling form state change
  const handleFormChange =
    (name) =>
    ({ target }) => {
      setFormValues({
        ...formValues,
        [name]: target.value,
      });
    };

  const onDateChange = (event) => {
    setDob(event.target.value);
  };

  const [dob, setDob] = useState(
    moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.sssZ")
  );

  const [bvnValues, setBvnValues] = useState({
    accountNumber: "",
    bvn: "",
    otp: "",
  });

  const [
    minorImage,
    livePhotoUpload,
    frontPage,
    onDrops,
    config,
    onError,
    imgSrc,
    retake,
    onDrop,
    onCapture,
    frontPageURL,
    minorImageURL,
    birthCert,
    birthCertURL,
    onDropp,
    parentSignature,
    parentSignatureURL,
    onDroppp,
    onDropsss,
    uploadPic,
    uploadPicURL,
    minorImgExtension,
    uploadedImgExtension,
    parentSignatureExtension,
    frontPageExtension,
    birthCertificateExtension,
  ] = useFiles();

  const [loading, setLoading] = useState(false);

  //state to hold bvn details
  const [bvnDetails, setBvnDetails] = useState({});

  const handleBvnChange =
    (name) =>
    ({ target }) => {
      setBvnValues({
        ...bvnValues,
        [name]: target.value,
      });
    };

  const validateForm = () => {
    getAccountCount();
  };

  // get age difference
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  // a and b are javascript Date objects
  function dateDiffInDays(currentDate, givenDate) {
    // Discard the time and time-zone information.
    const utc1 = Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const utc2 = Date.UTC(
      givenDate.getFullYear(),
      givenDate.getMonth(),
      givenDate.getDate()
    );

    return Math.floor((utc2 - utc1) / MS_PER_DAY);
  }
  const currentDate = new Date(),
    givenDate = new Date(formValues.ChildDob),
    differences = dateDiffInDays(givenDate, currentDate);

  const ageDifference = Math.ceil(differences / 365);
  

  const getAccountCount = () => {
    setLoading(true);
    const data = encrypt2(bvnValues.bvn);
    authOApi
      .get(`${appUrls.FIORANO_GET_ACCT_COUNTBY_BVN}?Data=${data}`)
      .then((res) => {
        const response = decryptHandler2(res.data);
        
        if (response.Count === 0) {
          message.error(
            "Oops!!! You do not have an existing Sterling account. Please Use the New customers' module"
          );
          setLoading(false);
          return;
        } else {
          isExisting();
          // setLoading(false);
        }
        setAccountCount(response.Count);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          "Unable to verify account details. Please try again later", 10
        )
      });
  };

  const fullInfo = () => {
    setLoading(true);
    const data = encrypt2(bvnValues.bvn);
    authOApi
      .get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);
        let accountInfos = res.GetAccountFullInfo.BankAccountFullInfo;
        setEmailInfo(res.GetAccountFullInfo.BankAccountFullInfo[0]);
        let accounts = accountInfos.filter(
          (x) => x.CustomerStatus !== "2" && x.BVN !== null
        );
      
        if (accounts.length > 0) setCustomerNumber(accounts[0]?.CUS_NUM);
        else
          setCustomerNumber(
            res.GetAccountFullInfo.BankAccountFullInfo[0]?.CUS_NUM
          );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          "Unable to get account details. Please try again later", 10
        )
      });
  };

  // get data needed with bvn
  const getSaveProcess = () => {
    setLoading(true);
    const data = encrypt2(bvnValues.bvn);
    authOApi
      .get(`${appUrls.SAVE_PROCESS_DATABY_BVN}?Data=${data}`)
      .then((res) => {
        const results = decryptHandler2(res.data);
        localStorage.setItem("saveddata", JSON.stringify(results));
        if (
          results.Data.length !== 0 &&
          results &&
          results.responseCode === "00"
        ) {
          results.data.forEach((el) => {
            const newData = el;
            
            if (el.productCode === "I.CAN.SAVE1") {
              setSavedProcessValues(newData);
              setFormValues({
                bvn: el.Bvn,
                dobirth: el.dateOfBirth,
                ChildFirstName: el.firstName,
                ChildLastName: el.lastName,
                phone: el.phone,
                email: el?.email,
                address: el.address,
                landmark: el.landmark,
                state: el.state,
                gender: el.gender,
              });
              setLoading(false);
            } else {
              setSavedProcessValues(results);
             
              setFormValues({
                bvn: results.data[0].Bvn,
                dobirth: results.data[0].dateOfBirth,
                ChildFirstName: results.data[0].firstName,
                ChildLastName: results.data[0].lastName,
                phone: results.data[0].phone,
                email: results.data[0]?.email,
                address: results.data[0].address,
                landmark: results.data[0].landmark,
                state: results.data[0].state,
                gender: results.data[0].gender,
              });
              // next();
              setLoading(false);
            }
          });
        } else {
          handleClosePendingModal();
          // message.error(
          //   "Oops!! You have no existing application."
          // );
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  // user continues process if user is not done
  const saveProcess = () => {
    setLoading(true);
    let payload = {
      Bvn: formValues.bvn,
      firstName: formValues.firstName,
      email: formValues.email,
      surname: formValues.surname,
      middleName: formValues.middleName,
      productCode: "I.CAN.SAVE1",
      currencyCode: "NGN",
      branch: formValues.branch,
      appId: "1",
      state: formValues.state,
      dateOfBirth: formValues.dateOfBirth,
      phoneNumber: formValues.phoneNumber,
      address: formValues.address,
      sector: "4200",
      industry: "4202",
      nationality: "NG",
      clientID: "52",
      mode: "1",
      channelId: "1",
      customerID: customerNumber,
      residence: formValues.residence,
      shortTitle: "",
      childGender: "",
      preferredName: formValues.preferredName,
      dao: formValues.dao,
    };
    const data = encrypt2(payload);
    authOApi
      .post(`${appUrls.SAVE_PROCESS}`, { data: data })
      .then(async (res) => {
        const results = await decryptHandler2(res.data);
        if (results && results.ResponseCode === "00") {
          setCurrent(current + 1);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          "Unable to save data. Please try again later", 10
        )
      });
    setLoading(false);
  };

  const isExisting = () => {
    setLoading(true);
    const data = encrypt2(bvnValues.bvn);
    authOApi
      .get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${data}`)
      .then(async (response) => {
        const results = await decryptHandler2(response.data);
        if (results && results.ResponseCode === "00") {
          const accountArray = results.GetAccountFullInfo.BankAccountFullInfo;
          // (accountArray);
          if (accountArray) {
            const checkAccount = accountArray.some(
              (accounts) => accounts.ACCT_TYPE === "I.CAN.SAVE1"
            );
            if (checkAccount === true) {
              message.error(
                <span>
                  <p>
                    Oops!!! You already have this type of account. Open another
                    account or call 0700 822 0000 to retrieve your existing
                    account.
                  </p>
                  <p>
                    Forgot Account Number?
                    <a style={{ color: "#db353a" }} href="/retrieve-account">
                      {" "}
                      Retrieve account number
                    </a>
                  </p>
                </span>
              );
              // verifyBvn();
              setLoading(false);
              return;
            } else {
              verifyBvn();
              // setLoading(false);
            }
          } else {
            verifyBvn();
            // setLoading(false);
          }
        } else {
          message.error(
            "Unable to get account details. Please try again later!"
          );
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          "Unable to verify account details. Please try again later", 10
        );
      });
  };

  const getFullInfo = () => {
    setLoading(true);
    const data = encrypt2(bvnValues.accountNumber);
    authOApi
			.get(`${appUrls.FIORANO_GET_ACCT_FULLINFO}?Data=${data}`)
			.then((response) => {
				const res = decryptHandler2(response.data);
				if (res.ResponseCode === "00") {
					setSupposedBvn(res.BankAccountFullInfo.BVN);
					setLoading(false);
				} else {
					setLoading(false);
				}
				// setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				message.error(
					"Unable to verify account details. Please try again later.",
					10
				);
			});
  };

  const verifyBvn = () => {
    if (!bvnValues.accountNumber) {
      message.error(
        "Oops!!! You're missing something. Please provide your Account number"
      );
      return;
    }
    if (!bvnValues.bvn) {
      message.error(
        "Oops!!! You're missing something. Please provide your BVN"
      );
      return;
    }
    if (supposedBvn !== bvnValues.bvn) {
      message.error(
        "Oops! There's a mismatch with your BVN and Account information, please check and enter the correct details"
      );
      return;
    } else {
      setLoading(true);
      let payload = {
        bvn: bvnValues.bvn,
        dateOfBirth: null,
        // dateOfBirth: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.sssZ"),

      };
      const enc = encrypt2(payload);
      getSaveProcess();
      authOApi
        .post(`${appUrls.VERIFY_BVN_V2}`, { data: enc })
        .then(async (response) => {
          const res = await decryptHandler2(response.data);
          if (res && res.ResponseCode === "00") {
            handleShowOtp();
            setBvnDetails(res);
            dispatch(set_bvn_info(res));
            let payload = {
              mobile: res.PhoneNumber,
              email: emailInfo?.email || res.Email,
              clientID: "52",
            };
            const enc = encrypt2(payload);
            authOApi
              .post(`${appUrls.GENERATE_OTP}`, { data: enc })
              .then((response) => {
                const res = decryptHandler2(response.data);
                setMob(res.PhoneNumber);
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                message.error(
                  "Unable to generate OTP. Please try again later", 10
                );
              });
          } else {
            setLoading(false);
          }
        });
    }
  };

  //validateOTP
  const validateOTP = () => {
    setLoading(true);
    const payload = {
      mobile: mob,
      otp: otp,
    };
    const data = encrypt2(payload);

    authOApi
      .post(`${appUrls.VALIDATE_OTP}`, { data: data })
      .then((response) => {
        const res = decryptHandler2(response.data);
        if (res.ResponseCode === "1") {
          handleCloseOtp();
          message.success("Great! Your BVN verification was successful!");
          const savedData = JSON.parse(localStorage.getItem("saveddata"));
          if (savedData.Data.length !== 0) {
            handleShowPendingModal();
          } else {
            // if user is creating application for the first time
            setCurrent(current + 1);
          }
          setLoading(false);
          let bvnPay = encrypt2(bvnValues.bvn);
          authOApi
            .get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${bvnPay}`)
            .then((response) => {
              const res = decryptHandler2(response.data);

              if (res.ResponseCode === "00") {
                message.success("Great! Your BVN verification was successful!");
                // setCurrent(current + 1);
              }
            });
          setLoading(false);
        } else {
          handleShowInvalidOTP();
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          "Unable to get account details. Please try again later.", 10
        );
      });
  };

  const validateFormTwo = () => {
    if (!formValues.ChildFirstName) {
      message.error(
        "Oops!!! You're missing something. Please input your first name"
      );
      return;
    }
    if (!formValues.ChildLastName) {
      message.error(
        "Oops!!! You're missing something. Please input your last name"
      );
      return;
    }
    if (!formValues.ChildDob) {
      message.error(
        "Oops!!! You're missing something. Please input your date of birth"
      );
      return;
    }
    if (ageDifference > 18) {
      message.error(
        "Oops!!! You're missing something. Child's age must be 18 years or below"
      );
      return;
    }
    if (!formValues.email) {
      message.error(
        "Oops!!! You're missing something. Please provide your Email to proceed"
      );
      return;
    }
    if (!EmailValidator.validate(formValues.email)) {
      message.error("This email is not valid, please update and try again ");
      return;
    }
    if (!address) {
      message.error(
        "Oops!!! You're missing something. Please input your address"
      );
      return;
    }
    if (!formValues.landmark) {
      message.error(
        "Oops!!! You're missing something. Please input your landmark"
      );
      return;
    }
    if (!formValues.state) {
      message.error(
        "Oops!!! You're missing something. Please input your state of residence"
      );
      return;
    }
    if (!formValues.ChildGender) {
      message.error(
        "Oops!!! You're missing something. Please input your gender"
      );
      return;
    }
    if (!formValues.nok) {
      message.error(
        "Oops!!! You're missing something. Please input your Next of kin"
      );
      return;
    }
    if (!identificationType) {
      message.error(
        "Oops!!! You're missing something. Please input your ID type"
      );
      return;
    }
    if (identificationType === "National ID") {
      if (!formValues.idCardNo) {
        message.error(
          "Oops!!! You're missing something. Please input your ID number"
        );
        return;
      }
      // if (!formValues.issueDate) {
      //   message.error(
      //     "Oops!!! You're missing something. Please input your Issue date"
      //   );
      //   return;
      // }
    }
    if (identificationType === "Permanent Voters Card") {
      if (!formValues.idCardNo) {
        message.error(
          "Oops!!! You're missing something. Please input your ID number"
        );
        return;
      }
      // if (!formValues.issueDate) {
      //   message.error(
      //     "Oops!!! You're missing something. Please input your Issue date"
      //   );
      //   return;
      // }
    }
    if (identificationType === "Drivers License") {
      if (!formValues.idCardNo) {
        message.error(
          "Oops!!! You're missing something. Please input your ID number"
        );
        return;
      }
      if (!formValues.issueDate) {
        message.error(
          "Oops!!! You're missing something. Please input your Issue date"
        );
        return;
      }
      if (!formValues.expiryDate) {
        message.error(
          "Oops!!! You're missing something. Please input your Expiry date"
        );
        return;
      }
    }
    if (identificationType === "International Passport") {
      if (!formValues.idCardNo) {
        message.error(
          "Oops!!! You're missing something. Please input your ID number"
        );
        return;
      }
      if (!formValues.issueDate) {
        message.error(
          "Oops!!! You're missing something. Please input your Issue date"
        );
        return;
      }
      if (!formValues.expiryDate) {
        message.error(
          "Oops!!! You're missing something. Please input your Expiry date"
        );
        return;
      }
    }
    saveProcess();
    next();
  };

  const validateFormThree = () => {
    if (!minorImage) {
      message.error(
        "Oops!!! You're missing something. Please upload Minor's photo"
      );
      return;
    }
    // if (!livePhotoUpload) {
    //   message.error(
    //     "Oops!!! You're missing something. Please upload a live picture"
    //   );
    //   return;
    // }
    if (!hpvImage && uploadPic === "") {
      message.error(
        "Oops!!! You're missing something. Please take a upload or take live picture"
      );
      return;
    }
    if (!frontPage) {
      message.error(
        "Oops!!! You're missing something. Please select your ID front page"
      );
      return;
    }
    if (!birthCert) {
      message.error(
        "Oops!!! You're missing something. Please select your Birth certification/Passport"
      );
      return;
    }
    if (!parentSignature) {
      message.error(
        "Oops!!! You're missing something. Please upload Parent/Guardian signature"
      );
      return;
    }
    if (!formValues.branch) {
      message.error(
        "Oops!!! You're missing something. Please select your preferred branch to proceed"
      );
      return;
    }
    openAccount();
  };

  const dateTime = new Date();

  const uploadToAzure = async () => {
    setLoading(true);
    if (!hpvImage) {
      const imageArr = minorImageURL.split(",");
      const imageArr2 = uploadPicURL.split(",");
      const imageArr3 = frontPageURL.split(",");
      const imageArr4 = birthCertURL.split(",");
      const imageArr5 = parentSignatureURL.split(",");

      const imageSplit = imageArr[1];
      const imageSplit2 = imageArr2[1];
      const imageSplit3 = imageArr3[1];
      const imageSplit4 = imageArr4[1];
      const imageSplit5 = imageArr5[1];

      const result = {
        liveImgSent: "",
        minorImgSent: "",
        frontIDSent: "",
        birthCertSent: "",
        signatureSent: "",
        bvnImgSent: "",
        success: false,
      };

      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/minorsImage`,
        extension: minorImgExtension,
      };
      const data1 = encrypt2(payload1);

      let payload2 = {
        file: imageSplit2,
        fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/uploadedImage`,
        extension: uploadedImgExtension,
      };
      const data2 = encrypt2(payload2);

      let payload3 = {
        file: imageSplit3,
        fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/ID`,
        extension: frontPageExtension,
      };
      const data3 = encrypt2(payload3);

      let payload4 = {
        file: imageSplit4,
        fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/birthCertificate`,
        extension: birthCertificateExtension,
      };
      const data4 = encrypt2(payload4);

      let payload5 = {
        file: imageSplit5,
        fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/parentsSignature`,
        extension: parentSignatureExtension,
      };
      const data5 = encrypt2(payload5);

      let payload6 = {
        file: bvnDetails.Base64Image,
        fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/bvnImage`,
        extension: ".png",
      };
      const data6 = encrypt2(payload6);

      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          const res1 = response.data;
          result.minorImgSent = res1;
          await authOApi
            .post(`${appUrls.UPLOAD_DOC_V2}`, payload2)
            .then(async (response) => {
              const res2 = response.data;
              result.liveImgSent = res2;
              await authOApi
                .post(`${appUrls.UPLOAD_DOC_V2}`, payload3)
                .then(async (response) => {
                  const res3 = response.data;
                  result.bvnImgSent = res3;
                  await authOApi
                    .post(`${appUrls.UPLOAD_DOC_V2}`, payload4)
                    .then(async (response) => {
                      const res4 = response.data;
                      result.frontIDSent = res4;
                      await authOApi
                        .post(`${appUrls.UPLOAD_DOC_V2}`, payload5)
                        .then(async (response) => {
                          const res5 = response.data;
                          result.birthCertSent = res5;
                          await authOApi
                            .post(`${appUrls.UPLOAD_DOC_V2}`, payload6)
                            .then(async (response) => {
                              const res6 = response.data;
                              result.signatureSent = await res6;
                            })
                            .catch((error) => {
                            message.error(
                              "Oops! Error Processing Documents", 10
                            )
                            });
                        });
                    });
                });
            });
          result.success = true;
        })
        .catch((error) => {
          result.success = false;
          message.error(
            "Öops! Error Processing Documents", 10
          )
        });
      return result;
    } else {
      const imageArr = minorImageURL.split(",");
      const imageArr2 = hpvImage.split(",");
      const imageArr3 = frontPageURL.split(",");
      const imageArr4 = birthCertURL.split(",");
      const imageArr5 = parentSignatureURL.split(",");

      const imageSplit = imageArr[1];
      const imageSplit2 = imageArr2[1];
      const imageSplit3 = imageArr3[1];
      const imageSplit4 = imageArr4[1];
      const imageSplit5 = imageArr5[1];

      const result = {
        liveImgSent: "",
        minorImgSent: "",
        frontIDSent: "",
        birthCertSent: "",
        signatureSent: "",
        bvnImgSent: "",
        success: false,
      };

      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/minorsImage`,
        extension: minorImgExtension,
      };
      const data1 = encrypt2(payload1);

      let payload2 = {
        file: imageSplit2,
        fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/selfie`,
        extension: ".jpeg",
      };
      const data2 = encrypt2(payload2);

      let payload3 = {
        file: imageSplit3,
        fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/ID`,
        extension: frontPageExtension,
      };
      const data3 = encrypt2(payload3);

      let payload4 = {
        file: imageSplit4,
        fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/birthCertificate`,
        extension: birthCertificateExtension,
      };
      const data4 = encrypt2(payload4);

      let payload5 = {
        file: imageSplit5,
        fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/parentsSignature`,
        extension: parentSignatureExtension,
      };
      const data5 = encrypt2(payload5);

      let payload6 = {
        file: bvnDetails.Base64Image,
        fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/bvnImage`,
        extension: ".png",
      };
      const data6 = encrypt2(payload6);

      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          const res1 = response.data;
          result.minorImgSent = res1;
          await authOApi
            .post(`${appUrls.UPLOAD_DOC_V2}`, payload2)
            .then(async (response) => {
              const res2 = response.data;
              result.liveImgSent = res2;
              await authOApi
                .post(`${appUrls.UPLOAD_DOC_V2}`, payload3)
                .then(async (response) => {
                  const res3 = response.data;
                  result.bvnImgSent = res3;
                  await authOApi
                    .post(`${appUrls.UPLOAD_DOC_V2}`, payload4)
                    .then(async (response) => {
                      const res4 = response.data;
                      result.frontIDSent = res4;
                      await authOApi
                        .post(`${appUrls.UPLOAD_DOC_V2}`, payload5)
                        .then(async (response) => {
                          const res5 = response.data;
                          result.birthCertSent = res5;
                          await authOApi
                            .post(`${appUrls.UPLOAD_DOC_V2}`, payload6)
                            .then(async (response) => {
                              const res6 = response.data;
                              result.signatureSent = await res6;
                            })
                            .catch((error) => {
                              message.error(
                                "Oops! Error Processing Documents", 10
                              )
                            });
                        });
                    });
                });
            });
          result.success = true;
        })
        .catch((error) => {
          result.success = false;
          message.error(
            "Oops! Error Processing Documents", 10
          )
        });
      return result;
    }
  };

  const verifyy = () => {
    if (identificationType === "International Passport") {
      youVerifyInternational();
    }

    if (identificationType === "Permanent Voters Card") {
      youVerifyPvc();
    }

    if (identificationType === "National ID") {
      youVerifyNationalID();
    }

    if (identificationType === "Drivers License") {
      youVerifyDriversLic();
    }
  };

  const youVerifyInternational = () => {
    setLoadingID(true);
    let payloadd = {
      id: formValues.idCardNo,
      lastName: formValues.lastName,
      isSubjectConsent: true,
      validations: {
        data: {
          firstName: formValues.firstName,
          dateOfBirth: moment(formValues.dobirth).format("YYYY-MM-DD"),
        },
      },
    };
    // (payloadd);
    authOApi
      .post(`${appUrls.ADD_INTL_PASSPORT}`, payloadd)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == "200") {
          next();
          setLoadingID(false);
          setIsVerified(true);
        } else {
          // message.error(response.data.message, 10);
          next();
          setLoadingID(false);
        }
      })
      .catch((error) => {
        setLoadingID(false);
        next();
      });
  };

  const youVerifyNationalID = () => {
    let payload = {
      id: formValues.idCardNo,
      isSubjectConsent: true,
    };
    //  
    authOApi
      .post(`${appUrls.VERIFY_NIN}`, payload)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == "200") {
          next();
          setLoadingID(false);
          setIsVerified(true);
        } else {
          // message.error(response.data.message, 10);
          next();
          setLoadingID(false);
        }
      })
      .catch((error) => {
        setLoadingID(false);
        next();
      });
  };

  const youVerifyPvc = () => {
    setLoadingID(true);
    let payload = {
      id: formValues.idCardNo,
      isSubjectConsent: true,
    };
    //  
    authOApi
      .post(`${appUrls.VERIFY_PVC}`, payload)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == "200") {
          next();
          setLoadingID(false);
          setIsVerified(true);
        } else {
          // message.error(response.data.message, 10);
          next();
          setLoadingID(false);
        }
      })
      .catch((error) => {
        next();
        setLoadingID(false);
      });
  };

  const youVerifyDriversLic = () => {
    setLoadingID(true);
    let payload = {
      id: formValues.idCardNo,
      isSubjectConsent: true,
    };
    authOApi
      .post(`${appUrls.VERIFY_DRIVER_LICENSE}`, payload)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == "200") {
          next();
          setLoadingID(false);
          setIsVerified(true);
        } else {
          // message.error(response.data.message);
          next();
          setLoadingID(false);
        }
      })
      .catch((error) => {
        next();
      });
  };

  const openAccount = async () => {
    setLoading(true);
    const results = await uploadToAzure();
    if (results.success) {
      let payload = {
        firstName: formValues.firstName,
        email: formValues.email,
        surName: formValues.lastName,
        middleName: formValues.secondName,
        productCode: "I.CAN.SAVE1",
        currencyCode: "NGN",
        branch: formValues.branch,
        appId: "1",
        state: formValues.state,
        bvn: formValues.bvn,
        dateOfBirth: moment(formValues.dobirth).format(
          "YYYY-MM-DDTHH:mm:ss.sssZ"
        ),
        phoneNumber: formValues.phone,
        address: address,
        sector: "4200",
        industry: "4202",
        nationality: "NG",
        clientID: "52",
        mode: "1",
        channelId: "1",
        customerID: customerNumber,
        residence: "NG",
        shortTitle: "",
        ChildDateOfBirth: moment(formValues.ChildDob).format(
          "YYYY-MM-DDTHH:mm:ss.sssZ"
        ),
        ChildFirstName: formValues.ChildFirstName,
        ChildMiddleName: formValues.ChildMiddleName,
        ChildLastName: formValues.ChildLastName,
        ChildGender: formValues.ChildGender,
        preferredName: formValues.firstName,
        dao: null,
        IsVerified: isVerified && valid,
        isRestriction: isRestriction,
      };
      const data = encrypt2(payload);
      //  
      // setLoading(false);
      await authOApi
        .post(`${appUrls.CREATE_ACCT_INAPP}`, { data: data })
        .then(async (response) => {
          const res = decryptHandler2(response.data);
          if (res.responseCode === "00") {
            
            setAccountInfo(res.nuban);
            let payload = {
              accountNumber: res.nuban,
              folio: "Digital Onboarding",
              source: "Digital Onboarding",
              photoMandateBase64: results.liveImgSent,
              photoIDBase64: results.minorImgSent,
              signatureMandateBase64: results.signatureSent,
              otherBase64: results.birthCertSent,
              meansOfIDBase64: results.frontIDSent,
              bvnImageURL: results.bvnImgSent,
              idtype: identificationType,
              idno: formValues.idCardNo,
              issuedate: formValues.issueDate,
              isexpirydate: formValues.expiryDate,
            };
            const docsEnc = encrypt2(payload);
            await authOApi
              .post(`${appUrls.UPLOAD_DOC_ONLINEACCOUNT}`, {
                data: docsEnc,
              })
              .then((response) => {
                const res = decryptHandler2(response.data);

                message.success(res.responseDescription);
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
              });
            handleShowCompleted();

            let payload1 = {
              accountNumber: res.nuban,
              phoneNumber: formValues.phone,
            };
            let enc = encrypt2(payload1);
            authOApi
              .post(`${appUrls.SIM_SWAP_STATUS}`, { data: enc })
              .then((response) => {
                let decRes = decryptHandler2(response.data);
                // (decRes);
                if (decRes.SimSwapMode === "SWAPPED") {
                  let payload = {
                    branchCode: formValues.branch,
                    accountNumber: res.nuban,
                    accontType: "I.CAN.SAVE1",
                  };
                  const encRestr = encrypt2(payload);
                  authOApi
                    .post(`${appUrls.ADD_RESTRICTION}`, {
                      data: encRestr,
                    })
                    .then((response) => {
                      let encRes = decryptHandler2(response.data);
                      // (encRes);
                      if (encRes.RestrictionResponse.responseCode === "1") {
                        customerCare();
                      }
                    });
                }
              });

            const customerCare = () => {
              let payload = {
                accountNumber: res.nuban,
                phoneNumber: formValues.phone,
              };
              authOApi
                .post(`${appUrls.EMAIL_CUSTOMER_CARE}`, {
                  data: payload,
                })
                .then((response) => {
                  let encRes = encrypt2(response.data);
                  // (encRes);
                  if (encRes === 1) {
                  }
                });
            };
          } else {
            message.error(res.responseText);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const initializeSdk = (token) => {
    window.HyperSnapSDK.init(token, window.HyperSnapParams.Region.Africa);
    window.HyperSnapSDK.startUserSession();
  };

  const runLiveness = () => {
    const hvFaceConfig = new window.HVFaceConfig();
    hvFaceConfig.setShouldShowInstructionPage(true);
    hvFaceConfig.setLivenessAPIParameters({
      rejectFaceMask: "yes",
      allowEyesClosed: "no",
      allowMultipleFaces: "no",
    });
    customizeFaceCaptureInstructionsScreen(hvFaceConfig);
    window.HVFaceModule.start(hvFaceConfig, livelinessCallback);
  };

  const customizeFaceCaptureInstructionsScreen = (hvFaceConfig) => {
    const { faceTextConfig } = hvFaceConfig;
    faceTextConfig.setFaceInstructionsTitle("Selfie capture Tips");
    // remove Top1, Top2
  };

  const livelinessCallback = (HVError, HVResponse) => {
    if (HVError) {
      const errorCode = HVError.getErrorCode();
      const errorMessage = HVError.getErrorMessage();
      message.error(errorMessage);
      // (errorCode, errorMessage);
    } else {
      const faceImageBase64 = HVResponse.imgBase64;
      setHpvImage(faceImageBase64);
      checkMatch(faceImageBase64);
    }
  };

  const checkMatch = (faceImageBase64) => {
    const imgg = localStorage.getItem("params");
    const hyp = faceImageBase64.split(",");
    const hypSplit = hyp[1];
    let payload = {
      Selfie: hypSplit,
      IdFaceString: imgg,
    };
    authOApi
      .post(`${appUrls.HYPERVERGE_FACE_MATCH}`, payload)
      .then((response) => {
        // (response.data);
        if (response.data.status === "failure") {
          message.error("Unable to verify Hyperverge !");
          setValid(false);
          setIsRestriction(true)
        } else {
          setValid(true);
          setIsRestriction(false)
        }
      })
      .catch((error) => {
        message.error(
          "OUnable to verify Face Match. Please try again later", 10
        )
      });
  };

  const startHyperverge = (firstParam) => {
    localStorage.setItem("params", firstParam);
    setIsLoadingHpvSdk(true);
    authOApi
      .get(`${appUrls.GET_HYPERVERGE_TOKEN}`
      // , {
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      //   },
      // }
      )
      .then(async (response) => {
        if (response.data.statusCode === "200") {
          const token = response.data.result.token;
          initializeSdk(token);
          runLiveness();
          setIsLoadingHpvSdk(false);
        }
      })
      .catch((err) => {
        setIsLoadingHpvSdk(false);
        message.error(
          "Error, Please try again later", 10
        )
      });
  };

  const steps = [
    {
      title: "BVN Validation",
      content: (
        <Bvn
          bvnValues={bvnValues}
          handleBvnChange={handleBvnChange}
          setDisableBtn={setDisableBtn}
        />
      ),
    },
    {
      title: "Upload details",
      content: (
        <BvnValidation
          formValues={formValues}
          handleFormChange={handleFormChange}
          bvnDetails={bvnDetails}
          identificationType={identificationType}
          setIdentificationType={setIdentificationType}
          setAccNum={setAccNum}
          setCustomerNumber={setCustomerNumber}
          setAccountCount={setAccountCount}
          address={address}
          setAddress={setAddress}
          handleSelect={handleSelect}
          bvnValues={bvnValues}
          setLoading={setLoading}
          setFormValues={setFormValues}
          bvnInfo={bvnInfo}
          fullInfo={fullInfo}
        />
      ),
    },
    {
      title: "Document upload",
      content: (
        <DocumentUpload
          formValues={formValues}
          handleFormChange={handleFormChange}
          minorImage={minorImage}
          onDrops={onDrops}
          onCapture={onCapture}
          config={config}
          onError={onError}
          imgSrc={imgSrc}
          retake={retake}
          onDrop={onDrop}
          frontPage={frontPage}
          birthCert={birthCert}
          onDropp={onDropp}
          onDroppp={onDroppp}
          parentSignature={parentSignature}
          branchCode={branchCode}
          tcHandler={tcHandler}
          isTCClicked={isTCClicked}
          handleShowTC={handleShowTC}
          onDropsss={onDropsss}
          uploadPic={uploadPic}
          hpvImage={hpvImage}
          isLoadingHpvSdk={isLoadingHpvSdk}
          startHyperverge={startHyperverge}
          bvnDetails={bvnDetails}
        />
      ),
    },
  ];

  const getBranches = () => {
    authOApi
      .get(`${appUrls.GET_STERLING_BRANCHES}`
      // , {
      //   headers: {
      //     "Access-Control-Allow-Origin": "*",
      //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
      //   },
      // }
      )
      .then((response) => {
        const res = decryptHandler2(response.data);
      
        setBranchCode(res.SterlingBranches);
      })
      .catch((err) => {
      });
  };

  useEffect(() => {
    getBranches();
  }, []);

  useEffect(() => {
    if (bvnValues.accountNumber.length === 10) {
      getFullInfo();
      fullInfo();
    }
  }, [bvnValues.accountNumber.length]);
  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 displayNone">
          <div className="leftDiv stickyDiv"></div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <Header />
          <div className="container-fluid pb-5 mt-5">
            <div class="row mb-5">
              <div class="container mr-3">
                <div className="steps-content mb-5">
                  {steps[current].content}
                </div>
                <div className="text-right"></div>
                <div className="steps-action pb-5 mt-3 text-right">
                  {current === steps.length - 3 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 btn-block w-80"
                      onClick={validateForm}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Validate"
                      )}
                    </button>
                  )}
                  {current > 0 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger px-5 mr-2 mb-3"
                      onClick={prev}
                    >
                      Previous
                    </button>
                  )}
                  {current < steps.length - 1 && current > 0 && (
                    <button
                      type="button"
                      className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-3"
                      onClick={validateFormTwo}
                    >
                      {/* {loadingID && "Validating ..."}
                      {!loadingID && "Next"} */}
                      Next
                    </button>
                  )}
                  {current === steps.length - 1 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                      onClick={validateFormThree}
                      // disabled={loading}
                      disabled={loading === true || isTCClicked === false}
                    >
                      {loading ? "Completing ..." : "Complete"}
                    </button>
                  )}
                </div>
                {current === 0 && (
                  <div>
                    <div class="my-4 text-centers text-dark">
                      <h3 class="mb-0 sec-headers">Kids Account</h3>
                    </div>
                    <div className="row pry-grey-clr mb-4 w-80">
                      <div className="col-md-6 ftsz-12">
                        <h6 className="ftsz-12 ftwt-500">Features</h6>
                        <ul className="custom-req-marker pl-3">
                          <li>
                            Minimum account opening balance of N2,000. (Not
                            Restrictive)
                          </li>
                          <li>Daily minimum balance of N1,000.</li>
                          <li>
                            Attractive interest rate i.e., 1.15% p.a. (30% of
                            MPR).
                          </li>
                          <li>
                            Allow lodgement of cheques and dividend warrants.
                          </li>
                          <li>Issuance of Verve card</li>
                          <li>SMS/Email alert.</li>
                          <li>
                            Incentive of N250,000 to beneficiary that have
                            scores of all A's in SSCE/Cambridge O'level in at
                            least 8 subjects at one sitting
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6 ftsz-12">
                        <h6 className="ftsz-12 ftwt-500">Requirements</h6>
                        <ul className="custom-req-marker pl-3">
                          <li>
                            Basic information of Minor and Parent/guardian.
                            (i.e., Name, Place and date of birth, Gender,
                            Address, Telephone number) **Address would be
                            verified
                          </li>
                          <li>
                            Birth certificate of the minor/International
                            passport / NIN Slip.
                          </li>
                          <li>
                            Parent’s/Guardian’s Valid and acceptable means of
                            Identification.
                          </li>
                          <li>
                            Passport photograph of minor and parent/ guardian.
                          </li>
                          <li>BVN of Parent/Guardian.</li>
                          <li>Signature of Parent/Guardian.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <OTP
        showOtp={showOtp}
        handleCloseOtp={handleCloseOtp}
        formValues={formValues}
        handleFormChange={handleFormChange}
        validateOTP={validateOTP}
        loading={loading}
        verifyBvn={verifyBvn}
        otp={otp}
        setOtp={setOtp}
      />
      <NewCompletedForm
        showCompleted={showCompleted}
        handleCloseCompleted={handleCloseCompleted}
        confirmCompleted={confirmCompleted}
        accountInfo={accountInfo}
        bvnDetails={bvnDetails}
        accNum={accNum}
        imgSrc={imgSrc}
      />
      <InvalidBVN
        showInvalid={showInvalid}
        handleCloseInvalid={handleCloseInvalid}
      />
      <InvalidOTP
        showInvalidOTP={showInvalidOTP}
        handleCloseInvalidOTP={handleCloseInvalidOTP}
      />

      <ExistingOTP
        showExistingOTP={showExistingOTP}
        handleCloseExistingOTP={handleCloseExistingOTP}
      />

      <PendingModal
        showPendingModal={isOpen}
        handleClosePendingModal={handleClosePendingModal}
        setNext={next}
        getSaveProcess={getSaveProcess}
      />

      <TermsAndConditions showTC={showTC} handleCloseTC={handleCloseTC} />
    </div>
  );
};

export default KidsExistingAcc;
