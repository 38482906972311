import React from "react";
import warning from "../../../../../../../../../../Images/warning-icon.svg";

const BvnDetails = ({ formValuess, handleFormChangess }) => {
  return (
    <div>
      <form action="">
        <p class=" pale-red-tone-bg pry-red-clr ftwt-600 ftsz-12 mb-0 px-3 py-2 mb-3 rounded-lg">
          <img src={warning} class="mr-3" alt="" />
          Update your address and email if it has changed
        </p>
        <div class="form-row mb-3 align-items-end">
          <div class="col-12 col-sm-6 col-md-4 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              First name
            </label>
            <input
              type="text"
              class="form-control pry-input-border bg-white"
              value={formValuess.firstName}
              disabled
            />
          </div>
          <div class="col-12 col-sm-6 col-md-4 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Second name
            </label>
            <input
              type="text"
              class="form-control pry-input-border bg-white"
              value={formValuess.secondName}
              disabled
            />
          </div>
          <div class="col-12 col-sm-6 col-md-4 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Last name
            </label>
            <input
              type="text"
              class="form-control pry-input-border bg-white"
              value={formValuess.lastName}
              disabled
            />
          </div>
          <div class="col-12 col-sm-6 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Phone number
            </label>
            <input
              type="text"
              class="form-control pry-input-border bg-white"
              value={formValuess.phone}
              onChange={handleFormChangess("phone")}
            />
          </div>
          <div class="col-12 col-sm-6 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Date of Birth
            </label>
            <input
              type="text"
              class="form-control pry-input-border bg-white"
              value={formValuess.dobirth}
              disabled
            />
          </div>
          <div class="col-12 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Email address
            </label>
            <input
              type="email"
              class="form-control pry-input-border"
              placeholder="Enter email address"
              value={formValuess.email}
              onChange={handleFormChangess("email")}
            />
          </div>
          <div class="col-12 mb-3">
            <div class="form-row">
              <div class="col-12">
                <h6 class="ftwt-600 ftsz-12">Residential address</h6>
              </div>
              <div class="col-12 col-md-12 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  House address
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border"
                  value={formValuess.address}
                  onChange={handleFormChangess("address")}
                  placeholder="House number"
                />
              </div>
              <div class="col-12 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  Nearest bus-stop/landmark
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border"
                  value={formValuess.landmark}
                  onChange={handleFormChangess("landmark")}
                  placeholder="Nearest bus-stop/landmark"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-12 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Nationality
            </label>
            <input
              type="text"
              class="form-control pry-input-border"
              value={formValuess.nationality}
              onChange={handleFormChangess("nationality")}
              placeholder="Nationality"
            />
          </div>
          <div class="col-12 col-sm-12 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              CERPAC ID number (If nationality is not Nigerian)
            </label>
            <input
              type="text"
              class="form-control pry-input-border"
              placeholder="Enter CERPAC ID number"
              value={formValuess.cerpac}
              onChange={handleFormChangess("cerpac")}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default BvnDetails;
