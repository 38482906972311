import React, { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import { Steps, message } from 'antd';
import Bvn from './components/bvn';
import OTP from './components/otp';
import BvnValidation from './components/bvnVal';
import DocumentUpload from './components/document';
import CompletedForm from './components/completed';
import {
  apiUrl,
  apiUrl2,
  apiUrl3,
  referenceEndpoint,
} from '../../../../redux/actions/endpoint';
import InvalidBVN from './components/bvn/errors/invalid';
import moment from 'moment';
import * as EmailValidator from 'email-validator';
import axios from 'axios';
import useFiles from './hooks/useFiles';
import InvalidOTP from './components/otp/errors/invalid';
import useGoogle from './hooks/useGoogle';
import ExistingOTP from './components/otp/errors/existing';
import { decryptHandler2 } from '../../../../dec';
import { encrypt2 } from '../../../../encr';
import TermsAndConditions from './components/modal/tc';
import NewCompletedForm from './components/newCompleted';
import { Spinner } from 'react-bootstrap';
import PendingModal from '../../../NewCustomer/components/PendingModal';
import { apiAccount } from '../../../../service/apiAccount';
import { appUrls } from '../../../../service/urls';
import { authOApi } from '../../../../service/authOApi';
const { Step } = Steps;

const ClassicSavings = () => {
  //forms state
  const [formValues, setFormValues] = useState({
    bvn: '',
    otp: '',
    dobirth: '',
    branch: '',
    firstName: '',
    secondName: '',
    lastName: '',
    phone: '',
    email: '',
    houseNo: '',
    address: '',
    landmark: '',
    state: '',
    addressSummary: '',
    productCode: 'CLASSIC.SAV1',
    employment: '',
    idCardNo: '',
    issueDate: '',
    expiryDate: '',
    refCode: '',
    nok: '',
    id: '',
    otp: '',
    dao: null,
    imgBvn: '',
  });

  // state to continue your account opening process
  const [savedProcessValues, setSavedProcessValues] = useState([]);

  //state to hold bvn details
  const [bvnDetails, setBvnDetails] = useState({});

  const [isVerified, setIsVerified] = useState(false);

  const [accountCount, setAccountCount] = useState(0);

  const [customerNumber, setCustomerNumber] = useState('');

  const [disableBtn, setDisableBtn] = useState(true);

  const [loadingID, setLoadingID] = useState(false);
  const [isRestriction, setIsRestriction] = useState(false);

  const [accNum, setAccNum] = useState('');

  const [address, setAddress, handleSelect] = useGoogle();

  const [otp, setOtp] = useState('');
  const [emailVal, setEmailVal] = useState('');

  const [
    signatureUpload,
    livePhotoUpload,
    frontPage,
    onDrops,
    config,
    onError,
    imgSrc,
    retake,
    onDrop,
    onCapture,
    frontPageURL,
    signatureURL,
    onDropss,
    utilBill,
    utilBillURL,
    onDropsss,
    uploadPic,
    uploadPicURL,
    signatureExtension,
    utilityBillExtension,
    frontPageExtension,
    uploadedImgExtension,
  ] = useFiles();

  const [mob, setMob] = useState('');

  //state to hold bvn details
  const [identificationType, setIdentificationType] = useState(
    'International Passport'
  );

  //state to hold account info
  const [accountInfo, setAccountInfo] = useState('');

  const [branchCode, setBranchCode] = useState([]);

  //invalid bvn modal
  const [showInvalid, setShowInvalid] = useState(false);
  const handleCloseInvalid = () => setShowInvalid(false);
  const handleShowInvalid = () => setShowInvalid(true);

  //otp modal
  const [showOtp, setShowOtp] = useState(false);
  const handleCloseOtp = () => setShowOtp(false);
  const handleShowOtp = () => setShowOtp(true);

  //completed modal
  const [showCompleted, setShowCompleted] = useState(false);
  const handleCloseCompleted = () => setShowCompleted(false);
  const handleShowCompleted = () => setShowCompleted(true);

  const [showInvalidOTP, setShowInvalidOTP] = useState(false);
  const handleCloseInvalidOTP = () => setShowInvalidOTP(false);
  const handleShowInvalidOTP = () => setShowInvalidOTP(true);

  const [showExistingOTP, setShowExistingOTP] = useState(false);
  const handleCloseExistingOTP = () => setShowExistingOTP(false);
  const handleShowExistingOTP = () => setShowExistingOTP(true);

  const [showTC, setShowTC] = useState(false);
  const handleCloseTC = () => setShowTC(false);
  const handleShowTC = () => setShowTC(true);

  const [isOpen, setIsOpen] = useState(false);
  const handleClosePendingModal = () => setIsOpen(false);
  const handleShowPendingModal = () => setIsOpen(true);

  const [isTCClicked, setIsTCClicked] = React.useState(false);

  const [hpvImage, setHpvImage] = useState('');
  const [valid, setValid] = useState(false);
  const [isLoadingHpvSdk, setIsLoadingHpvSdk] = useState(false);

  //stepper
  const [current, setCurrent] = React.useState(0);
  const prev = () => {
    setCurrent(current - 1);
  };

  const next = () => {
    setCurrent(current + 1);
    handleClosePendingModal();
  };

  const [dob, setDob] = useState(
    moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.sssZ')
  );

  //loading state
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [bvnLoading, setBvnLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [isDebitClicked, setIsDebitClicked] = React.useState(false);

  //handling form state change
  const handleFormChange =
    (name) =>
    ({ target }) => {
      setFormValues({
        ...formValues,
        [name]: target.value,
      });
    };

  const onDateChange = (event) => {
    setDob(event.target.value);
  };

  const tcHandler = (value) => {
    setIsTCClicked(value);
  };

  const debitHandler = (value) => {
    setIsDebitClicked(value);
  };

  //validation
  const validate = () => {
    isExisting();
  };

  // get data needed with bvn
  const getSaveProcess = () => {
    setIsLoading(true);
    const data = encrypt2(formValues.bvn);
    authOApi
      .get(`${appUrls.SAVE_PROCESS_DATABY_BVN}?Data=${data}`)
      .then((res) => {
        const results = decryptHandler2(res.data);
        localStorage.setItem('saveddata', JSON.stringify(results));
        if (
          results?.Data?.length !== 0 &&
          results &&
          results.responseCode === '00'
        ) {
          setSavedProcessValues(results);
          setFormValues({
            bvn: results.data[0].Bvn,
            dobirth: results.data[0].dateOfBirth,
            firstName: results.data[0].firstName,
            lastName: results.data[0].lastName,
            phone: results.data[0].phone,
            email: results.data[0].email,
            address: results.data[0].address,
            landmark: results.data[0].landmark,
            state: results.data[0].state,
            gender: results.data[0].gender,
          });
          // next()
          // setIsLoading(false);
        } else {
          handleClosePendingModal();
          // message.error(
          //   "Oops!! You have no existing application."
          // );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // setIsLoading(false);
      });
  };

  // user continues process if user is not done
  const saveProcess = () => {
    setIsLoading(true);
    let payload = {
      Bvn: formValues.bvn,
      firstName: formValues.firstName,
      email: formValues.email,
      surname: formValues.surname,
      middleName: formValues.middleName,
      productCode: 'CLASSIC.SAV1',
      currencyCode: 'NGN',
      branch: formValues.branch,
      appId: '1',
      state: formValues.state,
      dateOfBirth: formValues.dateOfBirth,
      phoneNumber: formValues.phoneNumber,
      address: formValues.address,
      sector: '4200',
      industry: '4202',
      nationality: 'NG',
      clientID: '52',
      mode: '1',
      channelId: '1',
      customerID: customerNumber,
      residence: formValues.residence,
      shortTitle: '',
      childGender: '',
      preferredName: formValues.preferredName,
      dao: formValues.dao,
    };
    const data = encrypt2(payload);
   authOApi
      .post(`${appUrls.SAVE_PROCESS}`, { data: data })
      .then(async (res) => {
        const results = await decryptHandler2(res.data);
        if (results && results.ResponseCode === '00') {
          setCurrent(current + 1);
          // setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error('Unable to save data, Please try again later', 10);
      });
  };

  const isExisting = async () => {
    setIsLoading(true);
    const data1 = encrypt2(formValues.bvn);
    await authOApi
      .get(`${appUrls.FIORANO_GET_ACCT_COUNTBY_BVN}?Data=${data1}`)
      .then((res) => {
        const response = decryptHandler2(res.data);
        setAccountCount(response.Count);
        let counts = response.Count;
        if (counts > 0) {
          message.error(
            <span>
              <p>
                Oops!!! You already have a Sterling account. Use the existing
                customers' module.
              </p>
              <p>
                Forgot Account Number?
                <a style={{ color: '#db353a' }} href="/retrieve-account">
                  {' '}
                  Retrieve account number
                </a>
              </p>
            </span>
          );
          setIsLoading(false);
          return;
        } else {
          const data = encrypt2(formValues.bvn);
         authOApi
            .get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${data}`)
            .then(async (response) => {
              const results = await decryptHandler2(response.data);
              if (results && results.ResponseCode === '00') {
                const accountArray =
                  results.GetAccountFullInfo.BankAccountFullInfo;
                setEmailVal(
                  results.GetAccountFullInfo.BankAccountFullInfo[0].email
                );
                if (accountArray) {
                  const checkAccount = accountArray.some(
                    (accounts) => accounts.ACCT_TYPE === 'Savings - Regular'
                  );
                  if (checkAccount === true) {
                    message.error(
                      <span>
                        <p>
                          Oops!!! You already have this type of account. Open
                          another account or call 0700 822 0000 to retrieve your
                          existing account.
                        </p>
                        <p>
                          Forgot Account Number?
                          <a
                            style={{ color: '#db353a' }}
                            href="/retrieve-account"
                          >
                            {' '}
                            Retrieve account number
                          </a>
                        </p>
                      </span>
                    );
                    setIsLoading(false);
                    return;
                    // verifyBvn();
                  } else {
                    verifyBvn();
                    // setIsLoading(false);
                  }
                } else {
                  verifyBvn();
                  // setIsLoading(false);
                }
              } else {
                setIsLoading(false);
                message.error(
                  'Unable to verify account details. Please try again later!'
                );
              }
            })
            .catch((error) => {
              setIsLoading(false);
              message.error('Unable to get account, please try again later');
            });
        }
      })
      .catch((error) => {
        setIsLoading(false);
        message.error(
          'Unable to get account at the moment, please try again later'
        );
      });
  };

  //endpoint to verify bvn
  const verifyBvn = async () => {
    setBvnLoading(true);
    const payload = {
      bvn: formValues.bvn,
      dateOfBirth: dob,
    };
    const encryptedBvnData = encrypt2(payload);
    getSaveProcess();
   authOApi
      .post(`${appUrls.VERIFY_BVN}`, { data: encryptedBvnData })
      .then(async (response) => {
        const results = await decryptHandler2(response.data);
        setBvnDetails(results);
        if (results && results.ResponseCode === '00') {
          handleShowOtp();
          setFormValues({
            bvn: results.BVN,
            firstName: results.FirstName,
            secondName: results.MiddleName,
            lastName: results.LastName,
            dobirth: results.DateOfBirth,
            phone: results.PhoneNumber,
            email: results.Email,
            address: results.ResidentialAddress,
            landmark: results.StateOfResidence,
            state: results.StateOfResidence,
            gender: results.Gender,
            imgBvn: results.Base64Image,
          });
          // setBvnLoading(false);
        } else {
          // handleShowInvalid();
          message.error(
            "Oops! There's a mismatch with your BVN information, please check and enter the correct details"
          );
          setBvnLoading(false);
        }
        let payload2 = {
          mobile: results.PhoneNumber,
          email: emailVal || results?.Email,
          clientID: '52',
        };
        setBvnLoading(false);
        const otpData = encrypt2(payload2);
       authOApi
          .post(`${appUrls.GENERATE_OTP}`, { data: otpData })
          .then((response) => {
            const resultss = decryptHandler2(response.data);
            setMob(resultss.PhoneNumber);
          })
         .catch((error) => {
            message.error('Unable to generate OTP, Please try again later', 10);
          });
      })
     .catch((error) => {
        setBvnLoading(false);
        message.error('Unable to verify BVN, please try again later', 10);
      });
  };

  //generateOTP
  const validateOTP = () => {
    setOtpLoading(true);
    let payload = {
      mobile: mob,
      otp: otp,
    };
    const valOtp = encrypt2(payload);
   authOApi
      .post(`${appUrls.VALIDATE_OTP}`, { data: valOtp })
      .then(async (response) => {
        const results = await decryptHandler2(response.data);
        if (results && results.ResponseCode === '1') {
          handleCloseOtp();
          message.success('Great! Your BVN verification was successful!');
          const savedData = JSON.parse(localStorage.getItem('saveddata'));
          if (savedData.Data.length !== 0) {
            handleShowPendingModal();
          } else {
            // if user is creating application for the first time
            setCurrent(current + 1);
          }
          // setCurrent(current + 1);
          setOtpLoading(false);
        } else {
          handleShowInvalidOTP();
          setOtpLoading(false);
        }
      })
     .catch((error) => {
        setOtpLoading(false);
        message.error('Unable to validate OTP, please try again later', 10);
      });
  };

  const validateForm = () => {
    if (current === 0) {
      if (!formValues.bvn) {
        message.error(
          "Oops!!! You're missing something. Please provide your BVN"
        );
        return;
      }
      if (!dob) {
        message.error(
          "Oops!!! You're missing something. Please provide your Date of birth"
        );
        return;
      }
      validate();
    }
  };

  const validateFormTwo = () => {
    if (!formValues.firstName) {
      message.error('First name not provided !');
      return;
    }
    if (!formValues.lastName) {
      message.error('Last name not provided !');
      return;
    }
    if (!formValues.dobirth) {
      message.error('Date of birth not provided !');
      return;
    }
    if (!formValues.email) {
      message.error(
        "Oops!!! You're missing something. Please provide your Email to proceed"
      );
      return;
    }
    if (!EmailValidator.validate(formValues.email)) {
      message.error('Email not valid!');
      return;
    }
    if (!address) {
      message.error('Address not provided !');
      return;
    }
    if (!formValues.landmark) {
      message.error('Landmark not provided !');
      return;
    }
    if (!formValues.state) {
      message.error('State of residence not provided !');
      return;
    }

    if (!formValues.nok) {
      message.error('Next of kin not provided !');
      return;
    }
    if (!identificationType) {
      message.error('ID type not provided !');
      return;
    }
    if (identificationType === 'National ID') {
      if (!formValues.idCardNo) {
        message.error('ID number not provided !');
        return;
      }
    }
    if (identificationType === 'Permanent Voters Card') {
      if (!formValues.idCardNo) {
        message.error('ID number not provided !');
        return;
      }
    }
    if (identificationType === 'Drivers License') {
      if (!formValues.idCardNo) {
        message.error('ID number not provided !');
        return;
      }
      if (!formValues.issueDate) {
        message.error('Issue date not provided !');
        return;
      }
      if (!formValues.expiryDate) {
        message.error('Expiry date not provided !');
        return;
      }
    }
    if (identificationType === 'International Passport') {
      if (!formValues.idCardNo) {
        message.error('ID number not provided !');
        return;
      }
      if (!formValues.issueDate) {
        message.error('Issue date not provided !');
        return;
      }
      if (!formValues.expiryDate) {
        message.error('Expiry date not provided !');
        return;
      }
    }
    saveProcess();
    // verifyy();
    next();
  };

  const validateFormThree = () => {
    if (!signatureUpload) {
      message.error(
        "Oops!!! You're missing something. Please upload your signature"
      );
      return;
    }
    if (!hpvImage && uploadPic === '') {
      message.error(
        "Oops!!! You're missing something. Please take a upload or take live picture"
      );
      return;
    }
    if (!frontPage) {
      message.error(
        "Oops!!! You're missing something. Please select your ID front page"
      );
      return;
    }
    if (!utilBill) {
      message.error(
        "Oops!!! You're missing something. Please upload your Utility bill"
      );
      return;
    }
    if (!formValues.branch) {
      message.error(
        "Oops!!! You're missing something. Please select your preferred branch to proceed"
      );
      return;
    }
    openAccount();
  };

  const dateTime = new Date();

  const uploadToAzure = async () => {
    setLoading(true);

    if (!hpvImage) {
      // const imageArr = signatureURL.split(",");
      // const imageArr2 = uploadPicURL.split(",");
      // const imageArr3 = frontPageURL.split(",");
      // const imageArr4 = utilBillURL.split(",");

      // const imageSplit = imageArr[1];
      // const imageSplit2 = imageArr2[1];
      // const imageSplit3 = imageArr3[1];
      // const imageSplit4 = imageArr4[1];

      const result = {
        imgSent: '',
        signatureSent: '',
        bvnImgSent: '',
        frontIDSent: '',
        utilBillSent: '',
        success: false,
      };

      // let payload1 = {
      //   file: imageSplit,
      //   fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/signature`,
      //   extension: signatureExtension,
      // };
      let payload1 = new FormData();
      payload1.append('file', signatureURL);
      const data1 = encrypt2(payload1);

      // let payload2 = {
      //   file: imageSplit2,
      //   fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/uploadedImage`,
      //   extension: uploadedImgExtension,
      // };
      let payload2 = new FormData();
      payload2.append('file', uploadPicURL);
      const data2 = encrypt2(payload2);

      // let payload3 = {
      //   file: imageSplit3,
      //   fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/ID`,
      //   extension: frontPageExtension,
      // };
      let payload3 = new FormData();
      payload3.append('file', frontPageURL);
      const data3 = encrypt2(payload3);

      // let payload4 = {
      //   file: imageSplit4,
      //   fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/utilityBill`,
      //   extension: utilityBillExtension,
      // };
      let payload4 = new FormData();
      payload4.append('file', utilBillURL);
      const data4 = encrypt2(payload4);

      let payload5 = {
        file: formValues.imgBvn,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-bvnImage`,
        extension: '.png',
      };
      const data5 = encrypt2(payload5);

      await authOApi
        .post(`${appUrls.UPLOAD_DOC}`, payload1)
        .then(async (response) => {
          const res1 = response.data;
          result.signatureSent = res1;
          await authOApi
            .post(`${appUrls.UPLOAD_DOC}`, payload2)
            .then(async (response) => {
              const res2 = response.data;
              result.imgSent = res2;
              await authOApi
                .post(`${appUrls.UPLOAD_DOC}`, payload3)
                .then(async (response) => {
                  const res3 = response.data;
                  result.bvnImgSent = res3;
                  await authOApi
                    .post(`${appUrls.UPLOAD_DOC}`, payload4)
                    .then(async (response) => {
                      const res4 = response.data;
                      result.frontIDSent = res4;
                      await authOApi
                        .post(`${appUrls.UPLOAD_DOC_V2}`, payload5)
                        .then(async (response) => {
                          const res5 = response.data;
                          result.utilBillSent = await res5;
                        })
                        .catch((error) => {
                          setLoading(false);
                          message.error('Oops! Error Processing Documents', 10);
                        });
                    });
                });
            });
          result.success = true;
        })
        .catch((error) => {
          setLoading(false);
          message.error('Oops! Error Processing Documents', 10);
          result.success = false;
        });
      return result;
    } else {
      // const imageArr = signatureURL.split(",");
      const imageArr2 = hpvImage.split(',');
      // const imageArr3 = frontPageURL.split(",");
      // const imageArr4 = utilBillURL.split(",");

      // const imageSplit = imageArr[1];
      const imageSplit2 = imageArr2[1];
      // const imageSplit3 = imageArr3[1];
      // const imageSplit4 = imageArr4[1];

      const result = {
        imgSent: '',
        signatureSent: '',
        bvnImgSent: '',
        frontIDSent: '',
        utilBillSent: '',
        success: false,
      };

      // let payload1 = {
      //   file: imageSplit,
      //   fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/signature`,
      //   extension: signatureExtension,
      // };
      let payload1 = new FormData();
      payload1.append('file', signatureURL);
      const data1 = encrypt2(payload1);

      let payload2 = {
        file: imageSplit2,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-selfie`,
        extension: '.jpeg',
      };
      const data2 = encrypt2(payload2);

      // let payload3 = {
      //   file: imageSplit3,
      //   fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/ID`,
      //   extension: frontPageExtension,
      // };
      let payload3 = new FormData();
      payload3.append('file', frontPageURL);
      const data3 = encrypt2(payload3);

      // let payload4 = {
      //   file: imageSplit4,
      //   fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/utilityBill`,
      //   extension: utilityBillExtension,
      // };
      let payload4 = new FormData();
      payload4.append('file', utilBillURL);
      const data4 = encrypt2(payload4);

      let payload5 = {
        file: formValues.imgBvn,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-bvnImage`,
        extension: '.png',
      };
      const data5 = encrypt2(payload5);

      await authOApi
        .post(`${appUrls.UPLOAD_DOC}`, payload1)
        .then(async (response) => {
          const res1 = response.data;
          result.signatureSent = res1;
          await authOApi
            .post(`${appUrls.UPLOAD_DOC_V2}`, payload2)
            .then(async (response) => {
              const res2 = response.data;
              result.imgSent = res2;
              await authOApi
                .post(`${appUrls.UPLOAD_DOC}`, payload3)
                .then(async (response) => {
                  const res3 = response.data;
                  result.bvnImgSent = res3;
                  await authOApi
                    .post(`${appUrls.UPLOAD_DOC}`, payload4)
                    .then(async (response) => {
                      const res4 = response.data;
                      result.frontIDSent = res4;
                      await authOApi
                        .post(`${appUrls.UPLOAD_DOC_V2}`, payload5)
                        .then(async (response) => {
                          const res5 = response.data;
                          result.utilBillSent = await res5;
                        })
                        .catch((error) => {
                          setLoading(false);
                          message.error('Oops! Error Processing Documents', 10);
                        });
                    });
                });
            });
          result.success = true;
        })
        .catch((error) => {
          setLoading(false);
          message.error('Oops! Error Processing Documents', 10);
          result.success = false;
        });
      return result;
    }
  };

  const addRestrictions = (accountNN) => {
    let restrictionPayload = {
      addRestriction: {
        branchcode: formValues.branch,
        account: accountNN,
        accountsType: 'ACCOUNTS',
        restriction_code: '23',
      },
    };
   authOApi
      .post(`${appUrls.FIORANO_ADD_RESTRICTION}`, restrictionPayload)
      .then((response) => {
        // message.success(response.data.responseDescription);
      })
     .catch((error) => {
      });
  };

  // let identificationTypes = "Drivers License";

  const verifyy = () => {
    if (identificationType === 'International Passport') {
      youVerifyInternational();
    }

    if (identificationType === 'Permanent Voters Card') {
      youVerifyPvc();
    }

    if (identificationType === 'National ID') {
      youVerifyNationalID();
    }

    if (identificationType === 'Drivers License') {
      youVerifyDriversLic();
    }
  };

  const youVerifyInternational = () => {
    setLoadingID(true);
    let payloadd = {
      id: formValues.idCardNo,
      lastName: formValues.lastName,
      isSubjectConsent: true,
      validations: {
        data: {
          firstName: formValues.firstName,
          dateOfBirth: moment(formValues.dobirth).format('YYYY-MM-DD'),
        },
      },
    };
   authOApi
      .post(`${appUrls.ADD_INTL_PASSPORT}`, payloadd)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == '200') {
          next();
          setLoadingID(false);
          setIsVerified(true);
        } else {
          // message.error(response.data.message, 10);
          next();
          setIsVerified(false);
          setLoadingID(false);
        }
      })
     .catch((error) => {
        setLoadingID(false);
        next();
      });
  };

  const youVerifyNationalID = () => {
    let payload = {
      id: formValues.idCardNo,
      isSubjectConsent: true,
    };
    //  
   authOApi
      .post(`${appUrls.VERIFY_NIN}`, payload)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == '200') {
          next();
          setLoadingID(false);
          setIsVerified(true);

        } else {
          // message.error(response.data.message, 10);
          next();
          setIsVerified(false);
          setLoadingID(false);
        }
      })
     .catch((error) => {
        setLoadingID(false);
        next();
        setIsVerified(true);
      });
  };

  const youVerifyPvc = () => {
    setLoadingID(true);
    let payload = {
      id: formValues.idCardNo,
      isSubjectConsent: true,
    };
    //  
   authOApi
      .post(`${appUrls.VERIFY_PVC}`, payload)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == '200') {
          next();
          setIsVerified(true);
          setLoadingID(false);

        } else {
          next();
          setLoadingID(false);
          setIsVerified(false);
        }
      })
     .catch((error) => {
        setLoadingID(false);
        next();
        setIsVerified(false);
      });
  };

  const youVerifyDriversLic = () => {
    setLoadingID(true);
    let payload = {
      id: formValues.idCardNo,
      isSubjectConsent: true,
    };
   authOApi
      .post(`${appUrls.VERIFY_DRIVER_LICENSE}`, payload)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == '200') {
          next();
          setIsVerified(true);
          setLoadingID(false);

        } else {
          next();
          setIsVerified(false);
          setLoadingID(false);
        }
      })
     .catch((error) => {
        next();
      });
  };

  // demo api to open accounts on test
  const openAccount = async () => {
    setLoading(true);
    const results = await uploadToAzure();

    if (results.success) {
      // if (results.success) {
      let payload = {
        BVN: formValues.bvn,
        DateOfBirth: formValues.dobirth,
        Email: formValues.email,
        PhoneNumber: formValues.phone,
        FirstName: formValues.firstName,
        LastName: formValues.lastName,
        ProductCode: 'CLASSIC.SAV1',
        PreferredName: formValues.firstName,
        ReferralCode: formValues.refCode,
        Branch: formValues.branch,
        Currency: 'NGN',
        Address: address,
        Sector: '4200',
        Industry: '4202',
        Nationality: 'NG',
        Gender: formValues.gender,
        ClientID: '52',
        StateOfResidence: formValues.state,
        IsVerified: isVerified && valid,
        isRestriction: isRestriction,
      };
      const encPayload = encrypt2(payload);
      await authOApi
        .post(`${appUrls.OPEN_ACCOUNT}`, { data: encPayload })
        .then(async (response) => {
          const res = decryptHandler2(response.data);

          if (res.ResponseCode === '00') {
            setAccountInfo(res.AccountDetails[0].AccountNumber);
            let rest = res.AccountDetails[0].AccountNumber;
            addRestrictions(rest);
            handleShowCompleted();

            let payload1 = {
              accountNumber: res.AccountDetails[0].AccountNumber,
              phoneNumber: formValues.phone,
            };
            let enc = encrypt2(payload1);
           authOApi
              .post(`${appUrls.SIM_SWAP_STATUS}`, { data: enc })
              .then((response) => {
                let decRes = decryptHandler2(response.data);
                if (decRes.SimSwapMode === 'SWAPPED') {
                  let payload = {
                    branchCode: formValues.branch,
                    accountNumber: res.AccountDetails[0].AccountNumber,
                    accontType: 'CLASSIC.SAV1',
                  };
                  const encRestr = encrypt2(payload);
                 authOApi
                    .post(`${appUrls.ADD_RESTRICTION}`, {
                      data: encRestr,
                    })
                    .then((response) => {
                      let encRes = decryptHandler2(response.data);
                      if (encRes.RestrictionResponse.ResponseCode === '1') {
                        customerCare();
                      }
                    });
                }
              });

            const customerCare = () => {
              let payload = {
                accountNumber: res.AccountDetails[0].AccountNumber,
                phoneNumber: formValues.phone,
              };
             authOApi
                .post(`${appUrls.EMAIL_CUSTOMER_CARE}`, {
                  data: payload,
                })
                .then((response) => {
                  let encRes = encrypt2(response.data);
                  if (encRes === 1) {
                    message.success('Message sent successfully');
                  }
                });
            };

            let payload = {
              accountNumber: res.AccountDetails[0].AccountNumber,
              folio: 'Digital Onboarding',
              source: 'Digital Onboarding',
              photoIDBase64: results.imgSent,
              signatureMandateBase64: results.signatureSent,
              bvnImageURL: results.bvnImgSent,
              otherBase64: results.utilBillSent,
              photoMandateBase64: results.frontIDSent,
              idtype: identificationType,
              idno: formValues.idCardNo,
              issuedate: formValues.issueDate,
              isexpirydate: formValues.expiryDate,
            };
            const encDocs = encrypt2(payload);
            //  
            await authOApi
              .post(`${appUrls.UPLOAD_DOC_ONLINEACCOUNT}`, {
                data: encDocs,
              })
              .then((response) => {
                const resp = decryptHandler2(response.data);
                message.success(resp.ResponseDescription);
                setLoading(false);
              })
              .catch((error) => {
              });
          } else {
            message.error(res.ResponseDescription);
          }
          addRestrictions();
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          message.error('Unable to open account, please try again later', 10);
        });
    }
  };

  const confirmCompleted = () => {
    handleCloseCompleted();
    setCurrent(0);
  };

  const initializeSdk = (token) => {
    window.HyperSnapSDK.init(token, window.HyperSnapParams.Region.Africa);
    window.HyperSnapSDK.startUserSession();
  };

  const runLiveness = () => {
    const hvFaceConfig = new window.HVFaceConfig();
    hvFaceConfig.setShouldShowInstructionPage(true);
    hvFaceConfig.setLivenessAPIParameters({
      rejectFaceMask: 'yes',
      allowEyesClosed: 'no',
      allowMultipleFaces: 'no',
    });
    customizeFaceCaptureInstructionsScreen(hvFaceConfig);
    window.HVFaceModule.start(hvFaceConfig, livelinessCallback);
  };

  const customizeFaceCaptureInstructionsScreen = (hvFaceConfig) => {
    const { faceTextConfig } = hvFaceConfig;
    faceTextConfig.setFaceInstructionsTitle('Selfie capture Tips');
    // remove Top1, Top2
  };

  const livelinessCallback = (HVError, HVResponse) => {
    if (HVError) {
      const errorCode = HVError.getErrorCode();
      const errorMessage = HVError.getErrorMessage();
      message.error(errorMessage);
    } else {
      const faceImageBase64 = HVResponse.imgBase64;
      setHpvImage(faceImageBase64);
      checkMatch(faceImageBase64);
    }
  };

  const checkMatch = (faceImageBase64) => {
    const imgg = localStorage.getItem('params');
    const hyp = faceImageBase64.split(',');
    const hypSplit = hyp[1];
    let payload = {
      Selfie: hypSplit,
      IdFaceString: imgg,
    };
   authOApi
      .post(`${appUrls.HYPERVERGE_FACE_MATCH}`, payload)
     .then((response) => {
        if (response.data.status === 'failure') {
          message.error('Unable to verify Hyperverge !');
          setValid(false);
          setIsRestriction(true);
        } else {
          setValid(true);
          setIsRestriction(false);
        }
      })
      .catch((error) => {
        message.error(
          'Unable to verify face match, Please try again later',
          10
        );
      });
  };

  const startHyperverge = (firstParam) => {
    localStorage.setItem('params', firstParam);
    setIsLoadingHpvSdk(true);
   authOApi
      .get(
        `${appUrls.GET_HYPERVERGE_TOKEN}`

      )
      .then(async (response) => {
        if (response.data.statusCode === '200') {
          const token = response.data.result.token;
          initializeSdk(token);
          runLiveness();
          setIsLoadingHpvSdk(false);
        }
      })
     .catch((err) => {
        setIsLoadingHpvSdk(false);
        message.error('Error, Please try again later', 10);
      });
  };

  const steps = [
    {
      title: 'BVN Validation',
      content: (
        <Bvn
          formValues={formValues}
          handleFormChange={handleFormChange}
          onDateChange={onDateChange}
          dob={dob}
          setDisableBtn={setDisableBtn}
        />
      ),
    },
    {
      title: 'Upload details',
      content: (
        <BvnValidation
          formValues={formValues}
          handleFormChange={handleFormChange}
          bvnDetails={bvnDetails}
          identificationType={identificationType}
          setIdentificationType={setIdentificationType}
          setAccountCount={setAccountCount}
          setCustomerNumber={setCustomerNumber}
          setAccNum={setAccNum}
          address={address}
          setAddress={setAddress}
          handleSelect={handleSelect}
          verifyy={verifyy}
        />
      ),
    },
    {
      title: 'Document upload',
      content: (
        <DocumentUpload
          formValues={formValues}
          handleFormChange={handleFormChange}
          signatureUpload={signatureUpload}
          onCapture={onCapture}
          config={config}
          onError={onError}
          imgSrc={imgSrc}
          retake={retake}
          onDrop={onDrop}
          onDrops={onDrops}
          frontPage={frontPage}
          onDropss={onDropss}
          utilBill={utilBill}
          branchCode={branchCode}
          tcHandler={tcHandler}
          isTCClicked={isTCClicked}
          handleShowTC={handleShowTC}
          isDebitClicked={isDebitClicked}
          debitHandler={debitHandler}
          onDropsss={onDropsss}
          uploadPic={uploadPic}
          hpvImage={hpvImage}
          isLoadingHpvSdk={isLoadingHpvSdk}
          startHyperverge={startHyperverge}
        />
      ),
    },
  ];

  const getBranches = () => {
   authOApi
      .get(`${appUrls.GET_STERLING_BRANCHES}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
      })
      .then((response) => {
        const res = decryptHandler2(response.data);
        setBranchCode(res.SterlingBranches);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getBranches();
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
          <div className="leftDiv stickyDiv"></div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <Header />
          <div className="container-fluid pb-5 mt-5">
            <div class="row mb-5">
              <div class="container mr-3">
                <div className="steps-content mb-5">
                  {steps[current].content}
                </div>
                <div className="text-right"></div>
                <div className="steps-action pb-1 mt-3 text-right">
                  {current === steps.length - 3 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 btn-block w-80"
                      onClick={validateForm}
                      disabled={disableBtn || isLoading}
                    >
                      {isLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        'Validate'
                      )}
                    </button>
                  )}
                  {current > 0 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger px-5 mr-2 mb-3"
                      onClick={prev}
                    >
                      Previous
                    </button>
                  )}
                  {current < steps.length - 1 && current > 0 && (
                    <button
                      type="submit"
                      className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-3"
                      onClick={validateFormTwo}
                      disabled={loadingID}
                      // type="submit"
                    >
                      {loadingID ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        'Next'
                      )}
                      {/* {!loadingID && "Next"} */}
                    </button>
                  )}
                  {current === steps.length - 1 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                      onClick={validateFormThree}
                      // disabled={loading}
                      disabled={loading === true || isTCClicked === false}
                    >
                      {loading ? 'Completing...' : 'Complete'}
                    </button>
                  )}
                </div>
                {current === 0 && (
                  <div>
                    <div class="my-4 text-centers text-dark">
                      <h3 class="mb-0 sec-headers">Classic Savings</h3>
                    </div>
                    <div className="row pry-grey-clr mb-4 w-80">
                      <div className="col-md-6 ftsz-12">
                        <h6 className="ftsz-12 ftwt-500">Features</h6>
                        <ul class="custom-req-marker pl-3">
                          <li>Zero initial opening balance is accepted.</li>
                          <li>
                            Maximum single deposit and cumulative
                            balance/threshold is unlimited.
                          </li>
                          <li>Daily minimum balance of N1,000.</li>
                          <li>
                            Attractive interest rate i.e., 1.15% p.a. (30% of
                            MPR)
                          </li>
                          <li>Issuance of Verve Card and Mastercard.</li>
                          <li>Online statements</li>
                          <li>SMS alert/Email.</li>
                          <li>Internet &amp; Mobile banking facility.</li>
                          <li>International funds transfer allowed.</li>
                        </ul>
                      </div>
                      <div className="col-md-6 ftsz-12">
                        <h6 className="ftsz-12 ftwt-500">Requirements</h6>
                        <ul class="custom-req-marker pl-3">
                          <li>
                            Basic customer information. (i.e., Name, Place and
                            date of birth, Gender, Address, Telephone number)
                            **Address would be verified.
                          </li>
                          <li>BVN.</li>
                          <li>Passport photograph.</li>
                          <li>Signature.</li>
                          <li>Valid ID.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <OTP
        showOtp={showOtp}
        handleCloseOtp={handleCloseOtp}
        formValues={formValues}
        handleFormChange={handleFormChange}
        validateOTP={validateOTP}
        loading={loading}
        verifyBvn={verifyBvn}
        otp={otp}
        setOtp={setOtp}
        otpLoading={otpLoading}
      />
      <NewCompletedForm
        showCompleted={showCompleted}
        handleCloseCompleted={handleCloseCompleted}
        confirmCompleted={confirmCompleted}
        accountInfo={accountInfo}
        bvnDetails={bvnDetails}
        accNum={accNum}
        imgSrc={imgSrc}
      />
      <InvalidBVN
        showInvalid={showInvalid}
        handleCloseInvalid={handleCloseInvalid}
      />
      <InvalidOTP
        showInvalidOTP={showInvalidOTP}
        handleCloseInvalidOTP={handleCloseInvalidOTP}
      />
      <ExistingOTP
        showExistingOTP={showExistingOTP}
        handleCloseExistingOTP={handleCloseExistingOTP}
      />

      <PendingModal
        showModal={isOpen}
        handleClosePendingModal={handleClosePendingModal}
        setNext={next}
        getSaveProcess={getSaveProcess}
      />

      <TermsAndConditions showTC={showTC} handleCloseTC={handleCloseTC} />
    </div>
  );
};

export default ClassicSavings;
