import React, { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import { Steps, message } from 'antd';
import Bvn from './components/bvn';
import OTP from './components/otp';
import BvnValidation from './components/bvnVal';
import DocumentUpload from './components/document';
import CompletedForm from './components/completed';
import {
  apiUrl,
  apiUrl3,
  apiUrl2,
  referenceEndpoint,
} from '../../../../redux/actions/endpoint';
import InvalidBVN from './components/bvn/errors/invalid';
import moment from 'moment';
import axios from 'axios';
import * as EmailValidator from 'email-validator';
import useFiles from './hooks/useFiles';
import InvalidOTP from './components/otp/errors/invalid';
import useModal from './hooks/useModal';
import useGoogle from '../KiakiaOne/hooks/useGoogle';
import ExistingOTP from './components/otp/errors/existing';
import { decryptHandler2 } from '../../../../dec';
import { encrypt2 } from '../../../../encr';
import TermsAndConditions from './components/modal/tc';
import NewCompletedForm from './components/newCompleted';
import { Spinner } from 'react-bootstrap';
import PendingModal from '../../../NewCustomer/components/PendingModal';
import { apiAccount } from '../../../../service/apiAccount';
import { appUrls } from '../../../../service/urls';
import { authOApi } from '../../../../service/authOApi';

const { Step } = Steps;

const KiakiaTwo = () => {
  //forms state
  const [formValues, setFormValues] = useState({
    bvn: '',
    otp: '',
    dobirth: '',
    branch: '',
    firstName: '',
    secondName: '',
    lastName: '',
    phone: '',
    email: '',
    houseNo: '',
    address: '',
    landmark: '',
    state: '',
    addressSummary: '',
    employment: '',
    idCardNo: '',
    issueDate: '',
    expiryDate: '',
    refCode: '',
    nok: '',
    otp: '',
    id: '',
    gender: '',
    dao: null,
    refCode: '',
    imgBvn: '',
  });

  // state to continue your account opening process
  const [savedProcessValues, setSavedProcessValues] = useState([]);

  const [
    signatureUpload,
    livePhotoUpload,
    frontPage,
    onDrops,
    config,
    onError,
    imgSrc,
    retake,
    onDrop,
    onCapture,
    frontPageURL,
    signatureURL,
    idPage,
    idPageURL,
    onDropss,
    onDropsss,
    uploadPic,
    uploadPicURL,

    signatureExtension,
    idExtension,
    uploadedImgExtension,
    frontPageExtension,
  ] = useFiles();

  const [
    showOtp,
    handleCloseOtp,
    handleShowOtp,
    showCompleted,
    handleCloseCompleted,
    handleShowCompleted,
    showInvalid,
    handleCloseInvalid,
    handleShowInvalid,
    showInvalidOTP,
    handleCloseInvalidOTP,
    handleShowInvalidOTP,
    showExistingOTP,
    handleCloseExistingOTP,
    handleShowExistingOTP,
    showTC,
    handleCloseTC,
    handleShowTC,
    isOpen,
    setIsOpen,
    handleClosePendingModal,
    handleShowPendingModal,
  ] = useModal();

  const [hpvImage, setHpvImage] = useState('');
  const [valid, setValid] = useState(false);
  const [isLoadingHpvSdk, setIsLoadingHpvSdk] = useState(false);

  const [otp, setOtp] = useState('');
  const [isVerified, setIsVerified] = useState(false);

  const [address, setAddress, handleSelect] = useGoogle();

  const [customerNumber, setCustomerNumber] = useState('');

  const [isLoading, setIsLoading] = useState(false);

  const [mob, setMob] = useState('');

  const [accNum, setAccNum] = useState('');

  const [disableBtn, setDisableBtn] = useState(true);

  //state to hold bvn details
  const [bvnDetails, setBvnDetails] = useState({});

  const [accountCount, setAccountCount] = useState(0);

  const [emailVal, setEmailVal] = useState('');

  const [isRestriction, setIsRestriction] = useState(false);

  const [token, setToken] = useState('');

  //state to hold bvn details
  const [identificationType, setIdentificationType] = useState(
    'International Passport'
  );

  //state to hold account info
  const [accountInfo, setAccountInfo] = useState('');

  const [dob, setDob] = useState(
    moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.sssZ')
  );

  //loading state
  const [loading, setLoading] = useState(false);
  const [loadingID, setLoadingID] = useState(false);
  const [bvnLoading, setBvnLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const [branchCode, setBranchCode] = useState([]);

  //handling form state change
  const handleFormChange =
    (name) =>
    ({ target }) => {
      setFormValues({
        ...formValues,
        [name]: target.value,
      });
    };

  const onDateChange = (event) => {
    setDob(event.target.value);
  };

  //stepper
  const [current, setCurrent] = React.useState(0);
  const prev = () => {
    setCurrent(current - 1);
  };

  const next = () => {
    setCurrent(current + 1);
    handleClosePendingModal();
  };

  //validation
  const validate = () => {
    isExisting();
  };

  const [isClicked, setIsClicked] = React.useState(false);

  const [isTCClicked, setIsTCClicked] = React.useState(false);

  const tcHandler = (value) => {
    setIsTCClicked(value);
  };

  const handler = (value) => {
    setIsClicked(value);
  };



  const getAccountCount = () => {
    const data = encrypt2(formValues.bvn);
    authOApi
      .get(`${appUrls.FIORANO_GET_ACCT_COUNTBY_BVN}?Data=${data}`)
      .then((res) => {
        const response = decryptHandler2(res.data);
        setAccountCount(response.Count);
      })
      .catch((error) => {});
  };

  const fullInfo = () => {
    setLoading(true);
    const data = encrypt2(formValues.bvn);
    authOApi
      .get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);
        let accountInfos = res.GetAccountFullInfo.BankAccountFullInfo;
        setEmailVal(res.GetAccountFullInfo.BankAccountFullInfo[0].email);
        let accounts = accountInfos.filter(
          (x) => x.CustomerStatus !== '2' && x.BVN !== null
        );
        if (accounts.length > 0) {
          setCustomerNumber(accounts[0].CUS_NUM);
          setLoading(false);
        } else
          setCustomerNumber(
            res.GetAccountFullInfo.BankAccountFullInfo[0].CUS_NUM
          );
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          'Unable to verify account details. Please try again later!',
          10
        );
      });
  };

  // get data needed with bvn
  const getSaveProcess = () => {
    setIsLoading(true);
    const data = encrypt2(formValues.bvn);
    authOApi
      .get(`${appUrls.SAVE_PROCESS_DATABY_BVN}?Data=${data}`)
      .then((res) => {
        const results = decryptHandler2(res.data);
        localStorage.setItem('saveddata', JSON.stringify(results));
        if (
          results.Data.length !== 0 &&
          results &&
          results.responseCode === '00'
        ) {
          setSavedProcessValues(results);
          setFormValues({
            bvn: results.data[0].Bvn,
            dobirth: results.data[0].dateOfBirth,
            firstName: results.data[0].firstName,
            lastName: results.data[0].lastName,
            phone: results.data[0].phone,
            email: results.data[0].email,
            address: results.data[0].address,
            landmark: results.data[0].landmark,
            state: results.data[0].state,
            gender: results.data[0].gender,
          });
        } else {
          handleClosePendingModal();
          setIsLoading(false);
        }
      })
      .catch((error) => {
      });
  };

  // user continues process if user is not done
  const saveProcess = () => {
    setIsLoading(true);
    let payload = {
      Bvn: formValues.bvn,
      firstName: formValues.firstName,
      email: formValues.email,
      surname: formValues.surname,
      middleName: formValues.middleName,
      productCode: 'KIA.KIA.21',
      currencyCode: 'NGN',
      branch: formValues.branch,
      appId: '1',
      state: formValues.state,
      dateOfBirth: formValues.dateOfBirth,
      phoneNumber: formValues.phoneNumber,
      address: formValues.address,
      sector: '4200',
      industry: '4202',
      nationality: 'NG',
      clientID: '52',
      mode: '1',
      channelId: '1',
      customerID: customerNumber,
      residence: formValues.residence,
      shortTitle: '',
      childGender: '',
      preferredName: formValues.preferredName,
      dao: formValues.dao,
    };
    const data = encrypt2(payload);
    authOApi
      .post(`${appUrls.SAVE_PROCESS}`, { data: data })
      .then(async (res) => {
        const results = await decryptHandler2(res.data);
        if (results && results.ResponseCode === '00') {
          setCurrent(current + 1);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error('Unable to save, Please try again later', 10);
      });
  };

  const isExisting = () => {
    setIsLoading(true);
    getAccountCount();
    // if (accountCount > 0) {
    //   message.error(
    //     "Oops!!! You already have a Sterling account. Use the existing customers' module"
    //   );
    //   setIsLoading(false);
    //   return;
    // }
    fullInfo();
    const data = encrypt2(formValues.bvn);
    authOApi
      .get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${data}`)
      .then(async (response) => {
        const results = await decryptHandler2(response.data);
        if (results && results.ResponseCode === '00') {
          const accountArray = results.GetAccountFullInfo.BankAccountFullInfo;
          if (accountArray) {
            const checkAccount = accountArray.some(
              (accounts) =>
                accounts.ACCT_TYPE === 'Savings - Financial Inclusion 2'
            );
            if (checkAccount === true) {
              // verifyBvn();
              message.error(
                <span>
                  <p>
                    Oops!!! You already have this type of account. Open another
                    account or call 0700 822 0000 to retrieve your existing
                    account.
                  </p>
                  <p>
                    Forgot Account Number?
                    <a style={{ color: '#db353a' }} href="/retrieve-account">
                      {' '}
                      Retrieve account number
                    </a>
                  </p>
                </span>
              );
              setIsLoading(false);
              return;
            } else {
              verifyBvn();
              // setIsLoading(false);
            }
          } else {
            verifyBvn();
            // setIsLoading(false);
          }
        } else {
          setIsLoading(false);
          message.error(
            'Unable to verify account details. Please try again later!',
            10
          );
        }
      })
      .catch((error) => {
        setIsLoading(false);
        message.error('Unable to get account, Please try again later', 10);
      });
  };

  //endpoint to verify bvn
  const verifyBvn = async () => {
    setBvnLoading(true);
    const payload = {
      bvn: formValues.bvn,
      dateOfBirth: dob,
    };
    const encryptedBvnData = encrypt2(payload);
    getSaveProcess();
    authOApi
      .post(`${appUrls.VERIFY_BVN}`, { data: encryptedBvnData })
      .then(async (response) => {
        const results = await decryptHandler2(response.data);
        setBvnDetails(results);
        if (results && results.ResponseCode === '00') {
          handleShowOtp();
          setFormValues({
            bvn: results.BVN,
            firstName: results.FirstName,
            secondName: results.MiddleName,
            lastName: results.LastName,
            dobirth: results.DateOfBirth,
            phone: results.PhoneNumber,
            email: results.Email,
            address: results.ResidentialAddress,
            landmark: results.StateOfResidence,
            state: results.StateOfResidence,
            gender: results.Gender,
            imgBvn: results.Base64Image,
          });
          // tokenGen();
        } else {
          // handleShowInvalid();
          message.error(
            "Oops! There's a mismatch with your BVN information, please check and enter the correct details",
            10
          );
        }
        let payload2 = {
          mobile: results.PhoneNumber,
          email: emailVal || results?.Email,
          clientID: '52',
        };
        setBvnLoading(false);
        const otpData = encrypt2(payload2);
        authOApi
          .post(`${appUrls.GENERATE_OTP}`, { data: otpData })
          .then((response) => {
            const resultss = decryptHandler2(response.data);
            setMob(resultss.PhoneNumber);
          })
          .catch((error) => {
            message.error('Unable to generate OTP, Please try again later', 10);
          });
      })
      .catch((error) => {
        setBvnLoading(false);
        message.error('Unable to verify BVN, Please try again later', 10);
      });
  };

  //validateOTP
  const validateOTP = () => {
    setOtpLoading(true);
    let payload = {
      mobile: mob,
      otp: otp,
    };
    const valOtp = encrypt2(payload);
    authOApi
      .post(`${appUrls.VALIDATE_OTP}`, { data: valOtp })
      .then(async (response) => {
        const results = await decryptHandler2(response.data);
        if (results && results.ResponseCode === '1') {
          handleCloseOtp();
          message.success('Great! Your BVN verification was successful!');
          const savedData = JSON.parse(localStorage.getItem('saveddata'));
          if (savedData.Data.length !== 0) {
            handleShowPendingModal();
          } else {
            // if user is creating application for the first time
            setCurrent(current + 1);
          }
          // setCurrent(current + 1);
          setOtpLoading(false);
          setBvnLoading(false);
        } else {
          handleShowInvalidOTP();
          setOtpLoading(false);
        }
      })
      .catch((error) => {
        setOtpLoading(false);
        message.error('Unable to validate OTP, please try again later', 10);
      });
  };

  const validateForm = () => {
    if (current === 0) {
      if (!formValues.bvn) {
        message.error(
          "Oops!!! You're missing something. Please provide your BVN"
        );
        return;
      }
      if (!dob) {
        message.error(
          "Oops!!! You're missing something. Please provide your Date of birth"
        );
        return;
      }
      validate();
    }
  };

  const validateFormTwo = () => {
    if (!formValues.firstName) {
      message.error(
        "Oops!!! You're missing something. Please input your first name"
      );
      return;
    }
    if (!formValues.lastName) {
      message.error(
        "Oops!!! You're missing something. Please input your last name"
      );
      return;
    }
    if (!formValues.dobirth) {
      message.error(
        "Oops!!! You're missing something. Please input your date of birth"
      );
      return;
    }
    if (!formValues.email) {
      message.error(
        "Oops!!! You're missing something. Please provide your Email to proceed"
      );
      return;
    }
    if (!EmailValidator.validate(formValues.email)) {
      message.error('This email is not valid, please update and try again ');
      return;
    }
    if (!address) {
      message.error(
        "Oops!!! You're missing something. Please input your address"
      );
      return;
    }
    if (!formValues.landmark) {
      message.error(
        "Oops!!! You're missing something. Please input your landmark"
      );
      return;
    }
    if (!formValues.state) {
      message.error(
        "Oops!!! You're missing something. Please input your state of residence"
      );
      return;
    }
    if (!formValues.nok) {
      message.error(
        "Oops!!! You're missing something. Please input your Next of kin"
      );
      return;
    }
    if (!identificationType) {
      message.error(
        "Oops!!! You're missing something. Please input your ID type"
      );
      return;
    }
    if (identificationType === 'National ID') {
      if (!formValues.idCardNo) {
        message.error(
          "Oops!!! You're missing something. Please input your ID number"
        );
        return;
      }
      // if (!formValues.issueDate) {
      //   message.error(
      //     "Oops!!! You're missing something. Please input your Issue date"
      //   );
      //   return;
      // }
    }
    if (identificationType === 'Permanent Voters Card') {
      if (!formValues.idCardNo) {
        message.error(
          "Oops!!! You're missing something. Please input your ID number"
        );
        return;
      }
      // if (!formValues.issueDate) {
      //   message.error(
      //     "Oops!!! You're missing something. Please input your Issue date"
      //   );
      //   return;
      // }
    }
    if (identificationType === 'Drivers License') {
      if (!formValues.idCardNo) {
        message.error(
          "Oops!!! You're missing something. Please input your ID number"
        );
        return;
      }
      if (!formValues.issueDate) {
        message.error(
          "Oops!!! You're missing something. Please input your Issue date"
        );
        return;
      }
      if (!formValues.expiryDate) {
        message.error(
          "Oops!!! You're missing something. Please input your Expiry date"
        );
        return;
      }
    }
    if (identificationType === 'International Passport') {
      if (!formValues.idCardNo) {
        message.error(
          "Oops!!! You're missing something. Please input your ID number"
        );
        return;
      }
      if (!formValues.issueDate) {
        message.error(
          "Oops!!! You're missing something. Please input your Issue date"
        );
        return;
      }
      if (!formValues.expiryDate) {
        message.error(
          "Oops!!! You're missing something. Please input your Expiry date"
        );
        return;
      }
    }
    saveProcess();
    verifyy();
    // next();
  };

  const validateFormThree = () => {
    if (!signatureUpload) {
      message.error(
        "Oops!!! You're missing something. Please upload your signature"
      );
      return;
    }
    if (!hpvImage && uploadPic === '') {
      message.error(
        "Oops!!! You're missing something. Please take a upload or take live picture"
      );
      return;
    }
    if (!frontPage) {
      message.error(
        "Oops!!! You're missing something. Please select your ID front page"
      );
      return;
    }
    if (!formValues.branch) {
      message.error(
        "Oops!!! You're missing something. Please select your preferred branch to proceed"
      );
      return;
    }
    openAccount();
  };

  const confirmCompleted = () => {
    handleCloseCompleted();
    setCurrent(0);
  };

  const dateTime = new Date();

  const uploadToAzure = async () => {
    setLoading(true);

    if (!hpvImage) {
      // const imageArr = signatureURL.split(",");
      // const imageArr2 = uploadPicURL.split(",");
      // const imageArr3 = frontPageURL.split(",");

      // const imageSplit = imageArr[1];
      // const imageSplit2 = imageArr2[1];
      // const imageSplit3 = imageArr3[1];

      const result = {
        imgSent: '',
        signatureSent: '',
        bvnImgSent: '',
        frontIDSent: '',
        success: false,
      };
      let payload1 = new FormData();
      payload1.append('file', signatureURL);
      // let payload1 = {
      //   file: imageSplit,
      //   fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/signature`,
      //   extension: signatureExtension,
      // };
      const data1 = encrypt2(payload1);

      // let payload2 = {
      //   file: imageSplit2,
      //   fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-uploadedImage`,
      //   extension: uploadedImgExtension,
      // };
      let payload2 = new FormData();
      payload2.append('file', uploadPicURL);
      const data2 = encrypt2(payload2);

      // let payload3 = {
      //   file: imageSplit3,
      //   fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-ID`,
      //   extension: frontPageExtension,
      // };
      let payload3 = new FormData();
      payload3.append('file', frontPageURL);
      const data3 = encrypt2(payload3);

      let payload4 = {
        file: formValues.imgBvn,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-bvnImage`,
        extension: '.png',
      };
      const data4 = encrypt2(payload4);

      await authOApi
        .post(`${appUrls.UPLOAD_DOC}`, payload1)
        .then(async (response) => {
          const res1 = response.data;
          result.signatureSent = res1;
          await authOApi
            .post(`${appUrls.UPLOAD_DOC}`, payload2)
            .then(async (response) => {
              const res2 = response.data;
              result.imgSent = res2;
              await authOApi
                .post(`${appUrls.UPLOAD_DOC}`, payload3)
                .then(async (response) => {
                  const res3 = response.data;
                  result.bvnImgSent = res3;
                  await authOApi
                    .post(`${appUrls.UPLOAD_DOC_V2}`, payload4)
                    .then(async (response) => {
                      const res4 = response.data;
                      result.frontIDSent = await res4;
                    })
                    .catch((error) => {
                      setLoading(false);
                      message.error('Oops! Error Processing Documents', 10);
                    });
                });
            });
          result.success = true;
        })
        .catch((error) => {
          setLoading(false);
          message.error('Oops! Error Processing Documents', 10);
          result.success = false;
        });
      return result;
    } else {
      // const imageArr = signatureURL.split(",");
      const imageArr2 = hpvImage.split(',');
      // const imageArr3 = frontPageURL.split(",");

      // const imageSplit = imageArr[1];
      const imageSplit2 = imageArr2[1];
      // const imageSplit3 = imageArr3[1];

      const result = {
        imgSent: '',
        signatureSent: '',
        bvnImgSent: '',
        frontIDSent: '',
        success: false,
      };

      // let payload1 = {
      //   file: imageSplit,
      //   fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/signature`,
      //   extension: signatureExtension,
      // };
      let payload1 = new FormData();
      payload1.append('file', signatureURL);
      const data1 = encrypt2(payload1);

      let payload2 = {
        file: imageSplit2,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-uploadedImage`,
        extension: '.jpeg',
      };
      const data2 = encrypt2(payload2);

      // let payload3 = {
      //   file: imageSplit3,
      //   fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-ID`,
      //   extension: frontPageExtension,
      // };
      let payload3 = new FormData();
      payload3.append('file', frontPageURL);
      const data3 = encrypt2(payload3);

      let payload4 = {
        file: formValues.imgBvn,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-bvnImage`,
        extension: '.png',
      };
      const data4 = encrypt2(payload4);

      await authOApi
        .post(`${appUrls.UPLOAD_DOC}`, payload1)
        .then(async (response) => {
          const res1 = response.data;
          result.signatureSent = res1;
          await authOApi
            .post(`${appUrls.UPLOAD_DOC_V2}`, payload2)
            .then(async (response) => {
              const res2 = response.data;
              result.imgSent = res2;
              await authOApi
                .post(`${appUrls.UPLOAD_DOC}`, payload3)
                .then(async (response) => {
                  const res3 = response.data;
                  result.bvnImgSent = res3;
                  await authOApi
                    .post(`${appUrls.UPLOAD_DOC_V2}`, payload4)
                    .then(async (response) => {
                      const res4 = response.data;
                      result.frontIDSent = await res4;
                    })
                    .catch((error) => {
                      setLoading(false);
                      message.error('Oops! Error Processing Documents', 10);
                    });
                });
            });
          result.success = true;
        })
        .catch((error) => {
          result.success = false;
          setLoading(false);
          message.error('Oops! Error Processing Documents', 10);
        });
      return result;
    }
  };

  // function dataURItoBlob(inputURI) {
  //   let binaryVal;
  //   const inputMIME = inputURI.split(",")[0].split(":")[1].split(";")[0];
  //   if (inputURI.split(",")[0].includes("base64"))
  //     binaryVal = atob(inputURI.split(",")[1]);
  //   else binaryVal = unescape(inputURI.split(",")[1]);
  //   const blobArray = [];
  //   for (let index = 0; index < binaryVal.length; index++) {
  //     blobArray.push(binaryVal.charCodeAt(index));
  //   }
  //   return new Blob([blobArray], {
  //     type: inputMIME,
  //   });
  // }

  // const tokenGen = () => {
  //   setLoading(true);
  //   let bvn = "55555555555";
  //   authOApi.post(`${apiUrl3}/nibss/token`, { data: bvn }).then((response) => {
  //     let encRes = encrypt2(response.data.Token);
  //     setToken(encRes);
  //   });
  // };

  // const nface = () => {
  //   setLoading(true);
  //   let payload = {
  //     bvn: 55555555555,
  //     device: 3,
  //     image: dataURItoBlob(imgSrc),
  //     jwt: token,
  //   };
  //   apiAccount
  //     .post(`${apiUrl3}/nibss/verify`, { data: payload })
  //     .then((response) => {
  //       let encRes = encrypt2(response.data);
  //       if (encRes.status !== 1) {
  //         addRestr();
  //       };
  //     });
  // };

  // const addRestr = () => {
  //   setLoading(true);
  //   let payload = {
  //     branchCode: formValues.branch,
  //     accountNumber: accountInfo,
  //     accontType: "KIA.KIA.21",
  //   };
  //   apiAccount
  //     .post(`${apiUrl3}/nibss/add-restriction`, { data: payload })
  //     .then((response) => {
  //       let encRes = encrypt2(response.data);
  //       if (encRes.restrictionResponse.responseCode === 1) {
  //         customerCare();
  //       }
  //     });
  // };

  // const customerCare = () => {
  //   setLoading(true);
  //   let payload = {
  //     accountNumber: accountInfo,
  //     phoneNumber: formValues.phone,
  //   };
  //   apiAccount
  //     .post(`${apiUrl3}/nibss/email-customer-care`, { data: payload })
  //     .then((response) => {
  //       let encRes = encrypt2(response.data);
  //       if (encRes === 1) {
  //       }
  //     });
  // };

  // const simSwap = () => {
  //   setLoading(true);
  //   let payload = {
  //     phoneNumber: formValues.phone,
  //   };
  //   let enc = encrypt2(payload);
  //   apiAccount
  //     .post(`${apiUrl3}/nibss/sim-swap-status`, { data: enc })
  //     .then((response) => {
  //       let decRes = decryptHandler2(response.data);
  //       if (decRes.SimSwapMode === "SWAPPED") {
  //         addRestr();
  //         customerCare();
  //         setLoading(false);
  //       }
  //     });
  // };

  const verifyy = () => {
    if (identificationType === 'International Passport') {
      youVerifyInternational();
    }

    if (identificationType === 'Permanent Voters Card') {
      youVerifyPvc();
    }

    if (identificationType === 'National ID') {
      youVerifyNationalID();
    }

    if (identificationType === 'Drivers License') {
      youVerifyDriversLic();
    }
  };

  const youVerifyInternational = () => {
    setLoadingID(true);
    let payloadd = {
      id: formValues.idCardNo,
      lastName: formValues.lastName,
      isSubjectConsent: true,
      validations: {
        data: {
          firstName: formValues.firstName,
          dateOfBirth: moment(formValues.dobirth).format('YYYY-MM-DD'),
        },
      },
    };
    authOApi
      .post(`${appUrls.ADD_INTL_PASSPORT}`, payloadd)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == '200') {
          next();
          setLoadingID(false);
          setIsVerified(true);
        } else {
          next();
          setLoadingID(false);
          setIsVerified(false);
        }
      })
      .catch((error) => {
        setLoadingID(false);
        setIsVerified(false);
      });
  };

  const youVerifyNationalID = () => {
    let payload = {
      id: formValues.idCardNo,
      isSubjectConsent: true,
    };
    //  
    authOApi
      .post(`${appUrls.VERIFY_NIN}`, payload)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == '200') {
          next();
          setLoadingID(false);
          setIsVerified(true);

        } else {
          next();
          setLoadingID(false);
          setIsVerified(false);
        }
      })
      .catch((error) => {
        next();
        setLoadingID(false);
        setIsVerified(false);
      });
  };

  const youVerifyPvc = () => {
    setLoadingID(true);
    let payload = {
      id: formValues.idCardNo,
      isSubjectConsent: true,
    };
    //  
    authOApi
      .post(`${appUrls.VERIFY_PVC}`, payload)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == '200') {
          next();
          setLoadingID(false);
          setIsVerified(true);

        } else {
          next();
          setLoadingID(false);
          setIsVerified(false);
        }
      })
      .catch((error) => {
        setLoadingID(false);
        setIsVerified(false);
        next();
      });
  };

  const youVerifyDriversLic = () => {
    setLoadingID(true);
    let payload = {
      id: formValues.idCardNo,
      isSubjectConsent: true,
    };
    authOApi
      .post(`${appUrls.VERIFY_DRIVER_LICENSE}`, payload)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == '200') {
          next();
          setLoadingID(false);
          setIsVerified(true);

        } else {
          next();
          setLoadingID(false);
          setIsVerified(false);
        }
      })
      .catch((error) => {
        next();
        setIsVerified(false);
      });
  };

  const openAccount = async () => {
    setLoading(true);
    const results = await uploadToAzure();

    if (results.success && accountCount === 0) {
      let payload = {
        BVN: formValues.bvn,
        DateOfBirth: formValues.dobirth,
        Email: formValues.email,
        PhoneNumber: formValues.phone,
        FirstName: formValues.firstName,
        LastName: formValues.lastName,
        ProductCode: 'KIA.KIA.21',
        PreferredName: formValues.firstName,
        ReferralCode: formValues.refCode,
        Branch: formValues.branch,
        Currency: 'NGN',
        Address: address,
        Sector: '4200',
        Industry: '4202',
        Nationality: 'NG',
        Gender: formValues.gender,
        ClientID: '52',
        StateOfResidence: formValues.state,
        Dao: null,
        IsVerified: isVerified && valid,
        isRestriction: isRestriction,
      };

      const encPayload = encrypt2(payload);
      await authOApi
        .post(`${appUrls.OPEN_ACCOUNT}`, { data: encPayload })
        .then(async (response) => {
          const res = decryptHandler2(response.data);

          if (res.ResponseCode === '00') {

            setAccountInfo(res.AccountDetails[0].AccountNumber);
            let payload = {
              accountNumber: res.AccountDetails[0].AccountNumber,
              folio: 'Digital Onboarding',
              source: 'Digital Onboarding',
              photoMandateBase64: results.imgSent,
              photoIDBase64: results.imgSent,
              signatureMandateBase64: results.signatureSent,
              otherBase64: results.frontIDSent,
              meansOfIDBase64: results.frontIDSent,
              bvnImageURL: results.bvnImgSent,
              idtype: identificationType,
              idno: formValues.idCardNo,
              issuedate: formValues.issueDate,
              isexpirydate: formValues.expiryDate,
            };
            const encDocs = encrypt2(payload);
            await authOApi
              .post(`${appUrls.UPLOAD_DOC_ONLINEACCOUNT}`, {
                data: encDocs,
              })
              .then((response) => {
                const resp = decryptHandler2(response.data);
                message.success(resp.ResponseDescription);
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                message.error(
                  'Error processing document, please try again later',
                  10
                );
              });
            handleShowCompleted();

            let payload1 = {
              accountNumber: res.AccountDetails[0].AccountNumber,
              phoneNumber: formValues.phone,
            };
            let enc = encrypt2(payload1);
            authOApi
              .post(`${appUrls.SIM_SWAP_STATUS}`, { data: enc })
              .then((response) => {
                let decRes = decryptHandler2(response.data);
                if (decRes.SimSwapMode === 'SWAPPED') {
                  let payload = {
                    branchCode: formValues.branch,
                    accountNumber: res.AccountDetails[0].AccountNumber,
                    accontType: 'KIA.KIA.21',
                  };
                  const encRestr = encrypt2(payload);
                  authOApi
                    .post(`${appUrls.ADD_RESTRICTION}`, {
                      data: encRestr,
                    })
                    .then((response) => {
                      let encRes = decryptHandler2(response.data);
                      if (encRes.RestrictionResponse.ResponseCode === '1') {
                        customerCare();
                      }
                    });
                }
              });

            const customerCare = () => {
              let payload = {
                accountNumber: res.AccountDetails[0].AccountNumber,
                phoneNumber: formValues.phone,
                response1: 'SIM SWAPPED',
                response2: '',
              };
              const encRestr = encrypt2(payload);
              authOApi
                .post(`${appUrls.EMAIL_CUSTOMER_CARE}`, {
                  data: encRestr,
                })
                .then((response) => {
                  let encRes = encrypt2(response.data);
                  if (encRes === true) {
                    message.success('Message sent successfully');
                  }
                });
            };
          } else {
            message.error(res.ResponseDescription);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          message.error('Unable to Open account, please try again later', 10);
        });
    }
    if (results.success && accountCount !== 0) {
      let payload = {
        FirstName: formValues.firstName,
        Email: formValues.email,
        SurName: formValues.lastName,
        MiddleName: formValues.secondName,
        ProductCode: 'KIA.KIA.21',
        CurrencyCode: 'NGN',
        Branch: formValues.branch,
        AppId: '1',
        State: formValues.state,
        Bvn: formValues.bvn,
        DateOfBirth: formValues.dobirth,
        PhoneNumber: formValues.phone,
        Address: address,
        Sector: '4200',
        Industry: '4202',
        Nationality: 'NG',
        ClientID: '52',
        Mode: '1',
        ChannelId: '1',
        CustomerID: customerNumber,
        Residence: formValues.state,
        ShortTitle: '',
        ChildGender: '',
        PreferredName: formValues.firstName,
        Dao: formValues.dao,
        IsVerified: isVerified && valid,
        isRestriction: isRestriction,
      };
      const payloadEnc = encrypt2(payload);
      await authOApi
        .post(`${appUrls.CREATE_ACCT_INAPP}`, { data: payloadEnc })
        .then(async (response) => {
          const res = decryptHandler2(response.data);

          if (res.responseCode === '00') {
            setAccountInfo(res.nuban);
            handleShowCompleted();

            let payload1 = {
              phoneNumber: formValues.phone,
            };
            let enc = encrypt2(payload1);
            authOApi
              .post(`${appUrls.SIM_SWAP_STATUS}`, { data: enc })
              .then((response) => {
                let decRes = decryptHandler2(response.data);
                if (decRes.SimSwapMode === 'SWAPPED') {
                  let payload = {
                    branchCode: formValues.branch,
                    accountNumber: res.nuban,
                    accontType: 'KIA.KIA.21',
                  };
                  const encRestr = encrypt2(payload);
                  authOApi
                    .post(`${appUrls.ADD_RESTRICTION}`, {
                      data: encRestr,
                    })
                    .then((response) => {
                      let encRes = decryptHandler2(response.data);
                      if (encRes.restrictionResponse.responseCode === 1) {
                        customerCare();
                      }
                    });
                }
              });

            const customerCare = () => {
              let payload = {
                accountNumber: res.nuban,
                phoneNumber: formValues.phone,
              };
              authOApi
                .post(`${appUrls.EMAIL_CUSTOMER_CARE}`, {
                  data: payload,
                })
                .then((response) => {
                  let encRes = encrypt2(response.data);
                  if (encRes === 1) {
                    message.success('Message sent successfully');
                  }
                });
            };

            let payload = {
              accountNumber: res.nuban,
              folio: 'Digital Onboarding',
              source: 'Digital Onboarding',
              photoMandateBase64: results.imgSent,
              photoIDBase64: results.imgSent,
              signatureMandateBase64: results.signatureSent,
              otherBase64: results.frontIDSent,
              meansOfIDBase64: results.frontIDSent,
              bvnImageURL: results.bvnImgSent,
              idtype: identificationType,
              idno: formValues.idCardNo,
              issuedate: formValues.issueDate,
              isexpirydate: formValues.expiryDate,
            };
            const docsEnc = encrypt2(payload);
            await authOApi
              .post(`${appUrls.UPLOAD_DOC_ONLINEACCOUNT}`, {
                data: docsEnc,
              })
              .then((response) => {
                const res = decryptHandler2(response.data);

                message.success(res.responseDescription);
                addRestrictions();
              })
              .catch((error) => {
                message.error(
                  'Error processing document, please try again later',
                  10
                );
              });
          } else {
            message.error(res.responseText);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          message.error(
            'Unable to create account right now, please try again later',
            10
          );
        });
    }
  };

  const addRestrictions = () => {
    let restrictionPayload = {
      addRestriction: {
        branchcode: formValues.branch,
        account: accNum,
        accountsType: 'ACCOUNTS',
        restriction_code: '25',
      },
    };
    authOApi
      .post(`${appUrls.FIORANO_ADD_RESTRICTION}`, restrictionPayload)
      .then((response) => {
      })
      .catch((error) => {});
  };

  const initializeSdk = (token) => {
    window.HyperSnapSDK.init(token, window.HyperSnapParams.Region.Africa);
    window.HyperSnapSDK.startUserSession();
  };

  const runLiveness = () => {
    const hvFaceConfig = new window.HVFaceConfig();
    hvFaceConfig.setShouldShowInstructionPage(true);
    hvFaceConfig.setLivenessAPIParameters({
      rejectFaceMask: 'yes',
      allowEyesClosed: 'no',
      allowMultipleFaces: 'no',
    });
    customizeFaceCaptureInstructionsScreen(hvFaceConfig);
    window.HVFaceModule.start(hvFaceConfig, livelinessCallback);
  };

  const customizeFaceCaptureInstructionsScreen = (hvFaceConfig) => {
    const { faceTextConfig } = hvFaceConfig;
    faceTextConfig.setFaceInstructionsTitle('Selfie capture Tips');
    // remove Top1, Top2
  };

  const livelinessCallback = (HVError, HVResponse) => {
    if (HVError) {
      const errorCode = HVError.getErrorCode();
      const errorMessage = HVError.getErrorMessage();
      message.error(errorMessage);
    } else {
      const faceImageBase64 = HVResponse.imgBase64;
      setHpvImage(faceImageBase64);
      checkMatch(faceImageBase64);
    }
  };

  const checkMatch = (faceImageBase64) => {
    const imgg = localStorage.getItem('params');
    const hyp = faceImageBase64.split(',');
    const hypSplit = hyp[1];
    let payload = {
      Selfie: hypSplit,
      IdFaceString: imgg,
    };
    authOApi
      .post(`${appUrls.HYPERVERGE_FACE_MATCH}`, payload)
      .then((response) => {
        if (response.data.status === 'failure') {
          message.error('Unable to verify Hyperverge !');
          setValid(false);
          setIsRestriction(true);
        } else {
          setValid(true);
          setIsRestriction(false);
        }
      })
      .catch((error) => {
        message.error(
          'Unable to verify face match, please try again later',
          10
        );
      });
  };

  const startHyperverge = (firstParam) => {
    localStorage.setItem('params', firstParam);
    setIsLoadingHpvSdk(true);
    authOApi
      .get(
        `${appUrls.GET_HYPERVERGE_TOKEN}`
      )
      .then(async (response) => {
        if (response.data.statusCode === '200') {
          const token = response.data.result.token;
          initializeSdk(token);
          runLiveness();
          setIsLoadingHpvSdk(false);
        }
      })
      .catch((err) => {
        setIsLoadingHpvSdk(false);
        message.error('Error,  please try again later', 10);
      });
  };

  const steps = [
    {
      title: 'BVN Validation',
      content: (
        <Bvn
          formValues={formValues}
          handleFormChange={handleFormChange}
          onDateChange={onDateChange}
          dob={dob}
          setDisableBtn={setDisableBtn}
        />
      ),
    },
    {
      title: 'Upload details',
      content: (
        <BvnValidation
          formValues={formValues}
          handleFormChange={handleFormChange}
          bvnDetails={bvnDetails}
          identificationType={identificationType}
          setIdentificationType={setIdentificationType}
          setAccountCount={setAccountCount}
          setAccNum={setAccNum}
          setCustomerNumber={setCustomerNumber}
          onDropss={onDropss}
          idPage={idPage}
          address={address}
          setAddress={setAddress}
          handleSelect={handleSelect}
          fullInfo={fullInfo}
        />
      ),
    },
    {
      title: 'Document upload',
      content: (
        <DocumentUpload
          formValues={formValues}
          handleFormChange={handleFormChange}
          signatureUpload={signatureUpload}
          onDrops={onDrops}
          onCapture={onCapture}
          config={config}
          onError={onError}
          imgSrc={imgSrc}
          retake={retake}
          onDrop={onDrop}
          frontPage={frontPage}
          branchCode={branchCode}
          isClicked={isClicked}
          handler={handler}
          tcHandler={tcHandler}
          isTCClicked={isTCClicked}
          handleShowTC={handleShowTC}
          onDropsss={onDropsss}
          uploadPic={uploadPic}
          hpvImage={hpvImage}
          isLoadingHpvSdk={isLoadingHpvSdk}
          startHyperverge={startHyperverge}
        />
      ),
    },
  ];

  const getBranches = () => {
    authOApi
      .get(
        `${appUrls.GET_STERLING_BRANCHES}`
      )
      .then((response) => {
        const res = decryptHandler2(response.data);
        setBranchCode(res.SterlingBranches);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getBranches();
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <div className="leftDiv stickyDiv"></div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <Header />

          <div class="container-fluid pb-5 mt-5">
            <div class="row mb-5">
              <div class="container mr-3">
                <div className="steps-content mb-5">
                  {steps[current].content}
                </div>
                <div className="text-right"></div>
                <div className="steps-action pb-1 mt-3 text-right">
                  {current === steps.length - 3 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 btn-block w-80"
                      onClick={validateForm}
                      disabled={disableBtn || isLoading}
                    >
                      {bvnLoading || isLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        'Validate'
                      )}
                    </button>
                  )}
                  {current > 0 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger px-5 mr-2 mb-3"
                      onClick={prev}
                    >
                      Previous
                    </button>
                  )}
                  {current < steps.length - 1 && current > 0 && (
                    <button
                      type="submit"
                      className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-3"
                      onClick={validateFormTwo}
                      // type="submit"
                    >
                      {/* {loadingID && "Validating ..."}
                      {!loadingID && "Next"} */}
                      Next
                    </button>
                  )}
                  {current === steps.length - 1 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                      onClick={validateFormThree}
                      // disabled={loading}
                      disabled={loading === true || isTCClicked === false}
                    >
                      {loading ? 'Completing...' : 'Complete'}
                    </button>
                  )}
                </div>

                {current === 0 && (
                  <div>
                    <div class="my-4 text-centers text-dark">
                      <h3 class="mb-0 sec-headers">Kiakia (Tier 2)</h3>
                    </div>
                    <div className="row pry-grey-clr mb-4 w-80">
                      <div className="col-md-6 ftsz-12">
                        <h6 className="ftsz-12 ftwt-500">Features</h6>
                        <ul className="custom-req-marker pl-3">
                          <li>Maximum single deposit of N100,000.</li>
                          <li>
                            Maximum cumulative balance/threshold of N500,000.
                          </li>
                          <li>Instant account opening.</li>
                          <li>Email/SMS alert.</li>
                          <li>Online statements</li>
                          <li>One account signatory.</li>
                          {/* <li>Mobile banking limit of N10,000</li> */}
                          <li>Verve card only.</li>
                          <li>No International Funds Transfer.</li>
                        </ul>
                      </div>
                      <div className="col-md-6 ftsz-12">
                        <h6 className="ftsz-12 ftwt-500">Requirements</h6>
                        <ul className="custom-req-marker pl-3">
                          <li>
                            Basic customer information. (i.e., Name, Place and
                            date of birth, Gender, Address, Telephone number)
                          </li>
                          <li>BVN.</li>
                          <li>Passport photograph.</li>
                          <li>Signature.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="pale-red-part-bg position-fixed"></div>
      <div className="">
        <Header />
        <div className="d-flex justify-content-between px-2">
          <div></div>
          <div className="w-50">
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
        </div>
        <div className="container-fluid pb-5 mt-5">
          <div class="row mb-5">
            <div class="col-xl-7 col-lg-9 col-md-10 mx-auto">
              <div class="container">
                <div class="app-details-form-wrapper pry-grey-clr bg-white px-3 px-lg-5 py-5">
                  <div className="steps-content">{steps[current].content}</div>
                  <div className="text-right"></div>
                  <div className="steps-action pb-5 mt-3 text-right">
                    {current === steps.length - 3 && (
                      <button
                        type="button"
                        className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                        onClick={validateForm}
                        disabled={disableBtn || isLoading}
                      >
                        {bvnLoading ? "Validating ..." : "Validate"}
                      </button>
                    )}
                    {current > 0 && (
                      <button
                        type="button"
                        className="btn btn-outline-danger px-5 mr-2 mb-3"
                        onClick={prev}
                      >
                        Previous
                      </button>
                    )}
                    {current < steps.length - 1 && current > 0 && (
                      <button
                        type="submit"
                        className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-3"
                        onClick={validateFormTwo}
                        // type="submit"
                      >
                        Next
                      </button>
                    )}
                    {current === steps.length - 1 && (
                      <button
                        type="button"
                        className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                        onClick={validateFormThree}
                        // disabled={loading}
                        disabled={loading === true || isTCClicked === false}
                      >
                        {loading ? "Completing..." : "Complete"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <OTP
        showOtp={showOtp}
        handleCloseOtp={handleCloseOtp}
        formValues={formValues}
        handleFormChange={handleFormChange}
        validateOTP={validateOTP}
        otpLoading={otpLoading}
        verifyBvn={verifyBvn}
        otp={otp}
        setOtp={setOtp}
      />
      {/* <CompletedForm
        showCompleted={showCompleted}
        handleCloseCompleted={handleCloseCompleted}
        confirmCompleted={confirmCompleted}
        accountInfo={accountInfo}
        bvnDetails={bvnDetails}
        accNum={accNum}
      /> */}
      <NewCompletedForm
        showCompleted={showCompleted}
        handleCloseCompleted={handleCloseCompleted}
        confirmCompleted={confirmCompleted}
        accountInfo={accountInfo}
        bvnDetails={bvnDetails}
        accNum={accNum}
        imgSrc={imgSrc}
      />
      <InvalidBVN
        showInvalid={showInvalid}
        handleCloseInvalid={handleCloseInvalid}
      />

      <InvalidOTP
        showInvalidOTP={showInvalidOTP}
        handleCloseInvalidOTP={handleCloseInvalidOTP}
      />
      <ExistingOTP
        showExistingOTP={showExistingOTP}
        handleCloseExistingOTP={handleCloseExistingOTP}
      />

      <PendingModal
        showModal={isOpen}
        handleClosePendingModal={handleClosePendingModal}
        setNext={next}
        getSaveProcess={getSaveProcess}
      />

      <TermsAndConditions showTC={showTC} handleCloseTC={handleCloseTC} />
    </div>
  );
};

export default KiakiaTwo;
