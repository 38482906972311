import React, { useEffect } from "react";
import Warning from "../../../../../../Images/warning-icon.svg";
import PlacesAutocomplete from "react-places-autocomplete";

const UpdateDetails = ({
  bvnDetails,
  formValues,
  handleFormChange,
  setAccNum,
  setCustomerNumber,
  address,
  setAddress,
  handleSelect,
  fullInfo
}) => {

  useEffect(() => {
    fullInfo();
  }, []);

  return (
    <div>
      <form action="">
        <h5 className="my-4 sec-header text-body text-center">
          Update Details
        </h5>
        <p className="ftsz-regular text-body text-center">
          Hello <span className="ftwt-600">{bvnDetails.FirstName}</span>,
          Welcome to Sterling bank
        </p>
        <p className="pale-red-tone-bg pry-red-clr ftwt-600 ftsz-12 mb-0 px-3 py-2 mb-3 rounded-lg">
          <img src={Warning} className="mr-3" alt="" />
          Update your address and email if it has changed
        </p>
        <div className="form-row align-items-md-end">
          <div className="col-md-4 mb-3">
            {/* <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
              Photo
            </label> */}
            <div className="image-placeholder imgHolder">
              <img
                src={`data:image/png;base64,${bvnDetails.Base64Image}`}
                alt=""
              />
            </div>
          </div>
          <div className="col-md-8 mb-3">
            <div className="form-row">
              <div className="col-12 col-sm-6 mb-3">
                <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                  First name
                </label>
                <input
                  type="text"
                  className="form-control pry-input-border bg-white"
                  value={formValues.firstName}
                  onChange={handleFormChange("firstName")}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                  Middle name
                </label>
                <input
                  type="text"
                  className="form-control pry-input-border bg-white"
                  value={formValues.secondName}
                  onChange={handleFormChange("secondName")}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                  Last name
                </label>
                <input
                  type="text"
                  className="form-control pry-input-border bg-white"
                  value={formValues.lastName}
                  onChange={handleFormChange("lastName")}
                  disabled
                />
              </div>
              <div className="col-12 col-sm-6 mb-3">
                <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                  Date of Birth
                </label>
                <input
                  type="text"
                  className="form-control pry-input-border bg-white"
                  value={formValues.dobirth}
                  onChange={handleFormChange("dobirth")}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div className="form-row mb-3">
          <div className="col-12 col-md-6 mb-3">
            <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
              Phone number
            </label>
            <input
              type="text"
              className="form-control pry-input-border bg-white"
              value={formValues.phone}
              onChange={handleFormChange("phone")}
              disabled
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
              Email address
            </label>
            <input
              type="email"
              className="form-control pry-input-border"
              value={formValues.email}
              onChange={handleFormChange("email")}
            />
          </div>
          <div className="col-12 mb-3">
            <div className="form-row">
              <div className="col-12">
                <h6 className="ftwt-600 ftsz-12">Residential address</h6>
              </div>
              <div className="col-12 col-md-12 mb-3">
                <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                  House address
                </label>
                {/* <input
                  type="text"
                  className="form-control pry-input-border"
                  value={formValues.address}
                  onChange={handleFormChange("address")}
                /> */}
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div key={suggestions.description}>
                      <input
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className:
                            "form-control pry-input-border location-search-input",
                        })}
                        // value={formValues.address}
                        // onChange={handleFormChange("address")}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <div className="col-12 mb-3">
                <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                  Nearest bus-stop/landmark
                </label>
                <input
                  type="text"
                  className="form-control pry-input-border"
                  value={formValues.landmark}
                  onChange={handleFormChange("landmark")}
                />
              </div>

              <div className="col-12 mb-3">
                <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                  Area/State
                </label>
                <input
                  type="text"
                  className="form-control pry-input-border"
                  value={formValues.state}
                  onChange={handleFormChange("state")}
                />
              </div>
            </div>
          </div>
          <div className="col-12 mb-3">
            <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
              DAO Code(Referral ID) - *Optional
            </label>
            <input
              type="text"
              className="form-control pry-input-border"
              placeholder="Enter referral code"
              value={formValues.refCode}
              onChange={handleFormChange("refCode")}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UpdateDetails;
