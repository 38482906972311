import React from "react";
import { Link } from "react-router-dom";
import "./index.css";
import SterlingBall from "../../../../Images/ball.png";

const Header = () => {
  return (
    <div>
      <div className="d-flex justify-content-between px-5 mt-4 mx-3">
        <div></div>
        <div className="ballDiv">
          <Link to="/">
            <img
              src={SterlingBall}
              class="img-fluid"
              alt="Sterling Bank Logo"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
