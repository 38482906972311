import React, { useState } from "react";
import { message, Steps } from "antd";
import BvnVal from "./bvn";
import BvnDetails from "./details";
import { apiUrl } from "../../../../../../../../../redux/actions/endpoint";
import { decryptHandler2 } from "../../../../../../../../../dec";
import { encrypt2 } from "../../../../../../../../../encr";
import axios from "axios";
import moment from "moment";
import { apiAccount } from "../../../../../../../../../service/apiAccount";
import { appUrls } from "../../../../../../../../../service/urls";
import { authOApi } from "../../../../../../../../../service/authOApi";

const { Step } = Steps;

const DirectorsStep = ({
  formValues,
  handleFormChanges,
  setFormValues,
  onClose,
  formVal,
  handleFormChange,
  formValuess,
  setFormValuess,
  handleFormChangess,
  setDirList,
  directorsList,
  dirList,
  tin
}) => {
  //stepper
  const [current, setCurrent] = useState(0);
  const prev = () => {
    setCurrent(current - 1);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const [dob, setDob] = useState(
    moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.sssZ")
  );

  const onDateChange = (event) => {
    setDob(event.target.value);
  };

  const [loading, setLoading] = useState(false);

  const [bvnDetails, setBvnDetails] = useState({});

  // const isDirectorExisting = () => {
  //   setLoading(true);
  //   directorsList();
  //   const checkSignatory = dirList;
  //   if (checkSignatory) {
  //     const checkExistence = checkSignatory.some(
  //       (director) => director.Bvn === formValues.bvn
  //     );
  //     if (checkExistence === true) {
  //       message.error(
  //         "Oops!!! You already have a Director with the provided BVN. Kindly provide another."
  //       );
  //       setLoading(false);
  //       return;
  //     } else {
  //       verifyBvn();
  //     }
  //   } else {
  //     verifyBvn();
  //   }
  // };

  const validate = () => {
    verifyBvn();
  };

  const verifyBvn = () => {
    setLoading(true);
    const payload = {
      bvn: formValuess.bvn_two,
      dateOfBirth: dob,
    };
    const enc = encrypt2(payload);
   
    authOApi
      .post(`${appUrls.VERIFY_BVN}`, { data: enc })
      .then((response) => {
        const res = decryptHandler2(response.data);

        setBvnDetails(res);
        if (res.ResponseCode === "00") {
          setFormValuess({
            bvn: res.BVN,
            firstName: res.FirstName,
            secondName: res.MiddleName,
            lastName: res.LastName,
            dobirth: res.DateOfBirth,
            phone: res.PhoneNumber,
            email: res.Email,
            address: res.ResidentialAddress,
            landmark: res.StateOfResidence,
            state: res.StateOfResidence,
            gender: res.Gender,
            imgBvn: res.Base64Image,
          });
          message.success("BVN validation was successful");
          setCurrent(current + 1);
          setLoading(false);
        } else {
          message.error(res.ResponseDesc);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          "Unable to verify BVN. Please try again later", 10
        )
      });
  };

  const addDirector = async () => {
    setLoading(true);
    let payload = {
      tin: tin,
      firstName: formValuess.firstName,
      lastName: formValuess.lastName,
      phoneNumber: formValuess.phone,
      dateOfBirth: moment(formValuess.dobirth).format(
        "YYYY-MM-DDTHH:mm:ss.sssZ"
      ),
      email: formValuess.email,
      residentialAddress: formValuess.address,
      houseNumber: 0,
      streetName: formValuess.address,
      landMark: formValuess.landmark,
      areaOrState: formValuess.landmark,
      nationality: formValuess.nationality,
      cerPadId: formValuess.cerpac,
      bvn: formValuess.bvn,
    };
    const enc = encrypt2(payload);
   
   authOApi
			.post(`${appUrls.ADD_DIRECTOR}`, { data: enc })
			.then((response) => {
				const res = decryptHandler2(response.data);

				if (res[0].ResponseCode === "200") {
					message.success("Director added successfully!");
					setDirList(res);
					directorsList();
					setLoading(false);
					setCurrent(0);
					onClose();
				} else {
					message.error(res.ResponseDescription);
					setLoading(false);
				}
				directorsList();
			})
			.catch((error) => {
				message.error(error.response.data.responseDescription);
				setCurrent(0);
				onClose();
				setLoading(false);
			});
  };

  const steps = [
    {
      title: "BVN Validation",
      content: (
        <BvnVal
          formValuess={formValuess}
          handleFormChangess={handleFormChangess}
          dob={dob}
          onDateChange={onDateChange}
        />
      ),
    },
    {
      title: "BVN details",
      content: (
        <BvnDetails
          formValuess={formValuess}
          handleFormChangess={handleFormChangess}
          bvnDetails={bvnDetails}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between px-2">
        <div></div>
        <div className="w-50">
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
      </div>
      <div className="container-fluid pb-5 mt-5">
        <div class="row mb-5">
          {/* <div class="col-xl-7 col-lg-9 col-md-10 mx-auto"> */}
          <div class="container">
            <div class="pry-grey-clr bg-white px-3 px-lg-1 py-5">
              <div className="steps-content">{steps[current].content}</div>
              <div className="text-right"></div>
              <div className="steps-action pb-5 mt-3 text-right">
                {current === steps.length - 2 && (
                  <button
                    type="button"
                    className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                    onClick={validate}
                    disabled={loading}
                  >
                    {loading ? "Validating ..." : "Validate"}
                  </button>
                )}
                {current > 0 && (
                  <button
                    type="button"
                    className="btn btn-outline-danger px-5 mr-2 mb-3"
                    onClick={prev}
                  >
                    Previous
                  </button>
                )}
                {current < steps.length - 1 && current > 0 && (
                  <button
                    type="button"
                    className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-3"
                    onClick={next}
                  >
                    Next
                  </button>
                )}
                {current === steps.length - 1 && (
                  <button
                    type="button"
                    className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                    onClick={addDirector}
                    disabled={loading}
                  >
                    {loading ? "Adding Director..." : "Add Director"}
                  </button>
                )}
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
      </div>
    </div>
  );
};

export default DirectorsStep;
