import React from 'react'
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { Spinner } from "react-bootstrap";

function OtpValidation({
    showOtp,
    handleCloseOtp,
    verifyBvn,
    validateOTP,
    handleFormChange,
    formValues,
    loading,
    isLoading,
    disableBtn,
    otp,
    setOtp,
    showCompleted,
    handleCloseCompleted,
    handleShowCompleted,
    handleProceed
}) {




    return (
        <div>
            <Modal
                show={showOtp}
                onHide={handleCloseOtp}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="my-0 text-center text-dark">
                            <h5 className="mb-0 sec-header">Enter OTP</h5>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <p className="mb-4 pry-grey-clr ftsz-regular text-center">
                            An OTP has been sent to the mobile number captured in your BVN. Kindly enter the OTP to proceed.
                        </p>
                        <div className="form-row mb-3">
                            <div className="form-group col-12 col-sm-6 col-md-5 mx-auto ftsz-12">
                                <div className="row justify-content-center form-group">
                                    <OtpInput
                                        value={otp}
                                        onChange={(otp) => setOtp(otp)}
                                        numInputs={6}
                                        className="form-control text-center otp-inputs"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button
                                type="button"
                                className="btn btn-danger pry-red-bg px-5"
                                onClick={validateOTP}
                                disabled={disableBtn || isLoading || loading}
                            >
                                {isLoading || loading ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    "Proceed"
                                )}
                            </button>
                        </div>
                    </form>
                    <p className="ftsz-12 text-center mt-4">
                        Did not get the OTP?
                        <button
                            type="button"
                            className="bare-btn text-underline"
                            onClick={verifyBvn}
                        >
                            Resend OTP
                        </button>
                    </p>
                </Modal.Body>
            </Modal>
            {/* <div class="mb-2 text-centers text-dark">
                <h3 class="mb-0 sec-headers">
                    <span
                        onClick={() => history.goBack()}
                        style={{ cursor: "pointer" }}
                    >
                        <i className="fas fa-long-arrow-alt-left mr-2"></i>
                    </span>
                    BVN Validation
                </h3>
            </div> */}
            {/* <div className="otp-card">
                <div className="card_inside">
                    <form action="">
                        <p className="mb-4 pry-grey-clr ftsz-regular text-center">
                            An OTP has been sent to the mobile number captured in your BVN. Kindly enter the OTP to proceed.
                        </p>
                        <div className="form-row mb-3">
                            <div className="form-group col-12 col-sm-6 col-md-5 mx-auto ftsz-12">
                                <div className="row justify-content-center form-group">
                                    <OtpInput
                                        value={otp}
                                        onChange={(otp) => setOtp(otp)}
                                        numInputs={4}
                                        className="form-control text-center otp-inputs"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="text-center">
                            <button
                                type="button"
                                className="btn btn-danger pry-red-bg px-5"
                                onClick={handleShowCompleted}
                            // disabled={disableBtn || isLoading || loading}
                            >
                                {loading ? (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                ) : (
                                    "Proceed"
                                )}
                            </button>
                        </div>
                    </form>
                    <p className="ftsz-12 text-center mt-4">
                        Did not get the OTP?
                        <button
                            type="button"
                            className="bare-btn text-underline"
                        // onClick={verifyBvn}
                        >
                            Resend OTP
                        </button>
                    </p>

                </div>
            </div> */}

        </div>
    )
}

export default OtpValidation