/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useHistory, useLocation } from "react-router-dom"
import { message } from "antd";
import ImageCapture from "react-image-data-capture";
import Spinner from "react-bootstrap/Spinner";
import Dropzone from "react-dropzone";
import {
    apiUrl,
    apiUrl3
} from "../../../../redux/actions/endpoint";
import axios from "axios";
import { encrypt2 } from "../../../../encr";
import { decryptHandler2 } from "../../../../dec";
import { convertBase64ToBinary } from "../../../../convert";
import SuccessAcct from './components/modal/SuccessAcct';
import RejectedAcct from './components/modal/RejectedAcct';
import SuccessBvn from './components/modal/SuccessBVN';
import Confirm from './components/modal/Confirm';
import RejectedBvn from './components/modal/RejectedBVN';
import { turnUpperCase } from '../../../Dashboard/components/helper'
import { apiAccount } from '../../../../service/apiAccount';
import { appUrls } from '../../../../service/urls';
import { authOApi } from '../../../../service/authOApi';

function LiveCheck({
    liveDetail,
    bvnResults,
    bvnRes,
    customerNumber,
    formValues,
    // showSuccess,
    // handleCloseSuccess,
    supposedBvn,
    // handleShowSuccess,
    showCompleted,
    handleCloseCompleted,
    handleConfirm,
    rejectConfirmation,
}) {
    const history = useHistory();

    const location = useLocation();
    let path = location.pathname.split("-")[1]

    const [loading, setLoading] = useState(false);

    const [uploadPic, setUploadPic] = useState("");
    const [uploadPicURL, setUploadPicURL] = useState("");
    const [hpvImage, setHpvImage] = useState("");
    const [valid, setValid] = useState(false);
    const [isLoadingHpvSdk, setIsLoadingHpvSdk] = useState(false);

    const [showRejected, setShowRejected] = useState(false);
    const handleCloseRejected = () => setShowRejected(false);
    const handleShowRejected = () => {
        setShowRejected(true)
        setTimeout(() => {
            setShowRejected(false);
        }, 180000);
    };

    const [showBvnSuccess, setShowBvnSuccess] = useState(false);
    const handleCloseBvnSuccess = () => setShowBvnSuccess(false);
    const handleShowBvnSuccess = () => {
        setShowBvnSuccess(true)
        sendBvnEmail();
        setLoading(false);
        setTimeout(() => {
            setShowBvnSuccess(false);
        }, 180000);
    };

    const [showSuccess, setShowSuccess] = useState(false);
    const handleCloseSuccess = () => setShowSuccess(false);
    const handleShowSuccess = () => {
        setShowSuccess(true);
        sendEmail();
        setLoading(false);
        setTimeout(() => {
            setShowSuccess(false);
        }, 180000);
    };

    const [showBvnRejected, setShowBvnRejected] = useState(false);
    const handleCloseBvnRejected = () => setShowBvnRejected(false);
    const handleShowBvnRejected = () => setShowBvnRejected(true);


    const imgBvn = liveDetail.Base64Image

    const [imgFile, setImgFile] = useState(null);
    const [livePhotoUpload, setLivePhotoUpload] = useState("");

    const sendEmail = () => {
        setLoading(true)
        let payload = {
            emailAddress: bvnRes.email,
            accountNumber: bvnRes.NUBAN,
            firstName: turnUpperCase(liveDetail.FirstName)
        };
        const enc = encrypt2(payload);
        authOApi
            .post(`${appUrls.FORGOT_ACCT_NUMBER}`, { data: enc })
            .then(async (response) => {
                const res = await decryptHandler2(response.data);
                if (res && res.ResponseCode === "00") {
                    message.success(
                        "Yes!!!. An email has been sent to you."
                    );
                    setLoading(false)
                }
                else {
                    message.error(
                        "Oops!!! Something went wrong"
                    );
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    const sendBvnEmail = () => {
        setLoading(true)
        let payload = {
            emailAddress: bvnResults.BankAccountFullInfo.email,
            bvn: bvnResults.BankAccountFullInfo.BVN,
            firstName: turnUpperCase(liveDetail.FirstName)
        };
        const enc = encrypt2(payload);
        authOApi
            .post(`${appUrls.FORGOT_BVN_NUMBER}`, { data: enc })
            .then(async (response) => {
                const res = await decryptHandler2(response.data);
                if (res && res.ResponseCode === "00") {
                    message.success(
                        "Yes!!!. Your bvn has been sent to your email address."
                    );
                    setLoading(false)
                }
                else {
                    message.error(
                        "Oops!!! Something went wrong"
                    );
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    }


    const initializeSdk = (token) => {
        window.HyperSnapSDK.init(token, window.HyperSnapParams.Region.Africa);
        window.HyperSnapSDK.startUserSession();
    };

    const customizeFaceCaptureInstructionsScreen = (hvFaceConfig) => {
        const { faceTextConfig } = hvFaceConfig;
        faceTextConfig.setFaceInstructionsTitle("Selfie capture Tips");
    };

    const runLiveness = () => {
        const hvFaceConfig = new window.HVFaceConfig();
        hvFaceConfig.setShouldShowInstructionPage(true);
        hvFaceConfig.setLivenessAPIParameters({
            rejectFaceMask: "yes",
            allowEyesClosed: "no",
            allowMultipleFaces: "no",
        });
        customizeFaceCaptureInstructionsScreen(hvFaceConfig);
        window.HVFaceModule.start(hvFaceConfig, livelinessCallback);
    };

    const livelinessCallback = (HVError, HVResponse) => {
        if (HVError) {
            const errorCode = HVError.getErrorCode();
            const errorMessage = HVError.getErrorMessage();
            message.error(errorMessage);
        } else {
            const faceImageBase64 = HVResponse.imgBase64;
            setHpvImage(faceImageBase64);
            checkMatch(faceImageBase64);
        }
    };

    const checkMatch = (faceImageBase64) => {
        setLoading(true)
        const imgg = localStorage.getItem("params");
        const hyp = faceImageBase64.split(",");
        const hypSplit = hyp[1];
        let payload = {
            Selfie: hypSplit,
            IdFaceString: imgg,
        };
        authOApi
            .post(`${appUrls.HYPERVERGE_FACE_MATCH}`, payload)
            .then((response) => {
                if (response.data.status === "failure") {
                    message.error("Unable to verify Hyperverge !");
                    handleShowRejected()
                    setValid(false);
                    setLoading(false)
                } else {
                    setValid(true);
                    if (path === "bvn") {
                        handleShowBvnSuccess()
                        setLoading(false)
                    }
                    if (path === "account") {
                        handleShowSuccess()
                        setLoading(false)
                    }
                    setLoading(false)
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const startHyperverge = (firstParam) => {
        localStorage.setItem("params", firstParam);
        setIsLoadingHpvSdk(true);
        authOApi
            .get(`${appUrls.GET_HYPERVERGE_TOKEN}`
            )
            .then(async (response) => {
                if (response.data.statusCode === "200") {
                    const token = response.data.result.token;
                    initializeSdk(token);
                    runLiveness();
                    setIsLoadingHpvSdk(false);
                }
            })
            .catch((err) => {
                setIsLoadingHpvSdk(false);
            });
    };



    // Use useMemo to avoid unexpected behaviour while rerendering
    const config = useMemo(() => ({ video: true }), []);
    const livePic = new FormData();
    livePic.append("file", livePhotoUpload);



    return (
        <div className="w-80">
            <div class="mb-2 text-centers text-dark">
                <h3 class="mb-0 sec-headers">
                    <span
                        onClick={() => history.goBack(1)}
                        style={{ cursor: "pointer" }}
                    >
                        <i className="fas fa-long-arrow-alt-left mr-2"></i>
                    </span>
                    Liveness Check
                </h3>
            </div>
            <div className="otp-card w-100 mt-5">
                <div className="card_inside">
                    {loading ? (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <h6>Please wait...</h6>
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        </div>
                    ) : (
                            <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
                                Take live picture
                            </h6>
                    )}
                    <div
                        style={{ width: "300px", cursor: "pointer", margin: "0 auto" }}
                        className="bb"
                        onClick={() => {
                            startHyperverge(imgBvn);
                        }}
                    >
                        {hpvImage !== "" && (
                            <img src={hpvImage} alt="avatar" style={{ width: "100%" }} />
                        )}
                        {hpvImage === "" && (
                            <div class="avatar-hpv">
                                <div class="avatar-text-hpv">
                                    {isLoadingHpvSdk ? (
                                        <>
                                            Loading Sdk....{" "}
                                            <Spinner
                                                as="span"
                                                animation="border"
                                                size="sm"
                                                role="status"
                                                aria-hidden="true"
                                            />
                                        </>
                                    ) : (
                                        "Please Click here to Take a Photo"
                                    )
                                    }


                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <SuccessAcct
                showSuccess={showSuccess}
                customerNumber={customerNumber}
                handleCloseSuccess={handleCloseSuccess}
                bvnResults={bvnResults}
                bvnRes={bvnRes}
            />

            <RejectedAcct
                showRejected={showRejected}
                handleCloseRejected={handleCloseRejected}
            />
            <Confirm
                showCompleted={showCompleted}
                handleCloseCompleted={handleCloseCompleted}
                handleConfirm={handleConfirm}
                rejectConfirmation={rejectConfirmation}
            />

            <SuccessBvn
                showBvnSuccess={showBvnSuccess}
                handleCloseSuccess={handleCloseBvnSuccess}
                supposedBvn={supposedBvn}
                bvnResults={bvnResults}
            />

            {/* <RejectedBvn
                showRejected={showRejected}
                handleCloseRejected={handleCloseRejected}
            /> */}
        </div>
    )
}

export default LiveCheck