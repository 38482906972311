const configureToken = (client) => {
  client.interceptors.request.use(
    function (request) {
      const auth =
        localStorage.getItem("auth") &&
        JSON.parse(localStorage.getItem("auth"));
      if (!auth) {
        return request;
      }

      request.headers.Authorization = `Bearer ${auth?.accessToken}`;

      return request;
    },
    function (error) {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  return client;
};

export default configureToken;
