import React, { useState } from 'react'
import Header from './components/Header';
import ImageCapture from "react-image-data-capture";
import LiveCheck from './liveCheck';


function LivenessCheck() {
    return (
        <div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 displayNone">
                    <div className="leftDiv stickyDiv"></div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Header />

                    <div className="container-fluid pb-5 mt-5">
                        <div class="row mb-5">
                            <div class="container">
                                <div className="steps-content mb-5">
                                    <LiveCheck />
                                </div>
                                <div className="text-right"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default LivenessCheck