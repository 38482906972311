import React, { useEffect } from "react";
import Warning from "../../../../../../Images/warning-icon.svg";
import InternationalPassport from "./idType/intPassport";
import NationalId from "./idType/nationalId";
import PermanentVotersCard from "./idType/pvc";
import NigerianDriversLicense from "./idType/nigerianDL";
import { useSelector, useDispatch } from "react-redux";
import { kiakTwoAction } from "../../../../../../redux/actions";
import { apiUrl, test } from "../../../../../../redux/actions/endpoint";
import axios from "axios";
import moment from "moment";
import PlacesAutocomplete from "react-places-autocomplete";
import { encrypt2 } from "../../../../../../encr";
import { decryptHandler2 } from "../../../../../../dec";
import { appUrls } from "../../../../../../service/urls";
// import { apiAccount } from "../../../../../../service/apiAccount";
import { authOApi } from '../../../../../../service/authOApi';

const UploadDocsEx = ({
  bvnInfo,
  formValues,
  handleFormChange,
  bvnDetails,
  identificationType,
  setIdentificationType,
  setAccountCount,
  setAccNum,
  setCustomerNumber,
  bvnValues,
  setLoading,
  setFormValues,
  address,
  setAddress,
  handleSelect,
  // fullInfo,
  getBranches,
}) => {
  const dispatch = useDispatch();
  const { id_type } = useSelector(({ kiakiaTwo }) => kiakiaTwo);

  const switchIntl = () => {
    dispatch(kiakTwoAction.set_id_type("intl"));
  };

  const switchPvc = () => {
    dispatch(kiakTwoAction.set_id_type("pvc"));
  };

  const switchNat = () => {
    dispatch(kiakTwoAction.set_id_type("natID"));
  };

  const switchNdl = () => {
    dispatch(kiakTwoAction.set_id_type("ndl"));
  };

  const getAccountCount = () => {
    const data = encrypt2(bvnValues.bvn);
    authOApi
      .get(`${appUrls.FIORANO_GET_ACCT_COUNTBY_BVN}?Data=${data}`)
      .then((response) => {
        
        const res = decryptHandler2(response.data);
        setAccountCount(res.Count);
      })
      .catch((error) => {
      });
  };

  const fullInfo = () => {
    const data = encrypt2(bvnValues.bvn);
    authOApi
      .get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);
        setAccNum(res.GetAccountFullInfo.BankAccountFullInfo[0].NUBAN);
        
        setCustomerNumber(
          res.GetAccountFullInfo.BankAccountFullInfo[0].CUS_NUM
        );
      })
      .catch((error) => {
      });
  };

  const setData = () => {
    let payload = {
      bvn: bvnInfo.bvn,
      dateOfBirth: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
    };
    //  
    setLoading(true);
    // axios.post(`${apiUrl}/VerifyBVN`, payload).then((response) => {
      authOApi.post(`${test}/VerifyBVN`, payload).then((response) => {
      
      setFormValues({
        bvn: response.data.bvn,
        firstName: response.data.firstName,
        secondName: response.data.middleName,
        lastName: response.data.lastName,
        dobirth: response.data.dateOfBirth,
        phone: response.data.phoneNumber,
        email: response.data.email,
        address: response.data.residentialAddress,
        landmark: response.data.stateOfResidence,
        state: response.data.stateOfResidence,
      });
      setLoading(false);
    });
  };

  useEffect(() => {
    setFormValues({
      bvn: bvnDetails.BVN,
      imgBvn: bvnDetails.Base64Image,
      firstName: bvnDetails.FirstName,
      secondName: bvnDetails.MiddleName,
      lastName: bvnDetails.LastName,
      dobirth: bvnDetails.DateOfBirth,
      phone: bvnDetails.PhoneNumber,
      email: bvnDetails.Email,
      address: bvnDetails.ResidentialAddress,
      landmark: bvnDetails.StateOfResidence,
      state: bvnDetails.StateOfResidence,
    });
    getBranches();
    getAccountCount();
    // setData();
    fullInfo();
  }, []);
  return (
    <div>
      <form action="">
        <h5 class="my-4 sec-header text-body text-center">Update Details</h5>
        <p class="ftsz-regular text-body text-center">
          Hello <span class="ftwt-600">{bvnDetails.FirstName}</span>, Welcome to
          Sterling bank
        </p>
        <p class="pale-red-tone-bg pry-red-clr ftwt-600 ftsz-12 mb-0 px-3 py-2 mb-3 rounded-lg">
          <img src={Warning} class="mr-3" alt="" />
          Update your address and email if it has changed
        </p>
        <div class="form-row align-items-md-end">
          <div class="col-md-4 mb-3">
            <div className="image-placeholder imgHolder">
              <img
                src={`data:image/jpeg;base64,${bvnDetails.Base64Image}`}
                alt=""
              />
            </div>
          </div>
          <div class="col-md-8 mb-3">
            <div class="form-row">
              <div class="col-12 col-sm-6 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  First name
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border bg-white"
                  value={formValues.firstName}
                  onChange={handleFormChange("firstName")}
                  disabled
                />
              </div>
              <div class="col-12 col-sm-6 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  Second name
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border bg-white"
                  value={formValues.secondName}
                  onChange={handleFormChange("secondName")}
                  disabled
                />
              </div>
              <div class="col-12 col-sm-6 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  Last name
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border bg-white"
                  value={formValues.lastName}
                  onChange={handleFormChange("lastName")}
                  disabled
                />
              </div>
              <div class="col-12 col-sm-6 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  Date of Birth
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border bg-white"
                  value={formValues.dobirth}
                  onChange={handleFormChange("dobirth")}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div class="form-row mb-3">
          <div class="col-12 col-md-6 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Phone number
            </label>
            <input
              type="text"
              class="form-control pry-input-border bg-white"
              value={formValues.phone}
              onChange={handleFormChange("phone")}
              placeholder="Enter phone number"
              disabled
            />
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Email address
            </label>
            <input
              type="email"
              class="form-control pry-input-border"
              value={formValues.email}
              onChange={handleFormChange("email")}
              placeholder="Enter email adddress"
            />
          </div>
          <div class="col-12 mb-3">
            <div class="form-row">
              <div class="col-12">
                <h6 class="ftwt-600 ftsz-12">Residential address</h6>
              </div>
              <div class="col-12 col-md-12 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  House address
                </label>
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div key={suggestions.description}>
                      <input
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className:
                            "form-control pry-input-border location-search-input",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
                {/* <input
                  type="text"
                  class="form-control pry-input-border"
                  value={formValues.address}
                  onChange={handleFormChange("address")}
                  placeholder="House number"
                /> */}
              </div>
              <div class="col-12 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  Nearest bus-stop/landmark
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border"
                  value={formValues.landmark}
                  onChange={handleFormChange("landmark")}
                  placeholder="Nearest bus-stop/landmark"
                />
              </div>
              <div class="col-12 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  Area/State
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border"
                  value={formValues.state}
                  onChange={handleFormChange("state")}
                  placeholder="Area/State"
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-md-6 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Select employment information
            </label>
            <select
              id=""
              class="form-control pry-input-border"
              onChange={handleFormChange("employment")}
              value={formValues.employment}
            >
              <option value="employed">Employed</option>
              <option value="self-employed">Self-employed</option>
              <option value="retired">Retired</option>
              <option value="others">Others</option>
            </select>
          </div>
          <div class=" col-12 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Name of next of kin
            </label>
            <input
              type="text"
              class="form-control pry-input-border"
              value={formValues.nok}
              onChange={handleFormChange("nok")}
              placeholder="Enter name of next of kin"
            />
          </div>
          <div className="col-12 mb-3">
            <h6 className="ftsz-12 ftwt-500 mb-3">Select identity card type</h6>
            <div className="form-row ftsz-10 pale-red-tone-bg pry-red-clr ftwt-500 py-2">
              <div className="col-md-auto">
                <div className="custom-radio-controls position-relative">
                  <input
                    type="radio"
                    id="intlPassportRadio"
                    name="idCardType"
                    className="invisible position-absolute"
                    checked={identificationType === "International Passport"}
                    value="International Passport"
                    onClick={() => {
                      setIdentificationType("International Passport");
                      
                    }}
                  />
                  <label
                    className=""
                    onClick={switchIntl}
                    for="intlPassportRadio"
                  >
                    International passport
                  </label>
                </div>
              </div>
              <div className="col-md-auto">
                <div className="custom-radio-controls position-relative">
                  <input
                    type="radio"
                    id="nationalIDRadio"
                    name="idCardType"
                    className="invisible position-absolute"
                    checked={identificationType === "National ID"}
                    value="National ID"
                    onClick={() => {
                      setIdentificationType("National ID");

                    }}
                  />
                  <label className="" onClick={switchNat} for="nationalIDRadio">
                    Virtual NIN
                  </label>
                </div>
              </div>
              <div className="col-md-auto">
                <div className="custom-radio-controls position-relative">
                  <input
                    type="radio"
                    id="pVCRadio"
                    name="idCardType"
                    className="invisible position-absolute"
                    checked={identificationType === "Permanent Voters Card"}
                    value="Permanent Voters Card"
                    onClick={() => {
                      setIdentificationType("Permanent Voters Card");
                      
                    }}
                  />
                  <label className="" onClick={switchPvc} for="pVCRadio">
                    Permanent Voter's card
                  </label>
                </div>
              </div>
              <div className="col-md-auto">
                <div className="custom-radio-controls position-relative">
                  <input
                    type="radio"
                    id="driverLicenceRadio"
                    name="idCardType"
                    className="invisible position-absolute"
                    checked={identificationType === "Drivers License"}
                    value="Drivers License"
                    onClick={() => {
                      setIdentificationType("Drivers License");
                     
                    }}
                  />
                  <label
                    className=""
                    onClick={switchNdl}
                    for="driverLicenceRadio"
                  >
                    Nigerian Driver's licence
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            {id_type === "intl" && (
              <InternationalPassport
                formValues={formValues}
                handleFormChange={handleFormChange}
              />
            )}
            {id_type === "natID" && (
              <NationalId
                formValues={formValues}
                handleFormChange={handleFormChange}
              />
            )}
            {id_type === "pvc" && (
              <PermanentVotersCard
                formValues={formValues}
                handleFormChange={handleFormChange}
              />
            )}
            {id_type === "ndl" && (
              <NigerianDriversLicense
                formValues={formValues}
                handleFormChange={handleFormChange}
              />
            )}
          </div>
          <div class=" col-12 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              DAO Code(Referral ID) - *Optional
            </label>
            <input
              type="text"
              class="form-control pry-input-border"
              value={formValues.refCode}
              onChange={handleFormChange("refCode")}
              placeholder="Enter referral code"
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default UploadDocsEx;
