import React from "react";
import AddReference from ".";
import SelectBank from "./selectBank";
import OtherBanks from "./others";
import { useSelector } from "react-redux";

const Options = ({
  onClose,
  accountInfo,
  formValues,
  handleFormChange,
  current,
  setCurrent,
  fileUrl,
  showAddRefOTP,
  handleCloseAddRefOTP,
  handleShowAddRefOTP,
  handleShowRefSuccessOTP,
  handleFormChanges,
  formVal
}) => {
  const { screen } = useSelector(({ bank }) => bank);
  return (
    <div>
      <div>
        {screen === "select" && <SelectBank />}
        {screen === "sterling" && (
          <AddReference
            onClose={onClose}
            accountInfo={accountInfo}
            formValues={formValues}
            handleFormChange={handleFormChange}
            current={current}
            setCurrent={setCurrent}
            showAddRefOTP={showAddRefOTP}
            handleCloseAddRefOTP={handleCloseAddRefOTP}
            handleShowAddRefOTP={handleShowAddRefOTP}
            handleFormChanges={handleFormChanges}
            formVal={formVal}
          />
        )}
        {screen === "otherBanks" && (
          <OtherBanks
            onClose={onClose}
            accountInfo={accountInfo}
            formValues={formValues}
            handleFormChange={handleFormChange}
            current={current}
            setCurrent={setCurrent}
            fileUrl={fileUrl}
            handleShowRefSuccessOTP={handleShowRefSuccessOTP}
            handleFormChanges={handleFormChanges}
            formVal={formVal}
          />
        )}
      </div>
    </div>
  );
};

export default Options;
