// Api configurations here * * connects directly with the backend....

import axios from 'axios';

import { appUrls } from './urls';
import { encrypt2 } from '../encr';
import { decryptHandler2 } from '../dec';

const baseURL = 'https://camsapigateway-live.appsuport.p.azurewebsites.net';

const apiResource = () => {
  const api = axios.create({
    baseURL: baseURL,

    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Methods': '*',
      'Access-Control-Allow-Origin': baseURL,
      'Access-Control-Allow-Credentials': true,
      'X-Frame-Options': 'allow-from *',
    },

    // withCredentials: true,
  });

  api.interceptors.request.use(
    (config) => {
      const token = sessionStorage.getItem('OAuthToken');
      const decryptedToken = decryptHandler2(token);

      if (!token) return config;

      config.headers['Authorization'] = `Bearer ${decryptedToken}`;

      config.headers['Content-Type'] = 'application/json';

      return config;
    },

    (error) => Promise.reject(error)
  );

  api.interceptors.response.use(
    (response) =>
      new Promise((resolve, reject) => {
        resolve(response);
      }),

    async (error) => {
      if (error?.response?.status === 403) {
        sessionStorage.clear();

        window.location = '/';
      } else if (error?.response?.status === 401) {
        const originalConfig = error.config;

        if (originalConfig.url !== `${'/'}` && error?.response) {
          // Access Token was expired

          if (error.response.status === 401 && !originalConfig._retry) {
            originalConfig._retry = true;

            try {
              await getToken();

              return api(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          } else if (error?.response?.status === 404) {
            originalConfig._retry = true;

            try {
              await getToken();

              return api(originalConfig);
            } catch (_error) {
              return Promise.reject(_error);
            }
          }
        }
      } else {
        return new Promise((resolve, reject) => {
          reject(error?.response);
        });
      }

      return Promise.reject(error?.response);
    }
  );

  return api;
};

export const authOApi = apiResource();

export default function authHeader() {
  if (localStorage.getItem('auth') !== null) {
    const currentUser = JSON.parse(localStorage.getItem('auth') || '{}');

    if (currentUser && currentUser.auth) {
      return {
        'Content-Type': 'application/json',

        'Access-Control-Allow-Origin': '*',

        Accept: 'application/json, text/plain',
      };
    } else {
      return {};
    }
  } else {
    return;
  }
}

export async function getToken() {
  const formData = new URLSearchParams({
    grant_type: 'client_credentials',
    scope: 'camsapi',
    client_id: encrypt2(process.env.REACT_APP_AUTHO_CLIENT_ID),
    client_secret: encrypt2(process.env.REACT_APP_AUTHO_CLIENT_SECRET),
  });
  try {
    const { data } = await axios.post(
      'https://docamsapigatewayidentityserver-api.sterlingapps.p.azurewebsites.net/connect/token',
      formData,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );
    if (data.access_token) {
      const encryptedToken = encrypt2(data.access_token);
      sessionStorage.setItem('OAuthToken', encryptedToken);
    }
  } catch (error) {
  }
}

export function OAuthHeader() {
  if (localStorage.getItem('OAuthToken') !== null) {
    const token = localStorage.getItem('OAuthToken') || '';

    if (token) {
      return {
        'Content-Type': 'application/json',

        'Access-Control-Allow-Origin': '*',

        Accept: 'application/json, text/plain',

        Authorization: `Bearer  ${token}`,
      };
    } else {
      return '';
    }
  } else {
    return;
  }
}
