import React from "react";
import { Link } from "react-router-dom";
import "../../savings/components/styles.css"

const ExistingClassicCurrent = () => {
  return (
    <div>
      <div id="newclassic-curr-req" className="">
        <div className="d-flex justify-content-between px-4 styling">
          <h5 className="sec-header mb-4">Classic current</h5>
          <div className="mb-4">
            <Link to="/existing-classic-current" className="pry-red-bg rounded-lg px-5 py-3 text-white">
              Proceed
            </Link>
          </div>
        </div>
        <div className="row pry-grey-clr mb-4">
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Features</h6>
            <ul className="custom-req-marker pl-3">
              <li>
                Minimum account opening balance of =N=5,000 (Not Restrictive)
              </li>
              <li>Access to overdraft facility.</li>
              <li>AMF at =N=1/mille (Negotiable with huge turnover)</li>
              <li>
                Internet and mobile banking facility i.e., online statement, SMS
                alert etc
              </li>
              <li>Chequebook.</li>
              <li>International funds transfer allowed.</li>
              <li>Issuance of Verve Card and Mastercard.</li>
            </ul>
          </div>
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Requirements</h6>
            <ul className="custom-req-marker pl-3">
              <li>
                Basic customer information. (i.e., Name, Place and date of
                birth, Gender, Address, Telephone number) **Address would be
                verified.
              </li>
              <li>BVN.</li>
              <li>Passport photograph.</li>
              <li>Signature.</li>
              <li>Valid ID.</li>
              <li>2 Suitable references.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExistingClassicCurrent;
