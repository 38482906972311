import React, { useEffect, useState } from 'react';
import Warning from '../../../../../../../../Images/warning-icon.svg';
import Edit from '../../../../../../../../Images/Edit.png';
import Delete from '../../../../../../../../Images/delete.png';
import { Drawer, message } from 'antd';
import SignatoriesSteps from './signatories/single';
import DeleteSignatory from './signatories/single/delete';
import EditSignatoryForm from './signatories/single/edit';
import Spinner from 'react-bootstrap/Spinner';
import Modal from 'react-bootstrap/Modal';
import axios from 'axios';
import PlacesAutocomplete from 'react-places-autocomplete';
import {
  apiUrl,
  referenceEndpoint,
} from '../../../../../../../../redux/actions/endpoint';
import useModal from '../../hooks/useModal';
import { decryptHandler2 } from '../../../../../../../../dec';
import { encrypt2 } from '../../../../../../../../encr';
import { apiAccount } from '../../../../../../../../service/apiAccount';
import { appUrls } from '../../../../../../../../service/urls';
import UpdateDetailsClientID from '../../../../../../../Welcome/components/newPages/businessModals/UpdateDetailsClientID';
import UpdateDetailsPdfForms from '../../../../../../../pdfForms/UpdateDetailsPdfForms';
import { authOApi } from '../../../../../../../../service/authOApi';

const UpdateDetails = ({
  formVal,
  handleFormChange,
  handleFormChanges,
  refList,
  setRefList,
  getSignatoriesByTin,
  errorMsg,
  setFormVal,
  setFormValues,
  formValues,
  address,
  setAddress,
  handleSelect,
  tin,
  isTinValidated,
  clientID,
  setClientID,
}) => {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState('right');
  const [editVisible, setEditVisible] = useState(false);
  const [reffId, setReffId] = useState('');

  const [showDeleted, setShowDeleted] = useState(false);
  const handleCloseDeleted = () => setShowDeleted(false);
  const handleShowDeleted = () => setShowDeleted(true);

  // const [showDeleted, handleShowDeleted, handleCloseDeleted] = useModal();

  const [success, setSuccess] = useState('');
  const [loading, setLoading] = useState(false);

  const confirmDeleted = () => {
    handleCloseDeleted();
  };

  //edit signatories drawer
  const update = () => {
    setEditVisible(false);
  };

  const showEditDrawer = () => {
    setEditVisible(true);
  };

  const onEditClose = () => {
    setEditVisible(false);
  };

  //add signatories drawer
  const showDrawer = () => {
    setVisible(true);
  };

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const onClose = () => {
    setVisible(false);
  };

  const deleteSignatory = async (refId) => {
    setLoading(true);
    const enc = encrypt2(refId);
    await authOApi
      .delete(`${apiUrl}/DeleteSignatoryById`, {
        data: JSON.stringify({ data: enc }),
      })
      .then((response) => {
        message.success('Signatory Deleted !');
        getSignatoriesByTin();
        setRefList([]);
        setLoading(false);
        handleCloseDeleted();
      })
      .catch((error) => {
        message.error('Unable to delete signatory !');
        handleCloseDeleted();
        setLoading(false);
      });
  };

  useEffect(() => {
    getSignatoriesByTin();
    // validateTIN();
  }, []);

  return (
    <div>
      {/* Modal */}
      <UpdateDetailsClientID clientID={clientID} setClientID={setClientID} />
      <div>
        <form action="">
          <h5 className="my-4 sec-header text-body text-center">
            Update Details
          </h5>
          <p className="ftsz-regular text-body text-center">
            Hello, &nbsp;
            {/* <span className="ftwt-600">{tinDetails.taxPayerName}</span>, */}
            Welcome to Sterling bank
          </p>
          <p className="pale-red-tone-bg pry-red-clr ftwt-600 ftsz-12 mb-0 px-3 py-2 mb-3 rounded-lg">
            <img src={Warning} className="mr-3" alt="" />
            For a seamless experience, please ensure your Business Name is
            correct
          </p>
          <div className="form-row mb-3">
            <div className="col-12 col-md-6 mb-3">
              <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                Business name
              </label>
              <input
                type="text"
                className="form-control pry-input-border bg-white"
                value={formVal.businessName}
                // onChange={(e) => setFormVal({
                //   ...formVal,
                //   businessName: e.target.value,
                // })}
                onChange={handleFormChange('businessName')}
                placeholder="Enter Business name"
                maxLength="35"
                disabled={isTinValidated ? true : false}
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                Business registration number
              </label>
              <input
                type="text"
                className="form-control pry-input-border bg-white"
                value={formVal.regNum}
                // onChange={(e) => setFormVal({
                //   ...formVal,
                //   regNum: e.target.value,
                // })}
                onChange={handleFormChange('regNum')}
                placeholder="Enter Business registration number"
                disabled={isTinValidated ? true : false}
              />
            </div>
            <div className="col-12 mb-3">
              <div className="form-row">
                <div className="col-12">
                  <h6 className="ftwt-600 ftsz-12">Business address</h6>
                </div>
                <div className="col-12 col-md-12 mb-3">
                  <PlacesAutocomplete
                    value={address}
                    onChange={setAddress}
                    onSelect={handleSelect}
                  >
                    {({
                      getInputProps,
                      suggestions,
                      getSuggestionItemProps,
                      loading,
                    }) => (
                      <div key={suggestions.description}>
                        <input
                          {...getInputProps({
                            placeholder: 'Search Places ...',
                            className:
                              'form-control pry-input-border location-search-input',
                          })}
                        />
                        <div className="autocomplete-dropdown-container">
                          {loading && <div>Loading...</div>}
                          {suggestions.map((suggestion) => {
                            const className = suggestion.active
                              ? 'suggestion-item--active'
                              : 'suggestion-item';
                            // inline style for demonstration purpose
                            const style = suggestion.active
                              ? {
                                  backgroundColor: '#fafafa',
                                  cursor: 'pointer',
                                }
                              : {
                                  backgroundColor: '#ffffff',
                                  cursor: 'pointer',
                                };
                            return (
                              <div
                                {...getSuggestionItemProps(suggestion, {
                                  className,
                                  style,
                                })}
                              >
                                <span>{suggestion.description}</span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </PlacesAutocomplete>
                </div>
                <div className="col-12 mb-3">
                  <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                    Email
                  </label>
                  <input
                    type="text"
                    className="form-control pry-input-border"
                    value={formVal.email}
                    placeholder=""
                    // onChange={(e) => setFormVal({
                    //   ...formVal,
                    //   email: e.target.value,
                    // })}
                    onChange={handleFormChange('email')}
                  />
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                Business phone number
              </label>
              <input
                type="text"
                className="form-control pry-input-border bg-white"
                value={formVal.phone}
                placeholder="Enter Business phone number"
                disabled={isTinValidated ? true : false}
                // onChange={(e) => setFormVal({
                //   ...formVal,
                //   phone: e.target.value,
                // })}
                onChange={handleFormChange('phone')}
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                Date of incorporation
              </label>
              <input
                type="date"
                className="form-control pry-input-border"
                value={formVal.dateOfInc}
                onChange={handleFormChange('dateOfInc')}
              />
            </div>
            <div
              className={`${
                isTinValidated ? 'd-block' : 'd-none'
              } col-12 mb-3 mt-1`}
            >
              <h6 className="ftsz-12 ftwt-500 mb-3">
                Select count of signatory
              </h6>
              <div className="form-row ftsz-12 pale-red-tone-bg pry-red-clr ftwt-500 py-2 mb-3">
                <div className="col-md-auto">
                  <div className="custom-radio-controls position-relative">
                    <input
                      type="radio"
                      id="compSingleSign"
                      name="signatoryCount"
                      className="invisible position-absolute"
                      checked
                    />
                    <label className="" for="compSingleSign">
                      Signatories (Minimum of Two is required)
                    </label>
                  </div>
                </div>
              </div>
              <div className="signatory-list form-row justify-content-between text-body align-items-center mb-3  pb-3 border-bottom-pale2">
                <div className="col-12">
                  {/* <h6 className="ftsz-12 ftwt-500 mb-3">Primary signatory</h6> */}
                </div>
                <div className="col-md-auto">
                  {loading ? (
                    <div>
                      <Spinner animation="border" role="status">
                        <span className="visually-hidden"></span>
                      </Spinner>
                    </div>
                  ) : (
                    <div>
                      {errorMsg === 404 ? (
                        <p className="ftsz-regular">
                          You do not have any signatories
                        </p>
                      ) : (
                        <div>
                          {/* {refList && ( */}
                          <div className="">
                            {refList.map((referee) => {
                              return (
                                <div
                                  className="col-12 ftwt-500 pale-red-tone-bg mb-2"
                                  key={referee.Id}
                                >
                                  <div className="signatoryDiv">
                                    <div>{`${referee.FirstName} ${referee.LastName}`}</div>
                                    <div className="imageToMove">
                                      <button
                                        type="button"
                                        className="bare-btn"
                                      >
                                        <img
                                          src={Delete}
                                          alt="Delete signaory"
                                          onClick={() => {
                                            handleShowDeleted();
                                            setReffId(referee.Id);
                                          }}
                                        />

                                        <Modal
                                          show={showDeleted}
                                          onHide={handleCloseDeleted}
                                          backdrop="static"
                                          keyboard={false}
                                        >
                                          <Modal.Body>
                                            <div className="text-center">
                                              <div className="mb-5">
                                                <h6 className="sec-header mb-4 mt-5 pt-2">
                                                  Delete signatory
                                                </h6>
                                                <p className="mb-5 pry-grey-clr">
                                                  Are you sure you want to
                                                  delete this signatory?
                                                </p>
                                                <div className="bdcenter-modal-btngrp d-flex justify-content-between">
                                                  <button
                                                    type="button"
                                                    className="btn btn-outline-danger mr-3"
                                                    onClick={handleCloseDeleted}
                                                  >
                                                    Cancel
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn btn-danger pry-red-bg"
                                                    disabled={loading}
                                                    onClick={() => {
                                                      deleteSignatory(reffId);
                                                    }}
                                                  >
                                                    {loading
                                                      ? 'Deleting...'
                                                      : 'Delete'}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </Modal.Body>
                                        </Modal>
                                      </button>
                                      {/* <div> */}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                          {/* )} */}
                        </div>
                      )}
                    </div>
                  )}
                </div>

                {/* {refList} */}
              </div>
              <div className="text-right border-bottom-pale2 pb-4">
                <button
                  type="button"
                  className="bare-btn pry-red-clr text-underline ftsz-12"
                  data-toggle="modal"
                  data-target="#signatoryBVNValidation"
                  onClick={showDrawer}
                >
                  Click to add signatory
                </button>
              </div>
            </div>

            <div
              className={`${
                isTinValidated ? 'd-block' : 'd-none'
              } col-12 mb-3 mt-3`}
            >
              <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                DAO Code(Referral ID) - *Optional
              </label>
              <input
                type="text"
                className="form-control pry-input-border"
                placeholder="Enter DAO code"
                value={formVal.refCode}
                onChange={handleFormChange('refCode')}
              />
            </div>
            {!isTinValidated ? <UpdateDetailsPdfForms /> : null}
          </div>
        </form>
      </div>

      <Drawer
        title="Add signatory"
        placement={placement}
        width={500}
        onClose={onClose}
        visible={visible}
      >
        <SignatoriesSteps
          onClose={onClose}
          formVal={formVal}
          setFormValues={setFormValues}
          formValues={formValues}
          handleFormChanges={handleFormChanges}
          getSignatoriesByTin={getSignatoriesByTin}
          refList={refList}
          tin={tin}
        />
      </Drawer>

      <Drawer
        title="Edit signatory"
        placement={placement}
        width={500}
        onClose={onEditClose}
        visible={editVisible}
      >
        <EditSignatoryForm update={update} />
      </Drawer>

      {/* <DeleteSignatory
        showDeleted={showDeleted}
        handleCloseDeleted={handleCloseDeleted}
        confirmDeleted={confirmDeleted}
      /> */}
    </div>
  );
};

export default UpdateDetails;
