import React, { useState, useEffect } from 'react'
import Spinner from "react-bootstrap/Spinner";
import Header from './components/Header';
import BvnValidation from './BvnValidation';
import { useHistory } from "react-router-dom";
import AccountValidation from './AccountValidation';
import OtpValidation from './OtpValidation';
import Confirm from './components/modal/Confirm';
import SuccessBvn from './components/modal/SuccessBVN';
import RejectedBvn from './components/modal/RejectedBVN'
import { Steps, message } from "antd";
import "../../forgot.css"
import axios from "axios";
import {
    apiUrl,
    apiUrl3
} from "../../../../redux/actions/endpoint";
import { encrypt2 } from "../../../../encr";
import { decryptHandler2 } from "../../../../dec";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { ForgotAcctMethodAction } from '../../../../redux/actions';
import { set_bvn_info } from "../../../../redux/actions/bvnAction";
import { convertBase64ToBinary } from "../../../../convert";
import LiveCheck from './liveCheck';
import { apiAccount } from "../../../../service/apiAccount";
import { appUrls } from "../../../../service/urls";
import { authOApi } from '../../../../service/authOApi';


function Forgotbvn() {
    const { Step } = Steps;
    const history = useHistory();
    const [otp, setOtp] = useState("");
    const [supposedBvn, setSupposedBvn] = useState("");
    const [customerNumber, setCustomerNumber] = useState("");
    const [bvnResults, setBvnResults] = useState({})
    const [mob, setMob] = useState("");
    const [disableBtn, setDisableBtn] = useState(true);
    const [accountCount, setAccountCount] = useState(0);
    const [bvnDetails, setBvnDetails] = useState({});
    const [dob, setDob] = useState(
        moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.sssZ")
    );
    const [displayLive, setDisplayLive] = useState(false)
    const [hpvImage, setHpvImage] = useState("");
    const [valid, setValid] = useState(false);
    const [isLoadingHpvSdk, setIsLoadingHpvSdk] = useState(false);
    const [drop, setDrop] = useState({
        method: "",
    });

    const [isBvnRetrieval, setIsBvnRetrieval] = useState(false)


    const { forgot_acct_method } = useSelector((methods) => methods);
    const dispatch = useDispatch();

    const { bvnInfo } = useSelector(({ bvn }) => bvn);

    const handleChange = (name) => (event) => {
        setDrop({
            ...drop,
            [name]: event.target.value,
        });
        dispatch(ForgotAcctMethodAction.set_forgot_acct_method(event.target.value));
    };


    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    //stepper
    const [current, setCurrent] = useState(0);

    const [showOtp, setShowOtp] = useState(false);
    const handleCloseOtp = () => setShowOtp(false);
    const handleShowOtp = () => setShowOtp(true);

    const [showCompleted, setShowCompleted] = useState(false);
    const handleCloseCompleted = () => setShowCompleted(false);
    const handleShowCompleted = () => setShowCompleted(true);

    const [showBvnSuccess, setShowBvnSuccess] = useState(false);
    const handleCloseBvnSuccess = () => setShowBvnSuccess(false);
    const handleShowBvnSuccess = () => setShowBvnSuccess(true);

    const [showBvnRejected, setShowBvnRejected] = useState(false);
    const handleCloseBvnRejected = () => setShowBvnRejected(false);
    const handleShowBvnRejected = () => setShowBvnRejected(true);


    const [formValues, setFormValues] = useState({
        bvn: "",
        otp: "",
        dobirth: "",
        // branch: bvnDetails.enrollmentBranch,
        firstName: "",
        secondName: "",
        lastName: "",
        phone: "",
        email: "",
        houseNo: "",
        address: "",
        landmark: "",
        state: "",
        addressSummary: "",
        productCode: "",
        employment: "",
        idCardNo: "",
        issueDate: "",
        expiryDate: "",
        refCode: "",
        nok: "",
        id: "",
    });


    const handleConfirm = () => {
        handleCloseCompleted();
    };

    const rejectConfirmation = () => {
        handleCloseCompleted();
    }

    const handleMethod = () => {

        setDisplayLive(true)
    }

    const onDateChange = (event) => {
        setDob(event.target.value);
    };


    //handling form state change
    const handleFormChange =
        (name) =>
            ({ target }) => {
                setFormValues({
                    ...formValues,
                    [name]: target.value,
                });
            };

    const [bvnVal, setBvnVal] = useState("")

    const [bvnValues, setBvnValues] = useState({
        accountNumber: "",
        bvn: "",
        otp: "",
    });

    //handling form state change
    const handleBvnChange =
        (name) =>
            ({ target }) => {
                setBvnValues({
                    ...bvnValues,
                    [name]: target.value,
                });
            };



    const fullInfo = () => {
        setLoading(true);
        const data = encrypt2(bvnValues.bvn);
        authOApi
            .get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${data}`)
            .then((response) => {
                const res = decryptHandler2(response.data);
                let accountInfos = res.GetAccountFullInfo.BankAccountFullInfo;
                let accounts = accountInfos.filter(
                    (x) => x.CustomerStatus !== "2" && x.BVN !== null
                );
                const accountNumber = []
                if (accounts.length > 0) {
                    accountNumber.push(accounts[0].NUBAN)
                    setCustomerNumber(accountNumber);

                }
                else
                    setCustomerNumber(
                        res.GetAccountFullInfo.BankAccountFullInfo.NUBAN
                    );
                verifyBvn();
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const getFullInfo = () => {
        setLoading(true);
        const enc = encrypt2(bvnValues.accountNumber);
        authOApi
					.get(`${appUrls.FIORANO_GET_ACCT_FULLINFO}?Data=${enc}`)
					.then((response) => {
						const res = decryptHandler2(response.data);

						if (res.ResponseCode === "00") {
							setBvnResults(res);
							let respp = res.BankAccountFullInfo.BVN;
							setBvnVal(respp);
							setSupposedBvn(respp);
                            verifyBvn();
						} else {
							message.error(
								"Oops!!! There were no record found. Please provide your Account number"
							);
							setLoading(false);
                        }
					});
    };


    const verifyBvn = () => {
        if (!bvnValues.accountNumber && bvnValues.accountNumber.length !== 10) {
            message.error(
                "Oops!!! You're missing something. Please provide your Account number"
            );
            return;
        }
        if (!formValues.firstName) {
            message.error(
                "Oops!!! You're missing something. Please provide your first name"
            );
            return;
        }
        if (!formValues.lastName) {
            message.error(
                "Oops!!! You're missing something. Please provide your last name"
            );
            return;
        }
        if (drop.method === "none") {
            message.error(
                "Oops!!! You're missing something. Please choose the method in which you want to verify"
            );
        }
        else {
            setLoading(true)
            let payload = {
                bvn: bvnVal,
                dateOfBirth: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
            };
            const enc = encrypt2(payload);
            // setLoading(true);
            authOApi
							.post(`${appUrls.VERIFY_BVN_V2}`, { data: enc })
							.then(async (response) => {
								const res = await decryptHandler2(response.data);
								if (res && res.ResponseCode === "00") {
									localStorage.setItem("livedata", JSON.stringify(res));
									const formValueFirstName = formValues.firstName.toLowerCase();
									const formValueLastName = formValues.lastName.toLowerCase();
									const resultsFirstName = res.FirstName.toLowerCase();
									const resultsLastName = res.LastName.toLowerCase();
									if (
										formValueFirstName === resultsFirstName &&
										formValueLastName === resultsLastName
									) {
										handleMethod();
										// handleProceed()
									} else {
										message.error(
											"Oops! There's a mismatch with your name. Please provide your correct name."
										);
										setLoading(false);
									}
									// setBvnDetails(res);
								} else {
									message.error(
										"Oops! There's a mismatch with your Account Number information, please check and enter the correct details"
									);
									setLoading(false);
								}
							});
        }
    };


    const validateFirst = () => {
        getFullInfo();
    };



    let live = JSON.parse(localStorage.getItem("livedata"))



    return (
        <div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 displayNone">
                    <div className="leftDiv stickyDiv"></div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Header />

                    <div className="container-fluid pb-5 mt-5">
                        <div class="row mb-5">
                            <div class="container">
                                <div className="steps-content mb-5">
                                    {displayLive ? (
                                        <LiveCheck
                                            bvnResults={bvnResults}
                                            liveDetail={live}
                                            customerNumber={customerNumber}
                                            supposedBvn={supposedBvn}
                                            handleCloseOtp={handleCloseOtp}
                                            showCompleted={showCompleted}
                                            handleCloseCompleted={handleCloseCompleted}
                                            handleConfirm={handleConfirm}
                                            rejectConfirmation={rejectConfirmation}
                                        />
                                    ) : (

                                            <BvnValidation
                                                handleShowOtp={handleShowOtp}
                                                loading={loading}
                                                isLoading={isLoading}
                                                handleFormChange={handleFormChange}
                                                formValues={formValues}
                                                dob={dob}
                                                bvnValues={bvnValues}
                                                handleBvnChange={handleBvnChange}
                                                setDisableBtn={setDisableBtn}
                                                validateFirst={validateFirst}
                                                disableBtn={disableBtn}
                                                onDateChange={onDateChange}
                                                handleChange={handleChange}
                                                supposedBvn={supposedBvn}
                                            />
                                    )}
                                </div>
                                <div className="text-right"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Confirm
                showCompleted={showCompleted}
                handleCloseCompleted={handleCloseCompleted}
                handleConfirm={handleConfirm}
                rejectConfirmation={rejectConfirmation}
            />

            <SuccessBvn
                showBvnSuccess={showBvnSuccess}
                handleCloseSuccess={handleCloseBvnSuccess}
                supposedBvn={supposedBvn}
                bvnResults={bvnResults}
            />

            <RejectedBvn
                showBvnRejected={showBvnRejected}
                handleCloseBvnRejected={handleCloseBvnRejected}
            />
        </div>
    )
}

export default Forgotbvn