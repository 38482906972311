import React from "react";
import Modal from "react-bootstrap/Modal";

const TinExists = ({
  showExisting,
  handleCloseExisting,
}) => {
  return (
    <div>
      <Modal
        show={showExisting}
        onHide={handleCloseExisting}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="text-center">
            <div className="mb-5">
              <h6 className="sec-header text-body mb-3 px-sm-5 mx-sm-5">
                TIN Exists
              </h6>
              <p className="ftsz-regular">
                There is an existing Sterling account with this TIN. Kindly use
                the Existing customer option
              </p>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-danger px-5 mr-sm-3 mb-3"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-danger pry-red-bg px-5 mb-3"
                >
                  Existing Customer
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TinExists;
