import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { decryptHandler2 } from '../../dec';
import { encrypt2 } from '../../encr';
import { apiUrl } from '../../redux/actions/endpoint';
import BusinessAccountScreen from './businessAccount';
import ComboOneScreen from './comboOne';
import ComboTwoScreen from './comboTwo';
import { apiAccount } from '../../service/apiAccount';
import { appUrls } from '../../service/urls';
import { authOApi } from '../../service/authOApi';

const DocumentUpload = () => {
  const [loading, setLoading] = useState(false);
  const [docType, setDocType] = useState('');
  const [showDoc, setShowDoc] = useState(false);
  const [docText, setDocText] = useState('');
  const [sectorList, setSectorList] = useState([]);
  const [industriesList, setIndustriesList] = useState([]);
  const [isLoading, setIsLoading] = useState();
  const [accNum, setAccNum] = useState('');

  const useQuery = () => new URLSearchParams(window.location.search);
  let query = useQuery();
  const tokenNeeded = query.get('token');

  const verifyEmailToken = () => {
    const tokReg = tokenNeeded.replace(/\s+/g, '+');
    let enc = encrypt2(tokReg);
    setLoading(true);
    authOApi
      .post(`${appUrls.CONFIRM_CORPORATE_EMAIL_TOKEN}`, { data: enc })
      .then((response) => {
        let res = decryptHandler2(response.data);
        var accN = res.accountNumber;
        checkStatus(accN);
        setAccNum(res.accountNumber);
        getSectors();
        setLoading(false);
      });
  };

  const checkStatus = (accountN) => {
    let acc = encrypt2(accountN);
    setLoading(true);
    authOApi
      .get(`${appUrls.GET_CORPORATEDOC_UPLOAD_STATUS}?Data=${acc}`)
      .then((response) => {
        let statusResponse = decryptHandler2(response.data);
        setDocType(statusResponse.DocumentCategory);
        setLoading(false);
      });
  };

  const [sec, setSec] = useState({
    sector_id: '',
    industry_id: '',
  });

  const handleSecChanges =
    (name) =>
    ({ target }) => {
      setSec({
        ...sec,
        [name]: target.value,
      });
      if (
        target.value == 6401 ||
        target.value == 6402 ||
        target.value == 6403 ||
        target.value == 5701 ||
        target.value == 4601 ||
        target.value == 4602 ||
        target.value == 4603
      ) {
        setShowDoc(true);
        if (target.value == 6401) {
          setDocText('Annual license to practice ');
        }
        if (target.value == 6402) {
          setDocText('Annual license to practice ');
        }
        if (target.value == 6403) {
          setDocText('Annual license to practice ');
        }
        if (target.value == 5701) {
          setDocText(
            'Evidence of membership to professional body(Call to bar cert., CITN, ICAN etc) '
          );
        }
        if (target.value == 4601) {
          setDocText(
            'State government approval/ National UNI commission approval '
          );
        }
        if (target.value == 4602) {
          setDocText(
            'State government approval/ National UNI commission approval '
          );
        }
        if (target.value == 4603) {
          setDocText(
            'State government approval/ National UNI commission approval '
          );
        }
      } else {
        setShowDoc(false);
      }
    };

  let secVal = sec.sector_id;

  const getSectors = () => {
    authOApi
      .get(
        `${appUrls.GET_SECTOR}`
        // , {
        // headers: {
        //   "Access-Control-Allow-Origin": "*",
        //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        // },
        // }
      )
      .then((response) => {
        const res = decryptHandler2(response.data);
        setSectorList(res.Sectors);
      })
      .catch((err) => {
        
      });
  };

  const getIndusties = () => {
    setIsLoading(true);
    let enc = encrypt2(secVal);
    authOApi
      .get(
        `${appUrls.GET_INDUSTRY}?Data=${enc}`
        // , {
        // headers: {
        //   "Access-Control-Allow-Origin": "*",
        //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        // },
        // }
      )
      .then((response) => {
        const res = decryptHandler2(response.data);
        setIndustriesList(res.Industries);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };


  useEffect(() => {
    verifyEmailToken();
  }, []);

  useEffect(() => {
    getIndusties();
  }, [secVal]);

  return (
    <div>
      {loading ? (
        <p className="xcenter">Loading ...</p>
      ) : (
        <div className="py-4">
          {docType === 'Combo1' && (
            <ComboOneScreen
              showDoc={showDoc}
              docText={docText}
              sec={sec}
              handleSecChanges={handleSecChanges}
              sectorList={sectorList}
              industriesList={industriesList}
              isLoading={isLoading}
              accNum={accNum}
            />
          )}
          {docType === 'Combo2' && (
            <ComboTwoScreen
              showDoc={showDoc}
              docText={docText}
              sec={sec}
              handleSecChanges={handleSecChanges}
              sectorList={sectorList}
              industriesList={industriesList}
              isLoading={isLoading}
              accNum={accNum}
            />
          )}
          {docType === 'BusinessDocument' && (
            <BusinessAccountScreen
              showDoc={showDoc}
              docText={docText}
              sec={sec}
              handleSecChanges={handleSecChanges}
              sectorList={sectorList}
              industriesList={industriesList}
              isLoading={isLoading}
              accNum={accNum}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default DocumentUpload;
