import React, { useState } from "react";
import { TrendChart } from "./components/trendChart";
import "./trends.css";
import InBranchAccount from "../../../Images/in-branch-accounts.svg";
import DigitalOnboarding from "../../../Images/digital-onboarding.svg";
import AverageAge from "../../../Images/average-age.svg";
import redArrowDown from "../../../Images/red_arrow_down.svg";
import GreenArrowUp from "../../../Images/green_arrow_up.svg";
import CircularProgressBar from "./components/CircularProgressBar";
import { MdTune, MdOutlineAccountBalance } from "react-icons/md"
import { BsArrowDown, BsArrowUp } from "react-icons/bs"
import { RiExchangeDollarLine } from "react-icons/ri"

function Trends() {
  const [accountType] = useState([
    {
      id: 1,
      value: 55,
      acct: "Classic Savings",
    },
    {
      id: 2,
      value: 15,
      acct: "Classic Current",
    },
    {
      id: 3,
      value: 5,
      acct: "Dom. Savings",
    },
    {
      id: 4,
      value: 10,
      acct: "Dom. Current",
    },
    {
      id: 5,
      value: 30,
      acct: "Business",
    },
    {
      id: 6,
      value: 10,
      acct: "Company",
    },
    {
      id: 7,
      value: 14,
      acct: "KiaKia",
    },
  ]);
  return (
    <div className="main__container">
      <div className="header">
        <ul>
          <h1>Trend Analysis</h1>
          <div>
            <label><MdTune /></label>
            <select className="date-select" name="" id="">
              <option>Last 30 days</option>
              <option>Last 60 days</option>
            </select>
          </div>
        </ul>
      </div>
      <div className="separatingDiv">
        <div className="container-trend">
          <div className="content-left">
            <div className="content-left_top">
              <div className="content-left_chart">
                <TrendChart />
              </div>
              <div className="content-left_summary">
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    borderBottom: "1px solid #C4C4C4",
                  }}
                >
                  <h2 style={{ fontSize: "30px", fontWeight: 700 }}>120</h2>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      textAlign: "left",
                    }}
                  >
                    Applications Started
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    borderBottom: "1px solid #C4C4C4",
                  }}
                >
                  <h2 style={{ fontSize: "30px", fontWeight: 700 }}>45</h2>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: "700",
                      textAlign: "left",
                    }}
                  >
                    Completed Applications
                  </p>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                    borderBottom: "1px solid #C4C4C4",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "30px",
                      fontWeight: 700,
                      height: "50px",
                      width: "50px",
                    }}
                  >
                    <CircularProgressBar value={10} />
                  </h2>
                  <p
                    style={{
                      fontSize: "16px",
                      fontWeight: 700,
                      textAlign: "left",
                    }}
                  >
                    DropOffs
                  </p>
                </div>
              </div>
            </div>
            <div className="content-left_bottom">
              <div className="content-left_bottom_summary">
                <div className="content-left_bottom_summary-ff">
                  <div className="cards_icon">
                    <MdOutlineAccountBalance className='card-img' />
                  </div>
                  <p className="title-h">
                    In-Branch Accounts
                  </p>
                </div>
                <div className="content-left_bottom_summary-ff">
                  <p className="card-value">350</p>
                  <BsArrowDown className="card-arrow-red" />
                </div>
              </div>
              <div className="content-left_bottom_summary">
                <div className="content-left_bottom_summary-ff">
                  <div className="cards_icon">
                    <RiExchangeDollarLine className='card-img' />

                  </div>
                  <p className="title-h">
                    Digital Onboarding
                  </p>
                </div>
                <div className="content-left_bottom_summary-ff">
                  <p className="card-value">538</p>
                  <BsArrowUp className="card-arrow-green" />
                </div>
              </div>
              <div className="content-left_bottom_summary">
                <div className="content-left_bottom_summary-ff">
                  <div className="cards_icon">
                    <MdOutlineAccountBalance className='card-img' />
                  </div>
                  <p className="title-h">Average Age</p>
                </div>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <p className="card-value">27</p>
                </div>
              </div>
            </div>
          </div>
          <div className="content-right">
            <p className="title-h">Acc. Types</p>
            {accountType.map((list) => {
              return (
                <div
                  key={list?.id}
                  style={{
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  <h2
                    style={{
                      fontSize: "30px",
                      fontWeight: 700,
                      height: "50px",
                      width: "50px",
                    }}
                  >
                    <CircularProgressBar value={list?.value} />
                  </h2>
                  <p style={{ fontSize: "16px", fontWeight: 600 }}>
                    {list?.acct}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Trends;
