import React from "react";
import SideCarousel from "../Welcome/components/SideCarousel";
import MainComponent from "./components/Main";

const NewCustomer = () => {
  return (
    <div>
      <div class="container-fluid px-sm-0 newBg">
        <div class="row">
          <div class="col-md-4">
              <SideCarousel />
          </div>
          <div class="col-12 col-md-8 pt-5">
              <MainComponent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewCustomer;
