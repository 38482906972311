import React from "react";
import "./index.css";
import Warning from "../../../../../../Images/warning-icon.svg";
import CurrentTab from "../current";
import SavingsTab from "../savings";

const ContentOne = () => {
  return (
    <div className="">
      <div className="px-sm-0">
        <div className="acct-req-selector-wrapper pb-5">
          <h1 className="pry-header mb-4">Personal Account</h1>
          <h2 className="ftsz-12 mb-2">Select account category </h2>
          <ul
            className="nav nav-pills mb-4 new-acct-req-pill flex-nowrap pale-red-tone-bg p-2"
            id="newAcctReqPill"
            role="tablist"
          >
            <li className="nav-item" role="presentation">
              <a
                className="nav-link active"
                id="new-savings-req-tab"
                data-toggle="pill"
                href="#new-savings-req"
                role="tab"
                aria-controls="new-savings-req"
                aria-selected="true"
              >
                Savings
              </a>
            </li>
            <li className="nav-item" role="presentation">
              <a
                className="nav-link"
                id="new-current-req-tab"
                data-toggle="pill"
                href="#new-current-req"
                role="tab"
                aria-controls="new-current-req"
                aria-selected="false"
              >
                Current
              </a>
            </li>
          </ul>
          <p className="pale-red-tone-bg pry-red-clr ftwt-600 ftsz-12 mb-0 px-3 py-2 mb-3 rounded-lg">
            <img src={Warning} className="mr-3" alt="warning icon" />
            {/* Kindly ensure that your BVN information is up to date */}
            For a seamless experience, please ensure your BVN information is up to date.
          </p>
          <div className="tab-content" id="newAcctReqPillContent">
            <div
              className="tab-pane fade show active"
              id="new-savings-req"
              role="tabpanel"
              aria-labelledby="new-savings-req-tab"
            >
              <SavingsTab />
            </div>
            <div
              className="tab-pane fade"
              id="new-current-req"
              role="tabpanel"
              aria-labelledby="new-current-req-tab"
            >
              <CurrentTab />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContentOne;
