import { useState, useCallback, useMemo } from "react";

export default () => {
  const [signature, setSignature] = useState("");
  const [signatureURL, setSignatureURL] = useState("");

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSignature(file);
      const base64 = await convertBase64(file);
      setSignatureURL(base64);
    }
  };

  return [onDrop, signature, signatureURL];
};
