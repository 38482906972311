import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { decryptHandler2 } from "../../../dec";
import { encrypt2 } from "../../../encr";
import { apiUrl } from "../../../redux/actions/endpoint";
import useCorporateFiles from "../hooks/useCorporateFiles";
import FormOne from "./form";
import { apiAccount } from "../../../service/apiAccount";
import { appUrls } from "../../../service/urls";
import { authOApi } from "../../../service/authOApi";

const ComboOneScreen = ({
  showDoc,
  docText,
  sec,
  handleSecChanges,
  sectorList,
  industriesList,
  isLoading,
  accNum,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [cac, setCac] = useState("");
  const [cacURL, setCacURL] = useState("");
  const [co2, setCo2] = useState("");
  const [co2URL, setCo2URL] = useState("");
  const [co7, setCo7] = useState("");
  const [co7URL, setCo7URL] = useState("");
  const [meMart, setMeMart] = useState("");
  const [meMartURL, setMeMartURL] = useState("");
  const [board, setBoard] = useState("");
  const [boardURL, setBoardURL] = useState("");
  const [refr, setRef] = useState("");
  const [refURL, setRefURL] = useState("");
  const [others, setOthers] = useState("");
  const [othersURL, setOthersURL] = useState("");
  const [evidence, setEvidence] = useState("");
  const [evidenceURL, setEvidenceURL] = useState("");

  const [documentArrayOne, setDocumentArrayOne] = useState([]);
  const [uploadD, setUploadD] = useState(false);

  const onDrop1 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setCac(file);
      const base64 = await convertBase64(file);
      setCacURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo1",
            documentTagName: "cac",
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop2 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setCo2(file);
      const base64 = await convertBase64(file);
      setCo2URL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo1",
            documentTagName: "co2",
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop3 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setCo7(file);
      const base64 = await convertBase64(file);
      setCo7URL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo1",
            documentTagName: "co7",
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop4 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setMeMart(file);
      const base64 = await convertBase64(file);
      setMeMartURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo1",
            documentTagName: "memart",
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop5 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setBoard(file);
      const base64 = await convertBase64(file);
      setBoardURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo1",
            documentTagName: "boardresolutionsignedsealed",
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop6 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setRef(file);
      const base64 = await convertBase64(file);
      setRefURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo1",
            documentTagName: "others1",
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop7 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setOthers(file);
      const base64 = await convertBase64(file);
      setOthersURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo1",
            documentTagName: "others",
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop8 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setEvidence(file);
      const base64 = await convertBase64(file);
      setEvidenceURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo1",
            documentTagName: "evidenceofmembership",
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const uploadDocs = async () => {
    setLoading(true);
    let payload = {
      documents: documentArrayOne,
    };
    const enc = encrypt2(payload);
    const dec = decryptHandler2(enc);

    await authOApi
      .post(`${appUrls.UPLOAD_CORPORATE_DOC}`, { data: enc })
      .then((response) => {
        const res = decryptHandler2(response.data);
        message.success("File successfully uploaded");
        setLoading(false);
        history.push("/");
      })
      .catch((error) => {
        message.error(error);
        setLoading(false);
      });
  };


  return (
    <div>
      <div>
        <FormOne
          showDoc={showDoc}
          docText={docText}
          sec={sec}
          handleSecChanges={handleSecChanges}
          sectorList={sectorList}
          industriesList={industriesList}
          isLoading={isLoading}
          accNum={accNum}
          loading={loading}
          uploadDocs={uploadDocs}
          onDrop1={onDrop1}
          onDrop2={onDrop2}
          onDrop3={onDrop3}
          onDrop4={onDrop4}
          onDrop5={onDrop5}
          onDrop6={onDrop6}
          onDrop7={onDrop7}
          onDrop8={onDrop8}
          co2={co2}
          co7={co7}
          meMart={meMart}
          board={board}
          refr={refr}
          others={others}
          evidence={evidence}
          cac={cac}
        />
        <div className="d-flex justify-content-center align-items-center">
          <button
            type="button"
            className="btn btn-outline-danger text-white pry-red-bg px-5 mb-1"
            disabled={loading || uploadD || (documentArrayOne.length === 0)}
            onClick={uploadDocs}
          >
            {loading && "Sending ..."}
            {uploadD && "Uploading ..."}
            {(!loading && !uploadD) && "Done"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComboOneScreen;
