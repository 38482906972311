import React from "react";
import { Link } from "react-router-dom";
import SterlingLogo from "../../../Images/Sterling_Bank_Logo_Straight_grey.png";

const Header = () => {
  return (
    <div>
      <div className="row my-5 align-items-end px-3">
        <div className="col logo">
          <Link to="/">
            <img
              src={SterlingLogo}
              className="img-fluid"
              alt="Sterling Bank Logo"
            />
          </Link>
        </div>
        {/* <div className="col">
          <div>
            <h1 class="mb-0 pry-grey-clr pry-header text-right">
              Referee confirmation
            </h1>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default Header;
