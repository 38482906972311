import React, { useState, useCallback } from 'react'
import Spinner from "react-bootstrap/Spinner";
import Header from './components/Header';
import { useHistory } from "react-router-dom";
import AccountValidation from './AccountValidation';
import OtpValidation from './OtpValidation';
import Confirm from './components/modal/Confirm';
import SuccessAcct from './components/modal/SuccessAcct';
import RejectedOTP from './components/modal/RejectedAcct'
import { Steps, message } from "antd";
import "../../forgot.css"
import axios from "axios";
import {
    apiUrl,
    apiUrl3
} from "../../../../redux/actions/endpoint";
import { encrypt2 } from "../../../../encr";
import { decryptHandler2 } from "../../../../dec";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { ForgotAcctMethodAction } from '../../../../redux/actions';
import { convertBase64ToBinary } from "../../../../convert";
import LiveCheck from './liveCheck';
import { turnUpperCase } from '../../../Dashboard/components/helper'
import { apiAccount } from '../../../../service/apiAccount';
import { appUrls } from '../../../../service/urls';
import { authOApi } from '../../../../service/authOApi';


function Forgotaccount() {
    const { Step } = Steps;
    const history = useHistory();
    const [otp, setOtp] = useState("");
    const [mob, setMob] = useState("");
    const [bvnResults, setBvnResults] = useState({})
    const [customerNumber, setCustomerNumber] = useState();
    const [disableBtn, setDisableBtn] = useState(true);
    const [accountCount, setAccountCount] = useState(0);
    const [dob, setDob] = useState(
        moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.sssZ")
    );
    const [displayLive, setDisplayLive] = useState(false)
    const [drop, setDrop] = useState({
        method: "",
    });
    const { forgot_acct_method } = useSelector((methods) => methods);
    const dispatch = useDispatch();

    const handleChange = (name) => (event) => {
        setDrop({
            ...drop,
            [name]: event.target.value,
        });
        dispatch(ForgotAcctMethodAction.set_forgot_acct_method(event.target.value));
    };


    const [isLoading, setIsLoading] = useState(false);
    const [loading, setLoading] = useState(false);

    //stepper
    const [current, setCurrent] = useState(0);

    const [showOtp, setShowOtp] = useState(false);
    const handleCloseOtp = () => setShowOtp(false);
    const handleShowOtp = () => setShowOtp(true);

    const [showCompleted, setShowCompleted] = useState(false);
    const handleCloseCompleted = () => setShowCompleted(false);
    const handleShowCompleted = () => setShowCompleted(true);

    const [showSuccess, setShowSuccess] = useState(false);
    const handleCloseSuccess = () => setShowSuccess(false);
    const handleShowSuccess = () => setShowSuccess(true);

    const [showRejected, setShowRejected] = useState(false);
    const handleCloseRejected = () => setShowRejected(false);
    const handleShowRejected = () => setShowRejected(true);



    const [formValues, setFormValues] = useState({
        bvn: "",
        otp: "",
        dobirth: "",
        branch: "",
        firstName: "",
        secondName: "",
        lastName: "",
        phone: "",
        email: "",
        houseNo: "",
        address: "",
        landmark: "",
        state: "",
        addressSummary: "",
        signature: "",
        livePhoto: "",
        accountNo: "",
        gender: "",
        dao: null,
        refCode: "",
        imgBvn: "",
    });

    const handleProceed = () => {
        handleCloseOtp();
        handleShowSuccess();
        sendEmail();
        setLoading(false);
        setTimeout(() => {
            setShowSuccess(false);
        }, 180000);
    }

    const handleRejected = () => {
        handleCloseOtp()
        handleShowRejected()
    }

    const handleConfirm = () => {
        handleCloseCompleted();
        // setCurrent(0);
    };

    const rejectConfirmation = () => {
        handleCloseCompleted();
    }




    const handleMethod = () => {
        if (drop.method === "otp") {
            setDisplayLive(false)
            handleShowOtp()

        }
        if (drop.method === "liveness-check") {
            handleCloseOtp()
            setDisplayLive(true)
        }

    }

    const onDateChange = (event) => {
        setDob(event.target.value);
    };


    //handling form state change
    const handleFormChange =
        (name) =>
            ({ target }) => {
                setFormValues({
                    ...formValues,
                    [name]: target.value,
                });
            };

    const [bvnValue, setBvnValue] = useState({
        bvn: "",
    });

    //handling form state change
    const handleBvnChange = (name) =>
        ({ target }) => {
            setBvnValue({
                ...bvnValue,
                [name]: target.value,
            });
        };


    const getAccountCount = () => {
        const data = encrypt2(bvnValue.bvn);
       authOApi 
            .get(`${appUrls.FIORANO_GET_ACCT_COUNTBY_BVN}?Data=${data}`)
            .then((res) => {
                const response = decryptHandler2(res.data);
                setAccountCount(response.Count);
                setIsLoading(false);
            })
           .catch((error) => {
                setIsLoading(false);
            });
    };

    const fullInfo = () => {
        setLoading(true);
        getAccountCount();
        const data = encrypt2(bvnValue.bvn);
        authOApi
            .get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${data}`)
            .then((response) => {
                const res = decryptHandler2(response.data);
                if (res && res.ResponseCode === "00") {
                    let accountInfos = res.GetAccountFullInfo.BankAccountFullInfo;
                    let accounts = accountInfos.filter(
                        (x) => x.CustomerStatus !== "2" && x.BVN !== null
                    );
                    setBvnResults(accounts[0])
                    const accountNumber = []
                    if (accounts.length > 0) {
                        accountNumber.push(accounts[0].NUBAN);
                        setCustomerNumber(accountNumber);
                    }
                    else {
                        setCustomerNumber(
                            res.GetAccountFullInfo.BankAccountFullInfo.NUBAN
                        );

                    }
                    verifyBvn();
                } else {
                    message.error(
                        "Oops!!! There were no record found. Please provide your Account number"
                    );
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };

    const validateFirst = () => {
        fullInfo();
    };


    const verifyBvn = async () => {
        if (!dob) {
            message.error(
                "Oops!!! You're missing something. Please provide your BVN"
            );
            return;
        }
        if (!bvnValue.bvn) {
            message.error(
                "Oops!!! You're missing something. Please provide your Date of birth"
            );
            return;
        }
        if (!formValues.firstName) {
            message.error(
                "Oops!!! You're missing something. Please provide your first name"
            );
            return;
        }
        if (!formValues.lastName) {
            message.error(
                "Oops!!! You're missing something. Please provide your last name"
            );
            return;
        }
        if (drop.method === "none") {
            message.error(
                "Oops!!! You're missing something. Please choose the method in which you want to verify"
            );
        }
        else {

            setLoading(true);
            const payload = {
                bvn: bvnValue.bvn,
                dateOfBirth: dob,
            };
            const encryptedBvnData = encrypt2(payload);
            authOApi
                .post(`${appUrls.VERIFY_BVN}`, { data: encryptedBvnData })
                .then(async (response) => {
                    const results = await decryptHandler2(response.data);
                    if (results && results.ResponseCode === "00") {
                        localStorage.setItem("livedata", JSON.stringify(results));
                        const formValueFirstName = formValues.firstName.toLowerCase()
                        const formValueLastName = formValues.lastName.toLowerCase()
                        const resultsFirstName = results.FirstName.toLowerCase()
                        const resultsLastName = results.LastName.toLowerCase()
                        const formValueDob = moment(dob).format("DD-MMM-YYYY")
                        const resultsDob = results.DateOfBirth
                        if (formValueFirstName === resultsFirstName && formValueLastName === resultsLastName && formValueDob === resultsDob) {
                            handleMethod();
                            // handleProceed()
                        } else {
                            message.error(
                                "Oops! There's a mismatch with your name. Please provide your correct name."
                            );
                            setLoading(false)
                        }
                    } else {
                        message.error(
                            "Oops! There's a mismatch with your BVN information, please check and enter the correct details"
                        );
                        setLoading(false);
                    }
                    setLoading(false);
                    let payload2 = {
                        mobile: results.PhoneNumber,
                        email: results.Email || bvnResults.email,
                        clientID: "52",
                    };
                    setLoading(true);
                    const otpData = encrypt2(payload2);
                    authOApi
                        .post(`${appUrls.GENERATE_OTP}`, { data: otpData })
                        .then((response) => {
                            const resultss = decryptHandler2(response.data);
                            setMob(resultss.PhoneNumber);
                            setLoading(false);
                        })
                        .catch((error) => {
                            setLoading(false);
                        });
                    // next();
                })
                .catch((error) => {
                    setLoading(false);
                });
        }
    };



    const sendEmail = () => {
        setLoading(true)
        let payload = {
            emailAddress: bvnResults.email,
            accountNumber: bvnResults.NUBAN,
            firstName: turnUpperCase(live.FirstName)
        };
        const enc = encrypt2(payload);
        authOApi
            .post(`${appUrls.FORGOT_ACCT_NUMBER}`, { data: enc })
            .then(async (response) => {
                const res = await decryptHandler2(response.data);
                if (res && res.ResponseCode === "00") {
                    message.success(
                        "Yes!!!. An email has been sent to you."
                    );
                    setLoading(false)
                }
                else {
                    message.error(
                        "Oops!!! Something went wrong"
                    );
                    setLoading(false)
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    }

    const validateOTP = () => {
        setLoading(true);
        let payload = {
            mobile: mob,
            otp: otp,
        };
        const valOtp = encrypt2(payload);
        authOApi
            .post(`${appUrls.VALIDATE_OTP}`, { data: valOtp })
            .then(async (response) => {
                const results = await decryptHandler2(response.data);
                if (results && results.ResponseCode === "1") {
                    handleCloseOtp();
                    message.success("Great! Your BVN verification was successful!");
                    handleProceed()
                    setLoading(false);
                } else {
                    handleRejected()
                    setLoading(false);
                }
            })
            .catch((error) => {
                setLoading(false);
            });
    };



    let live = JSON.parse(localStorage.getItem("livedata"))



    return (
        <div>
            <div className="row">
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 displayNone">
                    <div className="leftDiv stickyDiv"></div>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                    <Header />

                    <div className="container-fluid pb-5 mt-5">
                        <div class="row mb-5">
                            <div class="container">
                                <div className="steps-content mb-5">
                                    {displayLive ? (
                                        <LiveCheck
                                            bvnRes={bvnResults}
                                            liveDetail={live}
                                            customerNumber={customerNumber}
                                            showSuccess={showSuccess}
                                            handleCloseSuccess={handleCloseSuccess}
                                            showCompleted={showCompleted}
                                            handleCloseCompleted={handleCloseCompleted}
                                            handleConfirm={handleConfirm}
                                            rejectConfirmation={rejectConfirmation}
                                            formValues={formValues}
                                        />
                                    ) : (
                                            <AccountValidation
                                                handleShowOtp={handleShowOtp}
                                                loading={loading}
                                                isLoading={isLoading}
                                                handleFormChange={handleFormChange}
                                                formValues={formValues}
                                                dob={dob}
                                                bvnValue={bvnValue}
                                                handleBvnChange={handleBvnChange}
                                                handleChange={handleChange}
                                                setDisableBtn={setDisableBtn}
                                                validateFirst={validateFirst}
                                                disableBtn={disableBtn}
                                                onDateChange={onDateChange}
                                            />

                                    )}

                                </div>
                                <div className="text-right"></div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <OtpValidation
                showOtp={showOtp}
                handleCloseOtp={handleCloseOtp}
                showCompleted={showCompleted}
                handleProceed={handleProceed}
                handleCloseCompleted={handleCloseCompleted}
                handleShowCompleted={handleShowCompleted}
                otp={otp}
                setOtp={setOtp}
                validateOTP={validateOTP}
                verifyBvn={verifyBvn}
                formValues={formValues}
                handleFormChange={handleFormChange}
                disableBtn={disableBtn}
                isLoading={isLoading}
            />

            <Confirm
                showCompleted={showCompleted}
                handleCloseCompleted={handleCloseCompleted}
                handleConfirm={handleConfirm}
                rejectConfirmation={rejectConfirmation}
            />

            <SuccessAcct
                showSuccess={showSuccess}
                customerNumber={customerNumber}
                handleCloseSuccess={handleCloseSuccess}
                bvnResults={bvnResults}
            />

            <RejectedOTP
                showRejected={showRejected}
                handleCloseRejected={handleCloseRejected}
            />
        </div>
    )

}

export default Forgotaccount