import React from "react";
import "./sidebar.css";
import { useHistory } from "react-router-dom";
import SterlingBall from "../../../../../Images/ball.png";
// import { FaPowerOff } from "react-icons/fa";
import { AiOutlinePoweroff } from "react-icons/ai";
import { MdOutlineDashboard, MdOutlineReceiptLong } from "react-icons/md";
// import { RiFilePaper2Line } from "react-icons/ri";
import { BiStats } from "react-icons/bi";
// import { AiOutlineSetting } from "react-icons/ai";

const Sidebar = ({ sidebarOpen, closeSidebar }) => {
  const history = useHistory();
  const path = history.location.pathname;

  const handleLogout = () => {
    localStorage.removeItem("auth");
    history.push("/dashboard");
  };

  return (
    <div className={sidebarOpen ? "sidesidebar_responsive" : ""}>
      <div className="brand">
        <a href="/home">
          <img src={SterlingBall} class="img-fluid" alt="Sterling Bank Logo" />
        </a>
      </div>

      <ul className="sidebar-links">
        <div
          className={
            path == "/admin/home" ? "links activeSidebarLink" : "links"
          }
          onClick={() => {
            history.push(`/admin/home`);
          }}
        >
          <li>
            {/* <div className="icon__img"> */}
            <MdOutlineDashboard className="icon__img" />
            {/* </div> */}
            <p>Home</p>
          </li>
        </div>

        <div
          className={
            path == "/admin/trends" ? "links activeSidebarLink" : "links"
          }
          onClick={() => {
            history.push(`/admin/trends`);
          }}
        >
          <li>
            {/* <div className="icon__img"> */}
            <BiStats className="icon__img" />
            {/* </div> */}
            <p>Trends</p>
          </li>
        </div>

        <div
          className={
            path == "/admin/reports" ? "links activeSidebarLink" : "links"
          }
          onClick={() => {
            history.push(`/admin/reports`);
          }}
        >
          <li>
            {/* <div className="icon__img"> */}
            <MdOutlineReceiptLong className="icon__img" />
            {/* </div> */}
            <p>Reports</p>
          </li>
        </div>

        <div className="logout">
          <li onClick={handleLogout}>
            <button className="icon__img">
              <AiOutlinePoweroff />
            </button>
          </li>
        </div>
      </ul>
    </div>
  );
};

export default Sidebar;
