import React from "react";
import { Link } from "react-router-dom";
import SterlingLogo from "../../../Images/Sterling_Bank_Logo_Straight_grey.png";

const Header = () => {
  return (
    <div className="px-3">
      <div class="row my-5 align-items-end">
        <div class="col logo">
          <Link to="/">
            <img
              src={SterlingLogo}
              class="img-fluid"
              alt="Sterling Bank Logo"
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Header;
