import axios from "axios";
import configureToken from "./axiosInterceptor";
import header from "./header";

const client = axios.create({
    baseURL: "https://camsapigateway-live.appsuport.p.azurewebsites.net/api/dashboards",
    header: header(),
});

export default configureToken(client);
