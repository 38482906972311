import React from "react";
import { Link } from "react-router-dom";

const CompanyAccount = () => {
  return (
    <div>
      <div id="newcompany-aact-req" className="">
        <div className="d-flex justify-content-between px-4">
          <h5 className="sec-header mb-3">Company account</h5>
          <div className="mb-4">
            <Link
              to="/companyAccount"
              className="pry-red-bg rounded-lg px-5 py-3 text-white"
            >
              Proceed
            </Link>
          </div>
        </div>
        <div className="row pry-grey-clr mb-4">
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Features</h6>
            <ul className="custom-req-marker pl-3">
              <li>
                Minimum account opening balance of =N=5,000 (Not Restrictive).
              </li>
              <li>Access to overdraft facility.</li>
              <li>AMF at =N=1/mille (Negotiable with huge turnover).</li>
              <li>Access to business finance.</li>
              <li>
                Internet and mobile banking facility i.e. online statement, SMS
                alert etc..
              </li>
              <li>Chequebook.</li>
              <li>International funds transfer allowed.</li>
            </ul>
          </div>
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Requirements</h6>
            <ul className="custom-req-marker pl-3">
              <li>Certificate of incorporation.</li>
              <li>Certified true copy of Form CO2 and CO7 or Form CAC 1.1</li>
              <li>
                Certified true copy of Memorandum and Articles of Association.
              </li>
              <li>Taxpayer identification number TIN.</li>
              <li>
                Basic information about Company (company name, company address,
                company email and phone number, Registration number).
              </li>
              <li>BVN of each signatory.</li>
              <li>
                Valid and acceptable means of Identification for each signatory.
              </li>
              <li>
                Basic information of each signatory/proprietor. (i.e., Name,
                Place and date of birth, Gender, Address, Telephone number)
              </li>
              <li>
                Board Resolution duly sealed and signed by two directors or a
                director and Secretary on company letter head.
              </li>
              <li>Two satisfactory corporate references.</li>
              <li>One recent passport photograph of each signatory.</li>
              <li>SCUML Certificate (where applicable).</li>
              <li>
                For professional bodies, evidence of duly registered membership
                of the professional body is required.
              </li>
              <li>
                CERPAC- Combined Expatriate Residence Permit and Alien Card for
                directors of the company and signatories to the account who are
                foreign nationals.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompanyAccount;
