import React from "react";
import Upload from "../../../../../../../../../../../Images/upload-icon.svg";

const EditMandateInstruction = () => {
  return (
    <div>
      <form action="">
        <div className="form-row mb-3 align-items-end">
          <div className="col-12 mb-3">
            <label for="" className="form-label ftsz-12 ftwt-500 mb-1 text-dark">
              Mandate instruction
            </label>
            <textarea
              className="form-control pry-input-border"
              rows="5"
              placeholder="Enter mandate instruction"
            ></textarea>
          </div>
          <div className="col-12 my-4 text-body">
            <h6 className="mb-0 fw-regular ftsz-12">
              The file format should be JPEG or PDF
            </h6>
          </div>
          <div className="form-row mb-3 mb-3">
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">Upload signature </h6>
              <label
                for=""
                className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative"
              >
                <span>Click to upload or drag and drop file</span>
                <span>
                  <img
                    src={Upload}
                    className="img-fluid"
                    alt=""
                  />
                </span>
                <input type="file" className="form-control position-absolute" />
              </label>
            </div>
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">Upload passport</h6>
              <label
                for=""
                className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative"
              >
                <span>Click to upload or drag and drop file</span>
                <span>
                  <img
                    src={Upload}
                    className="img-fluid"
                    alt=""
                  />
                </span>
                <input type="file" className="form-control position-absolute" />
              </label>
            </div>
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">Upload scanned ID</h6>
              <label
                for=""
                className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative"
              >
                <span>Click to upload or drag and drop file</span>
                <span>
                  <img
                    src={Upload}
                    className="img-fluid"
                    alt=""
                  />
                </span>
                <input type="file" className="form-control position-absolute" />
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default EditMandateInstruction;
