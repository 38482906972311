import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router-dom";
import Completed from "../../../../Images/completed.png";

const AgreeModal = ({ showAgreeModal, handleCloseAgreeModal }) => {
  const history = useHistory();
  return (
    <div>
      <Modal
        show={showAgreeModal}
        onHide={handleCloseAgreeModal}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Body>
          <div className="text-center">
            <div className="mb-3">
              <img src={Completed} className="img-fluid" alt="success" />
            </div>
            <div>
              <p className="my-3 bold-texts">Feedback recorded successfully !</p>
            </div>
            <button
              type="button"
              className="btn btn-outline-danger px-5 mb-3"
              onClick={() => {
                history.push("/");
              }}
            >
              Done
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AgreeModal;
