import {
    secretKey,
    intiVector
} from "./dec.js"

var CryptoJS = require("crypto-js");

export const encrypt1 = (data) => {
  const preEncrypteds = CryptoJS.AES.encrypt(
    data,
    secretKey,
    { iv: intiVector, mode: CryptoJS.mode.CBC, keySize: 128 / 8 }
  );
  const result = preEncrypteds.toString(CryptoJS.format.Hex);

  return result;
};
