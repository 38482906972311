import React, { useState } from "react";
import Slider from "react-slick";
import Glasses from "../../../../Images/glasses.png";
import Branch from "../../../../Images/branch.png";
import ATM from "../../../../Images/atmIcon.png";
import Existing from "../../../../Images/existingIcon.png";

import ATMBG from "../../../../Images/newImg/atm_bg.png";
import ATMNBG from "../../../../Images/newImg/atm_noBg.png";
import BRANCHNBG from "../../../../Images/newImg/branch_noBg.png";
import BRANCHBG from "../../../../Images/newImg/branchBg.png";
import FORGOT from "../../../../Images/newImg/forgot2.png"
import FORGOTBG from "../../../../Images/newImg/forgot1.png"
import GLASSESNBG from "../../../../Images/newImg/glasses-noBg.png";
import GLASSESBG from "../../../../Images/newImg/glassesFrame-color.png";
import USERBG from "../../../../Images/newImg/user_bg.png";
import USERNBG from "../../../../Images/newImg/user_noBg.png";

const SliderComponent = ({
  switchBtn,
  switchBtnEx,
  switchBranch,
  switchAtm,
  switchForgotAcct,
  handleShowOptions
}) => {
  const [over1, setOver1] = useState(false);
  const [over2, setOver2] = useState(false);
  const [over3, setOver3] = useState(false);
  const [over4, setOver4] = useState(false);
  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: "#eb1a21" }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block", color: "#eb1a21" }}
        onClick={onClick}
      />
    );
  }

  var settings = {
    // dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3.5,
    slidesToScroll: 1.5,
    initialSlide: 0,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          // dots: true
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const switchToTrue1 = () => {
    setOver1(true);
  };

  const switchToTrue2 = () => {
    setOver2(true);
  };

  const switchToTrue3 = () => {
    setOver3(true);
  };

  const switchToTrue4 = () => {
    setOver4(true);
  };

  const switchToFalse1 = () => {
    setOver1(false);
  };

  const switchToFalse2 = () => {
    setOver2(false);
  };

  const switchToFalse3 = () => {
    setOver3(false);
  };

  const switchToFalse4 = () => {
    setOver4(false);
  };

  return (
    <div className="pr-3 mobile-my">
      <Slider {...settings}>
        <div
          className="box"
          onMouseOver={switchToTrue1}
          onMouseOut={switchToFalse1}
          onClick={switchBtn}
        >
          <div className="imgHldr">
            <img src={over1 ? GLASSESBG : GLASSESNBG} alt="New customers" />
          </div>
          <h2 className="w-50 h2-fullwidth">New Customer</h2>
          <button className="cardBtn" onClick={switchBtn}>
            Start now
          </button>
        </div>

        <div
          className="box"
          onMouseOver={switchToTrue2}
          onMouseOut={switchToFalse2}
          onClick={switchBtnEx}
        >
          <div className="imgHldr2">
            <img src={over2 ? USERBG : USERNBG} alt="Existing customers" />
          </div>
          <h2 className="w-50 h2-fullwidth">Existing Customer</h2>
          <button className="cardBtn" onClick={switchBtnEx}>
            Start now
          </button>
        </div>

        <div
          className="box"
          onClick={switchAtm}
          onMouseOver={switchToTrue3}
          onMouseOut={switchToFalse3}
        >
          <div className="imgHldr3">
            <img src={over3 ? ATMBG : ATMNBG} alt="ATM Locator" />
          </div>
          <h2 className="w-50">ATM & Branch Locator</h2>
        </div>

        <div
          className="box"
          onClick={handleShowOptions}
          onMouseOver={switchToTrue4}
          onMouseOut={switchToFalse4}
        >
          <div className="imgHldr4">
            <img src={over4 ? FORGOT : FORGOTBG} alt="Forgot Account Number" />
          </div>
          <h2 className="w-50">Forgot Account Number /BVN</h2>
        </div>
      </Slider>
    </div>
  );
};

export default SliderComponent;
