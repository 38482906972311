import React from 'react'
import Modal from "react-bootstrap/Modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message } from "antd";

function Confirm({
    showCompleted,
    handleCloseCompleted,
    handleConfirm,
    rejectConfirmation
    // accountInfo,
    // bvnDetails,
}) {

    const [Copied] = React.useState("");

    return (
        <div>
            <Modal
                show={showCompleted}
                onHide={handleCloseCompleted}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <div className="text-center">
                        <div className="mb-3">
                            {/* <img src={Completed} className="img-fluid" alt="" /> */}
                        </div>
                        <div className="mb-5">
                            <h6 className="sec-header text-body mb-3 px-sm-5 mx-sm-5">
                                Paste OTP{" "}
                                {/* <span className="font-weight-bold">{bvnDetails.FirstName}</span>
                                Do you want to paste 4578? */}
                            </h6>
                            <p className="ftsz-regular">Do you want to paste 4578?</p>
                            {/* <span className="new-acct-num pry-red-clr pale-red-tone-bg rounded-lg pry-header px-3 py-2">
                                {accountInfo}{" "}
                                <CopyToClipboard
                                    text={Copied}
                                onCopy={() => {
                                    message.success({
                                        content: "Copied to clipboard",
                                        style: {
                                            position: "relative",
                                            right: "0",
                                            marginTop: "20px",
                                        },
                                    });
                                }}
                                >
                                    <button type="button" className="bare-btn">
                                        <img src={Copy} alt="copy" />
                                    </button>
                                </CopyToClipboard>
                            </span> */}
                        </div>
                        <div className='btn-flex'>
                            <button
                                className="btn btn-outline-danger px-5 py-2"
                                onClick={rejectConfirmation}
                            >
                                Cancel
                            </button>
                            <button
                                className="btn btn-danger px-5 py-2"
                                onClick={handleConfirm}
                            >
                                Paste
                            </button>

                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Confirm