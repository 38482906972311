import React from "react";
import Upload from "../../../../../../../../../../../Images/upload-icon.svg";
import Dropzone from "react-dropzone";

const MandateInstruction = ({
  formV,
  handleFormC,
  photoID,
  onDrop,
  onDrops,
  signatureUpload,
  onDropss,
  passport,
}) => {
  return (
    <div>
      <form action="">
        <div className="form-row mb-3 align-items-end">
          <div className="col-12 mb-3">
            <label
              for=""
              className="form-label ftsz-12 ftwt-500 mb-1 text-dark"
            >
              Mandate instruction
            </label>
            <textarea
              className="form-control pry-input-border"
              rows="5"
              placeholder="Enter mandate instruction"
              value={formV.mandate}
              onChange={handleFormC("mandate")}
            ></textarea>
          </div>
          <div className="col-12 my-4 text-body">
            <h6 className="mb-0 fw-regular ftsz-12">
              The file format should be PNG, JPEG or PDF
            </h6>
          </div>
          <div className="form-row mb-3 mb-3">
            <div className="col-12 col-md-10 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
                Upload signature{" "}
              </h6>
                  <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                    <Dropzone
                      onDrop={onDrops}
                      multiple={false}
                      accept="image/jpeg, image/png, .pdf"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="doc-uploads">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} placeholder="" />
                            {!!signatureUpload.length &&
                              signatureUpload.map((file) => (
                                <p className="doc-texts">{file.path}</p>
                              ))}

                            {!signatureUpload.length && (
                              <section className="doc-texts">
                                Drag 'n' drop , or click to select
                              </section>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  {signatureUpload ? (
                    <p className="my-3 font-weight-bold text-danger">
                      {signatureUpload.path}
                    </p>
                  ) : (
                    <p className="my-3">No file uploaded</p>
                  )}
                </div>

                <div className="col-12 col-md-10 mb-3 ftsz-12 ">
                  <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
                    Upload passport
                  </h6>
                  <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                    <Dropzone
                      onDrop={onDrop}
                      multiple={false}
                      accept="image/jpeg, image/png, .pdf"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="doc-uploads">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} placeholder="" />
                            {!!photoID.length &&
                              photoID.map((file) => (
                                <p className="doc-texts">{file.path}</p>
                              ))}

                            {!photoID.length && (
                              <section className="doc-texts">
                                Drag 'n' drop , or click to select
                              </section>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  {photoID ? (
                    <p className="my-3 font-weight-bold text-danger">
                      {photoID.path}
                    </p>
                  ) : (
                    <p className="my-3">No file uploaded</p>
                  )}
                </div>

                <div className="col-12 col-md-10 mb-3 ftsz-12 ">
                  <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
                    Scanned ID
                  </h6>
                  <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                    <Dropzone
                      onDrop={onDropss}
                      multiple={false}
                      accept="image/jpeg, image/png, .pdf"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="doc-uploads">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} placeholder="" />
                            {!!passport.length &&
                              passport.map((file) => (
                                <p className="doc-texts">{file.path}</p>
                              ))}

                            {!passport.length && (
                              <section className="doc-texts">
                                Drag 'n' drop , or click to select <br />
                              </section>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  {passport ? (
                    <p className="my-3 font-weight-bold text-danger">
                      {passport.path}
                    </p>
                  ) : (
                    <p className="my-3">No file uploaded</p>
                  )}
                </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default MandateInstruction;
