import React, { useState, useEffect } from "react";
import Warning from "../../../../../../../../Images/warning-icon.svg";
import Copy from "../../../../../../../../Images/copy-icon.png";
import Delete from "../../../../../../../../Images/delete.png";
import { Drawer, message } from "antd";
import { Link } from "react-router-dom";
import AddReference from "./components";
import axios from "axios";
import Options from "./components/options";
import { encrypt2 } from "../../../../../../../../encr";
import { decryptHandler2 } from "../../../../../../../../dec";
import { apiUrl } from "../../../../../../../../redux/actions/endpoint";
import Modal from "react-bootstrap/Modal";
import { appUrls } from "../../../../../../../../service/urls";
// import { apiAccount } from "../../../../../../../../service/apiAccount";
import { authOApi } from '../../../../../../../../service/authOApi';


const ReferenceBusiness = ({
  accountInfo,
  formValues,
  handleFormChange,
  current,
  setCurrent,
  fileUrl,
  showAddRefOTP,
  handleCloseAddRefOTP,
  handleShowAddRefOTP,
  handleShowRefSuccessOTP,
  handleFormChanges,
  formVal
}) => {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [refereesList, setReferrsList] = useState([]);
  const [loading, setLoading] = useState(false);

  const [showDeleted, setShowDeleted] = useState(false);
  const handleCloseDeleted = () => setShowDeleted(false);
  const handleShowDeleted = () => setShowDeleted(true);

  const onClose = () => {
    setVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const getReference = () => {
    const data = encrypt2(accountInfo);
    authOApi
      .get(`${appUrls.GET_CUSTOMER_REFERENCES}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);

        setReferrsList(res);
      });
  };

  const deleteReferee = async (Id) => {
    setLoading(true);
    const enc = encrypt2(Id);
    await authOApi
      .delete(`${apiUrl}/DeleteReferenceById`, {
        data: JSON.stringify({ data: enc }),
      })
      .then((response) => {
        message.success("Referee Deleted !");
        getReference();
        setLoading(false);
        handleCloseDeleted();
      })
      .catch((error) => {
        message.error("Unable to delete referee !");
        handleCloseDeleted();
        setLoading(false);
      });
  };

  useEffect(() => {
    getReference();
  }, []);

  return (
    <div>
      <div class="text-center">
        <div class="mb-5">
          <h6 class="sec-header text-body text-center mb-3 px-sm-5 mx-sm-5">
            Add reference
          </h6>
          <div class="mb-4">
            <p class="text-center mx-md-5">
              Proceed to add reference, Kindly ensure that the referee’s account
              meets the following requirements:
            </p>
            <ul class="pl-3 text-left">
              <li class="custom-green-marker mb-2">
                Account has been in operation for more than 6 months.
              </li>
              <li class="custom-green-marker mb-2">Account is not dormant</li>
              <li class="custom-green-marker mb-2">
                Category criteria (References from a registered business can
                only be used for an individual account or another registered
                business but a reference from a registered business is suitable
                for all forms of current account.)
              </li>
            </ul>
          </div>
          <div class="d-flex align-items-center pale-red-tone-bg pry-red-clr ftsz-12 mb-0 mt-3 px-3 py-2 mb-3 rounded-lg">
            <img src={Warning} class="mr-3" alt="warning icon" />
            <div>
              <ul class="pl-3 text-left ftwt-600">
                <li class="custom-red-marker">
                  For refree with sterling account, an email is sent to the
                  referee to provide consent via a secured link.{" "}
                </li>
                <li class="custom-red-marker">
                  For refree with other bank account, download or email form to
                  refree and upload the completed form.{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="">
          <p className="text-left text-muted font-weight-bold">References</p>
          {/* <button onClick={getReference}>reload references</button> */}
          {refereesList.length != 0 ? (
            <div className="d-flex justify-content-between">
              <div>
                {refereesList.map((refereee, id) => (
                  <div className="d-flex justify-content-between " key={id}>
                    <p>{refereee.RefereeAccountName}</p>
                    <div className="imageToMove">
                      <button type="button" className="bare-btn">
                        <img
                          src={Delete}
                          alt="Delete signaory"
                          onClick={() => {

                            handleShowDeleted();
                          }}
                        />
                      </button>
                    </div>
                    <div>
                      <Modal
                        show={showDeleted}
                        onHide={handleCloseDeleted}
                        backdrop="static"
                        keyboard={false}
                      >
                        <Modal.Body>
                          <div className="text-center">
                            <div className="mb-5">
                              <h6 className="sec-header mb-4 mt-5 pt-2">
                                Delete signatory
                              </h6>
                              <p className="mb-5 pry-grey-clr">
                                Are you sure you want to delete this signatory?
                              </p>
                              <div className="">
                                <div className="bdcenter-modal-btngrp d-flex justify-content-between">
                                  <button
                                    type="button"
                                    className="btn btn-outline-danger mr-3"
                                    onClick={handleCloseDeleted}
                                  >
                                    Cancel
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger pry-red-bg"
                                    disabled={loading}
                                    onClick={() => {
                                     
                                      let refSig = refereee.Id;
                                      deleteReferee(refSig);
                                    }}
                                  >
                                    {loading ? "Deleting..." : "Delete"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <p className="text-left">No references added</p>
          )}
        </div>
        <div>
          <div className="text-right mb-2">
            <Link
              to="#"
              class="px-5 py-2 text-danger text-underline text-right"
              onClick={showDrawer}
            >
              Add reference
            </Link>
          </div>
        </div>
      </div>
      <Drawer
        title="Add reference"
        placement={placement}
        width={500}
        onClose={onClose}
        visible={visible}
      >
        <Options
          onClose={onClose}
          accountInfo={accountInfo}
          formValues={formValues}
          handleFormChange={handleFormChange}
          current={current}
          setCurrent={setCurrent}
          fileUrl={fileUrl}
          showAddRefOTP={showAddRefOTP}
          handleCloseAddRefOTP={handleCloseAddRefOTP}
          handleShowAddRefOTP={handleShowAddRefOTP}
          handleShowRefSuccessOTP={handleShowRefSuccessOTP}
          handleFormChanges={handleFormChanges}
          formVal={formVal}
        />
      </Drawer>
    </div>
  );
};

export default ReferenceBusiness;
