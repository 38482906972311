import React from "react";
import Dropzone from "react-dropzone";
import UploadIcon from "../../../../../../Images/upload-icon.svg";
import { Link } from "react-router-dom";
import ImageCapture from "react-image-data-capture";
import { Spinner } from "react-bootstrap";

const DocumentUpload = ({
  onDrops,
  minorImage,
  onCapture,
  config,
  onError,
  imgSrc,
  retake,
  onDrop,
  frontPage,
  birthCert,
  onDropp,
  onDroppp,
  parentSignature,
  formValues,
  handleFormChange,
  branchCode,
  tcHandler,
  handleShowTC,
  uploadPic,
  onDropsss,
  startHyperverge,
  hpvImage,
  isLoadingHpvSdk,
}) => {
  const MAX_SIZE = 2097152;
  return (
    <div>
      <form action="">
        <div className="my-4 text-center text-body">
          <h5 className="mb-0 sec-header">Document upload</h5>
          <h6 className="mb-0 fw-regular ftsz-12">
            **The file format should be JPEG or PDF or PNG
          </h6>
        </div>
        <div className="row mb-3 mb-3">
          <div className="col-12 col-md-6 mb-3 ftsz-12 ">
            <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
              Upload picture of Minor{" "}
            </h6>
            {minorImage ? <p>{minorImage.path}</p> : <p>No file uploaded</p>}
            <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
              <Dropzone
                onDrop={onDrops}
                multiple={false}
                accept="image/jpeg, image/png"
                maxSize={MAX_SIZE}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="doc-uploads">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} placeholder="" />
                      {!!minorImage.length &&
                        minorImage.map((file) => (
                          <p className="doc-texts">{file.path}</p>
                        ))}

                      {!minorImage.length && (
                        <section className="doc-texts">
                          Drag 'n' drop , or click to select <br />
                        </section>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-3 ftsz-12 ">
            <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
              Front page of ID card of Parent/Guardian
            </h6>
            {frontPage ? <p>{frontPage.path}</p> : <p>No file uploaded</p>}
            <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
              <Dropzone
                onDrop={onDropp}
                multiple={false}
                accept="image/jpeg, image/png"
                maxSize={MAX_SIZE}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="doc-uploads">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} placeholder="" />
                      {!!frontPage.length &&
                        frontPage.map((file) => (
                          <p className="doc-texts">{file.path}</p>
                        ))}

                      {!frontPage.length && (
                        <section className="doc-texts">
                          Drag 'n' drop , or click to select <br />
                        </section>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-3 ftsz-12 ">
            <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
              Scanned copy of Minor's birth certificate/NIN/International
              passport
            </h6>
            {birthCert ? <p>{birthCert.path}</p> : <p>No file uploaded</p>}
            <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
              <Dropzone
                onDrop={onDrop}
                multiple={false}
                accept="image/jpeg, image/png"
                maxSize={MAX_SIZE}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="doc-uploads">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} placeholder="" />
                      {!!birthCert.length &&
                        birthCert.map((file) => (
                          <p className="doc-texts">{file.path}</p>
                        ))}

                      {!birthCert.length && (
                        <section className="doc-texts">
                          Drag 'n' drop , or click to select <br />
                        </section>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-3 ftsz-12 ">
            <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
              Signature of Parent/Guardian
            </h6>
            {parentSignature ? (
              <p>{parentSignature.path}</p>
            ) : (
              <p>No file uploaded</p>
            )}
            <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
              <Dropzone
                onDrop={onDroppp}
                multiple={false}
                accept="image/jpeg, image/png"
                maxSize={MAX_SIZE}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="doc-uploads">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} placeholder="" />
                      {!!parentSignature.length &&
                        parentSignature.map((file) => (
                          <p className="doc-texts">{file.path}</p>
                        ))}

                      {!parentSignature.length && (
                        <section className="doc-texts">
                          Drag 'n' drop , or click to select <br />
                        </section>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
          </div>

          <div className="col-12 col-md-6 mb-3 ftsz-12 ">
            <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
              Take live picture
            </h6>
            <div className="col-6 col-md-6 mb-3 ftsz-12 ">
              <div
                style={{ width: "300px", cursor: "pointer" }}
                className="bb"
                onClick={() => {
                  startHyperverge(formValues.imgBvn);
                }}
              >
                {hpvImage !== "" && (
                  <img src={hpvImage} alt="avatar" style={{ width: "100%" }} />
                )}
                {hpvImage === "" && (
                  <div class="avatar-hpv">
                    <div class="avatar-text-hpv">
                      {isLoadingHpvSdk ? (
                        <>
                          Loading Sdk....{" "}
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </>
                      ) : (
                        "Please Click here to Take a Photo"
                      )}
                    </div>
                  </div>
                )}
                {/* <Link className="displayImage">Upload Image</Link> */}
              </div>
            </div>
          </div>

          <div className="col-6 col-md-6 mb-3 ftsz-12 ">
            <h6 className="ftwt-500 ftsz-12 mb-1 text-body">Upload Picture </h6>
            <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
              <Dropzone
                onDrop={onDropsss}
                multiple={false}
                accept="image/jpeg, image/png"
                maxSize={MAX_SIZE}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="doc-uploads">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} placeholder="" />
                      {!!uploadPic.length &&
                        uploadPic.map((file) => (
                          <p className="doc-texts">{file.path}</p>
                        ))}

                      {!uploadPic.length && (
                        <section className="doc-texts">
                          Drag 'n' drop , or click to select <br />
                        </section>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {uploadPic ? (
              <p className="my-3 font-weight-bold text-danger">
                {uploadPic.path}
              </p>
            ) : (
              <p className="my-3">No file uploaded</p>
            )}
          </div>

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="form-group">
              <label>Branch of account domiciliation.</label>
              <select
                className="form-control"
                onChange={handleFormChange("branch")}
                required
                value={formValues.branch}
              >
                {branchCode.map((branches) => (
                  <option value={branches.BranchCode}>
                    {branches.BranchName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-12 col-sm-12 mb-3 mt-2">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(ev) => tcHandler(ev.target.checked)}
              />
              <label className="form-check-label ftsz-regular text-body" for="">
                By checking this box, you have accepted the{" "}
                <a
                  className="text-red"
                  style={{ color: "red", textDecoration: "underline" }}
                  href="javascript:;"
                  onClick={(e) => {
                    handleShowTC(e);
                  }}
                >
                  Terms and conditions
                </a>
              </label>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DocumentUpload;
