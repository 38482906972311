import React from "react";
import SideBar from "../../components/SideBar";
import ContentOne from "./components/contentOne";

const PersonalAccount = () => {
  return (
    <div>
      <div className="container-fluid px-sm-0">
        <div className="row">
          <div className="col-md-4 noPadding">
            <SideBar />
          </div>
          <div className="col-12 col-md-8 pt-5">
              <ContentOne />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalAccount;
