import { SET_BANK_TYPE } from "../types";

export const setBank = (payload) => ({
    type: SET_BANK_TYPE,
    payload,
});

const exportedObject = {
    setBank,
}

export default exportedObject;