import React, { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import Header from "../../Dashboard/components/Header";
import { getQueryParam } from "../../../utils";
import { api } from "../../../service/api";
import { appUrls } from "../../../service/urls";
import { message } from "antd";

const Createpassword = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [inputVal, setInputVal] = useState({
    password: "",
    confirm_password: "",
  });
  const [userData, setUserData] = useState({});

  useEffect(() => {
    let mounted = false;
    (async () => {
      mounted = true;
      if (mounted) {
        handleVerifyToken();
      }
    })();
    return () => {
      mounted = false;
    };
  }, []);

  const handleVerifyToken = async () => {
    setLoading(true);
    const token = getQueryParam("token");
    const payload = {
      token,
    };
    try {
      const res = await api.post(appUrls.ADMIN_VERIFY_TOKEN, payload);
      if (res?.data?.success) {
        setUserData(res?.data?.data);
      }
    } catch (error) {
      message.error(error?.data?.message, 3);
    } finally {
      setLoading(false);
    }
  };

  const handleValidation = () => {
    if (
      inputVal?.password.trim() === "" &&
      inputVal?.confirm_password.trim() === ""
    ) {
      message.error("Username and password is Empty..", 5);
      return;
    }
    if (inputVal?.password.trim() === "") {
      message.error("Password cannot be Empty..", 5);
      return;
    }
    if (inputVal?.confirm_password.trim() === "") {
      message.error("Username cannot be Empty..", 5);
      return;
    }
    handleChangePassword();
  };

  const handleChangePassword = async () => {
    setLoading(true);
    const token = getQueryParam("token");
    const payload = {
      email: userData?.email,
      firstName: userData?.firstName,
      lastName: userData?.lastName,
      password: inputVal?.password,
      token: token,
    };
    try {
      const res = await api.post(appUrls.ADMIN_SET_PASSWORD, payload);
      if (res?.data?.success) {
        message.success(res?.data?.message, 3);
        setLoading(false);
        history.push("/api-dashboard/login");
      }
    } catch (error) {
      message.error(error?.data?.message, 3);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 displayNone">
          <div className="leftDiv stickyDiv"></div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <Header />
          <div className="container-fluid pb-5 mt-5">
            <div className="row mb-5">
              <div className="container w-100">
                <div className="steps-content mb-1">
                  <div className="w-80">
                    <form action="">
                      <div className="mb-2 text-centers text-dark mb-5">
                        <h3 className="mb-0 sec-headers">
                          <span
                            onClick={() => history.push("/")}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fas fa-long-arrow-alt-left mr-2"></i>
                          </span>
                          Create Password
                        </h3>
                      </div>
                      <div className="form-row mb-3">
                        <div className="form-group col-12 ftsz-12">
                          <h6 className="ftwt-500 ftsz-12 mb-1 text-dark">
                            Password
                          </h6>
                          <input
                            className="input-pad form-control pry-input-border"
                            placeholder="Enter your password"
                            type="password"
                            onChange={(e) => {
                              setInputVal({
                                ...inputVal,
                                password: e.target.value,
                              });
                            }}
                            value={inputVal.password}
                          />
                        </div>
                        <div className="form-group col-12 ftsz-12">
                          <h6 className="ftwt-500 ftsz-12 mb-1 text-dark">
                            Confirm Password
                          </h6>
                          <input
                            className="input-pad form-control pry-input-border"
                            placeholder="Confirm password"
                            type="password"
                            onChange={(e) => {
                              setInputVal({
                                ...inputVal,
                                confirm_password: e.target.value,
                              });
                            }}
                            value={inputVal.confirm_password}
                          />
                        </div>
                      </div>
                    </form>
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 validateBtn btn-block"
                      onClick={handleValidation}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Create password"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Createpassword;
