import React, { useState, useEffect } from "react";
import { Drawer, message } from "antd";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import Warning from "../../../../../../../../Images/warning-icon.svg";
import Edit from "../../../../../../../../Images/Edit.png";
import Delete from "../../../../../../../../Images/delete.png";
import DirectorsStep from "./components";
import { encrypt2 } from "../../../../../../../../encr";
import { apiUrl } from "../../../../../../../../redux/actions/endpoint";
import { authOApi } from "../../../../../../../../service/authOApi";

const Directors = ({
  dirList,
  formValues,
  handleFormChanges,
  dob,
  onDateChange,
  setFormValues,
  formVal,
  handleFormChange,
  directorsList,
  formValuess,
  setFormValuess,
  handleFormChangess,
  loading,
  setLoading,
  errorMsgs,
  setDirList,
  tin,
  getCustomerID,
}) => {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");

  const [showDeleted, setShowDeleted] = useState(false);
  const handleCloseDeleted = () => setShowDeleted(false);
  const handleShowDeleted = () => setShowDeleted(true);

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const onClose = () => {
    setVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const deleteDirectors = async (refId) => {
    setLoading(true);
    const enc = encrypt2(refId);
    await authOApi
      .delete(`${apiUrl}/DeleteDirectorById`, {
        data: JSON.stringify({ data: enc }),
      })
      .then((response) => {
        message.success("Director Deleted !");
        directorsList();
        setLoading(false);
        handleCloseDeleted();
      })
      .catch((error) => {
        message.error("Unable to delete signatory !");
        handleCloseDeleted();
        setLoading(false);
      });
  };

  useEffect(() => {
    getCustomerID(formValues.bvn);
  }, []);

  return (
    <div>
      <form action="">
        <h5 className="my-4 sec-header text-body text-center">Add directors</h5>
        <p className="pale-red-tone-bg pry-red-clr ftwt-600 ftsz-12 mb-0 px-3 py-2 mb-3 rounded-lg">
          <img src={Warning} className="mr-3" alt="" />
          For a seamless experience, please ensure your BVN information is up to
          date.
        </p>
        <div className="row mb-3">
          <div className="col-12 mb-4 mt-1">
            <h6 className="ftsz-12 font-weight-bold mb-4">Director(s)</h6>
            <div>
              <div className="col-md-auto">
                {loading ? (
                  <div>
                    <Spinner animation="border" role="status">
                      <span className="visually-hidden"></span>
                    </Spinner>
                  </div>
                ) : (
                  <div>
                    {errorMsgs === 404 ? (
                      <p className="ftsz-regular">
                        You do not have any signatories
                      </p>
                    ) : (
                      <div>
                        {/* {dirList.length ? (<div></div>) : (<p>No </p>) } */}
                        {dirList.map((directors, index) => (
                          <div key={index}>
                            {directors.length === 0 ? (
                              <p className="ftsz-regular text-body">
                                No directors added
                              </p>
                            ) : (
                              <div className="director-list form-row justify-content-between text-body align-items-end mb-3  pb-3 border-bottom-pale2">
                                <div className="col-md-auto">
                                  <p className="ftsz-12"></p>
                                  <p className="ftsz-regular text-body">
                                    {`${directors.FirstName} ${directors.LastName}`}
                                  </p>
                                </div>
                                <div className="col-md-auto pale-red-tone-bg">
                                  <button type="button" className="bare-btn">
                                    <img
                                      src={Delete}
                                      alt="Delete director"
                                      onClick={() => {
                                        
                                        handleShowDeleted();
                                      }}
                                    />
                                  </button>
                                </div>
                                <div>
                                  <Modal
                                    show={showDeleted}
                                    onHide={handleCloseDeleted}
                                    backdrop="static"
                                    keyboard={false}
                                  >
                                    <Modal.Body>
                                      <div className="text-center">
                                        <div className="mb-5">
                                          <h6 className="sec-header mb-4 mt-5 pt-2">
                                            Delete signatory
                                          </h6>
                                          <p className="mb-5 pry-grey-clr">
                                            Are you sure you want to delete this
                                            signatory?
                                          </p>
                                          <div className="">
                                            <div className="bdcenter-modal-btngrp d-flex justify-content-between">
                                              <button
                                                type="button"
                                                className="btn btn-outline-danger mr-3"
                                                onClick={handleCloseDeleted}
                                              >
                                                Cancel
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-danger pry-red-bg"
                                                disabled={loading}
                                                onClick={() => {
                                                  
                                                  let refSig = directors.Id;
                                                  deleteDirectors(refSig);
                                                }}
                                              >
                                                {loading
                                                  ? "Deleting..."
                                                  : "Delete"}
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </Modal.Body>
                                  </Modal>
                                </div>
                              </div>
                            )}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="text-right border-bottom-pale2 pb-4">
              <button
                type="button"
                className="bare-btn pry-red-clr text-underline ftsz-12"
                onClick={showDrawer}
              >
                Click to add director
              </button>
            </div>
          </div>
        </div>
      </form>
      <Drawer
        title="Add directors"
        placement={placement}
        width={500}
        onClose={onClose}
        visible={visible}
      >
        <DirectorsStep
          formValues={formValues}
          handleFormChanges={handleFormChanges}
          dob={dob}
          onDateChange={onDateChange}
          setFormValues={setFormValues}
          onClose={onClose}
          formVal={formVal}
          handleFormChange={handleFormChange}
          formValuess={formValuess}
          setFormValuess={setFormValuess}
          handleFormChangess={handleFormChangess}
          setDirList={setDirList}
          directorsList={directorsList}
          dirList={dirList}
          tin={tin}
        />
      </Drawer>
    </div>
  );
};

export default Directors;
