import React, { useEffect, useState } from "react";
import "./reports.css";
import { Table } from "react-bootstrap";
import dashboard from "../../../service/dashboard";
import { turnDate } from "../components/helper"
import Spinner from "react-bootstrap/Spinner";

function Reports() {
  const [reports, setReports] = useState([])
  const [loading, setLoading] = useState(false);



  const [tableHead] = useState([
    { id: 1, name: "Date" },
    { id: 2, name: "Account Number" },
    { id: 3, name: "Account Type" },
    { id: 4, name: "Csutomer ID" },
    { id: 5, name: "Currency" },
    { id: 6, name: "Gender" },
    { id: 7, name: "Document Upload" },
    { id: 8, name: "Platform" },
    { id: 9, name: "Status" },
  ]);

  const getReports = async () => {
    setLoading(true);
    await dashboard
      .get("account-details/reports")
      .then((res) => {
        if (res.status === 200) {
          setReports(res.data.data)
          setLoading(false);
        }
      })
      .catch((err) => {
        
      });
  }

  useEffect(() => {
    getReports()
  }, [])


  return (
    <div>
      {loading ? (
        <Spinner
          as="span"
          animation="border"
          role="status"
          size="lg"
          aria-hidden="true"
        />
      ) : (
          <div className="main__container">
            <div className="header">
              <ul>
                <h1>Reports</h1>
                <div>
                  <input placeholder="Search" type="text" className="search" />
                </div>
              </ul>

            </div>
            <div className="filter">
              <div className="selectContainer">
                <select className="select" name="" id="">
                  <option>Platform</option>
                </select>
                <select className="select" name="" id="">
                  <option>Account Type</option>
                </select>
                <select className="select" name="" id="">
                  <option>All Branches</option>
                </select>
                <select className="select" name="" id="">
                  <option>All Status</option>
                </select>
              </div>
              <div className="buttonContainer">
                <button className="buttonStyle">Apply</button>
                <button className="buttonStyle">Export</button>
              </div>
            </div>
            <div>
              <Table striped bordered variant="dark" size="sm" responsive>
                <thead>
                  <tr>
                    {tableHead.map((list, index) => {
                      return <th key={index}>{list?.name}</th>;
                    })}
                  </tr>
                </thead>
                <tbody>
                  {reports.map((report, index) => {
                    return (
                      <tr key={index}>
                        <td>{turnDate(report.addedDate)}</td>
                        <td>{report.accountNumber}</td>
                        <td>{report.accountType}</td>
                        <td>{report.customerId}</td>
                        <td>{report.currency}</td>
                        <td>{report.gender}</td>
                        <td>{report.documentUpload}</td>
                        <td>{report.platform === null ? "" : report.platform}</td>
                        <td>{report.status}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
        </div>

      )}

    </div>
  );
}

export default Reports;
