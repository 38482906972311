import { useState } from "react";

export default () => {
  //otp modal
  const [showOtp, setShowOtp] = useState(false);
  const handleCloseOtp = () => setShowOtp(false);
  const handleShowOtp = () => setShowOtp(true);

  //invalid bvn modal
  const [showInvalid, setShowInvalid] = useState(false);
  const handleCloseInvalid = () => setShowInvalid(false);
  const handleShowInvalid = () => setShowInvalid(true);

  //completed modal
  const [showCompleted, setShowCompleted] = useState(false);
  const handleCloseCompleted = () => setShowCompleted(false);
  const handleShowCompleted = () => setShowCompleted(true);

  const [showInvalidOTP, setShowInvalidOTP] = useState(false);
  const handleCloseInvalidOTP = () => setShowInvalidOTP(false);
  const handleShowInvalidOTP = () => setShowInvalidOTP(true);

  const [showExistingOTP, setShowExistingOTP] = useState(false);
  const handleCloseExistingOTP = () => setShowExistingOTP(false);
  const handleShowExistingOTP = () => setShowExistingOTP(true);

  const [showTC, setShowTC] = useState(false);
  const handleCloseTC = () => setShowTC(false);
  const handleShowTC = () => setShowTC(true);

  const [isOpen, setIsOpen] = useState(false)
  const handleClosePendingModal = () => setIsOpen(false);
  const handleShowPendingModal = () => setIsOpen(true);


  return [
    showOtp,
    handleCloseOtp,
    handleShowOtp,
    showInvalid,
    handleCloseInvalid,
    handleShowInvalid,
    showCompleted,
    handleCloseCompleted,
    handleShowCompleted,
    showInvalidOTP,
    handleCloseInvalidOTP,
    handleShowInvalidOTP,
    showExistingOTP,
    handleCloseExistingOTP,
    handleShowExistingOTP,

    showTC,
    handleCloseTC,
    handleShowTC,
    isOpen,
    setIsOpen,
    handleClosePendingModal,
    handleShowPendingModal
  ];
};
