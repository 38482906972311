export default function authHeader() {
    if (localStorage.getItem('auth') !== null) {
        const currentUser = JSON.parse(
            localStorage.getItem('auth') || '{}'
        );

        if (currentUser && currentUser.auth) {
            return {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Accept: 'application/json, text/plain',
            };
        } else {
            return {};
        }
    } else {
        return
    }

}