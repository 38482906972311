import React from 'react'
import Pagination from '../pagination';
import { Table } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';
import { Divider } from 'antd';

function RequestBranchesTable() {
    const location = useLocation()
    const { fromHome } = location.state



    return (
        <div>
            <Table striped bordered variant="dark" size="sm" responsive>
                <thead>
                    <tr>
                        <th>S/N</th>
                        <th>Branch</th>
                        <th>Region</th>
                        <th>Accounts Opened</th>
                    </tr>
                </thead>
                <tbody>
                    {fromHome.branches.map((branch, index) => {
                        return (
                            <tr>
                                <td>{index + 1}</td>
                                <td>{branch.branchName}</td>
                                <td>{branch.regionName}</td>
                                <td>{branch.totalCount}</td>
                            </tr>

                        )
                    })}
                </tbody>
            </Table>
            <Pagination />
        </div>

    )
}

export default RequestBranchesTable