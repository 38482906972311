import React, { useState } from "react";
import Info from "../../../Images/Info_icon.svg";
import axios from "../../../service/common";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";

const DashboardLogin = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [inputVal, setInputVal] = useState({
    username: "",
    password: "",
  });

  const login = async () => {
    setLoading(true);
    let payload = {
      ...inputVal,
    };

    await axios
      .post("login", payload)
      .then(function (response) {
        if (response.data.success === true) {
          localStorage.setItem("auth", JSON.stringify(response.data.data));
          setLoading(false);
          history.push("/admin/home");
        }
      })
      .catch((err) => {
        
      });
  };

  return (
    <div className="w-80">
      <form action="">
        <div class="mb-2 text-centers text-dark mb-5">
          <h3 class="mb-0 sec-headers">
            <span style={{ cursor: "pointer" }}>
              <i className="fas fa-long-arrow-alt-left mr-2"></i>
            </span>
            Dashboard
          </h3>
        </div>
        <div class="form-row mb-3">
          <div class="form-group col-12">
            <label
              for=""
              class="pry-grey-clr ftsz-12 ftwt-500 mb-1 d-flex justify-content-between align-items-center"
            >
              <span>Username</span>
              <div class="popover-wrapper position-relative">
                <div class="popover-title">
                  <img src={Info} alt="" style={{ cursor: "pointer" }} />
                </div>
                <div class="popover-content ftsz-12 p-2">
                  <ul class="pl-3">
                    <li>
                      The Username required is your Sterling official email
                    </li>
                  </ul>
                </div>
              </div>
            </label>
            <div className="form-group mb-0">
              <input
                type="text"
                class="form-control pry-input-border"
                id=""
                placeholder="Enter your username"
                onChange={(e) => {
                  setInputVal({ ...inputVal, username: e.target.value });
                }}
                value={inputVal.username}
              />
            </div>
          </div>
          <div class="form-group col-12 ftsz-12">
            <h6 class="ftwt-500 ftsz-12 mb-1 text-dark">Password</h6>
            <input
              className="input-pad form-control pry-input-border"
              placeholder="Enter your password"
              type="password"
              onChange={(e) => {
                setInputVal({ ...inputVal, password: e.target.value });
              }}
              value={inputVal.password}
            />
            {/* </div> */}
          </div>

          <div class="form-group col-12 ftsz-12">
            <h6 class="ftwt-500 ftsz-12 mb-1 text-dark">Token</h6>

            <input
              className="input-pad form-control pry-input-border"
              type="password"
            />
          </div>
        </div>
      </form>
      <button
        type="button"
        className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 validateBtn btn-block"
        onClick={login}
        disabled={loading}
      >
        {loading ? (
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
          />
        ) : (
          "Login"
        )}
      </button>
    </div>
  );
};

export default DashboardLogin;
