import React, { useState } from "react";
import Info from "../../../Images/Info_icon.svg";
import { message } from "antd";
import Spinner from "react-bootstrap/Spinner";
import { useHistory } from "react-router-dom";
import Header from "../../Dashboard/components/Header";
import { api } from "../../../service/api";
import { appUrls } from "../../../service/urls";

const ApiDashboardLogin = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [inputVal, setInputVal] = useState({
    username: "",
    password: "",
  });

  const login = async () => {
    setLoading(true);
    try {
      const res = await api.post(appUrls.ADMIN_LOGIN_URL, inputVal);
      if (res?.status === 200) {
        const { accessToken, refreshToken } = res?.data?.data;
        sessionStorage.setItem("api-dashboard-token", accessToken);
        sessionStorage.setItem("api-dashboard-refresh-token", refreshToken);
        history.push("/api-dashboard/merchants");
      }
    } catch (error) {
      message.error(error?.statusText, 5);
    } finally {
      setLoading(false);
    }
  };

  const togglePasswordVisiblity = () => {
    setShowPassword(showPassword ? false : true);
  };

  const handleValidation = () => {
    if (inputVal?.username.trim() === "" && inputVal?.password.trim() === "") {
      message.error("Username and password is Empty..", 5);
      return;
    }
    if (inputVal?.username.trim() === "") {
      message.error("Username cannot be Empty..", 5);
      return;
    }
    if (inputVal?.password.trim() === "") {
      message.error("Password cannot be Empty..", 5);
      return;
    }
    login();
  };

  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 displayNone">
          <div className="leftDiv stickyDiv"></div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <Header />
          <div className="container-fluid pb-5 mt-5">
            <div className="row mb-5">
              <div className="container w-100">
                <div className="steps-content mb-1">
                  <div className="w-80">
                    <form action="">
                      <div className="mb-2 text-centers text-dark mb-5">
                        <h3 className="mb-0 sec-headers">
                          <span
                            onClick={() => history.push("/")}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="fas fa-long-arrow-alt-left mr-2"></i>
                          </span>
                          Api Dashboard
                        </h3>
                      </div>
                      <div className="form-row mb-3">
                        <div className="form-group col-12">
                          <label
                            for=""
                            className="pry-grey-clr ftsz-12 ftwt-500 mb-1 d-flex justify-content-between align-items-center"
                          >
                            <span>Username</span>
                            <div className="popover-wrapper position-relative">
                              <div className="popover-title">
                                <img
                                  src={Info}
                                  alt=""
                                  style={{ cursor: "pointer" }}
                                />
                              </div>
                              <div className="popover-content ftsz-12 p-2">
                                <ul className="pl-3">
                                  <li>
                                    The Username required is your Sterling
                                    official email
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </label>
                          <div className="form-group mb-0">
                            <input
                              type="text"
                              className="form-control pry-input-border"
                              id=""
                              placeholder="Enter your username"
                              onChange={(e) => {
                                setInputVal({
                                  ...inputVal,
                                  username: e.target.value,
                                });
                              }}
                              value={inputVal.username}
                            />
                          </div>
                        </div>
                        <div className="form-group col-12 ftsz-12">
                          <h6 className="ftwt-500 ftsz-12 mb-1 text-dark">
                            Password
                          </h6>
                          <input
                            className="input-pad form-control password-input pry-input-border"
                            placeholder="Enter your password"
                            type={showPassword ? "text" : "password"}
                            onChange={(e) => {
                              setInputVal({
                                ...inputVal,
                                password: e.target.value,
                              });
                            }}
                            value={inputVal.password}
                          />
                          {
                            showPassword ? (
                              <i onClick={togglePasswordVisiblity} className="eye-icon fa fa-eye"></i>
                            ) : (
                              <i onClick={togglePasswordVisiblity} className="eye-icon fa fa-eye-slash"></i>
                            )
                          }

                          {/* </div> */}
                        </div>
                      </div>
                    </form>
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 validateBtn btn-block"
                      onClick={handleValidation}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Login"
                      )}
                    </button>
                  </div>
                </div>
                <div className="text-right"></div>
                <div className="steps-action pb-1 text-right otherBtn shiftFromBottom">
                  {/* {current === steps.length - 1 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 validateBtn btn-block w-80"
                      onClick={validateUser}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Login"
                      )}
                    </button>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApiDashboardLogin;
