import React, { useState } from "react";
import { message } from "antd";
import axios from "axios";
import { decryptHandler2 } from "../../../dec";
import { encrypt2 } from "../../../encr";
import { apiUrl } from "../../../redux/actions/endpoint";
import useCorporateFiles from "../hooks/useCorporateFiles";
import FormTwo from "./form";
import { useHistory } from "react-router-dom";
import { apiAccount } from "../../../service/apiAccount";
import { appUrls } from "../../../service/urls";
import { authOApi } from "../../../service/authOApi";

const ComboTwoScreen = ({
  showDoc,
  docText,
  sec,
  handleSecChanges,
  sectorList,
  industriesList,
  isLoading,
  accNum,
}) => {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const [caCTwo, setCaCTwo] = useState("");
  const [caCTwoURL, setCaCTwoURL] = useState("");
  const [caC11, setCaC11] = useState("");
  const [caC11URL, setCaC11URL] = useState("");
  const [meMarTTwo, setMeMarTTwo] = useState("");
  const [meMarTTwoURL, setMeMarTTwoURL] = useState("");
  const [boarDTwo, setBoarDTwo] = useState("");
  const [boarDTwoURL, setBoarDTwoURL] = useState("");
  const [refRTwo, setReFTwo] = useState("");
  const [reFTwoURL, setReFTwoURL] = useState("");
  const [otherSTwo, setOtherSTwo] = useState("");
  const [otherSTwoURL, setOtherSTwoURL] = useState("");
  const [evidencETwo, setEvidencETwo] = useState("");
  const [evidencETwoURL, setEvidencETwoURL] = useState("");

  const [documentArrayTwo, setDocumentArrayTwo] = useState([]);
  const [uploadD, setUploadD] = useState(false);

  const onDrop9 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setCaCTwo(file);
      const base64 = await convertBase64(file);
      setCaCTwoURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo2",
            documentTagName: "cac",
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop10 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setCaC11(file);
      const base64 = await convertBase64(file);
      setCaC11URL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo2",
            documentTagName: "cac11",
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop11 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setMeMarTTwo(file);
      const base64 = await convertBase64(file);
      setMeMarTTwoURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo2",
            documentTagName: "memart",
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop12 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setBoarDTwo(file);
      const base64 = await convertBase64(file);
      setBoarDTwoURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo2",
            documentTagName: "boardresolutionsignedsealed",
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop13 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setReFTwo(file);
      const base64 = await convertBase64(file);
      setReFTwoURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo2",
            documentTagName: "others1",
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop14 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setOtherSTwo(file);
      const base64 = await convertBase64(file);
      setOtherSTwoURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo2",
            documentTagName: "others",
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop15 = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setEvidencETwo(file);
      const base64 = await convertBase64(file);
      setEvidencETwoURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / folder 1`,
        fileName: `digitalOnboarding / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: accNum,
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "Combo2",
            documentTagName: "evidenceofmembership",
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };


  const uploadDocs = async () => {
    setLoading(true);
    let payload = {
      documents: documentArrayTwo,
    };
    const enc = encrypt2(payload);
    const dec = decryptHandler2(enc);

    await authOApi
      .post(`${appUrls.UPLOAD_CORPORATE_DOC}`, { data: enc })
      .then((response) => {
        const res = decryptHandler2(response.data);
        message.success("File successfully uploaded");
        setLoading(false);
        history.push("/");
      })
      .catch((error) => {
        message.error(error);
        setLoading(false);
      });
  };


  return (
    <div>
      <div>
        <FormTwo
          showDoc={showDoc}
          docText={docText}
          sec={sec}
          handleSecChanges={handleSecChanges}
          sectorList={sectorList}
          industriesList={industriesList}
          isLoading={isLoading}
          accNum={accNum}
          onDrop9={onDrop9}
          onDrop10={onDrop10}
          onDrop11={onDrop11}
          onDrop12={onDrop12}
          onDrop13={onDrop13}
          onDrop14={onDrop14}
          onDrop15={onDrop15}
          caCTwo={caCTwo}
          caC11={caC11}
          meMarTTwo={meMarTTwo}
          boarDTwo={boarDTwo}
          refRTwo={refRTwo}
          otherSTwo={otherSTwo}
          evidencETwo={evidencETwo}
        />
        <div className="d-flex justify-content-center align-items-center">
          <button
            type="button"
            className="btn btn-outline-danger text-white pry-red-bg px-5 mb-1"
            disabled={loading || uploadD || (documentArrayTwo.length === 0)}
            onClick={uploadDocs}
          >
            {loading && "Sending ..."}
            {uploadD && "Uploading ..."}
            {!loading && !uploadD && "Done"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ComboTwoScreen;
