import React from 'react';
import { BsDownload } from 'react-icons/bs';

import FIRSFORMCOMPANY from '../../pdf/FIRS-REGISTRATION-FORM-COMPANY.pdf';
import FIRSFORMENTERPRISE from '../../pdf/FIRS-REGISTRATION-FORM-ENTERPRISE.pdf';
import TAXPAYERFORM from '../../pdf/TAXPAYERS-UPDATE-FORM.pdf';

const UpdateDetailsPdfForms = () => {
  return (
    <div className="col-12 mb-3 mt-5">
      <p>Please download and fill out the form below to regularise your TIN</p>
      <div>
        <a
          href={FIRSFORMENTERPRISE}
          download
          className="px-3 py-2 d-flex justify-content-between border mt-1 pry-grey-clr"
        >
          <span> FIRS Registration Form - Enterprises</span>
          <span>
            <BsDownload className="pry-grey-clr" />
          </span>
        </a>
        <a
          href={FIRSFORMCOMPANY}
          download
          className="px-3 py-2 d-flex justify-content-between border mt-1 pry-grey-clr"
        >
          <span> FIRS Registration Form - Limited Liability Company</span>
          <span>
            <BsDownload className="pry-grey-clr" />
          </span>
        </a>
        <a
          href={TAXPAYERFORM}
          download
          className="px-3 py-2 d-flex justify-content-between border mt-1 pry-grey-clr"
        >
          <span> Taxpayer Update Form</span>
          <span>
            <BsDownload className="pry-grey-clr" />
          </span>
        </a>
      </div>
    </div>
  );
};

export default UpdateDetailsPdfForms;
