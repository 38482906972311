import React from "react";
import AddReference from ".";
import SelectBank from "./selectBank";
import OtherBanks from "./otherBank";
import { useSelector } from "react-redux";
import AddSterlingReference from "./sterlingBank";

const Options = ({
  onClose,
  // accountInfo,
  formValues,
  handleFormChange,
  refData,
  fileUrl,
  handleShowAddRefOTP
}) => {
  const { screen } = useSelector(({ bank }) => bank);
  return (
    <div>
      <div>
        {screen === "select" && <SelectBank />}
        {screen === "sterling" && (
          <AddSterlingReference
            onClose={onClose}
            formValues={formValues}
            handleFormChange={handleFormChange}
            refData={refData}
            handleShowAddRefOTP={handleShowAddRefOTP}
          />
        )}
        {screen === "otherBanks" && (
          <OtherBanks
            onClose={onClose}
            formValues={formValues}
            handleFormChange={handleFormChange}
            refData={refData}
            handleShowAddRefOTP={handleShowAddRefOTP}
            fileUrl={fileUrl}
          />
        )}
      </div>
    </div>
  );
};

export default Options;
