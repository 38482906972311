//endpoint
// export const endpoint = 'https://pass.sterling.ng/CAMS/api';
export const referenceEndpoint =
  "https://camsapigateway-live.appsuport.p.azurewebsites.net/api";
// export const apiUrl = 'https://camsapii.sterlingapps.p.azurewebsites.net/api/v2/users';
export const apiUrl =
  "https://camsapigateway-live.appsuport.p.azurewebsites.net/api/v2/users";
export const apiUrl3 =
  "https://camsapigateway-live.appsuport.p.azurewebsites.net/api";
export const apiUrl1 =
  "https://camsapigateway-live.appsuport.p.azurewebsites.net/api/User";
export const apiUrl2 =
  "https://camsapigateway-live.appsuport.p.azurewebsites.net/api/v1";

export const test = "https://camsapigateway-live.appsuport.p.azurewebsites.net/api/User";

// export const trackingID = "G-H8J8KPKVSY";
// export const trackingID = "G-FFZRVJ1SZJ";
export const trackingID = "G-C9916M9QKH";

// https://camsv2-test.sterlingapps.p.azurewebsites.net/index.html;
