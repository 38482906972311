import React from "react";

const Progress = () => {
  return (
    <div>
      <section class="step-wizard">
        <ul class="step-wizard-list">
          <li class="step-wizard-item">
            <span class="progress-count">1</span>
            <span class="progress-label"> ID Verification <br /> (24hours)</span>
          </li>
          <li class="step-wizard-item current-item">
            <span class="progress-count"></span>
            <span class="progress-label">Address Verification <br /> (24 - 72hours)</span>
          </li>
          {/* <li class="step-wizard-item">
            <span class="progress-count">3</span>
            <span class="progress-label">Reference Confirmation</span>
          </li> */}
        </ul>
      </section>
    </div>
  );
};

export default Progress;
