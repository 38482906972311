import React from "react";
import Modal from "react-bootstrap/Modal";

function AccountOrBvn({
  showOptions,
  handleCloseOptions,
  switchForgotAcct,
  switchForgotBvn,
}) {
  return (
    <div>
      <Modal
        show={showOptions}
        onHide={handleCloseOptions}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="text-center">
            <div className="mb-5">
              <p className="ftsz-regular">Choose preferred option</p>
              <div className="btn-flex">
                <button
                  className="btn btn-outline-danger px-5 py-2"
                  onClick={switchForgotAcct}
                >
                  Retrieve Account Number
                </button>
                <button
                  className="btn btn-danger px-5 py-2"
                  onClick={switchForgotBvn}
                >
                  Retrieve BVN
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default AccountOrBvn;
