import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { decryptHandler2 } from '../../dec';
import { encrypt2 } from '../../encr';
import { apiUrl, apiUrl3 } from '../../redux/actions/endpoint';
import Header from './components/Header';
import AgreeModal from './components/Modals/Agree';
import DisAgreeModal from './components/Modals/Disagree';
import { apiAccount } from '../../service/apiAccount';
import { authOApi } from '../../service/authOApi';
import { appUrls } from '../../service/urls';

const Feedback = () => {
  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [accVerified, setAccVerified] = useState(false);

  const [formValues, setFormValues] = useState({
    reason: '',
  });
  //handling form state change
  const handleFormChange =
    (name) =>
    ({ target }) => {
      setFormValues({
        ...formValues,
        [name]: target.value,
      });
    };

  

  const [showAgreeModal, setShowAgreeModal] = useState(false);
  const handleCloseAgreeModal = () => setShowAgreeModal(false);
  const handleShowAgreeModal = () => setShowAgreeModal(true);

  const [showDisAgreeModal, setShowDisAgreeModal] = useState(false);
  const handleCloseDisAgreeModal = () => setShowDisAgreeModal(false);
  const handleShowDisAgreeModal = () => setShowDisAgreeModal(true);

  const useQuery = () => new URLSearchParams(window.location.search);
  let query = useQuery();
  const tokenNeeded = query.get('token');

  const removeRestriction = () => {
    setLoading(true);
    let payload = encrypt2(tokenNeeded);
    authOApi
      .post(`${appUrls.REMOVE_RESTRICTION}`, {
        data: payload,
      })
      .then((response) => {
        let res = decryptHandler2(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const emailFraudDesk = () => {
    setLoading(true);
    let acc = tokenNeeded;
    let payload = {
      accountNumber: acc,
    };
    let encs = encrypt2(payload);
    authOApi
      .post(`${appUrls.EMAIL_FRAUD_DESK}`, {
        data: encs,
      })
      .then((response) => {
        let res = decryptHandler2(response.data);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const positiveFeedback = () => {
    setLoading(true);
    let acc = tokenNeeded;
    let payload = {
      accountNumber: acc,
      isVerificationSuccessful: true,
      customerCareResponse: formValues.reason,
    };
    let enc = encrypt2(payload);
    authOApi
      .post(`${appUrls.CUSTOMER_CARE_FEEDBACK}`, { data: enc })
      .then((response) => {
        let res = decryptHandler2(response.data);
        removeRestriction();
        handleShowAgreeModal();
        setLoading(false);
      })
      .catch((error) => {
       
      });
  };

  const negativeFeedback = () => {
    setIsLoading(true);
    let acc = tokenNeeded;
    let payload = {
      accountNumber: acc,
      isVerificationSuccessful: false,
      customerCareResponse: formValues.reason,
    };
    let enc = encrypt2(payload);
    authOApi
      .post(`${appUrls.CUSTOMER_CARE_FEEDBACK}`, { data: enc })
      .then((response) => {
        let res = decryptHandler2(response.data);
        emailFraudDesk();
        handleShowDisAgreeModal();
        setIsLoading(false);
      })
      .catch((error) => {
        
      });
  };

  const verifyAccount = () => {
    setLoading1(true);
    let payload = encrypt2(tokenNeeded);
    authOApi
      .post(`${appUrls.VERIFY_ACCOUNT}`, {
        data: payload,
      })
      .then((response) => {
        let res = decryptHandler2(response.data);
        if (res.IsVerficationSuccessful === true) {
          setAccVerified(true);
        }
        setLoading1(false);
      })
      .catch((error) => {
        setLoading1(false);
      });
  };

  useEffect(() => {
    verifyAccount();
  }, []);

  return (
    <div>
      <div className="pale-red-part-bg position-fixed"></div>
      <div>
        <Header />
      </div>
      <div className="container-fluid pb-5 mt-5">
        {loading1 === false ? (
          <div class="row mb-5">
            <div class="col-xl-7 col-lg-9 col-md-10 mx-auto">
              <div class="container">
                <div class="app-details-form-wrapper pry-grey-clr bg-white px-3 px-lg-5 py-5">
                  <div className="text-center">
                    {accVerified ? (
                      <form>
                        <h6 className="mb-3">Feedback form</h6>
                        <div className="form-group">
                          <textarea
                            className="form-control"
                            placeholder="Reason for decision ..."
                            rows="5"
                            value={formValues.reason}
                            onChange={handleFormChange('reason')}
                          />
                        </div>
                        <div className="mt-5">
                          <button
                            type="button"
                            className="btn btn-outline-danger px-5 mr-2 mb-3"
                            onClick={positiveFeedback}
                            disabled={loading}
                          >
                            {loading ? 'Approving ...' : 'Approve'}
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                            onClick={negativeFeedback}
                            disabled={isLoading}
                          >
                            {isLoading ? 'Declining ...' : 'Decline'}
                          </button>
                        </div>
                      </form>
                    ) : (
                      <div>
                        <p className="">
                          Account already Approved/Declined or link is broken!
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <p className="xcenters">Loading ...</p>
        )}
      </div>
      <AgreeModal
        showAgreeModal={showAgreeModal}
        handleCloseAgreeModal={handleCloseAgreeModal}
      />
      <DisAgreeModal
        showDisAgreeModal={showDisAgreeModal}
        handleCloseDisAgreeModal={handleCloseDisAgreeModal}
      />
    </div>
  );
};

export default Feedback;
