export const appUrls = {
  ADMIN_LOGIN_URL: '/api/v1/auths/admins/login',
  ADMIN_ADD_MERCHANT_URL: '/api/v1/auths/register-merchant',
  ADMIN_GET_ALL_USERS: '/api/v1/auths/get-all-users',
  ADMIN_ACTIVATE_USER: '/api/v1/auths/activate-user',
  ADMIN_USER_SET_PASSWORD: '/api/v1/auths/set-password',
  ADMIN_VERIFY_TOKEN: '/api/v1/auths/verify-token',
  ADMIN_SET_PASSWORD: '/api/v1/auths/set-password',
  REFRESHTOKEN_URL: '/api/v1/auths/refresh-token',
  ADMIN_DELETEEDIT_USER: '/api/v1/auths',

  // {apiUrl}
  FIORANO_GET_ACCT_COUNTBY_BVN: '/api/v2/users/FioranoGetAccountCountByOnlyBVN',
  FIORANO_GET_ACCTBY_BVN: '/api/v2/users/FioranoGetAccountByOnlyBVN',
  FIORANO_ADD_RESTRICTION: '/api/v2/users/FioranoAddRestriction',
  FIORANO_GET_ACCT_FULLINFO: '/api/v2/users/FioranoGetAccountFullInfo',
  VERIFY_REFEREE_ACCOUNT: '/api/v2/users/VerifyRefereeAccount',
  VERIFY_BVN: '/api/v2/users/VerifyBVN',
  VERIFY_BVN_V2: '/api/v2/users/VerifyBVNV2',
  GENERATE_OTP: '/api/v2/users/GenerateOTP',
  VALIDATE_OTP: '/api/v2/users/ValidateOTP',
  UPLOAD_DOC_V2: '/api/v2/users/uploads/document-v2',
  UPLOAD_DOC: '/api/v2/users/uploads/document',
  OPEN_ACCOUNT: '/api/v2/users/OpenAccountsDocVersionV2',
  UPLOAD_DOC_ONLINEACCOUNT:
    '/api/v2/users/UploadDocumentForOnlineAccountSingleCall',
  GET_STERLING_BRANCHES: '/api/v2/users/GetSterlingBranches',
  CHEQUE_REQUISITION: '/api/v2/users/ChequeRequisition',
  CREATE_ACCT_INAPP: '/api/v2/users/CreateAccountInApp',
  VALIDATE_TIN: '/api/v2/users/validate-tin',
  VERIFY_BUSINESS: '/api/v2/users/BusinessVerification',
  GET_SIGNATORIESBY_TIN: '/api/v2/users/GetSignatoriesByTIN',
  OPEN_CORPORATE_ACCT: '/api/v2/users/OpenCorporateAccount',
  SENDDATA_TO_ONEXPRESS: '/api/v2/users/SendDataToOneXpress',
  UPDATE_TIN_EMAIL: '/api/v2/users/tin-update-email',
  ADD_SIGNATORY: '/api/v2/users/AddSignatory',
  ADD_DIRECTOR: '/api/v2/users/AddDirector',
  GET_SECTOR: '/api/v2/users/GetSector',
  GET_INDUSTRY: '/api/v2/users/GetIndustry',
  SEND_CORPORATE_CUSTOMER_EMAIL: '/api/v2/users/SendCorporateCustomerEmail',
  GET_CUSTOMER_REFERENCES: '/api/v2/users/GetCustomerReferences',
  GET_DIRECTORIESBY_TIN: '/api/v2/users/GetDirectorsByTIN',
  SEND_REFEREE_CONSENTBY_EMAIL: '/api/v2/users/SendRefereeConsentEmail',
  SEND_MAIL_ATTACHMENT_NONSTERLING:
    '/api/v2/users/SendMailAttachmentToNonSterling',
  VERIFY_REFRENCE_TOKEN: '/api/v2/users/VerifyConfirmReferenceToken',
  FORGOT_ACCT_NUMBER: '/api/v2/users/forgot-account-number',
  FORGOT_BVN_NUMBER: '/api/v2/users/forgot-bvn-number',
  BUSINESSDOC_UPLOAD_STATUS: '/api/v2/users/BusinessDocumentUploadStatus',
  UPLOAD_IMAGE_AZURE: '/api/v2/users/UploadImageToAzureWithResponseCode',
  UPLOAD_CORPORATE_DOC: '/api/v2/users/UploadCorporateDocument',
  COMBOONE_DOC_UPLOAD_STATUS: '/api/v2/users/ComboOneDocumentUploadStatus',
  COMBOTWO_DOC_UPLOAD_STATUS: '/api/v2/users/ComboTwoDocumentUploadStatus',
  CONFIRM_CORPORATE_EMAIL_TOKEN: '/api/v2/users/ConfirmCorporateEmailToken',
  GET_CORPORATEDOC_UPLOAD_STATUS:
    '/api/v2/users/CheckCorporateDocumentUploadStatus',
  PROVIDE_REFERENCE_CONSENT: '/api/v2/users/ProvideReferenceConsent',
  GET_NEAREST_BRANCHES: '/api/v2/users/nearest-branches',
  GET_NEAREST_ATMS: '/api/v2/users/nearest-atms',
  ADD_UNVERIFIED_TIN_USER: '/api/v2/users/AddUnverifiedTinUser',
  GET_UNVERIFIED_TIN_DETAILS: '/api/v2/users/Get-UnverifiedTin-Details',

  //{apiUrl1}
  GET_DOC: '/api/User/document',
  GET_STATES: '/api/User/states',
  UPLOAD_DOC_CLOUDINARY: '/api/User/upload/document',

  // {apiUrl2}
  SAVE_PROCESS_DATABY_BVN: '/api/v1/save-process/bvn',
  SAVE_PROCESS: '/api/v1/save-process',

  // {apiUrl3}
  ADMIN_LOGIN: '/api/v1/auths/login',
  ADD_INTL_PASSPORT: '/api/youverify/intl-passport',
  VERIFY_NIN: '/api/youverify/nin',
  VERIFY_PVC: '/api/youverify/pvc',
  VERIFY_DRIVER_LICENSE: '/api/youverify/driver-license',
  SIM_SWAP_STATUS: '/api/nibss/sim-swap-status',
  VERIFY_ACCOUNT: '/api/nibss/verify-account',
  ADD_RESTRICTION: '/api/nibss/add-restriction',
  REMOVE_RESTRICTION: '/api/nibss/remove-restriction',
  EMAIL_CUSTOMER_CARE: '/api/nibss/email-customer-care',
  CUSTOMER_CARE_FEEDBACK: '/api/nibss/customer-care-feedback',
  EMAIL_FRAUD_DESK: '/api/nibss/email-fraud-desk',
  HYPERVERGE_FACE_MATCH_1: '/api/hyper-verge/selfie-face-match',
  HYPERVERGE_FACE_MATCH: '/api/hyper-verge/selfie-face-match-v2',
  GET_HYPERVERGE_TOKEN: '/api/hyper-verge/token',
  VERIFY_TOKEN: '/api/v1/auths/verify-token',
  REFRESH_TOKEN: '/api/v1/auths/refresh-token',
  LOGIN: '/api/v1/auths/login',
};
