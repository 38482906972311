import { message } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { decryptHandler2 } from "../../../dec";
import { encrypt2 } from "../../../encr";
import { apiUrl } from "../../../redux/actions/endpoint";
import useCorporateFiles from "../hooks/useCorporateFiles";
import { apiAccount } from "../../../service/apiAccount";
import { appUrls } from "../../../service/urls";
import { authOApi } from "../../../service/authOApi";

const FormTwo = ({
  showDoc,
  docText,
  sec,
  handleSecChanges,
  sectorList,
  industriesList,
  isLoading,
  accNum,
  onDrop9,
  onDrop10,
  onDrop11,
  onDrop12,
  onDrop13,
  onDrop14,
  onDrop15,
  caCTwo,
  caC11,
  meMarTTwo,
  boarDTwo,
  refRTwo,
  otherSTwo,
  evidencETwo,
}) => {
  const [cacc, setCacc] = useState(false);
  const [cac111, setCac111] = useState(false);
  const [memartt, setMemartt] = useState(false);
  const [boardR, setBoardR] = useState(false);
  const [other, setOther] = useState(false);
  const [lic, setLic] = useState(false);
  const [reff, setReff] = useState(false);

  const check = () => {
    let pay = accNum;
    let enc = encrypt2(pay);
    authOApi
      .get(`${appUrls.COMBOTWO_DOC_UPLOAD_STATUS}?Data=${enc}`)
      .then((response) => {
        const res = decryptHandler2(response.data);
        setCacc(res.CAC);
        setCac111(res.CAC11);
        setMemartt(res.MEMART);
        setBoardR(res.BoardResolutionSignedSealed);
        setOther(res.Others1);
        setLic(res.EvidenceOfMembership);
        setReff(res.Others2);
      })
      .catch((error) => {
        message.error(error);
      });
  };
  useEffect(() => {
    check();
  }, []);

  return (
    <div className="container">
      <form action="">
        <h6 className="mb-2 sec-header font-weight-normal text-body text-center ftsz-12">
          Upload pdf file formats. Asterisked items (**) are mandatory
        </h6>
        <div className="form-row mb-2 pb-2 mt-3">
          {cacc === false && (
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">CAC**</h6>
              {caCTwo ? <p>{caCTwo.path}</p> : <p>No file uploaded</p>}
              <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                <Dropzone
                  onDrop={onDrop9}
                  multiple={false}
                  accept="image/jpeg, image/png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="doc-uploads">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} placeholder="" />
                        {!!caCTwo.length &&
                          caCTwo.map((file) => (
                            <p className="doc-texts">{file.path}</p>
                          ))}

                        {!caCTwo.length && (
                          <section className="doc-texts">
                            Drag 'n' drop , or click to select <br />
                          </section>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}

          {cac111 === false && (
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">CAC 1.1**</h6>
              {caC11 ? <p>{caC11.path}</p> : <p>No file uploaded</p>}
              <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                <Dropzone
                  onDrop={onDrop10}
                  multiple={false}
                  accept="image/jpeg, image/png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="doc-uploads">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} placeholder="" />
                        {!!caC11.length &&
                          caC11.map((file) => (
                            <p className="doc-texts">{file.path}</p>
                          ))}

                        {!caC11.length && (
                          <section className="doc-texts">
                            Drag 'n' drop , or click to select <br />
                          </section>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}

          {memartt === false && (
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">MeMart**</h6>
              {meMarTTwo ? <p>{meMarTTwo.path}</p> : <p>No file uploaded</p>}
              <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                <Dropzone
                  onDrop={onDrop11}
                  multiple={false}
                  accept="image/jpeg, image/png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="doc-uploads">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} placeholder="" />
                        {!!meMarTTwo.length &&
                          meMarTTwo.map((file) => (
                            <p className="doc-texts">{file.path}</p>
                          ))}

                        {!meMarTTwo.length && (
                          <section className="doc-texts">
                            Drag 'n' drop , or click to select <br />
                          </section>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}

          {boardR === false && (
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
                Board resolution signed and sealed**
              </h6>
              {boarDTwo ? <p>{boarDTwo.path}</p> : <p>No file uploaded</p>}
              <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                <Dropzone
                  onDrop={onDrop12}
                  multiple={false}
                  accept="image/jpeg, image/png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="doc-uploads">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} placeholder="" />
                        {!!boarDTwo.length &&
                          boarDTwo.map((file) => (
                            <p className="doc-texts">{file.path}</p>
                          ))}

                        {!boarDTwo.length && (
                          <section className="doc-texts">
                            Drag 'n' drop , or click to select <br />
                          </section>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}

          {reff && (
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">References</h6>
              {refRTwo ? <p>{refRTwo.path}</p> : <p>No file uploaded</p>}
              <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                <Dropzone
                  onDrop={onDrop13}
                  multiple={false}
                  accept="image/jpeg, image/png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="doc-uploads">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} placeholder="" />
                        {!!refRTwo.length &&
                          refRTwo.map((file) => (
                            <p className="doc-texts">{file.path}</p>
                          ))}

                        {!refRTwo.length && (
                          <section className="doc-texts">
                            Drag 'n' drop , or click to select <br />
                          </section>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}

          {other === false ? (
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">Others</h6>
              {otherSTwo ? <p>{otherSTwo.path}</p> : <p>No file uploaded</p>}
              <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                <Dropzone
                  onDrop={onDrop14}
                  multiple={false}
                  accept="image/jpeg, image/png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="doc-uploads">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} placeholder="" />
                        {!!otherSTwo.length &&
                          otherSTwo.map((file) => (
                            <p className="doc-texts">{file.path}</p>
                          ))}

                        {!otherSTwo.length && (
                          <section className="doc-texts">
                            Drag 'n' drop , or click to select <br />
                          </section>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          ) : (
            ""
          )}

          {lic === false && (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <div>
                <div className="form-row">
                  <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                    Select Sector
                  </label>
                  <select
                    className="custom-select pry-input-border"
                    onChange={handleSecChanges("sector_id")}
                    required
                    value={sec.sector_id}
                  >
                    {sectorList.map((sectors, index) => (
                      <option value={sectors.Sector_T24Code} key={index}>
                        {sectors.Sector}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
            {lic === false && (
              <div>
                <div className="form-row">
                  <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                    Select Industry
                  </label>
                  <select
                    className="custom-select pry-input-border"
                    onChange={handleSecChanges("industry_id")}
                    required
                    value={sec.industry_id}
                    disabled={isLoading}
                  >
                    <option value="">Select industry</option>
                    {industriesList.map((industry, index) => (
                      <option value={industry.Industry_T24Code} key={index}>
                        {industry.Industry}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>

          <div className="col-12 col-md-6 mb-3 ftsz-12 ">
            {showDoc && (
              <div>
                <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
                  <p>{docText} **</p>
                </h6>
                {evidencETwo ? <p>{evidencETwo.path}</p> : <p>No file uploaded</p>}
                <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                  <Dropzone
                    onDrop={onDrop15}
                    multiple={false}
                    accept="image/jpeg, image/png"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="doc-uploads">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} placeholder="" />
                          {!!evidencETwo.length &&
                            evidencETwo.map((file) => (
                              <p className="doc-texts">{file.path}</p>
                            ))}

                          {!evidencETwo.length && (
                            <section className="doc-texts">
                              Drag 'n' drop , or click to select <br />
                            </section>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormTwo;
