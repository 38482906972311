import { SET_WELCOME_TYPE } from "../types";

export const setWelcome = (payload) => ({
    type: SET_WELCOME_TYPE,
    payload,
});

const exportedObject = {
    setWelcome,
}

export default exportedObject;