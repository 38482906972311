import React from 'react'
import Pagination from '../pagination';
import { Table } from 'react-bootstrap';

function IncompleteAcctsTable() {



    return (
        <div>
            <Table striped bordered variant="dark" size="sm" responsive>
                <thead>
                    <tr>
                        <th>Date of request</th>
                        <th>Account Name</th>
                        <th>Account Number</th>
                        <th>Account Type</th>
                        <th>Branch CEMP</th>
                        <th>Branch of domiciliation</th>
                        <th>State/Location of delivery</th>
                        <th>Code</th>
                        <th>Pending Document</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                        <td>1</td>
                        <td>Mark</td>
                        <td>Otto</td>
                        <td>@mdo</td>
                        <td>@mdo</td>
                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                        <td>2</td>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                        <td>@fat</td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td >Larry the Bird</td>
                        <td>@twitter</td>
                        <td>@fat</td>
                        <td>3</td>
                        <td >Larry the Bird</td>
                        <td>@twitter</td>
                        <td>@fat</td>
                        <td>@fat</td>
                    </tr>
                </tbody>
            </Table>
            <Pagination />
        </div>

    )
}

export default IncompleteAcctsTable