import React, { useState } from "react";
import Sidebar from "./sidebar/sidebar";
// import Navbar from "./navbar/navbar";
import { useHistory } from "react-router-dom";
import "./index.css";
import Login from "../login";
import Home from "../../pages/home";
import Trends from "../../pages/trends";
import Reports from "../../pages/reports";
import Settings from "../../pages/settings";
import Branches from "../../pages/branches";
import RestrictedAccounts from "../../pages/restrictedAccts";
import IncompleteDocs from "../../pages/incompleteDocs";
import { Route, Switch } from "react-router-dom/cjs/react-router-dom.min";

const AdminLayout = ({ children }) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const history = useHistory();

  const auth = localStorage.getItem("auth");

  const openSidebar = () => {
    setSidebarOpen(true);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  if (!auth) {
    history.push("/dashboard");
  }

  return (
    <div className="layout">
      <div className="children-wrap">
        <div className="layout__body ">
          <div className="layout__sidebar">
            <Sidebar closeSidebar={closeSidebar} openSidebar={openSidebar} />
          </div>
          <div className="layout__content">
            {/* <Navbar /> */}
            <Switch>
              <Route path="/admin/home" component={Home} />
              <Route path="/admin/trends" component={Trends} />
              <Route path="/admin/reports" component={Reports} />
              <Route path="/admin/settings" component={Settings} />
              <Route path="/admin/request-braches" component={Branches} />
              <Route path="/admin/incomplete-docs" component={IncompleteDocs} />
              <Route
                path="/admin/restricted-accts"
                component={RestrictedAccounts}
              />
            </Switch>
          </div>
        </div>
      </div>
    </div>
    // <div className="dash-container">
    //   <Sidebar closeSidebar={closeSidebar} openSidebar={openSidebar} />
    //   <main>
    //     <Navbar />

    // <Switch>
    //   <Route path='/admin/home' component={Home} />
    // </Switch>

    //   </main>
    // </div>
  );
};

export default AdminLayout;
