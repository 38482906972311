import React from "react";
import Info from "../../../../../../../../Images/Info_icon.svg";
import { useHistory } from "react-router-dom";

const TinStep = ({ formVal, handleFormChange, setTin, tin }) => {
  const history = useHistory();
  const inputAlphaNumeric = (e, callback) => {
    const value = e.target.value
      ? e.target.value.replace(/[^0-9(\-)]+/gi, "")
      : "";

    if (e.target.value !== value) {
      e.target.value = value;
    }

    if (typeof callback === "function") {
      return callback(value);
    }
  };
  return (
    <div className="w-80">
      <form action="">
        <div class="my-4 text-centers text-dark">
          <h3 class="mb-0 sec-headers">
            <span
              onClick={() => history.goBack()}
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-long-arrow-alt-left mr-2"></i>
            </span>
            TIN Validation
          </h3>
        </div>
        <div className="form-row mb-3">
          <div className="form-group col-12">
            <label
              for=""
              className="pry-grey-clr ftsz-12 ftwt-500 mb-1 d-flex justify-content-between align-items-center"
            >
              <span>Enter TIN</span>
              <div className="popover-wrapper position-relative">
                <div className="popover-title">
                  <img src={Info} alt="" style={{ cursor: "pointer" }} />
                </div>
                <div className="popover-content ftsz-12 p-2">
                  <ul className="pl-3">
                    <li>Please see format here 12345678-0001</li>
                  </ul>
                </div>
              </div>
            </label>
            <input
              type="text"
              className="form-control pry-input-border"
              id=""
              placeholder="Enter your TIN"
              value={tin}
              // onChange={handleFormChange("tin")}
              onChange={(e) =>
                inputAlphaNumeric(e, (value) => {
                  setTin(value);
                })
              }
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default TinStep;
