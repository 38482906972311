import React from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";

const PendingModal = ({
    handleClosePendingModal, showPendingModal, getSaveProcess, setNext
}) => {

    return (
        <div>
            <Modal
                show={showPendingModal}
                onHide={handleClosePendingModal}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="my-0 text-center text-dark">
                            <h5 className="mb-0 sec-header">Pending Account Opening</h5>
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <p className="mb-4 pry-grey-clr ftsz-regular text-center">
                            Good news! You have a pending account opening application. Do you want to pick up where you left off or begin a new one?
                        </p>
                        <div className="form-row mb-3">
                            <div className="form-group col-12 col-sm-6 col-md-5 mx-auto ftsz-12">
                                <div className="row justify-content-center col-gap form-group">
                                    <button
                                        type="button"
                                        onClick={setNext}
                                        className="btn btn-danger btn-danger-outline pry-red-bg px-5">
                                        Continue Application
                                    </button>
                                    <button
                                        type="button"
                                        onClick={setNext}
                                        className="btn btn-danger btn-danger-outline pry-red-bg px-5">
                                        Start New Application
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default PendingModal;
