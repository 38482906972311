import { SET_COMBO_TYPE } from "../types";

export const set_combo_type = (payload) => ({
    type: SET_COMBO_TYPE,
    payload,
});

const exportedObject = {
    set_combo_type
}

export default exportedObject;