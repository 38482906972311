import React from "react";
import { useHistory } from "react-router-dom";
import { BiArrowBack } from "react-icons/bi";
import IncompleteAcctsTable from "../components/Tables/IncompleteAcctsTable";

function IncompleteDocs() {
  let history = useHistory();

  return (
    <div className="main__container">
      <div className="header">
        <button onClick={() => history.goBack()} className="btn-back">
          {" "}
          <div className="cards_icon cards_icon-black">
            <BiArrowBack style={{ color: "white" }} />
          </div>
          <h1>Incomplete Documents </h1>
        </button>
      </div>
      <div className="separatingDiv">
        <IncompleteAcctsTable />
      </div>
    </div>
  );
}

export default IncompleteDocs;
