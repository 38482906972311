import { SET_REF_VALUES } from "../types";

const INITIAL_STATE = {
  refInfo: {},
};

const RefReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_REF_VALUES:
      return {
        ...state,
        refInfo: action.payload,
      };

    default:
      return state;
  }
};

export default RefReducer;
