import React from "react";
import Completed from "../../../../../../Images/completed.png";
import Copy from "../../../../../../Images/copy-icon.png";
import Modal from "react-bootstrap/Modal";
import CopyToClipboard from "react-copy-to-clipboard";
import { message } from "antd";
import Progress from "./Progress/progress";
import "./Progress/progress.css";

const NewCompletedForm = ({
  accNum,
  showCompleted,
  handleCloseCompleted,
  confirmCompleted,
  bvnDetails,
  accountInfo,
  imgSrc,
  next
}) => {
  const [Copied] = React.useState("");
  return (
    <div>
      <Modal
        show={showCompleted}
        onHide={handleCloseCompleted}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="text-center">
            <div className="d-flex align-items-center text-center justify-content-center">
              <div className="mb-3 mr-2">
                <img
                  src={Completed}
                  className="img-fluid"
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
              <p>Congratulations, Your account opening was successful</p>
            </div>
            <div style={{ width: "100px" }} className="m-auto">
              <img
                src={imgSrc}
                alt="Team_image"
                style={{ width: "100%", borderRadius: "50%", height: "100px" }}
              />
            </div>
            <p className="mt-3 font-weight-bold">
              {bvnDetails.FirstName} {bvnDetails.LastName}
            </p>
            <div className="mb-5">
              <span className="new-acct-num pry-red-clr pale-red-tone-bg rounded-lg pry-header px-3 py-2">
                {accountInfo}{" "}
                <CopyToClipboard
                  text={Copied}
                  onCopy={() => {
                    message.success({
                      content: "Copied to clipboard",
                      style: {
                        position: "relative",
                        right: "0",
                        marginTop: "20px",
                      },
                    });
                  }}
                >
                  <button type="button" className="bare-btn">
                    <img src={Copy} alt="copy" />
                  </button>
                </CopyToClipboard>
              </span>
            </div>

            <Progress />
            <p className="mb-4">Account Opening Progress (64%)</p>
            <div class="mt-4 mx-4">
              <p class="text-center mx-md-5 w-75">
                Proceed to add reference, Kindly ensure that the referee’s
                account meets the following requirements:
              </p>
              <ul class="pl-3 text-left">
                <li class="custom-green-marker mb-2">
                  Account has been in operation for more than 6 months.
                </li>
                <li class="custom-green-marker mb-2">Account is not dormant</li>
                <li class="custom-green-marker mb-2">
                  Category criteria (References from a registered business can
                  only be used for an individual account or another registered
                  business but a reference from a registered business is
                  suitable for all forms of current account.)
                </li>
              </ul>
            </div>
            <div class="text-center">
              <button
                class="btn btn-outline-danger px-5 py-2 mr-4"
                onClick={confirmCompleted}
              >
                End
              </button>
              <button
                class="btn btn-danger pry-red-bg px-5 py-2"
                onClick={() => {
                  handleCloseCompleted();
                  next();
                }}
              >
                Add reference
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewCompletedForm;
