import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    // title: {
    //   display: true,
    //   text: "Chart.js Line Chart",
    // },
  },
};

const labels = ["01", "08", "15", "22", "30"];

export const data = {
  labels,
  datasets: [
    {
      label: "In-Branch Accounts",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "#2B98B5",
      backgroundColor: "#2B98B5",
    },
    {
      label: "Digital Onboarding",
      data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
      borderColor: "#FF7276",
      backgroundColor: "#FF7276",
    },
  ],
};

export function TrendChart() {
  return <Line options={options} data={data} />;
}
