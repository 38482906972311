import { SET_ACCOUNT_TYPE } from "../types";

export const set_account_type = (payload) => ({
    type: SET_ACCOUNT_TYPE,
    payload,
});

const exportedObject = {
    set_account_type
}

export default exportedObject;