import React, { useEffect } from 'react'
import Dropzone from 'react-dropzone'

const UploadDocument = ({
	regCert,
	appBus,
	busRes,
	refSent,
	others,
	evidence,
	onDrop,
	onDrops,
	onDropss,
	onDropsss,
	onDropssss,
	onDropsssss,
	branchCode,
	handleFormChange,
	formVal,
	getSignatoriesByTin,
	stateList,
	handleSecChanges,
	sec,
	sectorList,
	industriesList,
	isLoading,
	showDoc,
	docText,
	tcHandler,
	isTCClicked,
	handleShowTC,
	handleShowTBH,
	formValues,
	getCustomerID
}) => {
	const MAX_SIZE = 2097152
	useEffect(() => {
		getSignatoriesByTin()
		getCustomerID(formValues.bvn)
	}, [])
	return (
		<div>
			<form action="">
				<div className="mb-4">
					<h5 className="sec-header text-body text-center">Upload documents</h5>
					<h6 className="mb-0 font-weight-normal text-body text-center ftsz-12">
						Upload pdf file formats. Asterisked items (**) are mandatory.{' '}
					</h6>
				</div>
				<div className="form-row mb-5 pb-5">
					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">
							Certificate of registration**
						</h6>
						{regCert ? <p>{regCert.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop}
								multiple={false}
								accept="image/jpeg, image/png, .pdf"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!regCert.length &&
												regCert.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!regCert.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">
							Application for business name**
						</h6>
						{appBus ? <p>{appBus.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrops}
								multiple={false}
								accept="image/jpeg, image/png, .pdf"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!appBus.length &&
												appBus.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!appBus.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">
							Business resolution*
						</h6>
						{busRes ? <p>{busRes.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDropss}
								multiple={false}
								accept="image/jpeg, image/png, .pdf"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!busRes.length &&
												busRes.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!busRes.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">References</h6>
						{refSent ? <p>{refSent.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDropsss}
								multiple={false}
								accept="image/jpeg, image/png, .pdf"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!refSent.length &&
												refSent.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!refSent.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">Others</h6>
						{others ? <p>{others.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDropssss}
								multiple={false}
								accept="image/jpeg, image/png, .pdf"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!others.length &&
												others.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!others.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>
					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
						<div className="form-row">
							<label for="" className="form-label ftsz-12 ftwt-500 mb-1">
								Select Sector
							</label>
							<select
								className="custom-select pry-input-border"
								onChange={handleSecChanges('sector_id')}
								required
								value={sec.sector_id}
							>
								{sectorList.map((sectors, index) => (
									<option value={sectors.Sector_T24Code} key={index}>
										{sectors.Sector}
									</option>
								))}
							</select>
						</div>
					</div>

					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
						<div className="form-row">
							<label for="" className="form-label ftsz-12 ftwt-500 mb-1">
								Select Industry
							</label>
							<select
								className="custom-select pry-input-border"
								onChange={handleSecChanges('industry_id')}
								required
								value={sec.industry_id}
								disabled={isLoading}
							>
								<option value="">Select industry</option>
								{industriesList.map((industry, index) => (
									<option value={industry.Industry_T24Code} key={index}>
										{industry.Industry}
									</option>
								))}
							</select>
						</div>
					</div>

					{showDoc ? (
						<div className="col-12 col-md-6 mb-3 ftsz-12 ">
							<h6 className="ftwt-500 ftsz-12 mb-1 text-body">
								<p>{docText} **</p>
							</h6>
							{evidence ? <p>{evidence.path}</p> : <p>No file uploaded</p>}
							<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
								<Dropzone
									onDrop={onDropsssss}
									multiple={false}
									accept="image/jpeg, image/png, .pdf"
									maxSize={MAX_SIZE}
								>
									{({ getRootProps, getInputProps }) => (
										<section className="doc-uploads">
											<div {...getRootProps()}>
												<input {...getInputProps()} placeholder="" />
												{!!evidence.length &&
													evidence.map((file) => (
														<p className="doc-texts">{file.path}</p>
													))}

												{!evidence.length && (
													<section className="doc-texts">
														Drag 'n' drop , or click to select <br />
													</section>
												)}
											</div>
										</section>
									)}
								</Dropzone>
							</div>
						</div>
					) : (
						''
					)}

					<div className="form-row">
						<div className="col-12 my-4">
							<h6 className="ftwt-500 ftsz-12">Select value-added services</h6>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div className="form-group">
								<label>Branch of account domiciliation.</label>
								<select
									className="form-control"
									onChange={handleFormChange('branch')}
									required
									value={formVal.branch}
								>
									{branchCode.map((branches, index) => (
										<option value={branches.BranchCode}>
											{branches.BranchName}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div className="form-group">
								<label>State of Residence</label>
								<select
									className="form-control"
									onChange={handleFormChange('stateOfRes')}
									required
									value={formVal.stateOfRes}
								>
									{stateList.map((states, index) => (
										<option value={states.id} key={index}>
											{states.stateName}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-12 col-sm-5 mb-3">
							<div className="form-check">
								<input type="checkbox" className="form-check-input" />
								<label
									className="form-check-label ftsz-regular text-body"
									for=""
								>
									SMS/Email alert.
								</label>
							</div>
						</div>
						<div className="col-12 col-sm-5 mb-3">
							<div className="form-check">
								<input type="checkbox" className="form-check-input" id="" />
								<label
									className="form-check-label ftsz-regular text-body"
									for=""
								>
									Cheque book.
								</label>
							</div>
						</div>
						<div className="col-12 mb-3">
							<h6 className="ftwt-500 ftsz-12 mb-2">
								Currencies of account to be opened
							</h6>
							<div className="form-row">
								<div className="col-sm-3">
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="" />
										<label
											className="form-check-label ftsz-regular text-body"
											for=""
										>
											NGN
										</label>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="" />
										<label
											className="form-check-label ftsz-regular text-body"
											for=""
										>
											USD
										</label>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="" />
										<label
											className="form-check-label ftsz-regular text-body"
											for=""
										>
											EURO
										</label>
									</div>
								</div>
								<div className=" col-sm-3">
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="" />
										<label
											className="form-check-label ftsz-regular text-body"
											for=""
										>
											GBP
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className=" mb-3 mt-2">
						<div className="form-check d-none">
							<input
								type="checkbox"
								className="form-check-input"
								onChange={(ev) => tcHandler(ev.target.checked)}
							/>
							<label className="form-check-label ftsz-regular text-body" for="">
								By checking this box, you have accepted to be part of the {''}
								<a
									className="text-red"
									style={{ color: 'red', textDecoration: 'underline' }}
									href="javascript:;"
									onClick={(e) => {
										handleShowTBH(e)
									}}
								>
									TBH Community
								</a>
							</label>
						</div>
						<div className="form-check">
							<input
								type="checkbox"
								className="form-check-input"
								onChange={(ev) => tcHandler(ev.target.checked)}
							/>
							<label className="form-check-label ftsz-regular text-body" for="">
								By checking this box, you have accepted the{' '}
								<a
									className="text-red"
									style={{ color: 'red', textDecoration: 'underline' }}
									href="javascript:;"
									onClick={(e) => {
										handleShowTC(e)
									}}
								>
									Terms and conditions
								</a>
							</label>
						</div>
					</div>
				</div>
			</form>
		</div>
	)
}

export default UploadDocument
