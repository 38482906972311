import React from "react";
import { useHistory } from "react-router-dom";
import Info from "../../../../../../Images/Info_icon.svg";
import Warning from "../../../../../../Images/warning-icon.svg";

const Bvn = ({
  formValues,
  handleFormChange,
  setDisableBtn,
  dob,
  onDateChange,
  bvnVal,
  handleBvnChange,
}) => {
  const history = useHistory();
  if (bvnVal.bvn.length <= 10) {
    setDisableBtn(true);
  } else {
    setDisableBtn(false);
  }
  return (
    <div className="w-80">
      <form action="">
        <div class="mb-2 text-centers text-dark">
          <h3 class="mb-0 sec-headers">
            <span
              onClick={() => history.goBack()}
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-long-arrow-alt-left mr-2"></i>
            </span>
            BVN Validation
          </h3>
        </div>
        <div>
          <p className="pale-red-tone-bg pry-red-clr ftwt-600 ftsz-12 mb-0 px-1 py-2 mb-3 rounded-lg">
            <img src={Warning} className="mr-1" alt="warning icon" />
            {/* Kindly ensure that your BVN information is up to date */}
            For a seamless experience, please ensure your BVN information is up
            to date.
          </p>
        </div>
        <div class="form-row mb-3">
          <div class="form-group col-12">
            <label
              for=""
              class="pry-grey-clr ftsz-12 ftwt-500 mb-1 d-flex justify-content-between align-items-center"
            >
              <span>
                Enter BVN{" "}
                <span className="font-weight-bolder text-black">
                  (Must be 11 digits)
                </span>
              </span>
              <div class="popover-wrapper position-relative">
                <div class="popover-title">
                  <img src={Info} alt="" style={{ cursor: "pointer" }} />
                </div>
                <div class="popover-content ftsz-12 p-2">
                  <ul class="pl-3">
                    <li>
                      The Bank Verification Number (BVN) is an 11-digit number
                    </li>
                    <li>Dial *565*0# to check your BVN</li>
                  </ul>
                </div>
              </div>
            </label>
            <div className="form-group">
              <input
                maxLength="11"
                type="text"
                class="form-control pry-input-border"
                id=""
                placeholder="Enter your BVN"
                value={bvnVal.bvn}
                onChange={handleBvnChange("bvn")}
                // value={formValues.bvn}
                // onChange={handleFormChange("bvn")}
              />
            </div>
          </div>
          <div class="form-group col-12 ftsz-12">
            <h6 class="ftwt-500 ftsz-12 mb-1 text-dark">Date of birth</h6>
            {/* <div class="row"> */}
            <input
              className="input-pad form-control pry-input-border"
              type="date"
              value={dob}
              onChange={onDateChange}
            />
            {/* </div> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default Bvn;
