import React from "react";
import Dropzone from "react-dropzone";

const ComboTwo = ({
  onDrop9,
  onDrop10,
  onDrop11,
  onDrop12,
  onDrop13,
  onDrop14,
  onDrop15,
  caCTwo,
  caC11,
  meMarTTwo,
  boarDTwo,
  refRTwo,
  otherSTwo,
  evidencETwo,
  branchCode,
  formValues,
  handleFormChange,
  handleSecChanges,
  sec,
  sectorList,
  industriesList,
  isLoading,
  showDoc,
  docText,
  tcHandler,
  isTCClicked,
  handleShowTC,
  handleShowTBH,
  stateList,
  formVal,
}) => {
  const MAX_SIZE = 2097152;
  return (
		<div>
			<form action="">
				<h6 className="mb-2 sec-header font-weight-normal text-body text-center ftsz-12">
					Upload pdf file formats. Asterisked items (**) are mandatory
				</h6>
				<div className="form-row mb-5 pb-5 mt-3">
					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">CAC**</h6>
						{caCTwo ? <p>{caCTwo.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop9}
								multiple={false}
								accept="image/jpeg, image/png, .pdf"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!caCTwo.length &&
												caCTwo.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!caCTwo.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">CAC 1.1**</h6>
						{caC11 ? <p>{caC11.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop10}
								multiple={false}
								accept="image/jpeg, image/png, .pdf"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!caC11.length &&
												caC11.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!caC11.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">MeMart**</h6>
						{meMarTTwo ? <p>{meMarTTwo.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop11}
								multiple={false}
								accept="image/jpeg, image/png, .pdf"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!meMarTTwo.length &&
												meMarTTwo.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!meMarTTwo.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">
							Board resolution signed and sealed**
						</h6>
						{boarDTwo ? <p>{boarDTwo.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop12}
								multiple={false}
								accept="image/jpeg, image/png, .pdf"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!boarDTwo.length &&
												boarDTwo.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!boarDTwo.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">References</h6>
						{refRTwo ? <p>{refRTwo.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop13}
								multiple={false}
								accept="image/jpeg, image/png, .pdf"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!refRTwo.length &&
												refRTwo.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!refRTwo.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">Others</h6>
						{otherSTwo ? <p>{otherSTwo.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop14}
								multiple={false}
								accept="image/jpeg, image/png, .pdf"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!otherSTwo.length &&
												otherSTwo.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!otherSTwo.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
						<div className="form-row">
							<label for="" className="form-label ftsz-12 ftwt-500 mb-1">
								Select Sector
							</label>
							<select
								className="custom-select pry-input-border"
								onChange={handleSecChanges('sector_id')}
								required
								value={sec.sector_id}
							>
								{sectorList.map((sectors, index) => (
									<option value={sectors.Sector_T24Code} key={index}>
										{sectors.Sector}
									</option>
								))}
							</select>
						</div>
					</div>

					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
						<div className="form-row">
							<label for="" className="form-label ftsz-12 ftwt-500 mb-1">
								Select Industry
							</label>
							<select
								className="custom-select pry-input-border"
								onChange={handleSecChanges('industry_id')}
								required
								value={sec.industry_id}
								disabled={isLoading}
							>
								{industriesList.map((industry, index) => (
									<option value={industry.Industry_T24Code} key={index}>
										{industry.Industry}
									</option>
								))}
							</select>
						</div>
					</div>

					{showDoc ? (
						<div className="col-12 col-md-6 mb-3 ftsz-12 ">
							<h6 className="ftwt-500 ftsz-12 mb-1 text-body">
								<p>{docText} **</p>
							</h6>
							{evidencETwo ? (
								<p>{evidencETwo.path}</p>
							) : (
								<p>No file uploaded</p>
							)}
							<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
								<Dropzone
									onDrop={onDrop15}
									multiple={false}
									accept="image/jpeg, image/png, .pdf"
									maxSize={MAX_SIZE}
								>
									{({ getRootProps, getInputProps }) => (
										<section className="doc-uploads">
											<div {...getRootProps()}>
												<input {...getInputProps()} placeholder="" />
												{!!evidencETwo.length &&
													evidencETwo.map((file) => (
														<p className="doc-texts">{file.path}</p>
													))}

												{!evidencETwo.length && (
													<section className="doc-texts">
														Drag 'n' drop , or click to select <br />
													</section>
												)}
											</div>
										</section>
									)}
								</Dropzone>
							</div>
						</div>
					) : (
						''
					)}

					<div className="form-row">
						<div className="col-12 my-4">
							<h6 className="ftwt-500 ftsz-12">Select value-added services</h6>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div className="form-group">
								<label>Branch of account domiciliation.</label>
								<select
									className="form-control"
									onChange={handleFormChange('branch')}
									required
									value={formValues.branch}
								>
									{branchCode.map((branches) => (
										<option value={branches.BranchCode}>
											{branches.BranchName}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div className="form-group">
								<label>State of Residence</label>
								<select
									className="form-control"
									onChange={handleFormChange('stateOfRes')}
									required
									value={formVal.stateOfRes}
								>
									{stateList.map((states, index) => (
										<option value={states.id} key={index}>
											{states.stateName}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-12 col-sm-5 mb-3">
							<div className="form-check">
								<input type="checkbox" className="form-check-input" />
								<label
									className="form-check-label ftsz-regular text-body"
									for=""
								>
									SMS/Email alert.
								</label>
							</div>
						</div>
						<div className="col-12 col-sm-5 mb-3">
							<div className="form-check">
								<input type="checkbox" className="form-check-input" id="" />
								<label
									className="form-check-label ftsz-regular text-body"
									for=""
								>
									Cheque book.
								</label>
							</div>
						</div>
						<div className="col-12 mb-3">
							<h6 className="ftwt-500 ftsz-12 mb-2">
								Currencies of account to be opened
							</h6>
							<div className="form-row">
								<div className="col-sm-3">
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="" />
										<label
											className="form-check-label ftsz-regular text-body"
											for=""
										>
											NGN
										</label>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="" />
										<label
											className="form-check-label ftsz-regular text-body"
											for=""
										>
											USD
										</label>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="" />
										<label
											className="form-check-label ftsz-regular text-body"
											for=""
										>
											EURO
										</label>
									</div>
								</div>
								<div className=" col-sm-3">
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="" />
										<label
											className="form-check-label ftsz-regular text-body"
											for=""
										>
											GBP
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className=" mb-3 mt-2">
						<div className="form-check d-none">
							<input
								type="checkbox"
								className="form-check-input"
								onChange={(ev) => tcHandler(ev.target.checked)}
							/>
							<label className="form-check-label ftsz-regular text-body" for="">
								By checking this box, you have accepted to be part of the {''}
								<a
									className="text-red"
									style={{ color: 'red', textDecoration: 'underline' }}
									href="javascript:;"
									onClick={(e) => {
										handleShowTBH(e)
									}}
								>
									TBH Community
								</a>
							</label>
						</div>

						<div className="form-check">
							<input
								type="checkbox"
								className="form-check-input"
								onChange={(ev) => tcHandler(ev.target.checked)}
							/>
							<label className="form-check-label ftsz-regular text-body" for="">
								By checking this box, you have accepted the{' '}
								<a
									className="text-red"
									style={{ color: 'red', textDecoration: 'underline' }}
									href="javascript:;"
									onClick={(e) => {
										handleShowTC(e)
									}}
								>
									Terms and conditions
								</a>
							</label>
						</div>
					</div>
				</div>
			</form>
		</div>
	)
};

export default ComboTwo;
