import { CHANGE_ID_TYPE } from "../types";

const initaial_state = {
  id_type: "intl",
};

const KiakiaTwoReducer = (state = initaial_state, action) => {
  switch (action.type) {
    case CHANGE_ID_TYPE: {
      return {
        ...state,
        id_type: action.payload,
      };
    }
    default:
      return state;
  }
};

export default KiakiaTwoReducer;
