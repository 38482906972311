import React from "react";
import { Spinner } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import OtpInput from "react-otp-input";

const OTP = ({
  showOtp,
  handleCloseOtp,
  validateOTP,
  handleFormChange,
  formValues,
  loading,
  verifyBvn,
  otp,
  setOtp,
}) => {
  return (
    <div>
      <Modal
        show={showOtp}
        onHide={handleCloseOtp}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <div className="my-0 text-center text-dark">
              <h5 className="mb-0 sec-header">Enter OTP</h5>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <p className="mb-4 pry-grey-clr ftsz-regular text-center">
              An OTP was sent to your mobile number registered to your BVN.
              Enter the OTP to proceeed.
            </p>
            <div className="form-row mb-3">
              <div className="form-group col-12 col-sm-6 col-md-5 mx-auto ftsz-12">
                <div className="row justify-content-center form-group">
                  <OtpInput
                    value={otp}
                    onChange={(otp) => setOtp(otp)}
                    numInputs={6}
                    className="form-control text-center otp-inputs"
                  />
                </div>
              </div>
            </div>
            <div className="text-center">
              <button
                type="button"
                className="btn btn-danger pry-red-bg px-5"
                disabled={loading}
                onClick={validateOTP}
              >
                {loading ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  "Proceed"
                )}
              </button>
            </div>
          </form>
          <p className="ftsz-12 text-center mt-4">
            Did not get the OTP?
            <button
              type="button"
              className="bare-btn text-underline"
              onClick={verifyBvn}
            >
              Resend OTP
            </button>
          </p>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default OTP;
