import React from "react";
import Warning from "../../../../../../../Images/warning-icon.svg";

const NationalId = ({ formValues, handleFormChange }) => {
  return (
    <div>
      <p class="pale-red-tone-bg pry-red-clr ftwt-600 ftsz-12 mb-0 px-3 py-2 mb-3 rounded-lg">
        <img
          src={Warning}
          class="mr-3"
          style={{ width: "30px", height: "30px" }}
          alt=""
        />
        Dial *346# to get your Virtual NIN
      </p>
      <div className="form-row">
        <div className="col-12 col-md-12 mb-3">
          <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
            NIN number
          </label>
          <input
            type="text"
            className="form-control pry-input-border"
            placeholder="Enter Virtual NIN"
            value={formValues.idCardNo}
            onChange={handleFormChange("idCardNo")}
          />
        </div>
        {/* <div className="col-sm-6 col-md-4 mb-3">
          <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
            Issue Date
          </label>
          <input
            type="date"
            className="form-control pry-input-border"
            value={formValues.issueDate}
            onChange={handleFormChange("issueDate")}
          />
        </div> */}
      </div>
    </div>
  );
};

export default NationalId;
