import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { currentAction } from "../../../../../../redux/actions";
import ExistingDomCurrent from "./components/domCurrent";
import ExistingClassicCurrent from "./components/classicCurrent";
import ExistingCurrentNone from "./components/none";

const CurrentTab = () => {
  const [drop, setDrop] = useState({
    account: "",
  });

  const dispatch = useDispatch();

  const handleChange = (name) => (event) => {
    setDrop({
      ...drop,
      [name]: event.target.value,
    });
    dispatch(currentAction.set_account_type(event.target.value));
  };

  const { account_type } = useSelector(({ current }) => current);

  return (
    <div>
      <div className="mb-5">
        <label htmlFor="" className="ftsz-12 ftwt-500">
          Select account type
        </label>
        <select
          className="custom-select pale-red-tone-bg pry-input-border border-0"
          onChange={handleChange("account")}
          value={drop.account}
        >
          <option value="none">Select account type</option>
          <option value="cclassic">Classic current</option>
          <option value="cdom">Domiciliary current</option>
        </select>
      </div>
      <div>
        {account_type === "none" && <ExistingCurrentNone />}
        {account_type === "cclassic" && <ExistingClassicCurrent />}
        {account_type === "cdom" && <ExistingDomCurrent />}
      </div>
    </div>
  );
};

export default CurrentTab;
