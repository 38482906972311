import { useState } from "react";
import "./App.css";
import { Router, Route, Switch } from "react-router-dom";
import { createBrowserHistory } from "history";
import "antd/dist/antd.css";
// import dotenv from "dotenv";
import "react-circular-progressbar/dist/styles.css";
// import dotenv from "dotenv";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import HomePage from "./container/Welcome";
import NewCustomer from "./container/NewCustomer";
import ExistingCustomer from "./container/ExistingCustomer";
import BusinessAccount from "./container/NewCustomer/modules/Business";
import PersonalAccount from "./container/NewCustomer/modules/Personal";
import KiaKiaOne from "./container/NewCustomer/modules/KiakiaOne";
import KiakiaTwo from "./container/NewCustomer/modules/KiakiaTwo";
import ClassicSavings from "./container/NewCustomer/modules/classicSavings";
import KidsAccount from "./container/NewCustomer/modules/kidsAccount";
import CompanyAcc from "./container/NewCustomer/modules/Business/components/company/company";
import BusinessAcc from "./container/NewCustomer/modules/Business/components/business/components/business";
import ClassicCurrent from "./container/NewCustomer/modules/classicCurrent";
import ExistingPersonal from "./container/ExistingCustomer/modules/personal";
import DomSavings from "./container/NewCustomer/modules/domSavings";
import ClassicSavingsExistingCustomers from "./container/ExistingCustomer/modules/cSavings";
import KidsExistingAcc from "./container/ExistingCustomer/modules/kidsExisting";
import verifyReferee from "./container/NewCustomer/modules/domSavings/components/reference/components/verifyReferee";
import RefereesConsent from "./container/References/refereesConsent";
import CloudinaryUpload from "./container/cloudinary";
import DomCurrent from "./container/NewCustomer/modules/domCurrent";
import DomCurrentEx from "./container/ExistingCustomer/modules/domCurrent";
import DomSavingsEx from "./container/ExistingCustomer/modules/domSavings";
import ClassicCurrentEx from "./container/ExistingCustomer/modules/classicCurrent";
import Options from "./container/NewCustomer/modules/domSavings/components/reference/components/options";
import AddReference from "./container/References/AddReference";
import NonSterlingRef from "./container/References/NonSterling/reference";
import ExBusinessAccount from "./container/ExistingCustomer/modules/Business";
import ExBusinessAcc from "./container/ExistingCustomer/modules/Business/components/business/components/business";
import ExistingCompanyAcc from "./container/ExistingCustomer/modules/Business/components/company/company";
import DocumentUpload from "./container/DocUpload/documentUpload";
import DocumentUploadTest from "./container/test/documentUpload";
import Feedback from "./container/Customercare/feedback";
import NewLandingPage from "./container/Welcome/newLanding";
import ReactGAComponent from "./ga";
import DashboardMain from "./container/Dashboard/pages/dashboard";
import AdminLayout from "./container/Dashboard/components/layouts/adminlayout";
import Home from "./container/Dashboard/components/home";
import AtmLocatorMap from "./container/Welcome/components/newPages/AtmLocator/atmLocatorMap";
import ForgotAccount from "./container/Welcome/components/ForgotAccount/forgotaccount";
import LivenessCheck from "./container/Welcome/components/ForgotAccount/livenessCheck";
import SterlingCaresAccount from "./container/NewCustomer/modules/Business/components/sterlingCares/components/sterlingCares";
import ExistingSterlingCaresAccount from "./container/ExistingCustomer/modules/Business/components/sterlingCares/components/sterlingCares";
import ZeroAMFAccount from "./container/NewCustomer/modules/Business/components/zeroAMF/components/zeroAMF";
import ExistingZeroAMFAccount from "./container/ExistingCustomer/modules/Business/components/zeroAMF/components/zeroAMF";
import ForgotBvn from "./container/Welcome/components/ForgotAccount/forgotbvn";
import ApiDashboardLogin from "./container/apiDashboard/pages/login";
import ApidashboardLayout from "./container/apiDashboard/components/ApidashboardHeader";
import CreatePassword from "./container/apiDashboard/pages/createPassword";
import ProtectedRoutes from "./container/protectedRoutes";

export const history = createBrowserHistory();
function App() {
  return (
    <div className="App">
      <Router history={history}>
        <ReactGAComponent>
          <Switch>
            {/* new savings  */}
            <Route exact path="/dashboard" component={DashboardMain} />
            <Route path="/admin" component={AdminLayout} />
            <Route path="/api-dashboard/login" component={ApiDashboardLogin} />
            <Route
              path="/api-dashboard/create-password"
              component={CreatePassword}
            />
            <ProtectedRoutes
              path="/api-dashboard"
              component={ApidashboardLayout}
            />

            {/* </Route> */}
            <Route exact path="/kiakiaOne" component={KiaKiaOne} />
            <Route exact path="/kiakiaTwo" component={KiakiaTwo} />
            <Route exact path="/kidsAccount" component={KidsAccount} />
            <Route exact path="/classicSavings" component={ClassicSavings} />
            <Route exact path="/domSavings" component={DomSavings} />
            {/* existing savings  */}
            <Route
              exact
              path="/existing-classicSavings"
              component={ClassicSavingsExistingCustomers}
            />
            <Route exact path="/existing-kids" component={KidsExistingAcc} />
            <Route
              exact
              path="/existing-dom-savings"
              component={DomSavingsEx}
            />
            {/* new current  */}
            <Route exact path="/classicCurrent" component={ClassicCurrent} />
            <Route exact path="/domCurrent" component={DomCurrent} />
            {/* existing current  */}
            <Route
              exact
              path="/existing-classic-current"
              component={ClassicCurrentEx}
            />
            <Route
              exact
              path="/existing-dom-current"
              component={DomCurrentEx}
            />
            {/* new business  */}
            <Route exact path="/business-account" component={BusinessAccount} />
            <Route exact path="/businessAccount" component={BusinessAcc} />
            <Route exact path="/ngo" component={SterlingCaresAccount} />
            <Route exact path="/zeroAMF" component={ZeroAMFAccount} />
            {/* existing business  */}
            <Route
              exact
              path="/existing-business-account"
              component={ExBusinessAccount}
            />
            <Route
              exact
              path="/existing-businessAccount"
              component={ExBusinessAcc}
            />
            <Route
              exact
              path="/existing-CompanyAccount"
              component={ExistingCompanyAcc}
            />
            <Route
              exact
              path="/existing-ngo"
              component={ExistingSterlingCaresAccount}
            />

            <Route
              exact
              path="/existing-zeroAMF"
              component={ExistingZeroAMFAccount}
            />

            {/* reference  */}
            <Route exact path="/add-referee" component={AddReference} />
            <Route
              exact
              path="/referee-non-sterling"
              component={NonSterlingRef}
            />
            <Route exact path="/refereeConsent" component={RefereesConsent} />
            <Route exact path="/ref-verify" component={verifyReferee} />
            {/* others  */}
            <Route
              exact
              path="/upload-corporate-document"
              component={DocumentUpload}
            />
            <Route exact path="/customercare-feedback" component={Feedback} />
            <Route exact path="/testing" component={DocumentUploadTest} />
            <Route exact path="/options" component={Options} />
            <Route exact path="/cloudUpload" component={CloudinaryUpload} />
            <Route
              exact
              path="/personal-existing"
              component={ExistingPersonal}
            />
            <Route exact path="/companyAccount" component={CompanyAcc} />
            <Route exact path="/personal-account" component={PersonalAccount} />
            <Route exact path="/new-customer" component={NewCustomer} />
            <Route
              exact
              path="/existing-customer"
              component={ExistingCustomer}
            />
            {/* <Route exact path="/" component={HomePage} /> */}
            <Route exact path="/" component={NewLandingPage} />
            <Route exact path="/atm-locator" component={AtmLocatorMap} />
            <Route exact path="/retrieve-account" component={ForgotAccount} />
            <Route exact path="/retrieve-bvn" component={ForgotBvn} />
            <Route exact path="/liveness-check" component={LivenessCheck} />
          </Switch>
        </ReactGAComponent>
      </Router>
    </div>
  );
}

export default App;
