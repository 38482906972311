import { useState } from "react";

export default () => {
      //completed modal
  const [showCompleted, setShowCompleted] = useState(false);
  const handleCloseCompleted = () => setShowCompleted(false);
  const handleShowCompleted = () => setShowCompleted(true);

  //otp modal
  const [showOtp, setShowOtp] = useState(false);
  const handleCloseOtp = () => setShowOtp(false);
  const handleShowOtp = () => setShowOtp(true);

  const [showInvalidOTP, setShowInvalidOTP] = useState(false);
  const handleCloseInvalidOTP = () => setShowInvalidOTP(false);
  const handleShowInvalidOTP = () => setShowInvalidOTP(true);

  const [showTC, setShowTC] = useState(false);
  const handleCloseTC = () => setShowTC(false);
    const handleShowTC = () => setShowTC(true);

    const [isOpen, setIsOpen] = useState(false)
    const handleClosePendingModal = () => setIsOpen(false);
    const handleShowPendingModal = () => setIsOpen(true);

  return [
      showCompleted,
      handleCloseCompleted,
      handleShowCompleted,
      showOtp,
      handleShowOtp,
      handleCloseOtp,
      showInvalidOTP,
      handleCloseInvalidOTP,
      handleShowInvalidOTP,
      showTC,
      handleCloseTC,
      handleShowTC,
      isOpen,
      setIsOpen,
      handleClosePendingModal,
      handleShowPendingModal
  ]
}