import axios from "axios";
import React, { useState } from "react";
import Warning from "../../../../../../../../../Images/warning-icon.svg";
import { useHistory } from "react-router";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { encrypt2 } from "../../../../../../../../../encr";
import { decryptHandler2 } from "../../../../../../../../../dec";
import { set_ref_info } from "../../../../../../../../../redux/actions/refAction";
import { apiUrl } from "../../../../../../../../../redux/actions/endpoint";
import { apiAccount } from "../../../../../../../../../service/apiAccount";
import { appUrls } from "../../../../../../../../../service/urls";
import { authOApi } from "../../../../../../../../../service/authOApi";

const AddReference = ({
  accountInfo,
  formValues,
  handleFormChange,
  current,
  setCurrent,
  showAddRefOTP,
  handleCloseAddRefOTP,
  handleShowAddRefOTP,
  handleShowRefSuccessOTP,
  handleFormChanges
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [refDetails, setRefDetails] = useState();
  const history = useHistory();
  const dispatch = useDispatch();

  const verifyRef = () => {
    setIsLoading(true);
    const payload = {
      refAccountNumber: formValues.refAccount,
      customerAccountNubmer: accountInfo,
    };
    const data = encrypt2(payload);
    authOApi
      .post(`${appUrls.VERIFY_REFEREE_ACCOUNT}`, { data: data })
      .then((response) => {
        const res = decryptHandler2(response.data);

        if (res.ResponseCode === "00") {
          setRefDetails(res);
          dispatch(set_ref_info(res));
          setIsLoading(false);
          handleShowAddRefOTP();
        } else {
          message.error(res.ResponseDescription);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        message.error(error.response.statusText);
        setIsLoading(false);
      });
  };

  return (
    <div>
      <div className="">
        <div className="mb-5">
          <h6 className="sec-header text-body text-center mb-3 px-sm-5 mx-sm-5">
            Add reference
          </h6>
          <div className="mb-4">
            <p className="text-center mx-md-5">
              Proceed to add reference, Kindly ensure that the referee’s account
              meets the following requirements:
            </p>
            <ul className="pl-3 text-left">
              <li className="custom-green-marker mb-2">
                Account has been in operation for more than 6 months.
              </li>
              <li className="custom-green-marker mb-2">
                Account is not dormant
              </li>
              <li className="custom-green-marker mb-2">
                Category criteria (References from a registered business can
                only be used for an individual account or another registered
                business but a reference from a registered business is suitable
                for all forms of current account.)
              </li>
            </ul>
          </div>
          <div className="d-flex align-items-center pale-red-tone-bg pry-red-clr ftsz-12 mb-0 mt-3 px-3 py-2 mb-3 rounded-lg">
            <img src={Warning} className="mr-3" alt="" />
            <div>
              <ul className="pl-3 text-left ftwt-600">
                <li className="custom-red-marker">
                  For refree with sterling account, an email is sent to the
                  referee to provide consent via a secured link.{" "}
                </li>
                <li className="custom-red-marker">
                  For refree with other bank account, download or email form to
                  refree and upload the completed form.{" "}
                </li>
              </ul>
            </div>
          </div>
          <form action="">
            <div className="col-12 mb-3">
              <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                Referee’s account number
              </label>
              <input
                type="text"
                className="form-control pry-input-border"
                placeholder="Enter your referee’s account number"
                value={formValues.refAccount}
                onChange={handleFormChanges("refAccount")}
              />
            </div>
            <div className="text-right">
              <button
                type="button"
                className="btn btn-danger pry-red-bg px-5 mb-3"
                disabled={isLoading}
                onClick={verifyRef}
              >
                {isLoading ? "Processing ..." : "Proceed"}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddReference;
