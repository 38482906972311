import React from "react";
import Dropzone from "react-dropzone";

const ComboOne = ({
  onDrop1,
  onDrop2,
  onDrop3,
  onDrop4,
  onDrop5,
  onDrop6,
  onDrop7,
  onDrop8,
  cac,
  co2,
  co7,
  meMart,
  board,
  refr,
  others,
  evidence,
  branchCode,
  formVal,
  formValues,
  handleFormChange,
  handleSecChanges,
  sec,
  sectorList,
  industriesList,
  isLoading,
  showDoc,
  docText,
  tcHandler,
  isTCClicked,
  handleShowTC,
  handleShowTBH,
  stateList,
}) => {
  const MAX_SIZE = 2097152;
  return (
		<div>
			<form action="">
				<h6 className="mb-2 sec-header font-weight-normal text-body text-center ftsz-12">
					Upload pdf file formats. Asterisked items (**) are mandatory
				</h6>
				<div className="form-row mb-5 pb-5 mt-3">
					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">CAC**</h6>
						{cac ? <p>{cac.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop1}
								multiple={false}
								accept="image/jpeg, image/png"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!cac.length &&
												cac.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!cac.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">Co2**</h6>
						{co2 ? <p>{co2.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop2}
								multiple={false}
								accept="image/jpeg, image/png"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!co2.length &&
												co2.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!co2.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">co7**</h6>
						{co7 ? <p>{co7.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop3}
								multiple={false}
								accept="image/jpeg, image/png"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!co7.length &&
												co7.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!co7.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">MeMart**</h6>
						{meMart ? <p>{meMart.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop4}
								multiple={false}
								accept="image/jpeg, image/png"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!meMart.length &&
												meMart.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!meMart.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">
							Board resolution signed and sealed**
						</h6>
						{board ? <p>{board.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop5}
								multiple={false}
								accept="image/jpeg, image/png"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!board.length &&
												board.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!board.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">References</h6>
						{refr ? <p>{refr.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop6}
								multiple={false}
								accept="image/jpeg, image/png"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!refr.length &&
												refr.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!refr.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						<h6 className="ftwt-500 ftsz-12 mb-1 text-body">Others</h6>
						{others ? <p>{others.path}</p> : <p>No file uploaded</p>}
						<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
							<Dropzone
								onDrop={onDrop7}
								multiple={false}
								accept="image/jpeg, image/png"
								maxSize={MAX_SIZE}
							>
								{({ getRootProps, getInputProps }) => (
									<section className="doc-uploads">
										<div {...getRootProps()}>
											<input {...getInputProps()} placeholder="" />
											{!!others.length &&
												others.map((file) => (
													<p className="doc-texts">{file.path}</p>
												))}

											{!others.length && (
												<section className="doc-texts">
													Drag 'n' drop , or click to select <br />
												</section>
											)}
										</div>
									</section>
								)}
							</Dropzone>
						</div>
					</div>

					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
						<div className="form-row">
							<label for="" className="form-label ftsz-12 ftwt-500 mb-1">
								Select Sector
							</label>
							<select
								className="custom-select pry-input-border"
								onChange={handleSecChanges('sector_id')}
								required
								value={sec.sector_id}
							>
								{sectorList.map((sectors, index) => (
									<option value={sectors.Sector_T24Code} key={index}>
										{sectors.Sector}
									</option>
								))}
							</select>
						</div>
					</div>

					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
						<div className="form-row">
							<label for="" className="form-label ftsz-12 ftwt-500 mb-1">
								Select Industry
							</label>
							<select
								className="custom-select pry-input-border"
								onChange={handleSecChanges('industry_id')}
								required
								value={sec.industry_id}
								disabled={isLoading}
							>
								{industriesList.map((industry, index) => (
									<option value={industry.Industry_T24Code} key={index}>
										{industry.Industry}
									</option>
								))}
							</select>
						</div>
					</div>

					{showDoc ? (
						<div className="col-12 col-md-6 mb-3 ftsz-12 ">
							<h6 className="ftwt-500 ftsz-12 mb-1 text-body">
								<p>{docText} **</p>
							</h6>
							{evidence ? <p>{evidence.path}</p> : <p>No file uploaded</p>}
							<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
								<Dropzone
									onDrop={onDrop8}
									multiple={false}
									accept="image/jpeg, image/png"
								>
									{({ getRootProps, getInputProps }) => (
										<section className="doc-uploads">
											<div {...getRootProps()}>
												<input {...getInputProps()} placeholder="" />
												{!!evidence.length &&
													evidence.map((file) => (
														<p className="doc-texts">{file.path}</p>
													))}

												{!evidence.length && (
													<section className="doc-texts">
														Drag 'n' drop , or click to select <br />
													</section>
												)}
											</div>
										</section>
									)}
								</Dropzone>
							</div>
						</div>
					) : (
						''
					)}

					<div className="form-row">
						<div className="col-12 my-4">
							<h6 className="ftwt-500 ftsz-12">Select value-added services</h6>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div className="form-group">
								<label>Branch of account domiciliation.</label>
								<select
									className="form-control"
									onChange={handleFormChange('branch')}
									required
									value={formValues.branch}
								>
									{branchCode.map((branches) => (
										<option value={branches.BranchCode}>
											{branches.BranchName}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
							<div className="form-group">
								<label>State of Residence</label>
								<select
									className="form-control"
									onChange={handleFormChange('stateOfRes')}
									required
									value={formVal.stateOfRes}
								>
									{stateList.map((states, index) => (
										<option value={states.id} key={index}>
											{states.stateName}
										</option>
									))}
								</select>
							</div>
						</div>
						<div className="col-12 col-sm-5 mb-3">
							<div className="form-check">
								<input type="checkbox" className="form-check-input" />
								<label
									className="form-check-label ftsz-regular text-body"
									for=""
								>
									SMS/Email alert.
								</label>
							</div>
						</div>
						<div className="col-12 col-sm-5 mb-3">
							<div className="form-check">
								<input type="checkbox" className="form-check-input" id="" />
								<label
									className="form-check-label ftsz-regular text-body"
									for=""
								>
									Cheque book.
								</label>
							</div>
						</div>
						<div className="col-12 mb-3">
							<h6 className="ftwt-500 ftsz-12 mb-2">
								Currencies of account to be opened
							</h6>
							<div className="form-row">
								<div className="col-sm-3">
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="" />
										<label
											className="form-check-label ftsz-regular text-body"
											for=""
										>
											NGN
										</label>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="" />
										<label
											className="form-check-label ftsz-regular text-body"
											for=""
										>
											USD
										</label>
									</div>
								</div>
								<div className="col-sm-3">
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="" />
										<label
											className="form-check-label ftsz-regular text-body"
											for=""
										>
											EURO
										</label>
									</div>
								</div>
								<div className=" col-sm-3">
									<div className="form-check">
										<input type="checkbox" className="form-check-input" id="" />
										<label
											className="form-check-label ftsz-regular text-body"
											for=""
										>
											GBP
										</label>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className=" mb-3 mt-2">
						<div className="form-check d-none">
							<input
								type="checkbox"
								className="form-check-input"
								onChange={(ev) => tcHandler(ev.target.checked)}
							/>
							<label className="form-check-label ftsz-regular text-body" for="">
								By checking this box, you have accepted to be part of the {''}
								<a
									className="text-red"
									style={{ color: 'red', textDecoration: 'underline' }}
									href="javascript:;"
									onClick={(e) => {
										handleShowTBH(e)
									}}
								>
									TBH Community
								</a>
							</label>
						</div>

						<div className="form-check">
							<input
								type="checkbox"
								className="form-check-input"
								onChange={(ev) => tcHandler(ev.target.checked)}
							/>
							<label className="form-check-label ftsz-regular text-body" for="">
								By checking this box, you have accepted the{' '}
								<a
									className="text-red"
									style={{ color: 'red', textDecoration: 'underline' }}
									href="javascript:;"
									onClick={(e) => {
										handleShowTC(e)
									}}
								>
									Terms and conditions
								</a>
							</label>
						</div>
					</div>
				</div>
			</form>
		</div>
	)
};

export default ComboOne;
