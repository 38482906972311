import React, { useState } from "react";
import styles from "../../components/apidashboard.module.css";
import Info from "../../../../Images/Info_icon.svg";
import Spinner from "react-bootstrap/Spinner";
import { api } from "../../../../service/api";
import { appUrls } from "../../../../service/urls";
import { message } from "antd";

export default function AddMerchants() {
  const [loading, setLoading] = useState(false);
  const [inputVal, setInputVal] = useState({
    firstName: "",
    lastName: "",
    email: "",
    url: window.origin + "/api-dashboard/create-password",
  });

  const handleCreateMerchant = async () => {
    setLoading(true);
    try {
      const res = await api.post(appUrls.ADMIN_ADD_MERCHANT_URL, inputVal);
      if (res?.status === 200) {
        message.success(res?.data?.message, 3);
        setInputVal((prev) => ({
          ...prev,
          firstName: "",
          lastName: "",
          email: "",
        }));
      }
    } catch (error) {
      message.error(error?.data?.message, 3);
    } finally {
      setLoading(false);
    }
  };

  const handleValidation = () => {
    if (inputVal?.firstName.trim() === "") {
      message.error("First Name cannot be Empty..", 3);
      return;
    }
    if (inputVal?.lastName.trim() === "") {
      message.error("Last Name cannot be Empty..", 3);
      return;
    }
    if (inputVal?.email.trim() === "") {
      message.error("Email cannot be Empty..", 3);
      return;
    }
    handleCreateMerchant();
  };

  return (
    <div className={styles.addmerchants_container}>
      <div className={styles.addmerchants_title}>New User</div>
      <form action="">
        <div className="form-row mb-3">
          <div className="form-group col-12">
            <label
              htmlFor=""
              className="pry-grey-clr ftsz-12 ftwt-500 mb-1 d-flex justify-content-between align-items-center"
            >
              First Name
            </label>
            <div className="form-group mb-0">
              <input
                type="text"
                className="form-control pry-input-border"
                placeholder="Enter Merchant First Name"
                onChange={(e) => {
                  setInputVal({
                    ...inputVal,
                    firstName: e.target.value,
                  });
                }}
                value={inputVal.firstName}
              />
            </div>
          </div>
          <div className="form-group col-12">
            <label
              htmlFor=""
              className="pry-grey-clr ftsz-12 ftwt-500 mb-1 d-flex justify-content-between align-items-center"
            >
              Last Name
            </label>
            <div className="form-group mb-0">
              <input
                type="text"
                className="form-control pry-input-border"
                placeholder="Enter Merchant Last Name"
                onChange={(e) => {
                  setInputVal({
                    ...inputVal,
                    lastName: e.target.value,
                  });
                }}
                value={inputVal.lastName}
              />
            </div>
          </div>
          <div className="form-group col-12 ftsz-12">
            <h6 className="ftwt-500 ftsz-12 mb-1 text-dark">Email</h6>
            <input
              className="input-pad form-control pry-input-border"
              placeholder="Enter User Email Address"
              type="email"
              onChange={(e) => {
                setInputVal({
                  ...inputVal,
                  email: e.target.value,
                });
              }}
              value={inputVal.email}
            />
          </div>
        </div>
        <button
          type="button"
          className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 validateBtn btn-block"
          disabled={loading}
          onClick={handleValidation}
        >
          {loading ? (
            <Spinner
              as="span"
              animation="border"
              size="sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            "Create user"
          )}
        </button>
      </form>
    </div>
  );
}
