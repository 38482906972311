import React, { useState } from "react";
import { Skeleton } from "antd";
import styles from "./atmStatus.module.css";

export default function AtmStatus({
  atmLocations,
  branchLocations,
  isLoading,
}) {
  const [searchTerm, setSearchTerm] = useState("");

  return (
    <div className={styles.containerStatus}>
      <input
        className={styles.search}
        placeholder="Enter Location"
        type="text"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {/* <div className={styles.atmTypeStyle}> */}
      {/* <div className={styles.withdrawalAtm}>Withdrawal ATM</div> */}
      {/* <div className={styles.depositAtm}>Deposit ATM</div> */}
      {/* </div> */}
      {/* <div className={styles.nonFundedStyle}>
        <input className={styles.checkbox} type="checkbox" />
        Show non- funded ATM’s
      </div> */}
      <p>BRANCHES AND ATM'S CLOSE TO YOU</p>
      <Skeleton loading={isLoading} active />
      <div className={styles.location}>
        {[...atmLocations, ...branchLocations]
          .filter((fil) => {
            const pattern = /[^a-zA-Z0-9]/g;
            if (searchTerm === "") return fil;
            return fil.name
              .replace(pattern, "")
              .toLowerCase()
              .includes(searchTerm.replace(pattern, "").toLocaleLowerCase());
          })
          .map((list, index) => {
            return (
              <div
                key={index}
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  gap: ".5rem",
                  fontWeight: 400,
                  fontSize: "16px",
                  borderBottom: "1px solid #CAC2C3",
                  paddingBottom: "10px",
                  color: "#000000",
                }}
                onClick={() =>
                  window.open(
                    `https://www.google.com/maps/search/?api=1&query=${list?.latitude},${list?.longitude}`,
                    "_blank"
                  )
                }
              >
                {list?.name}
                <div
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    gap: "1rem",
                    alignItems: "center",
                  }}
                >
                  {list?.distance} km
                  {/* <div
                    style={{
                      padding: "5px 10px",
                      background: `${true ? "#E1FFE5" : "#FDE3E4"}`,
                      borderRadius: "4px",
                      color: `${true ? "#1DED3E" : "#D92128"}`,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {true ? "FUNDED" : "NOT FUNDED"}
                  </div> */}
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}
