import React from "react";
import { useDispatch } from "react-redux";
import { bankAction } from "../../../../../../../redux/actions";

const SelectBank = () => {
  const dispatch = useDispatch();

  const switchSterling = () => {
    dispatch(bankAction.setBank("sterling"));
  };

  const switchOthers = () => {
    dispatch(bankAction.setBank("otherBanks"));
  };

  return (
    <div>
      <button
        className="btn btn-danger btn-lg btn-block"
        onClick={switchSterling}
      >
        Sterling
      </button>
      <br />
      <button
        className="btn btn-outline-danger btn-lg btn-block"
        onClick={switchOthers}
      >
        Others
      </button>
    </div>
  );
};

export default SelectBank;
