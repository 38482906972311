import React from "react";
import warning from "../../../../../../../../../../../Images/warning-icon.svg";
import EditMandateInstruction from "./instruction";

const EditSignatoryForm = ({ update }) => {
  return (
    <div>
      <form action="">
        <p class=" pale-red-tone-bg pry-red-clr ftwt-600 ftsz-12 mb-0 px-3 py-2 mb-3 rounded-lg">
          <img src={warning} class="mr-3" alt="" />
          Update your address and email if it has changed
        </p>
        <div class="form-row mb-3 align-items-end">
          <div class="col-12 col-sm-6 col-md-4 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              First name
            </label>
            <input
              type="text"
              class="form-control pry-input-border bg-white"
              value="Tunde"
              disabled
            />
          </div>
          <div class="col-12 col-sm-6 col-md-4 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Second name
            </label>
            <input
              type="text"
              class="form-control pry-input-border bg-white"
              value="Emeka"
              disabled
            />
          </div>
          <div class="col-12 col-sm-6 col-md-4 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Last name
            </label>
            <input
              type="text"
              class="form-control pry-input-border bg-white"
              value="Aminah"
              disabled
            />
          </div>
          <div class="col-12 col-sm-6 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Phone number
            </label>
            <input
              type="text"
              class="form-control pry-input-border bg-white"
              value="0801 2345 678"
              disabled
            />
          </div>
          <div class="col-12 col-sm-6 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Date of Birth
            </label>
            <input
              type="text"
              class="form-control pry-input-border bg-white"
              value="12-04-1989"
              disabled
            />
          </div>
          <div class="col-12 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Email address
            </label>
            <input
              type="email"
              class="form-control pry-input-border"
              placeholder="Enter email address"
              value="sample@email.com"
            />
          </div>
          <div class="col-12 mb-3">
            <div class="form-row">
              <div class="col-12">
                <h6 class="ftwt-600 ftsz-12">Residential address</h6>
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  House number
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border"
                  value="No 12"
                  placeholder="House number"
                />
              </div>
              <div class="col-12 col-md-6 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  Street name
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border"
                  value="Bode Thomas"
                  placeholder="Street name"
                />
              </div>
              <div class="col-12 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  Nearest bus-stop/landmark
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border"
                  value="Masha round about"
                  placeholder="Nearest bus-stop/landmark"
                />
              </div>
              <div class="col-12 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  Area/State
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border"
                  value="Lagos"
                  placeholder="Area/State"
                />
              </div>
              <div class="col-12 mb-3">
                <input
                  type="text"
                  class="form-control pry-input-border bg-white"
                  value="No 12 Bode Thomas Masha round about Lagos"
                  aria-label="Full address"
                  disabled
                />
              </div>
            </div>
          </div>
          <div class="col-12 col-sm-6 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Nationality
            </label>
            <select class="custom-select pry-input-border">
              <option>Select nationality</option>
            </select>
          </div>
          <div class="col-12 col-sm-6 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              CERPAC ID number (If nationality is not Nigerian)
            </label>
            <input
              type="text"
              class="form-control pry-input-border"
              placeholder="Enter CERPAC ID number"
            />
          </div>
          <div class="col-12 mb-3">
            <h6 class="ftsz-12 ftwt-500 mb-3">Select identity card type</h6>
            <div class="form-row ftsz-10 pale-red-tone-bg pry-red-clr ftwt-500 py-2">
              <div class="col-md-auto">
                <div class="custom-radio-controls position-relative">
                  <input
                    type="radio"
                    id="intlPassportRadio"
                    name="idCardType"
                    class="invisible position-absolute"
                  />
                  <label class="" for="intlPassportRadio">
                    International passport
                  </label>
                </div>
              </div>
              <div class="col-md-auto">
                <div class="custom-radio-controls position-relative">
                  <input
                    type="radio"
                    id="nationalIDRadio"
                    name="idCardType"
                    class="invisible position-absolute"
                  />
                  <label class="" for="nationalIDRadio">
                    National ID card
                  </label>
                </div>
              </div>
              <div class="col-md-auto">
                <div class="custom-radio-controls position-relative">
                  <input
                    type="radio"
                    id="pVCRadio"
                    name="idCardType"
                    class="invisible position-absolute"
                  />
                  <label class="" for="pVCRadio">
                    Permanent Voter's card
                  </label>
                </div>
              </div>
              <div class="col-md-auto">
                <div class="custom-radio-controls position-relative">
                  <input
                    type="radio"
                    id="driverLicenceRadio"
                    name="idCardType"
                    class="invisible position-absolute"
                  />
                  <label class="" for="driverLicenceRadio">
                    Nigerian Driver's licence
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-row">
              <div class="col-12 col-md-4 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  ID card number
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border"
                  placeholder="Enter ID card number"
                />
              </div>
              <div class="col-sm-6 col-md-4 mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  Issue Date
                </label>
                <input type="date" class="form-control pry-input-border" />
              </div>
              <div class="col-sm-6 col-md-4 mb-3 id-card-expiry-date">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  Expiry Date
                </label>
                <input type="date" class="form-control pry-input-border" />
              </div>
            </div>
          </div>
          <div class=" col-12 mb-3">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              ID Card name
            </label>
            <input
              type="text"
              class="form-control pry-input-border"
              placeholder="Enter your name from the ID card"
            />
          </div>
        </div>
        <EditMandateInstruction />
        <div className="text-right pb-5">
          <button
            type="button"
            className="btn btn-outline-danger text-white pry-red-bg px-5 mb-1"
            onClick={update}
          >
            Update
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditSignatoryForm;
