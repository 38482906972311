import React, { useState } from "react";
import Completed from "../../../../../../../../Images/completed.png";
import Copy from "../../../../../../../../Images/copy-icon.png";
import Modal from "react-bootstrap/Modal";

const CompletedForm = ({
  showComplete,
  handleCloseComplete,
  confirmComplete,
  accountInfo,
  formVal,
  next,
  handleFormChange,
  loading,
  sendEmail,
}) => {
  const [dirr, setDirr] = useState(false);
  return (
    <div>
      <Modal
        show={showComplete}
        onHide={handleCloseComplete}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="text-center">
            <div className="mb-3">
              <img src={Completed} className="img-fluid" alt="" />
            </div>
            <div className="mb-5">
              <h6 className="sec-header text-body mb-3 px-sm-5 mx-sm-5">
                Congratulations {formVal.businessName}, your account opening was
                successfull
              </h6>
              <p className="ftsz-regular">Here are your account details</p>
              <div className="d-flex justify-content-center">
                <p className="new-acct-num pry-red-clr pale-red-tone-bg rounded-lg pry-header px-3 py-2 mb-4 fit-content">
                  {accountInfo}
                </p>
              </div>
              <div>
                <p className="ftsz-regular">
                  Please fund your account with N10,000 being payment for legal
                  search, note that this is required to activate your account.
                </p>
              </div>
              <button
                type="button"
                className="btn btn-outline-danger text-white pry-red-bg px-5 mb-2"
                onClick={() => setDirr(!dirr)}
              >
                Add directors to upload document
              </button>

              {dirr && (
                <div>
                  <div className="form-group d-flex">
                    <input
                      type="email"
                      className="form-control w-75"
                      placeholder="Enter email address"
                      value={formVal.dirEmail}
                      onChange={handleFormChange("dirEmail")}
                    />
                    <button
                      type="button"
                      className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-1 ml-1"
                      onClick={sendEmail}
                      disabled={loading}
                    >
                      {loading ? "Sending ..." : "Send"}
                    </button>
                  </div>
                </div>
              )}
            </div>
            <div class="text-center">
              <button
                class="btn btn-outline-danger px-5 py-2 mr-4"
                onClick={confirmComplete}
              >
                End
              </button>
              <button
                class="btn btn-danger pry-red-bg px-5 py-2"
                onClick={() => {
                  handleCloseComplete();
                  next();
                }}
              >
                Add reference
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CompletedForm;
