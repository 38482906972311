import { useState, useCallback, useMemo, useEffect } from "react";

export default () => {
  const [imgSrc, setImgSrc] = useState(null);
  const [signatureUpload, setSignatureUpload] = useState("");
  const [livePhotoUpload, setLivePhotoUpload] = useState("");
  const [signatureURL, setSignatureURL] = useState("");
  const [uploadPic, setUploadPic] = useState("");
  const [uploadPicURL, setUploadPicURL] = useState("");
  const [signatureExtension, setSignatureExtension] = useState("");
  const [uploadedImgExtension, setUploadedImgExtension] = useState("");
  // useEffect(() => {
  //   if (signatureUpload) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const base64String = reader.result;
  //       setSignatureURL(base64String);
  //     };
  //     reader.readAsDataURL(signatureUpload);
  //   } else {
  //     setSignatureURL("");
  //   }
  // }, [signatureUpload]);

  //camera
  const onCapture = (imageData) => {
    setImgSrc(imageData.webP);
    setLivePhotoUpload(imageData.file);
  };

  // Use useCallback to avoid unexpected behaviour while rerendering
  const onError = useCallback((error) => {
    console.log(error);
  }, []);

  // Use useMemo to avoid unexpected behaviour while rerendering
  const config = useMemo(() => ({ video: true }), []);
  const livePic = new FormData();
  livePic.append("file", livePhotoUpload);

  //retake image
  const retake = () => {
    setImgSrc(null);
  };

  //upload docs
  const onDrops = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSignatureUpload(file);
      const base64 = await convertBase64(file);
      setSignatureURL(file);
      const extension = signatureUpload.path.slice(
        (Math.max(0, signatureUpload.path.lastIndexOf(".")) || Infinity) + 1
      );
      setSignatureExtension(`.${extension}`);
    }
  };

  const onDropss = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setUploadPic(file);
      const base64 = await convertBase64(file);
      setUploadPicURL(file);
      const extension = uploadPic.path.slice(
        (Math.max(0, uploadPic.path.lastIndexOf(".")) || Infinity) + 1
      );
      setUploadedImgExtension(`.${extension}`);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return [
    signatureURL,
    signatureUpload,
    onDrops,
    config,
    imgSrc,
    onCapture,
    onError,
    livePhotoUpload,
    retake,
    onDropss,
    uploadPic,
    uploadPicURL,
    signatureExtension,
    uploadedImgExtension,
  ];
};
