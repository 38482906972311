import axios from "axios";
import React, { useState, useEffect } from "react";
import { decryptHandler2 } from "../../../dec";
import { encrypt2 } from "../../../encr";
import { apiUrl, apiUrl1 } from "../../../redux/actions/endpoint";
import AddRefOTP from "./modal/AddRef";
import RefSuccessOTP from "./modal/RefSucess";
import useModal from "./modal/useModal";
import RefDetails from "./RefDetails";
import { apiAccount } from "../../../service/apiAccount";
import { appUrls } from "../../../service/urls";
import { authOApi } from "../../../service/authOApi";

const AddReference = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refData, setRefData] = useState({});
  const [fileUrl, setFileUrl] = useState("");

  const useQuery = () => new URLSearchParams(window.location.search);
  let query = useQuery();
  const tokenNeeded = query.get("token");

  const [
    showAddRefOTP,
    handleCloseAddRefOTP,
    handleShowAddRefOTP,
    showRefSuccessOTP,
    handleCloseRefSuccessOTP,
    handleShowRefSuccessOTP,
  ] = useModal();

  const [formValues, setFormValues] = useState({
    refAccount: "",
    email: "",
    refSubject: "",
    refName: "",
    refMail: "",
    accountNumber: ""
  });

  const handleFormChange =
    (name) =>
    ({ target }) => {
      setFormValues({
        ...formValues,
        [name]: target.value,
      });
    };

  const verifyToken = () => {
    const tokReg = tokenNeeded.replace(/\s+/g, "+");
    setLoading(true);
    authOApi
      .post(`${appUrls.VERIFY_REFRENCE_TOKEN}?token=${tokReg}`)
      .then((response) => {
        setRefData(response.data);
        setFormValues({
          accountNumber: response.data.customerNuban,
        });
        setIsVerified(true);
        setLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setLoading(false);
      });
  };

  const docUpload = () => {
    authOApi.get(`${appUrls.GET_DOC}`).then((response) => {
			setFileUrl(response.data);
		});
  };

  useEffect(() => {
    docUpload();
  }, []);

  return (
    <div>
      <div className="pale-red-part-bg position-fixed"></div>
      <div className="">
        {/* <Header /> */}
        <div className="container-fluid pb-5 mt-5">
          <div class="row mb-5">
            <div class="col-xl-7 col-lg-9 col-md-10 mx-auto">
              <div class="container">
                <div class="app-details-form-wrapper pry-grey-clr bg-white px-3 px-lg-5 py-5">
                  <div className="text-right"></div>
                  <div className="steps-action pb-5 mt-3">
                    {!isVerified && (
                      <div className="text-center font-weight-bold">
                        You need to be verified to give consent <br />
                        <button
                          type="button"
                          className="btn btn-outline-danger px-5 mr-2 mb-3 mt-5"
                          onClick={verifyToken}
                          disabled={loading}
                        >
                          {loading ? "Please wait ..." : "Get verified"}
                        </button>
                      </div>
                    )}
                    {isVerified && (
                      <RefDetails
                        formValues={formValues}
                        handleFormChange={handleFormChange}
                        refData={refData}
                        handleShowAddRefOTP={handleShowAddRefOTP}
                        fileUrl={fileUrl}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddRefOTP
        showAddRefOTP={showAddRefOTP}
        handleCloseAddRefOTP={handleCloseAddRefOTP}
        handleShowRefSuccessOTP={handleShowRefSuccessOTP}
        refData={refData}
      />
      <RefSuccessOTP
        showRefSuccessOTP={showRefSuccessOTP}
        handleCloseRefSuccessOTP={handleCloseRefSuccessOTP}
      />
    </div>
  );
};

export default AddReference;
