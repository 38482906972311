import { SET_REF_VALUES } from "../types";

export const set_ref_info = (value) => ({
    type: SET_REF_VALUES,
    payload: value,
});

const exportedObject = {
    set_ref_info
}

export default exportedObject;