import { SET_BANK_TYPE } from "../types";
const INITIAL_STATE = {
  screen: "select",
};

const BankReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BANK_TYPE:
      return {
        ...state,
        screen: action.payload,
      };

    default:
      return state;
  }
};

export default BankReducer;