import React, { useEffect, useState, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import styles from "../../components/apidashboard.module.css";
import Dropdown from "react-bootstrap/Dropdown";
import { message } from "antd";
import { api } from "../../../../service/api";
import { appUrls } from "../../../../service/urls";
import { debounce } from "../../../../utils";
import Spinner from "react-bootstrap/Spinner";
import ResponsivePagination from "react-responsive-pagination";
import "react-responsive-pagination/themes/classic.css";

const Loader = () => {
  return (
    <div class="lds-ellipsis">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default function Merchants() {
  const [isLoading, setIsLoading] = useState({
    getUsers: false,
    enableDisable: false,
  });
  const [showEnableDisableModal, setShowEnableDisableModal] = useState({
    show: false,
    details: null,
    type: null,
  });
  const [showDeleteModal, setShowDeleteModal] = useState({
    show: false,
    details: null,
    type: null,
  });

  const [showEditModal, setShowEditModal] = useState({
    show: false,
    details: null,
    type: null,
  });
  const [loading, setLoading] = useState(false);
  const [merchants, setMerchants] = useState([]);
  const [tableHead] = useState([
    { id: 1, name: "S/N" },
    { id: 2, name: "Merchant Name" },
    { id: 3, name: "Email Address" },
    { id: 4, name: "Username" },
    { id: 5, name: "Status" },
    { id: 6, name: "" },
  ]);
  const [search, setSearch] = useState("");
  const itemsPerPage = 8;
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const [inputVal, setInputVal] = useState({
    username: "",
    firstName: "",
    lastName: "",
  });



  const handleGetAllUsers = async (total, active) => {
    setIsLoading((prev) => ({
      ...prev,
      getUsers: true,
    }));
    try {
      const res = await api.get(
        appUrls.ADMIN_GET_ALL_USERS +
          `?PageNumber=${currentPage}&PageSize=${itemsPerPage}${search}`
      );
      if (res?.status === 200) {
        const result = res?.data?.data;
        setMerchants(result);
        let paginationResponse = res?.data?.meta?.pagination
        let totalItems = paginationResponse.total
        setTotalPages(Math.ceil(totalItems / itemsPerPage));
      }
    } catch (error) {
      message.error(error?.statusText, 5);
      setMerchants([]);
    } finally {
      setIsLoading((prev) => ({
        ...prev,
        getUsers: false,
      }));
    }
  };

  const handleEnableDisable = async () => {
    setIsLoading((prev) => ({ ...prev, enableDisable: true }));
    const payload = {
      enableUser: showEnableDisableModal?.type === "Enable" ? true : false,
    };
    try {
      const res = await api.put(
        appUrls.ADMIN_ACTIVATE_USER + `/${showEnableDisableModal?.details}`,
        payload
      );
      if (res?.status === 200) {
        message.success(res?.data?.message, 5);
        setShowEnableDisableModal({
          show: false,
          details: null,
          type: null,
        });
        handleGetAllUsers();
      }
    } catch (error) {
      message.error(error?.statusText, 5);
    } finally {
      setIsLoading((prev) => ({ ...prev, enableDisable: false }));
    }
  };


  const handleDelete = async () => {
    setIsLoading((prev) => ({ ...prev, enableDisable: true }));
    try {
      const res = await api.delete(
        appUrls.ADMIN_DELETEEDIT_USER + `/${showDeleteModal?.details}`,
      );
      if (res?.status === 200) {
        message.success(res?.data?.message, 5);
        setShowDeleteModal({
          show: false,
          details: null,
          type: null
        });
        handleGetAllUsers();
      }
    } catch (error) {
      message.error(error?.statusText, 5);
    } finally {
      setIsLoading((prev) => ({ ...prev, enableDisable: false }));
    }
  };

  const handleEditMerchant = async () => {
    setLoading(true);
    let payload = {
      username: inputVal?.username,
      firstName: inputVal?.firstName,
      lastName: inputVal?.lastName
    }
    try {
      const res = await api.put(
        appUrls.ADMIN_DELETEEDIT_USER + `/${showEditModal?.details}`,
        payload
      );
      if (res?.status === 200) {
        message.success(res?.data?.message, 5);
        setShowEditModal({
          show: false,
          details: null,
          type: null,
        })
        setInputVal({ username: "", firstName: "", lastName: "" });
        handleGetAllUsers();
      }
    } catch (error) {
      message.error(error?.statusText, 5);
    } finally {
      setLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleClose = () => {
    setShowEditModal({
      show: false,
      details: null,
      type: null,
    })
  }


  const handleInputChange =
    (name) =>
      ({ target }) => {
        setInputVal({
          ...inputVal,
          [name]: target.value,
        });
      };


  useEffect(() => {
    let mounted = false;
    (async () => {
      mounted = true;
      if (mounted) {
        handleGetAllUsers(10, 1);
      }
    })();
    return () => {
      mounted = false;
    };
  }, [search, currentPage]);

  const handleSearchFunc = (value) => {
    if (value === "") return setSearch("");
    setSearch(`&Search=${value}`);
  };

  const handleValidation = () => {
    if (inputVal?.firstName.trim() === "") {
      message.error("First Name cannot be Empty..", 5);
      return;
    }
    if (inputVal?.lastName.trim() === "") {
      message.error("Last Name cannot be Empty..", 5);
      return;
    }
    if (inputVal?.username.trim() === "") {
      message.error("Username cannot be Empty..", 5);
      return;
    }
    handleEditMerchant();
  };


  const optimizedFn = useCallback(debounce(handleSearchFunc), []);

  return (
    <>
      <div className={styles.view_merchants_head}>
        <p className={styles.view_merchants_left}>Profiled Users</p>
        <div>
          <input
            onChange={(e) => optimizedFn(e.target.value)}
            placeholder="Search"
            type="text"
            className={styles.search}
          />
        </div>
      </div>
      <div className={styles.table_container}>
        {isLoading?.getUsers && (
          <div
            style={{ display: "flex", width: "100%", justifyContent: "center" }}
          >
            <Loader />
          </div>
        )}
        {!!!isLoading?.getUsers && (
          <table style={{ width: "100%" }}>
            <thead>
              <tr>
                {tableHead.map((list, index) => {
                  return <th key={index}>{list?.name}</th>;
                })}
              </tr>
            </thead>
            <tbody className={styles.table_body_content}>
              {merchants.map((merchant, index) => {
                return (
                  <tr key={merchant?.id}>
                    <td>{index + 1}</td>
                    <td>
                      {merchant?.firstName} {merchant?.lastName}
                    </td>
                    <td>{merchant?.email}</td>
                    <td>{merchant?.username || "No Username"}</td>
                    <td
                      className={`${
                        merchant?.verified
                          ? styles.activeColor
                          : styles.InactiveColor
                      }`}
                    >
                      {merchant?.verified ? "Active" : "Inactive"}
                    </td>
                    <td className={styles.status}>
                      <Dropdown>
                        <Dropdown.Toggle
                          className="btn-toggle"
                          variant=""
                          bsPrefix="pl-4"
                          id="dropdown-basic"
                        >
                          ...
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {merchant?.verified ? (
                            <Dropdown.Item as="button">
                              <div
                                onClick={() =>
                                  setShowEnableDisableModal({
                                    show: true,
                                    type: "Disable",
                                    details: merchant?.id,
                                  })
                                }
                              >
                                Disable
                              </div>
                            </Dropdown.Item>
                          ) : (
                            <Dropdown.Item as="button">
                              <div
                                onClick={() =>
                                  setShowEnableDisableModal({
                                    show: true,
                                    type: "Enable",
                                    details: merchant?.id,
                                  })
                                }
                              >
                                Enable
                              </div>
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item as="button">
                            <div
                              onClick={() =>
                                setShowDeleteModal({
                                  show: true,
                                  type: "Delete",
                                  details: merchant?.id,
                                })
                              }
                            >
                              Delete
                            </div>
                          </Dropdown.Item>
                          <Dropdown.Item as="button">
                            <div
                              onClick={() => {
                                setShowEditModal({
                                  show: true,
                                  type: "Edit",
                                  details: merchant?.id,
                                });
                                setInputVal({
                                  username: merchant?.username,
                                  firstName: merchant?.firstName,
                                  lastName: merchant?.lastName
                                })
                              }
                              }
                            >
                              Edit
                            </div>
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
      <Modal
        show={showEnableDisableModal?.show}
        backdrop="static"
        fullscreen="md-down"
        keyboard={false}
        centered={true}
      >
        <Modal.Body>
          <p className={styles.logoutModal_confirm_text}>
            Are you sure you want to {showEnableDisableModal?.type}
          </p>
          <div className="btn-flex">
            <button
              onClick={handleEnableDisable}
              className={styles.logout_yes}
              type="button"
            >
              {isLoading?.enableDisable ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "yes"
              )}
            </button>
            <button
              onClick={() =>
                setShowEnableDisableModal({
                  show: false,
                  details: null,
                  type: null,
                })
              }
              className={styles.logout_no}
              type="button"
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={showDeleteModal?.show}
        backdrop="static"
        fullscreen="md-down"
        keyboard={false}
        centered={true}
      >
        <Modal.Body>
          <p className={styles.logoutModal_confirm_text}>
            Are you sure you want to {showDeleteModal?.type}
          </p>
          <div className="btn-flex">
            <button
              onClick={handleDelete}
              className={styles.logout_yes}
              type="button"
            >
              {isLoading?.deleteEdit ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "yes"
              )}
            </button>
            <button
              onClick={() =>
                setShowDeleteModal({
                  show: false,
                  details: null,
                  type: null,
                })
              }
              className={styles.logout_no}
              type="button"
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={showEditModal?.show}
        backdrop="static"
        fullscreen="md-down"
        onHide={handleClose}
        keyboard={false}
        centered={true}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-row mb-3">
              <div className="form-group col-12 ftsz-12">
                <h6 className="ftwt-500 ftsz-12 mb-1 text-dark">Username</h6>
                <input
                  className="input-pad form-control pry-input-border"
                  placeholder="Enter Username"
                  type="text"
                  onChange={handleInputChange('username')}
                  value={inputVal.username}
                />
              </div>
              <div className="form-group col-12">
                <label
                  htmlFor=""
                  className="pry-grey-clr ftsz-12 ftwt-500 mb-1 d-flex justify-content-between align-items-center"
                >
                  First Name
                </label>
                <div className="form-group mb-0">
                  <input
                    type="text"
                    className="form-control pry-input-border"
                    placeholder="Enter Merchant First Name"
                    onChange={handleInputChange('firstName')}
                    value={inputVal.firstName}
                  />
                </div>
              </div>
              <div className="form-group col-12">
                <label
                  htmlFor=""
                  className="pry-grey-clr ftsz-12 ftwt-500 mb-1 d-flex justify-content-between align-items-center"
                >
                  Last Name
                </label>
                <div className="form-group mb-0">
                  <input
                    type="text"
                    className="form-control pry-input-border"
                    placeholder="Enter Merchant Last Name"
                    onChange={handleInputChange('lastName')}
                    value={inputVal.lastName}
                  />
                </div>
              </div>

            </div>
            <button
              type="button"
              className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 validateBtn btn-block"
              disabled={loading}
              onClick={handleValidation}
            >
              {loading ? (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              ) : (
                "Edit user"
              )}
            </button>
          </form>
        </Modal.Body>
      </Modal>
      <ResponsivePagination
        className={styles.pagination_section}
        current={currentPage}
        total={totalPages}
        onPageChange={handlePageChange}
      />
    </>
  );
}
