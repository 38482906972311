// Api configurations here * * connects directly with the backend....
import axios from "axios";
import { appUrls } from "./urls";

const baseURL = "https://camsapigateway-live.appsuport.p.azurewebsites.net";

const apiResource = () => {
    const api = axios.create({
        baseURL: baseURL,
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Methods": "*",
            "Access-Control-Allow-Origin": baseURL,
            "Access-Control-Allow-Credentials": true,
            "X-Frame-Options": "allow-from *"

        },
        // withCredentials: true,
    });
    api.interceptors.request.use(
        (config) => {
            const token = sessionStorage.getItem("api-account-token");
            if (!token) return config;
            config.headers["Authorization"] = `Bearer ${token}`;
            config.headers["Content-Type"] = "application/json";
            return config;
        },
        (error) => Promise.reject(error)
    );
    api.interceptors.response.use(
        (response) =>
            new Promise((resolve, reject) => {
                resolve(response);
            }),
        async (error) => {
            if (error?.response?.status === 403) {
                sessionStorage.clear();
                window.location = "/";
            } else if (error?.response?.status === 401) {
                const originalConfig = error.config;
                // sessionStorage.clear();
                // window.location = "/";
                if (
                    // originalConfig.url !== `${appUrls.ADMIN_LOGIN_URL}` && //login url for account that will be created
                    error?.response
                ) {
                    // Access Token was expired
                    // if (error.response.status === 401 && !originalConfig._retry) {
                    //     originalConfig._retry = true;
                    //     const token = sessionStorage.getItem("api-account-token");
                    //     const refreshToken = sessionStorage.getItem("api-account-refresh-token");
                    //     const payload = {
                    //         "accessToken": token,
                    //         "refreshToken": refreshToken
                    //     }
                    //     try {
                    //         const rs = await api.post(
                    //             appUrls.REFRESHTOKEN_URL, payload
                    //         );
                    //         const { accessToken, refreshToken } = rs.data.data;
                    //         sessionStorage.setItem("api-dashboard-token", accessToken);
                    //         sessionStorage.setItem("api-dashboard-refresh-token", refreshToken);
                    //         return api(originalConfig);
                    //     } catch (_error) {
                    //         return Promise.reject(_error);
                    //     }
                    // }
                }
            } else {
                return new Promise((resolve, reject) => {
                    reject(error?.response);
                });
            }
            return Promise.reject(error?.response);
        }
    );
    return api;
};

export const apiAccount = apiResource();
