import React, { useState, useEffect } from 'react';
import Header from './components/Header/Header';
import { Steps, message } from 'antd';
import axios from 'axios';
import moment from 'moment';
import {
  apiUrl,
  apiUrl2,
  apiUrl3,
  referenceEndpoint,
} from '../../../../redux/actions/endpoint';
import Bvn from './components/bvn';
import OTP from './components/otp';
import BvnValidation from './components/bvnVal';

import * as EmailValidator from 'email-validator';
import DocumentUpload from './components/document';
import CompletedForm from './components/completed';
import InvalidBVN from './components/bvn/errors/invalid';
import useFiles from './hooks/useFiles';
import InvalidOTP from './components/otp/errors/invalid';
import useGoogle from './hooks/useGoogle';
import ExistingOTP from './components/otp/errors/existing';
import RegisteredModal from './components/otp/errors/registered';
import { decryptHandler2 } from '../../../../dec';
import { encrypt2 } from '../../../../encr';
import TermsAndConditions from './components/modal/tc';
import NewCompletedForm from './components/newCompleted';
import { Spinner } from 'react-bootstrap';
import PendingModal from '../../../NewCustomer/components/PendingModal';
import {  apiAccount } from '../../../../service/apiAccount';
import { appUrls } from '../../../../service/urls';
import { authOApi } from '../../../../service/authOApi';

const { Step } = Steps;

const KidsAccount = () => {
  //otp modal
  const [showOtp, setShowOtp] = useState(false);
  const handleCloseOtp = () => setShowOtp(false);
  const handleShowOtp = () => setShowOtp(true);

  //invalid bvn modal
  const [showInvalid, setShowInvalid] = useState(false);
  const handleCloseInvalid = () => setShowInvalid(false);
  const handleShowInvalid = () => setShowInvalid(true);

  //completed modal
  const [showCompleted, setShowCompleted] = useState(false);
  const handleCloseCompleted = () => setShowCompleted(false);
  const handleShowCompleted = () => setShowCompleted(true);

  const [showInvalidOTP, setShowInvalidOTP] = useState(false);
  const handleCloseInvalidOTP = () => setShowInvalidOTP(false);
  const handleShowInvalidOTP = () => setShowInvalidOTP(true);

  const [showExistingOTP, setShowExistingOTP] = useState(false);
  const handleCloseExistingOTP = () => setShowExistingOTP(false);
  const handleShowExistingOTP = () => setShowExistingOTP(true);

  const [showRegistered, setShowRegistered] = useState(false);
  const handleCloseRegistered = () => setShowRegistered(false);
  const handleShowRegistered = () => setShowRegistered(true);

  const [showTC, setShowTC] = useState(false);
  const handleCloseTC = () => setShowTC(false);
  const handleShowTC = () => setShowTC(true);

  const [isOpen, setIsOpen] = useState(false);
  const handleClosePendingModal = () => setIsOpen(false);
  const handleShowPendingModal = () => setIsOpen(true);

  //state to hold bvn details
  const [identificationType, setIdentificationType] = useState(
    'International Passport'
  );

  const [emailVal, setEmailVal] = useState('');

  //state to hold account info
  const [accountInfo, setAccountInfo] = useState('');

  const [isVerified, setIsVerified] = useState(false);

  const [accNum, setAccNum] = useState('');

  const [customerNumber, setCustomerNumber] = useState('');

  const [accountCount, setAccountCount] = useState('');

  const [mob, setMob] = useState('');

  const [address, setAddress, handleSelect] = useGoogle();

  const [otp, setOtp] = useState('');

  const [branchCode, setBranchCode] = useState([]);

  const [isMinor, setIsMinor] = useState(false);

  const [hpvImage, setHpvImage] = useState('');
  const [valid, setValid] = useState(false);
  const [isLoadingHpvSdk, setIsLoadingHpvSdk] = useState(false);

  //stepper
  const [current, setCurrent] = React.useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingID, setLoadingID] = useState(false);
  const [bvnLoading, setBvnLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);

  const [disableBtn, setDisableBtn] = useState(true);

  const [isTCClicked, setIsTCClicked] = React.useState(false);

  const [isRestriction, setIsRestriction] = useState(false);

  const tcHandler = (value) => {
    setIsTCClicked(value);
  };

  //forms state
  const [formValues, setFormValues] = useState({
    bvn: '',
    otp: '',
    dobirth: '',
    ChildFirstName: '',
    ChildMiddleName: '',
    ChildLastName: '',
    childDob: '',
    ChildGender: '',
    branch: '',
    firstName: '',
    secondName: '',
    lastName: '',
    phone: '',
    email: '',
    houseNo: '',
    address: '',
    landmark: '',
    state: '',
    addressSummary: '',
    minorImage: '',
    livePhoto: '',
    accountNo: '',
    productCode: 'I.CAN.SAVE1',
    employment: '',
    idCardNo: '',
    issueDate: '',
    expiryDate: '',
    refCode: '',
    nok: '',
    id: '',
    gender: '',
    imgBvn: '',
  });

  // state to continue your account opening process
  const [savedProcessValues, setSavedProcessValues] = useState({});

  const [
    minorImage,
    livePhotoUpload,
    frontPage,
    onDrops,
    config,
    onError,
    imgSrc,
    retake,
    onDrop,
    onCapture,
    frontPageURL,
    minorImageURL,
    birthCert,
    birthCertURL,
    onDropp,
    parentSignature,
    parentSignatureURL,
    onDroppp,
    onDropsss,
    uploadPic,
    uploadPicURL,
  ] = useFiles();

  const prev = () => {
    setCurrent(current - 1);
  };

  const next = () => {
    setCurrent(current + 1);
    handleClosePendingModal();
  };

  const onDateChange = (event) => {
    setDob(event.target.value);
  };

  const [dob, setDob] = useState(
    moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.sssZ')
  );

  const [loading, setLoading] = useState(false);

  //state to hold bvn details
  const [bvnDetails, setBvnDetails] = useState({});

  const handleFormChange =
    (name) =>
    ({ target }) => {
      setFormValues({
        ...formValues,
        [name]: target.value,
      });
    };

  // get age difference
  const MS_PER_DAY = 1000 * 60 * 60 * 24;
  function dateDiffInDays(currentDate, givenDate) {
    const utc1 = Date.UTC(
      currentDate.getFullYear(),
      currentDate.getMonth(),
      currentDate.getDate()
    );
    const utc2 = Date.UTC(
      givenDate.getFullYear(),
      givenDate.getMonth(),
      givenDate.getDate()
    );

    return Math.floor((utc2 - utc1) / MS_PER_DAY);
  }
  const currentDate = new Date(),
    givenDate = new Date(formValues.childDob),
    differences = dateDiffInDays(givenDate, currentDate);
  const ageDifference = Math.ceil(differences / 365);

  //validation
  const validate = () => {
    isExisting();
  };

  // get data needed with bvn
  const getSaveProcess = () => {
    setIsLoading(true);
    const data = encrypt2(formValues.bvn);
    authOApi
      .get(`${appUrls.SAVE_PROCESS_DATABY_BVN}?Data=${data}`)
      .then((res) => {
        const results = decryptHandler2(res.data);
        localStorage.setItem('saveddata', JSON.stringify(results));
        if (
          results?.Data?.length !== 0 &&
          results &&
          results.responseCode === '00'
        ) {
          setSavedProcessValues(results);
          setFormValues({
            bvn: results.data[0].Bvn,
            dobirth: results.data[0].dateOfBirth,
            firstName: results.data[0].firstName,
            lastName: results.data[0].lastName,
            phone: results.data[0].phone,
            email: results.data[0].email,
            address: results.data[0].address,
            landmark: results.data[0].landmark,
            state: results.data[0].state,
            gender: results.data[0].gender,
          });
          // next()
          // setIsLoading(false);
        } else {
          handleClosePendingModal();
          // message.error(
          //   "Oops!! You have no existing application."
          // );
          setIsLoading(false);
        }
      })
      .catch((error) => {
        // setIsLoading(false);
      });
  };

  // user continues process if user is not done
  const saveProcess = () => {
    setIsLoading(true);
    let payload = {
      Bvn: formValues.bvn,
      firstName: formValues.firstName,
      email: formValues.email,
      surname: formValues.surname,
      middleName: formValues.middleName,
      productCode: 'I.CAN.SAVE1',
      currencyCode: 'NGN',
      branch: formValues.branch,
      appId: '1',
      state: formValues.state,
      dateOfBirth: formValues.dateOfBirth,
      phoneNumber: formValues.phoneNumber,
      address: formValues.address,
      sector: '4200',
      industry: '4202',
      nationality: 'NG',
      clientID: '52',
      mode: '1',
      channelId: '1',
      customerID: customerNumber,
      residence: formValues.residence,
      shortTitle: '',
      childGender: '',
      preferredName: formValues.preferredName,
      dao: formValues.dao,
    };
    const data = encrypt2(payload);
     authOApi
      .post(`${appUrls.SAVE_PROCESS}`, { data: data })
      .then(async (res) => {
        const results = await decryptHandler2(res.data);
        if (results && results.ResponseCode === '00') {
          setCurrent(current + 1);
          // setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error('Unable to save, Please try again later', 10);
      });
  };

  const isExisting = async () => {
    setBvnLoading(true);
    const data1 = encrypt2(formValues.bvn);
    await  authOApi
      .get(`${appUrls.FIORANO_GET_ACCT_COUNTBY_BVN}?Data=${data1}`)
      .then((res) => {
        const response = decryptHandler2(res.data);
        setAccountCount(response.Count);
        let counts = response.Count;
        if (counts > 0) {
          message.error(
            <span>
              <p>
                Oops!!!You already have a Sterling account. Use the existing
                customers' module.
              </p>
              <p>
                Forgot Account Number?
                <a style={{ color: '#db353a' }} href="/retrieve-account">
                  {' '}
                  Retrieve account number
                </a>
              </p>
            </span>,
            10
          );
          setBvnLoading(false);
          return;
        } else {
          const data = encrypt2(formValues.bvn);
           authOApi
            .get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${data}`)
            .then(async (response) => {
              const results = await decryptHandler2(response.data);
              if (results && results.ResponseCode === '00') {
                const accountArray =
                  results.GetAccountFullInfo.BankAccountFullInfo;
                setEmailVal(
                  results.GetAccountFullInfo.BankAccountFullInfo[0].email
                );
                if (accountArray) {
                  const checkAccount = accountArray.some(
                    (accounts) => accounts.ACCT_TYPE === 'I.CAN.SAVE1'
                  );
                  if (checkAccount === true) {
                    message.error(
                      <span>
                        <p>
                          Oops!!! You already have this type of account. Open
                          another account or call 0700 822 0000 to retrieve your
                          existing account.
                        </p>
                        <p>
                          Forgot Account Number?
                          <a
                            style={{ color: '#db353a' }}
                            href="/retrieve-account"
                          >
                            {' '}
                            Retrieve account number
                          </a>
                        </p>
                      </span>,
                      10
                    );
                    setBvnLoading(false);
                    return;
                  } else {
                    verifyBvn();
                    // setBvnLoading(false);
                  }
                } else {
                  verifyBvn();
                  // setBvnLoading(false);
                }
              } else {
                message.error(
                  'Unable to verify account details. Please try again later!',
                  10
                );
                setBvnLoading(false);
              }
            })
            .catch((error) => {
              setBvnLoading(false);
              message.error('Unable to verify BVN, Please try again later', 10);
            });
        }
      });
  };

  //endpoint to verify bvn
  const verifyBvn = async () => {
    setBvnLoading(true);
    const payload = {
      bvn: formValues.bvn,
      dateOfBirth: dob,
    };
    const encryptedBvnData = encrypt2(payload);
    getSaveProcess();
     authOApi
      .post(`${appUrls.VERIFY_BVN}`, { data: encryptedBvnData })
      .then(async (response) => {
        const results = await decryptHandler2(response.data);
        setBvnDetails(results);
        if (results && results.ResponseCode === '00') {
          handleShowOtp();
          setFormValues({
            bvn: results.BVN,
            firstName: results.FirstName,
            secondName: results.MiddleName,
            lastName: results.LastName,
            dobirth: results.DateOfBirth,
            phone: results.PhoneNumber,
            email: results.Email,
            address: results.ResidentialAddress,
            landmark: results.StateOfResidence,
            state: results.StateOfResidence,
            gender: results.Gender,
            imgBvn: results.Base64Image,
          });
        } else {
          // handleShowInvalid();
          message.error(
            "Oops! There's a mismatch with your BVN information, please check and enter the correct details"
          );
          setBvnLoading(false);
        }
        let payload2 = {
          mobile: results.PhoneNumber,
          email: emailVal || results?.Email,
          clientID: '52',
        };
        setBvnLoading(true);
        const otpData = encrypt2(payload2);
         authOApi
          .post(`${appUrls.GENERATE_OTP}`, { data: otpData })
          .then((response) => {
            const resultss = decryptHandler2(response.data);
            setMob(resultss.PhoneNumber);
            setBvnLoading(false);
          })
          .catch((error) => {
            message.error('Unable to generate OTP, Please try again later', 10);
          });
      })
      .catch((error) => {
        setBvnLoading(false);
        message.error('Unable to verify BVN, Please try again later', 10);
      });
  };

  //validateOTP
  const validateOTP = () => {
    setOtpLoading(true);
    let payload = {
      mobile: mob,
      otp: otp,
    };
    const valOtp = encrypt2(payload);
     authOApi
      .post(`${appUrls.VALIDATE_OTP}`, { data: valOtp })
      .then(async (response) => {
        const results = await decryptHandler2(response.data);
        if (results && results.ResponseCode === '1') {
          handleCloseOtp();
          message.success('Great! Your BVN verification was successful!');
          const savedData = JSON.parse(localStorage.getItem('saveddata'));
          if (savedData.Data.length !== 0) {
            handleShowPendingModal();
          } else {
            // if user is creating application for the first time
            setCurrent(current + 1);
          }
          // setCurrent(current + 1);
          setOtpLoading(false);
        } else {
          handleShowInvalidOTP();
          setOtpLoading(false);
        }
      })
      .catch((error) => {
        setOtpLoading(false);
        message.error('Unable to validate OTP, please try again later', 10);
      });
  };

  const validateForm = () => {
    if (current === 0) {
      if (!formValues.bvn) {
        message.error(
          "Oops!!! You're missing something. Please provide your BVN"
        );
        return;
      }
      if (!dob) {
        message.error(
          "Oops!!! You're missing something. Please provide your Date of birth"
        );
        return;
      }
      validate();
    }
  };

  const validateFormTwo = () => {
    if (!formValues.firstName) {
      message.error(
        "Oops!!! You're missing something. Please input your first name"
      );
      return;
    }
    if (!formValues.lastName) {
      message.error(
        "Oops!!! You're missing something. Please input your last name"
      );
      return;
    }
    if (!formValues.dobirth) {
      message.error(
        "Oops!!! You're missing something. Please input your date of birth"
      );
      return;
    }
    if (ageDifference > 18) {
      message.error(
        "Oops!!! You're missing something. Child's age must be 18 years or below"
      );
      return;
    }
    if (!formValues.email) {
      message.error(
        "Oops!!! You're missing something. Please provide your Email to proceed"
      );
      return;
    }
    if (!EmailValidator.validate(formValues.email)) {
      message.error('This email is not valid, please update and try again ');
      return;
    }
    if (!address) {
      message.error(
        "Oops!!! You're missing something. Please input your address"
      );
      return;
    }
    if (!formValues.landmark) {
      message.error(
        "Oops!!! You're missing something. Please input your landmark"
      );
      return;
    }
    if (!formValues.state) {
      message.error(
        "Oops!!! You're missing something. Please input your state of residence"
      );
      return;
    }
    if (!formValues.gender) {
      message.error(
        "Oops!!! You're missing something. Please input your gender"
      );
      return;
    }
    if (!formValues.nok) {
      message.error(
        "Oops!!! You're missing something. Please input your Next of kin"
      );
      return;
    }
    if (!identificationType) {
      message.error(
        "Oops!!! You're missing something. Please input your ID type"
      );
      return;
    }
    if (identificationType === 'National ID') {
      if (!formValues.idCardNo) {
        message.error(
          "Oops!!! You're missing something. Please input your ID number"
        );
        return;
      }
    }
    if (identificationType === 'Permanent Voters Card') {
      if (!formValues.idCardNo) {
        message.error(
          "Oops!!! You're missing something. Please input your ID number"
        );
        return;
      }
    }
    if (identificationType === 'Drivers License') {
      if (!formValues.idCardNo) {
        message.error(
          "Oops!!! You're missing something. Please input your ID number"
        );
        return;
      }
      if (!formValues.issueDate) {
        message.error(
          "Oops!!! You're missing something. Please input your Issue date"
        );
        return;
      }
      if (!formValues.expiryDate) {
        message.error(
          "Oops!!! You're missing something. Please input your Expiry date"
        );
        return;
      }
    }
    if (identificationType === 'International Passport') {
      if (!formValues.idCardNo) {
        message.error(
          "Oops!!! You're missing something. Please input your ID number"
        );
        return;
      }
      if (!formValues.issueDate) {
        message.error(
          "Oops!!! You're missing something. Please input your Issue date"
        );
        return;
      }
      if (!formValues.expiryDate) {
        message.error(
          "Oops!!! You're missing something. Please input your Expiry date"
        );
        return;
      }
    }
    saveProcess();
    verifyy();
  };

  const validateFormThree = () => {
    if (!minorImage) {
      message.error(
        "Oops!!! You're missing something. Please upload Minor's photo"
      );
      return;
    }

    if (!hpvImage && uploadPic === '') {
      message.error(
        "Oops!!! You're missing something. Please take a upload or take live picture"
      );
      return;
    }

    if (!frontPage) {
      message.error(
        "Oops!!! You're missing something. Please select your ID front page"
      );
      return;
    }
    if (!birthCert) {
      message.error(
        "Oops!!! You're missing something. Please select your Birth certification/Passport"
      );
      return;
    }
    if (!parentSignature) {
      message.error(
        "Oops!!! You're missing something. Please upload Parent/Guardian signature"
      );
      return;
    }
    if (!formValues.branch) {
      message.error(
        "Oops!!! You're missing something. Please select your preferred branch to proceed"
      );
      return;
    }
    openAccount();
  };

  const dateTime = new Date();

  const uploadToAzure = async () => {
    setLoading(true);
    if (!hpvImage) {
      const imageArr = minorImageURL.split(',');
      const imageArr2 = uploadPicURL.split(',');
      const imageArr3 = frontPageURL.split(',');
      const imageArr4 = birthCertURL.split(',');
      const imageArr5 = parentSignatureURL.split(',');

      const imageSplit = imageArr[1];
      const imageSplit2 = imageArr2[1];
      const imageSplit3 = imageArr3[1];
      const imageSplit4 = imageArr4[1];
      const imageSplit5 = imageArr5[1];

      const result = {
        liveImgSent: '',
        minorImgSent: '',
        frontIDSent: '',
        birthCertSent: '',
        signatureSent: '',
        bvnImgSent: '',
        success: false,
      };

      // let payload1 = {
      //   file: imageSplit,
      //   fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-minorsImage`,
      //   extension: ".png",
      // };
      let payload1 = new FormData();
      payload1.append('file', minorImageURL);
      const data1 = encrypt2(payload1);

      // let payload2 = {
      //   file: imageSplit2,
      //   fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-uploadedImage`,
      //   extension: ".png",
      // };
      let payload2 = new FormData();
      payload2.append('file', uploadPicURL);
      const data2 = encrypt2(payload2);

      // let payload3 = {
      //   file: imageSplit3,
      //   fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-ID`,
      //   extension: ".png",
      // };
      let payload3 = new FormData();
      payload3.append('file', frontPageURL);
      const data3 = encrypt2(payload3);

      // let payload4 = {
      //   file: imageSplit4,
      //   fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-birthCertificate`,
      //   extension: ".png",
      // };
      let payload4 = new FormData();
      payload4.append('file', birthCertURL);
      const data4 = encrypt2(payload4);

      // let payload5 = {
      //   file: imageSplit5,
      //   fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-parentsSignature`,
      //   extension: ".png",
      // };
      let payload5 = new FormData();
      payload5.append('file', parentSignatureURL);
      const data5 = encrypt2(payload5);

      let payload6 = {
        file: formValues.imgBvn,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-bvnImage`,
        extension: '.png',
      };
      const data6 = encrypt2(payload6);

      await  authOApi
        .post(`${appUrls.UPLOAD_DOC}`, payload1)
        .then(async (response) => {
          const res1 = response.data;
          result.minorImgSent = res1;
          await  authOApi
            .post(`${appUrls.UPLOAD_DOC}`, payload2)
            .then(async (response) => {
              const res2 = response.data;
              result.liveImgSent = res2;
              await  authOApi
                .post(`${appUrls.UPLOAD_DOC}`, payload3)
                .then(async (response) => {
                  const res3 = response.data;
                  result.bvnImgSent = res3;
                  await  authOApi
                    .post(`${appUrls.UPLOAD_DOC}`, payload4)
                    .then(async (response) => {
                      const res4 = response.data;
                      result.frontIDSent = res4;
                      await  authOApi
                        .post(`${appUrls.UPLOAD_DOC}`, payload5)
                        .then(async (response) => {
                          const res5 = response.data;
                          result.birthCertSent = res5;
                          await  authOApi
                            .post(`${appUrls.UPLOAD_DOC_V2}`, payload6)
                            .then(async (response) => {
                              const res6 = response.data;
                              result.signatureSent = await res6;
                            })
                            .catch((error) => {
                              setLoading(false);
                              message.error(
                                'Oops! Error Processing Documents',
                                10
                              );
                            });
                        });
                    });
                });
            });
          result.success = true;
        })
        .catch((error) => {
          setLoading(false);
          message.error('Oops! Error Processing Documents', 10);
          result.success = false;
        });
      setLoading(false);
      return result;
    } else {
      const imageArr = minorImageURL.split(',');
      const imageArr2 = hpvImage.split(',');
      const imageArr3 = frontPageURL.split(',');
      const imageArr4 = birthCertURL.split(',');
      const imageArr5 = parentSignatureURL.split(',');

      const imageSplit = imageArr[1];
      const imageSplit2 = imageArr2[1];
      const imageSplit3 = imageArr3[1];
      const imageSplit4 = imageArr4[1];
      const imageSplit5 = imageArr5[1];

      const result = {
        liveImgSent: '',
        minorImgSent: '',
        frontIDSent: '',
        birthCertSent: '',
        signatureSent: '',
        bvnImgSent: '',
        success: false,
      };

      // let payload1 = {
      //   file: imageSplit,
      //   fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-minorsImage`,
      //   extension: ".png",
      // };
      let payload1 = new FormData();
      payload1.append('file', minorImageURL);
      const data1 = encrypt2(payload1);

      let payload2 = {
        file: imageSplit2,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-selfie`,
        extension: '.png',
      };
      const data2 = encrypt2(payload2);

      // let payload3 = {
      //   file: imageSplit3,
      //   fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-ID`,
      //   extension: ".pdf",
      // };
      let payload3 = new FormData();
      payload3.append('file', frontPageURL);
      const data3 = encrypt2(payload3);

      // let payload4 = {
      //   file: imageSplit4,
      //   fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-birthCertificate`,
      //   extension: ".pdf",
      // };
      let payload4 = new FormData();
      payload4.append('file', birthCertURL);
      const data4 = encrypt2(payload4);

      // let payload5 = {
      //   file: imageSplit5,
      //   fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-parentsSignature`,
      //   extension: ".pdf",
      // };
      let payload5 = new FormData();
      payload5.append('file', parentSignatureURL);
      const data5 = encrypt2(payload5);

      let payload6 = {
        file: formValues.imgBvn,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-bvnImage`,
        extension: '.png',
      };
      const data6 = encrypt2(payload6);

      await  authOApi
        .post(`${appUrls.UPLOAD_DOC}`, payload1)
        .then(async (response) => {
          const res1 = response.data;
          result.minorImgSent = res1;
          await  authOApi
            .post(`${appUrls.UPLOAD_DOC_V2}`, payload2)
            .then(async (response) => {
              const res2 = response.data;
              result.liveImgSent = res2;
              await  authOApi
                .post(`${appUrls.UPLOAD_DOC}`, payload3)
                .then(async (response) => {
                  const res3 = response.data;
                  result.bvnImgSent = res3;
                  await  authOApi
                    .post(`${appUrls.UPLOAD_DOC}`, payload4)
                    .then(async (response) => {
                      const res4 = response.data;
                      result.frontIDSent = res4;
                      await  authOApi
                        .post(`${appUrls.UPLOAD_DOC}`, payload5)
                        .then(async (response) => {
                          const res5 = response.data;
                          result.birthCertSent = res5;
                          await  authOApi
                            .post(`${appUrls.UPLOAD_DOC_V2}`, payload6)
                            .then(async (response) => {
                              const res6 = response.data;
                              result.signatureSent = await res6;
                            })
                            .catch((error) => {
                              setLoading(false);
                              message.error(
                                'Oops! Error Processing Documents',
                                10
                              );
                            });
                        });
                    });
                });
            });
          result.success = true;
        })
        .catch((error) => {
          setLoading(false);
          message.error('Oops! Error Processing Documents', 10);
          result.success = false;
        });
      setLoading(false);
      return result;
    }
  };

  const verifyy = () => {
    if (identificationType === 'International Passport') {
      youVerifyInternational();
    }

    if (identificationType === 'Permanent Voters Card') {
      youVerifyPvc();
    }

    if (identificationType === 'National ID') {
      youVerifyNationalID();
    }

    if (identificationType === 'Drivers License') {
      youVerifyDriversLic();
    }
  };

  const youVerifyInternational = () => {
    setLoadingID(true);
    let payloadd = {
      id: formValues.idCardNo,
      lastName: formValues.lastName,
      isSubjectConsent: true,
      validations: {
        data: {
          firstName: formValues.firstName,
          dateOfBirth: moment(formValues.dobirth).format('YYYY-MM-DD'),
        },
      },
    };
     authOApi
      .post(`${appUrls.ADD_INTL_PASSPORT}`, payloadd)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == '200') {
          next();
          setLoadingID(false);
          setIsVerified(true);
        } else {
          next();
          setLoadingID(false);
        }
      })
      .catch((error) => {
        setLoadingID(false);
        next();
      });
  };

  const youVerifyNationalID = () => {
    let payload = {
      id: formValues.idCardNo,
      isSubjectConsent: true,
    };
    //  
     authOApi
      .post(`${appUrls.VERIFY_NIN}`, payload)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == '200') {
          next();
          setLoadingID(false);
          setIsVerified(true);
        } else {
          // message.error(response.data.message, 10);
          next();
          setLoadingID(false);
        }
      })
      .catch((error) => {
        next();
        setLoadingID(false);
      });
  };

  const youVerifyPvc = () => {
    setLoadingID(true);
    let payload = {
      id: formValues.idCardNo,
      isSubjectConsent: true,
    };
    //  
     authOApi
      .post(`${appUrls.VERIFY_PVC}`, payload)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == '200') {
          next();
          setLoadingID(false);
          setIsVerified(true);
        } else {
          // message.error(response.data.message, 10);
          next();
          setLoadingID(false);
        }
      })
      .catch((error) => {
        setLoadingID(false);
        next();
      });
  };

  const youVerifyDriversLic = () => {
    setLoadingID(true);
    let payload = {
      id: formValues.idCardNo,
      isSubjectConsent: true,
    };
     authOApi
      .post(`${appUrls.VERIFY_DRIVER_LICENSE}`, payload)
      .then((response) => {
        let res = response.data;
        if (response.data.statusCode == '200') {
          next();
          setLoadingID(false);
          setIsVerified(true);
        } else {
          // message.error(response.data.message);
          next();
          setLoadingID(false);
        }
      })
      .catch((error) => {
        next();
      });
  };

  const openAccount = async () => {
    setLoading(true);
    const results = await uploadToAzure();

    if (results.success) {
      let payload = {
        BVN: formValues.bvn,
        DateOfBirth: formValues.dobirth,
        ChildDateOfBirth: moment(formValues.childDob).format(
          'YYYY-MM-DDTHH:mm:ss.sssZ'
        ),
        ChildFirstName: formValues.ChildFirstName,
        ChildMiddleName: formValues.ChildMiddleName,
        ChildLastName: formValues.ChildLastName,
        ChildGender: formValues.ChildGender,
        Email: formValues.email,
        PhoneNumber: formValues.phone,
        FirstName: formValues.firstName,
        LastName: formValues.lastName,
        ProductCode: 'I.CAN.SAVE1',
        PreferredName: formValues.firstName,
        ReferralCode: formValues.refCode,
        Branch: formValues.branch,
        Currency: 'NGN',
        Address: address,
        Sector: '4200',
        Industry: '4202',
        Nationality: 'NG',
        Gender: formValues.gender,
        ClientID: '52',
        StateOfResidence: formValues.state,
        Dao: null,
        IsVerified: isVerified && valid,
        isRestriction: isRestriction,
      };
      const encPayload = encrypt2(payload);
      await  authOApi
        .post(`${appUrls.OPEN_ACCOUNT}`, { data: encPayload })
        .then(async (response) => {
          const res = decryptHandler2(response.data);

          if (res.ResponseCode === '00') {

            setAccountInfo(res.AccountDetails[0].AccountNumber);

            let payload1 = {
              accountNumber: res.AccountDetails[0].AccountNumber,
              phoneNumber: formValues.phone,
            };
            let enc = encrypt2(payload1);
             authOApi
              .post(`${appUrls.SIM_SWAP_STATUS}`, { data: enc })
              .then((response) => {
                let decRes = decryptHandler2(response.data);
                if (decRes.SimSwapMode === 'SWAPPED') {
                  let payload = {
                    branchCode: formValues.branch,
                    accountNumber: res.AccountDetails[0].AccountNumber,
                    accontType: 'I.CAN.SAVE1',
                  };
                  const encRestr = encrypt2(payload);
                   authOApi
                    .post(`${appUrls.ADD_RESTRICTION}`, {
                      data: encRestr,
                    })
                    .then((response) => {
                      let encRes = decryptHandler2(response.data);
                      if (encRes.restrictionResponse.responseCode === '1') {
                        customerCare();
                      }
                    });
                }
              });

            const customerCare = () => {
              let payload = {
                accountNumber: res.AccountDetails[0].AccountNumber,
                phoneNumber: formValues.phone,
              };
               authOApi
                .post(`${appUrls.EMAIL_CUSTOMER_CARE}`, {
                  data: payload,
                })
                .then((response) => {
                  let encRes = encrypt2(response.data);
                  if (encRes === 1) {
                    message.success('Message sent successfully');
                  }
                });
            };

            let payload = {
              accountNumber: res.AccountDetails[0].AccountNumber,
              folio: 'Digital Onboarding',
              source: 'Digital Onboarding',
              photoMandateBase64: results.liveImgSent,
              photoIDBase64: results.minorImgSent,
              signatureMandateBase64: results.signatureSent,
              otherBase64: results.birthCertSent,
              meansOfIDBase64: results.frontIDSent,
              bvnImageURL: results.bvnImgSent,
              idtype: identificationType,
              idno: formValues.idCardNo,
              issuedate: formValues.issueDate,
              isexpirydate: formValues.expiryDate,
            };
            const encDocs = encrypt2(payload);
            await  authOApi
              .post(`${appUrls.UPLOAD_DOC_ONLINEACCOUNT}`, {
                data: encDocs,
              })
              .then((response) => {
                const resp = decryptHandler2(response.data);
                message.success(resp.ResponseDescription);
                setLoading(false);
              })
              .catch((error) => {
                setLoading(false);
                message.error(
                  'Error processing document, please try again later',
                  10
                );
              });
            handleShowCompleted();
          } else {
            message.error(res.ResponseDescription);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          message.error('Unable to Open account, please try again later', 10);
        });
    }
  };

  const confirmCompleted = () => {
    handleCloseCompleted();
    setCurrent(0);
  };

  const initializeSdk = (token) => {
    window.HyperSnapSDK.init(token, window.HyperSnapParams.Region.Africa);
    window.HyperSnapSDK.startUserSession();
  };

  const runLiveness = () => {
    const hvFaceConfig = new window.HVFaceConfig();
    hvFaceConfig.setShouldShowInstructionPage(true);
    hvFaceConfig.setLivenessAPIParameters({
      rejectFaceMask: 'yes',
      allowEyesClosed: 'no',
      allowMultipleFaces: 'no',
    });
    customizeFaceCaptureInstructionsScreen(hvFaceConfig);
    window.HVFaceModule.start(hvFaceConfig, livelinessCallback);
  };

  const customizeFaceCaptureInstructionsScreen = (hvFaceConfig) => {
    const { faceTextConfig } = hvFaceConfig;
    faceTextConfig.setFaceInstructionsTitle('Selfie capture Tips');
    // remove Top1, Top2
  };

  const livelinessCallback = (HVError, HVResponse) => {
    if (HVError) {
      const errorCode = HVError.getErrorCode();
      const errorMessage = HVError.getErrorMessage();
      message.error(errorMessage);
    } else {
      const faceImageBase64 = HVResponse.imgBase64;
      setHpvImage(faceImageBase64);
      checkMatch(faceImageBase64);
    }
  };

  const checkMatch = (faceImageBase64) => {
    const imgg = localStorage.getItem('params');
    const hyp = faceImageBase64.split(',');
    const hypSplit = hyp[1];
    let payload = {
      Selfie: hypSplit,
      IdFaceString: imgg,
    };
     authOApi
      .post(`${appUrls.HYPERVERGE_FACE_MATCH}`, payload)
      .then((response) => {
        if (response.data.status === 'failure') {
          message.error('Unable to verify Hyperverge !');
          setValid(false);
          setIsRestriction(true);
        } else {
          setValid(true);
          setIsRestriction(false);
        }
      })
      .catch((error) => {
        message.error(
          'Unable to verify face match, please try again later',
          10
        );
      });
  };

  const startHyperverge = (firstParam) => {
    localStorage.setItem('params', firstParam);
    setIsLoadingHpvSdk(true);
     authOApi
      .get(`${appUrls.GET_HYPERVERGE_TOKEN}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
      })
      .then(async (response) => {
        if (response.data.statusCode === '200') {
          const token = response.data.result.token;
          initializeSdk(token);
          runLiveness();
          setIsLoadingHpvSdk(false);
        }
      })
      .catch((err) => {
        setIsLoadingHpvSdk(false);
        message.error('Error,  please try again later', 10);
      });
  };

  const steps = [
    {
      title: 'BVN Validation',
      content: (
        <Bvn
          formValues={formValues}
          handleFormChange={handleFormChange}
          onDateChange={onDateChange}
          dob={dob}
          setDisableBtn={setDisableBtn}
        />
      ),
    },
    {
      title: 'Upload details',
      content: (
        <BvnValidation
          formValues={formValues}
          handleFormChange={handleFormChange}
          bvnDetails={bvnDetails}
          identificationType={identificationType}
          setIdentificationType={setIdentificationType}
          setAccNum={setAccNum}
          setCustomerNumber={setCustomerNumber}
          setAccountCount={setAccountCount}
          address={address}
          setAddress={setAddress}
          handleSelect={handleSelect}
        />
      ),
    },
    {
      title: 'Document upload',
      content: (
        <DocumentUpload
          formValues={formValues}
          handleFormChange={handleFormChange}
          minorImage={minorImage}
          onDrops={onDrops}
          onCapture={onCapture}
          config={config}
          onError={onError}
          imgSrc={imgSrc}
          retake={retake}
          onDrop={onDrop}
          frontPage={frontPage}
          birthCert={birthCert}
          onDropp={onDropp}
          onDroppp={onDroppp}
          parentSignature={parentSignature}
          branchCode={branchCode}
          tcHandler={tcHandler}
          isTCClicked={isTCClicked}
          handleShowTC={handleShowTC}
          onDropsss={onDropsss}
          uploadPic={uploadPic}
          hpvImage={hpvImage}
          isLoadingHpvSdk={isLoadingHpvSdk}
          startHyperverge={startHyperverge}
        />
      ),
    },
  ];

  const getBranches = () => {
     authOApi
      .get(`${appUrls.GET_STERLING_BRANCHES}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
      })
      .then((response) => {
        const res = decryptHandler2(response.data);
        setBranchCode(res.SterlingBranches);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getBranches();
  }, []);
  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 ">
          <div className="leftDiv stickyDiv"></div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <Header />
          <div className="container-fluid pb-5 mt-5">
            <div class="row mb-5">
              <div class="container mr-3">
                <div className="steps-content mb-5">
                  {steps[current].content}
                </div>
                <div className="text-right"></div>
                <div className="steps-action pb-1 mt-3 text-right">
                  {current === steps.length - 3 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 btn-block w-80"
                      onClick={validateForm}
                      disabled={bvnLoading || disableBtn}
                    >
                      {bvnLoading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        'Validate'
                      )}
                    </button>
                  )}
                  {current > 0 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger px-5 mr-2 mb-3"
                      onClick={prev}
                    >
                      Previous
                    </button>
                  )}
                  {current < steps.length - 1 && current > 0 && (
                    <button
                      type="button"
                      className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-3"
                      onClick={validateFormTwo}
                    >
                      {/* {loadingID && "Validating..."} */}
                      {/* {!loadingID && "Next"} */}
                      Next
                    </button>
                  )}
                  {current === steps.length - 1 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                      onClick={validateFormThree}
                      disabled={loading === true || isTCClicked === false}
                    >
                      {loading ? 'Completing ...' : 'Complete'}
                    </button>
                  )}
                </div>
                {current === 0 && (
                  <div>
                    <div class="my-4 text-centers text-dark">
                      <h3 class="mb-0 sec-headers">Kids Account</h3>
                    </div>
                    <div className="row pry-grey-clr mb-4 w-80">
                      <div className="col-md-6 ftsz-12">
                        <h6 className="ftsz-12 ftwt-500">Features</h6>
                        <ul className="custom-req-marker pl-3">
                          <li>
                            Minimum account opening balance of N2,000. (Not
                            Restrictive)
                          </li>
                          <li>Daily minimum balance of N1,000.</li>
                          <li>
                            Attractive interest rate i.e., 1.15% p.a. (30% of
                            MPR).
                          </li>
                          <li>
                            Allow lodgement of cheques and dividend warrants.
                          </li>
                          <li>Issuance of Verve card</li>
                          <li>SMS/Email alert.</li>
                          <li>
                            Incentive of N250,000 to beneficiary that have
                            scores of all A's in SSCE/Cambridge O'level in at
                            least 8 subjects at one sitting
                          </li>
                        </ul>
                      </div>
                      <div className="col-md-6 ftsz-12">
                        <h6 className="ftsz-12 ftwt-500">Requirements</h6>
                        <ul className="custom-req-marker pl-3">
                          <li>
                            Basic information of Minor and Parent/guardian.
                            (i.e., Name, Place and date of birth, Gender,
                            Address, Telephone number) **Address would be
                            verified
                          </li>
                          <li>
                            Birth certificate of the minor/International
                            passport / NIN Slip.
                          </li>
                          <li>
                            Parent’s/Guardian’s Valid and acceptable means of
                            Identification.
                          </li>
                          <li>
                            Passport photograph of minor and parent/ guardian.
                          </li>
                          <li>BVN of Parent/Guardian.</li>
                          <li>Signature of Parent/Guardian.</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="pale-red-part-bg position-fixed"></div>
      <div className="">
        <Header />
        <div className="d-flex justify-content-between px-2">
          <div></div>
          <div className="w-50">
            <Steps current={current}>
              {steps.map((item) => (
                <Step key={item.title} title={item.title} />
              ))}
            </Steps>
          </div>
        </div>
        <div className="container-fluid pb-5 mt-5">
          <div class="row mb-5">
            <div class="col-xl-7 col-lg-9 col-md-10 mx-auto">
              <div class="container">
                <div class="app-details-form-wrapper pry-grey-clr bg-white px-3 px-lg-5 py-5">
                  <div className="steps-content">{steps[current].content}</div>
                  <div className="text-right"></div>
                  <div className="steps-action pb-5 mt-3 text-right">
                    {current === steps.length - 3 && (
                      <button
                        type="button"
                        className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                        onClick={validateForm}
                        disabled={disableBtn || bvnLoading}
                      >
                        {bvnLoading ? "Validating ..." : "Validate"}
                      </button>
                    )}
                    {current > 0 && (
                      <button
                        type="button"
                        className="btn btn-outline-danger px-5 mr-2 mb-3"
                        onClick={prev}
                      >
                        Previous
                      </button>
                    )}
                    {current < steps.length - 1 && current > 0 && (
                      <button
                        type="button"
                        className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-3"
                        onClick={validateFormTwo}
                      >
                        Next
                      </button>
                    )}
                    {current === steps.length - 1 && (
                      <button
                        type="button"
                        className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                        onClick={validateFormThree}
                        disabled={loading === true || isTCClicked === false}
                      >
                        {loading ? "Completing ..." : "Complete"}
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <OTP
        showOtp={showOtp}
        handleCloseOtp={handleCloseOtp}
        formValues={formValues}
        handleFormChange={handleFormChange}
        validateOTP={validateOTP}
        loading={loading}
        verifyBvn={verifyBvn}
        otp={otp}
        setOtp={setOtp}
        otpLoading={otpLoading}
      />
      <NewCompletedForm
        showCompleted={showCompleted}
        handleCloseCompleted={handleCloseCompleted}
        confirmCompleted={confirmCompleted}
        accountInfo={accountInfo}
        bvnDetails={bvnDetails}
        accNum={accNum}
        imgSrc={imgSrc}
      />
      <InvalidBVN
        showInvalid={showInvalid}
        handleCloseInvalid={handleCloseInvalid}
      />
      <InvalidOTP
        showInvalidOTP={showInvalidOTP}
        handleCloseInvalidOTP={handleCloseInvalidOTP}
      />

      <ExistingOTP
        showExistingOTP={showExistingOTP}
        handleCloseExistingOTP={handleCloseExistingOTP}
      />

      <RegisteredModal
        showRegistered={showRegistered}
        handleCloseRegistered={handleCloseRegistered}
      />

      <PendingModal
        showModal={isOpen}
        handleClosePendingModal={handleClosePendingModal}
        setNext={next}
        getSaveProcess={getSaveProcess}
      />

      <TermsAndConditions showTC={showTC} handleCloseTC={handleCloseTC} />
    </div>
  );
};

export default KidsAccount;
