import axios from "axios";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { welcomeAction } from "../../../redux/actions";

const AtmLocator = ({ branches }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const dispatch = useDispatch();

  const switchMain = () => {
    dispatch(welcomeAction.setWelcome("main"));
  };

  return (
    <div>
      <div
        className="px-4"
        id="ATMLocator"
        aria-labelledby="ATMLocator"
        aria-hidden="true"
      >
        <div className=" ml-auto mr-0">
          <div className=" border-0">
            {/* <a href="/web/viewer.html?file=Referenceform.pdf" target="_blank">Download file</a> */}
            <div className="border-0 d-flex justify-content-between align-items-center mb-3">
              <button
                type="button"
                className="bare-btn sec-header"
                onClick={switchMain}
              >
                <i className="fas fa-long-arrow-alt-left"></i>
              </button>
              <h5 className="sec-header" id="ATMLocatorLabel">
                ATM locator
              </h5>
            </div>
            <div className="">
              <div className="mb-5">
                <label
                  htmlFor=""
                  className="form-label pry-grey-clr ftsz-12 mb-0"
                >
                  Your location
                </label>
                <div className="position-relative">
                  <input
                    type="text"
                    className="form-control pry-input-border pr-5"
                    id=""
                    placeholder="Enter your location"
                    onChange={(event) => {
                      setSearchTerm(event.target.value);
                    }}
                  />
                  <span className="position-absolute input-srch-icon">
                    <i className="fas fa-search"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="atm-scroll">
              {branches
                .filter((fil) => {
                  if (searchTerm == "") {
                    return fil;
                  } else if (
                    fil.BranchName.toLowerCase().includes(
                      searchTerm.toLocaleLowerCase()
                    )
                  ) {
                    return fil;
                  }
                })
                .map((branch, id) => (
                  <ul
                    className="list-unstyled locator-list pry-grey-clr"
                    key={id}
                  >
                    <li className="locator-list-item mb-4">
                      <p>{branch.BranchName}</p>
                      <p className="ftsz-regular mb-1"></p>
                      <div className="text-right mb-1">
                        <span className="ftsz-12 text-right">
                          {branch.RegionName}
                        </span>
                      </div>
                    </li>
                  </ul>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AtmLocator;
