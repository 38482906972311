import React, { useState } from 'react';
import axios from 'axios';
import { Image } from 'cloudinary-react';
import {
  apiUrl1,
  endpoint,
  referenceEndpoint,
} from '../../redux/actions/endpoint';
// import { apiAccount } from "../../service/apiAccount";
import { authOApi } from '../../service/authOApi';
import { appUrls } from '../../service/urls';

const CloudinaryUpload = () => {
  const [imageSelected, setImageSelected] = useState('');
  const uploadImage = () => {
    const formData = new FormData();
    formData.append('file', imageSelected);
    // formData.append("upload_preset", "ocfeczpe");

    authOApi
      //   .post("https://api.cloudinary.com/v1_1/dskbffvaq/image/upload", formData)
      .post(`${appUrls.UPLOAD_DOC_CLOUDINARY}`, formData)
      .then((response) => {

      });
  };
  return (
    <div>
      <div>
        <input
          type="file"
          onChange={(event) => {
            setImageSelected(event.target.files[0]);
          }}
        />
        <button onClick={uploadImage}>Submit</button>
      </div>
    </div>
  );
};

export default CloudinaryUpload;
