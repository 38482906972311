import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalContent from "./content";

const TermsAndConditions = ({ showTC, handleCloseTC }) => {
  return (
    <div>
      <Modal
        show={showTC}
        onHide={handleCloseTC}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h2>Terms And Conditions</h2>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <ModalContent />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default TermsAndConditions;
