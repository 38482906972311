import { SET_FORGOT_ACCT_METHOD } from "../types";

export const set_forgot_acct_method = (payload) => ({
    type: SET_FORGOT_ACCT_METHOD,
    payload,
});

const exportedObject = {
    set_forgot_acct_method
}

export default exportedObject;