import { SET_BVN_VALUES } from "../types";

export const set_bvn_info = (value) => ({
    type: SET_BVN_VALUES,
    payload: value,
});

const exportedObject = {
    set_bvn_info
}

export default exportedObject;