import React from "react";
import { Link, useHistory } from "react-router-dom";
import New from "../../../../Images/New.png";
import Existing from "../../../../Images/Existing.png";
import Personal from "../../../../Images/Personal.png";
import Business from "../../../../Images/Business.png";

const MainComponent = () => {
  const history = useHistory();
  return (
    <div>
      <div className="row">
        <div className="col-lg-9 mx-auto">
          <section className="form-section text-centers pt-2 mt-5 customersContainer">
            <div className="p-3">
              <div className="mb-4 pb-2">
                <h3 className="pry-header mb-0">We are excited to meet you</h3>
                <p className="sec-headers">Let's get started...</p>
              </div>
              <div className="d-flex justify-content-centers flex-wrap">
                <div className="mr-3 mb-3 position-relative containerEx pl-3 pr-5 py-3">
                  <div>
                    <img src={New} alt="new customers icon" />
                  </div>
                  <div className="ml-3">
                    <p className="pb-0 mb-0 bold-text text-red active-style">
                      New customer
                    </p>
                    <p className="ftsz-12 pry-grey-clr mb-0">
                      Create a new account
                    </p>
                  </div>
                </div>
                <div
                  className="mr-3 mb-3 position-relative containerEx pl-3 pr-5 py-3"
                  onClick={() => {
                    history.push("/existing-customer");
                  }}
                >
                  <div>
                    <img src={Existing} alt="existing customers icon" />
                  </div>
                  <div className="ml-3">
                    <p className="pb-0 mb-0 bold-text">Existing customer</p>
                    <p className="ftsz-12 pry-grey-clr mb-0">
                      Open an additional account
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="px-3">
              <p className="">What type of account do you want to open?</p>
              {/* <hr /> */}
              <div className="d-flex justify-content-centers flex-wrap pt-3 extraStyle">
                <div
                  className="mr-3 mb-3 position-relative containerEx pl-3 pr-5 py-2"
                  onClick={() => {
                    history.push("/personal-account");
                  }}
                >
                  <div>
                    <img src={Personal} alt="Personal customers icon" />
                  </div>
                  <div className="ml-3">
                    <p className="pb-0 mb-0 bold-text">Personal</p>
                  </div>
                </div>

                <div
                  className="mr-3 mb-3 position-relative containerEx pl-3 pr-5 py-2"
                  onClick={() => {
                    history.push("/business-account");
                  }}
                >
                  <div>
                    <img src={Business} alt="Business customers icon" />
                  </div>
                  <div className="ml-3">
                    <p className="pb-0 mb-0 bold-text">Business</p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  );
};

export default MainComponent;
