import { SET_LANDING_TYPE } from "../types";

export const setLanding = (payload) => ({
  type: SET_LANDING_TYPE,
  payload,
});

const exportedObject = {
  setLanding,
};

export default exportedObject;
