import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { withRouter } from 'react-router-dom';
import AppConfig from './App.config';

ReactGA.initialize(AppConfig.GOOGLE.GA_TRACKING_CODE);

const ReactGAComponent = ({children}) => {

    useEffect(() => {
        ReactGA.pageview(window.location.pathname + window.location.search);
    }, [])
    return (
        <div>{children}</div>
    )
}

export default withRouter(ReactGAComponent);
