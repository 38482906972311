import React from "react";
import Info from "../../../../../../../../../../Images/Info_icon.svg";

const BvnVal = ({ formValuess, handleFormChangess, dob, onDateChange }) => {
  return (
    <div>
      <form action="">
        <div class="my-4 text-center text-dark">
          <h5 class="mb-0 sec-header">BVN Validation</h5>
        </div>
        <div class="form-row mb-3">
          <div class="form-group col-12">
            <label
              for=""
              class="pry-grey-clr ftsz-12 ftwt-500 mb-1 d-flex justify-content-between align-items-center"
            >
              <span>
                Enter BVN{" "}
                <span className="font-weight-bolder text-black">
                  (Must be 11 digits)
                </span>
              </span>
              <div class="popover-wrapper position-relative">
                <div class="popover-title">
                  <img src={Info} alt="" style={{ cursor: "pointer" }} />
                </div>
                <div class="popover-content ftsz-12 p-2">
                  <ul class="pl-3">
                    <li>
                      The Bank Verification Number (BVN) is an 11-digit number
                    </li>
                    <li>Dial *565*0# to check your BVN</li>
                  </ul>
                </div>
              </div>
            </label>
            <div className="form-group">
              <input
                maxLength="11"
                type="text"
                class="form-control pry-input-border"
                id=""
                placeholder="Enter your BVN"
                value={formValuess.bvn_two}
                onChange={handleFormChangess("bvn_two")}
                // value={formValuess.bvn}
                // onChange={handleFormChange("bvn")}
              />
            </div>
          </div>
          <div class="form-group col-12 ftsz-12">
            <h6 class="ftwt-500 ftsz-12 mb-1 text-dark">Date of birth</h6>
            {/* <div class="row"> */}
              <input
                className="input-pad form-control"
                type="date"
                value={dob}
                onChange={onDateChange}
              />
            {/* </div> */}
          </div>
        </div>
      </form>
    </div>
  );
};

export default BvnVal;
