import { Modal } from 'react-bootstrap';
import { BsCheck2Circle } from 'react-icons/bs';

const ValidationSuccessful = ({
  clientIDSuccessModal,
  setClientIDSuccessModal,
  handleProceed,
}) => {
  return (
    <Modal
      show={clientIDSuccessModal}
      onHide={() => setClientIDSuccessModal(false)}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="text-center pt-5">
        <h5 className="text-success">Client ID Successfully validated</h5>
        <div className="d-flex justify-content-center mb-2">
          <BsCheck2Circle color="green" stroke="#F3FBED" fontSize={40} />
        </div>
        <button
          className="btn btn-outline-danger mt-2 mb-2"
          onClick={handleProceed}
        >
          Proceed
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default ValidationSuccessful;
