import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { decryptHandler2 } from "../../../../../../../../dec";
import { comboAction } from "../../../../../../../../redux/actions";
import ComboOne from "./components/comboOne";
import ComboTwo from "./components/comboTwo";

const UploadDocsCom = ({
  setComboType,
  onDrop1,
  onDrop2,
  onDrop3,
  onDrop4,
  onDrop5,
  onDrop6,
  onDrop7,
  onDrop8,
  onDrop9,
  onDrop10,
  onDrop11,
  onDrop12,
  onDrop13,
  onDrop14,
  onDrop15,
  cac,
  cac1,
  co2,
  co7,
  meMart,
  board,
  refr,
  others,
  evidence,
  branchCode,
  formValues,
  handleFormChange,
  handleSecChanges,
  sec,
  sectorList,
  industriesList,
  isLoading,
  showDoc,
  docText,
  tcHandler,
  isTCClicked,
  handleShowTC,
  handleShowTBH,
  caCTwo,
  caC11,
  meMarTTwo,
  boarDTwo,
  refRTwo,
  otherSTwo,
  evidencETwo,
  stateList,
  formVal
}) => {
  const dispatch = useDispatch();
  const { combo_type } = useSelector(({ combo }) => combo);

  const switchToComboOne = () => {
    dispatch(comboAction.set_combo_type("combo1"));
    setComboType("combo-one");
  };

  const switchToComboTwo = () => {
    dispatch(comboAction.set_combo_type("combo2"));
    setComboType("combo-two");
  };
  
  return (
		<div>
			<div className="row mb-5">
				<div className="mb-2">
					<h5 className="sec-header text-body text-center">Upload documents</h5>
					<h6 className="mb-0 font-weight-normal text-body text-center ftsz-12">
						Select Document upload combo
					</h6>
				</div>
				<div className="mb-4">
					<div className="row">
						<div className="col-lg-6">
							<button onClick={switchToComboOne} className="btn btn-primary">
								<span className="combo-num d-inline-block p-2 ftwt-500 mb-3">
									Combo 1
								</span>
								<p className="combo-doc-req">
									CAC, Co2, Co7, MeMART, Board resolution signed and sealed ,
									References and Others
								</p>
							</button>
						</div>
						<div className="col-lg-6">
							<button onClick={switchToComboTwo} className="btn btn-primary">
								<span className="combo-num d-inline-block p-2 ftwt-500 mb-3">
									Combo 2
								</span>
								<p className="combo-doc-req">
									CAC, CAC 1.1, MeMART, Board resolution signed and sealed,
									References and Others
								</p>
							</button>
						</div>
					</div>
				</div>

				<div className="col-12 pt-5">
					{combo_type === 'combo1' && (
						<ComboOne
							onDrop1={onDrop1}
							onDrop2={onDrop2}
							onDrop3={onDrop3}
							onDrop4={onDrop4}
							onDrop5={onDrop5}
							onDrop6={onDrop6}
							onDrop7={onDrop7}
							onDrop8={onDrop8}
							cac={cac}
							co2={co2}
							co7={co7}
							meMart={meMart}
							board={board}
							refr={refr}
							others={others}
							evidence={evidence}
							branchCode={branchCode}
							formValues={formValues}
							handleFormChange={handleFormChange}
							handleSecChanges={handleSecChanges}
							sec={sec}
							sectorList={sectorList}
							industriesList={industriesList}
							isLoading={isLoading}
							showDoc={showDoc}
							docText={docText}
							tcHandler={tcHandler}
							isTCClicked={isTCClicked}
							handleShowTC={handleShowTC}
							handleShowTBH={handleShowTBH}
							formVal={formVal}
							stateList={stateList}
						/>
					)}
					{combo_type === 'combo2' && (
						<ComboTwo
							onDrop9={onDrop9}
							onDrop10={onDrop10}
							onDrop11={onDrop11}
							onDrop12={onDrop12}
							onDrop13={onDrop13}
							onDrop14={onDrop14}
							onDrop15={onDrop15}
							caCTwo={caCTwo}
							caC11={caC11}
							meMarTTwo={meMarTTwo}
							boarDTwo={boarDTwo}
							refRTwo={refRTwo}
							otherSTwo={otherSTwo}
							evidencETwo={evidencETwo}
							branchCode={branchCode}
							formValues={formValues}
							handleFormChange={handleFormChange}
							handleSecChanges={handleSecChanges}
							sec={sec}
							sectorList={sectorList}
							industriesList={industriesList}
							isLoading={isLoading}
							tcHandler={tcHandler}
							isTCClicked={isTCClicked}
							handleShowTC={handleShowTC}
							handleShowTBH={handleShowTBH}
							formVal={formVal}
							stateList={stateList}
						/>
					)}
				</div>
			</div>
		</div>
	)
};

export default UploadDocsCom;
