import { CHANGE_ID_TYPE } from "../types";

export const set_id_type = (payload) => ({
    type: CHANGE_ID_TYPE,
    payload
});

const exportedObject = {
    set_id_type
};

export default exportedObject;