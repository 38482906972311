import crypto from "crypto-js";
const key = "zAL7X5AVRm8l4lfs",
  //   IV_LENGTH = "BE/s3V0HtpPsE+1x";
  IV_LENGTH = 16;
export const appEncryptToken = (t) => {
  let r = crypto.randomBytes(Number(IV_LENGTH)),
    e = crypto.createCipheriv("aes-256-cbc", Buffer.from(key), r),
    o = e.update(t);
  return (
    (o = Buffer.concat([o, e.final()])),
    r.toString("base64") + ":" + o.toString("base64")
  );
};
export const appDecryptToken = (t) => {
  let r = t.split(":"),
    e = Buffer.from(r.shift(), "base64"),
    o = Buffer.from(r.join(":"), "base64"),
    c = crypto.createDecipheriv("aes-256-cbc", Buffer.from(key), e),
    n = c.update(o);
  return (n = Buffer.concat([n, c.final()])).toString();
};
export const appEncryptObject = (t) => {
  const r = {};
  for (const e in t) {
    const o = appEncryptToken(t[e]);
    r[e] = o;
  }
  return r;
};
export const appDecryptObject = (t) => {
  const r = {};
  for (const e in t) {
    const o = appDecryptToken(t[e]);
    r[e] = o;
  }
  return r;
};
