import React, { useState, useEffect } from 'react';
import Warning from '../../../../../../Images/warning-icon.svg';
import Copy from '../../../../../../Images/copy-icon.png';
import { Drawer } from 'antd';
import { Link } from 'react-router-dom';
import AddReference from './components';
import axios from 'axios';
import { apiUrl } from '../../../../../../redux/actions/endpoint';
import Options from './components/options';
import { decryptHandler2 } from '../../../../../../dec';
import { encrypt2 } from '../../../../../../encr';
import { apiAccount } from '../../../../../../service/apiAccount';
import { appUrls } from '../../../../../../service/urls';
import { authOApi } from '../../../../../../service/authOApi';

const ReferenceCurrent = ({
  accountInfo,
  formValues,
  handleFormChange,
  current,
  setCurrent,
  fileUrl,
  showAddRefOTP,
  handleCloseAddRefOTP,
  handleShowAddRefOTP,
  handleShowRefSuccessOTP,
}) => {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState('right');
  const [refereesList, setReferrsList] = useState([]);

  const onClose = () => {
    setVisible(false);
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onChange = (e) => {
    setPlacement(e.target.value);
  };

  const getReference = () => {
    const data = encrypt2(accountInfo);
    authOApi
      .get(`${appUrls.GET_CUSTOMER_REFERENCES}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);

        setReferrsList(res);
      });
  };

  useEffect(() => {
    getReference();
  }, []);

  return (
    <div>
      <div class="text-center">
        <div class="mb-5">
          <h6 class="sec-header text-body text-center mb-3 px-sm-5 mx-sm-5">
            Add reference
          </h6>
          <div class="mb-4">
            <p class="text-center mx-md-5">
              Proceed to add reference, Kindly ensure that the referee’s account
              meets the following requirements:
            </p>
            <ul class="pl-3 text-left">
              <li class="custom-green-marker mb-2">
                Account has been in operation for more than 6 months.
              </li>
              <li class="custom-green-marker mb-2">Account is not dormant</li>
              <li class="custom-green-marker mb-2">
                Category criteria (References from a registered business can
                only be used for an individual account or another registered
                business but a reference from a registered business is suitable
                for all forms of current account.)
              </li>
            </ul>
          </div>
          <div class="d-flex align-items-center pale-red-tone-bg pry-red-clr ftsz-12 mb-0 mt-3 px-3 py-2 mb-3 rounded-lg">
            <img src={Warning} class="mr-3" alt="warning icon" />
            <div>
              <ul class="pl-3 text-left ftwt-600">
                <li class="custom-red-marker">
                  For refree with sterling account, an email is sent to the
                  referee to provide consent via a secured link.{' '}
                </li>
                <li class="custom-red-marker">
                  For refree with other bank account, download or email form to
                  refree and upload the completed form.{' '}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="">
          <p className="text-left text-muted font-weight-bold">References</p>
          <div className="d-flex justify-content-between">
            <div>
              {refereesList.map((referee, id) => {
                return (
                  <div key={id}>
                    <section>
                      <p className="font-weight-bold">
                        {referee.refereeAccountName}
                      </p>
                      <br />
                      <p>{referee.refereeNuban}</p>
                      <br />
                    </section>
                    <hr />
                  </div>
                );
              })}
            </div>
            <Link
              to="#"
              class="px-5 py-2 text-danger text-underline text-right"
              onClick={showDrawer}
            >
              Add reference
            </Link>
          </div>
        </div>
      </div>
      <Drawer
        title="Add reference"
        placement={placement}
        width={500}
        onClose={onClose}
        visible={visible}
      >
        <Options
          onClose={onClose}
          accountInfo={accountInfo}
          formValues={formValues}
          handleFormChange={handleFormChange}
          current={current}
          setCurrent={setCurrent}
          fileUrl={fileUrl}
          showAddRefOTP={showAddRefOTP}
          handleCloseAddRefOTP={handleCloseAddRefOTP}
          handleShowAddRefOTP={handleShowAddRefOTP}
          handleShowRefSuccessOTP={handleShowRefSuccessOTP}
        />
      </Drawer>
    </div>
  );
};

export default ReferenceCurrent;
