import { message } from 'antd';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { BiCopy } from 'react-icons/bi';
import { BsCheck2Circle } from 'react-icons/bs';
import { useHistory } from 'react-router-dom';

const UpdateDetailsClientID = ({ clientID, setClientID }) => {
  const history = useHistory();

  const copyTextToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      message.success('Client ID copied successfully!', 10);
      return await navigator.clipboard.writeText(text);
    } else {
      message.success('Client ID copied successfully!', 10);
      return document.execCommand('copy', true, text);
    }
  };

  return (
    <Modal show={clientID} onHide={() => setClientID(null)} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="d-flex justify-content-center align-items-center text-center flex-column">
        <div className="d-flex justify-content-center mb-2">
          <BsCheck2Circle color="green" stroke="#F3FBED" fontSize={40} />
        </div>
        <h5 className="ftsz-regular">
          You have successfully generated a Tracking ID for this request which
          has been sent to your email
        </h5>
        <p className="pry-grey-clr mt-2">Here is your tracking ID</p>
        <div className="d-flex mb-3 px-3 py-2 w-75 rounded justify-content-around pale-red-tone-bg align-items-center">
          <p className="text-danger pry-header fw-bold mb-0">{clientID}</p>
          <button
            style={{
              width: '43px',
              height: '43px',
              borderRadius: '50%',
              border: 'none',
              backgroundColor: '#fff',
            }}
            className=" rounded-full d-flex justify-content-center align-items-center"
            onClick={() => copyTextToClipboard(clientID)}
          >
            <BiCopy width={24} height={24} />
          </button>
        </div>
        <p className="mb-0 pry-grey-clr mx-auto w-75">
          Kindly visit the nearest FIRS office with the filled forms to
          regularise your TIN
        </p>
        <button
          className="btn btn-danger px-3 mt-2 mb-3"
          onClick={() => {
            setClientID(null);
            history.goBack();
          }}
        >
          End
        </button>
      </Modal.Body>
    </Modal>
  );
};

export default UpdateDetailsClientID;
