import React from "react";
import Completed from "../../../../../../../../../Images/completed.png";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { message } from "antd";
import { bankAction } from "../../../../../../../../../redux/actions";

const RefSuccessOTP = ({
  showRefSuccessOTP,
  handleCloseRefSuccessOTP,
  setCurrent,
  getReference
}) => {
  const dispatch = useDispatch();
  return (
    <div>
      <Modal
        show={showRefSuccessOTP}
        onHide={handleCloseRefSuccessOTP}
        backdrop="static"
        keyboard={false}
        // size="lg"
      >
        <Modal.Body>
          <div className="text-center">
            <div className="mb-3">
              <img src={Completed} className="img-fluid" alt="success" />
            </div>
            <div>
              <p className="my-3">
                We have contacted your Referee, and we await their
                consent. You will be notified if no response is received within
                24hrs.
              </p>
              <p>
                Confirmation commences immediately after consent has been
                received and is completed within 1-2 working days. You will be
                notified of the outcome.
              </p>
            </div>

            <div class="text-center">
              <button
                class="btn btn-outline-danger px-5 py-2 mr-4"
                onClick={() => {
                    handleCloseRefSuccessOTP();
                    setCurrent(0);
                }}
              >
                Okay
              </button>
              <button
                class="btn btn-danger pry-red-bg px-5 py-2"
                onClick={() => {
                  handleCloseRefSuccessOTP();
                  dispatch(bankAction.setBank("select"));
                  getReference();
                  setCurrent(3);
                }}
              >
                Add another reference
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RefSuccessOTP;
