import { SET_BVN_VALUES } from "../types";

const INITIAL_STATE = {
  bvnInfo: {},
};

const BvnReduver = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BVN_VALUES:
      return {
        ...state,
        bvnInfo: action.payload,
      };

    default:
      return state;
  }
};

export default BvnReduver;
