import { message } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { decryptHandler2 } from "../../../dec";
import { encrypt2 } from "../../../encr";
import { apiUrl } from "../../../redux/actions/endpoint";
import useBusinessFiles from "../hooks/useBusinessFiles";
import { apiAccount } from "../../../service/apiAccount";
import { appUrls } from "../../../service/urls";
import { authOApi } from "../../../service/authOApi";

const BusinessForm = ({
  showDoc,
  docText,
  sec,
  handleSecChanges,
  sectorList,
  industriesList,
  isLoading,
  accNum,
  regCert,
  appBus,
  busRes,
  refSent,
  others,
  evidence,
  onDrop,
  onDrops,
  onDropss,
  onDropsss,
  onDropssss,
  onDropsssss,
}) => {
  const [other, setOther] = useState(false);
  const [cert, setCert] = useState(false);
  const [bus, setBus] = useState(false);
  const [evid, setEvid] = useState(false);
  const [app, setApp] = useState(false);
  const [reff, setReff] = useState(false);

  const check = () => {
    // let payload = {
    //   accountNumber: accNum,
    // };
    let payload = accNum;
	  let enc = encrypt2(payload);
    authOApi
      .get(`${appUrls.BUSINESSDOC_UPLOAD_STATUS}?Data=${enc}`)
      .then((response) => {
		  const res = decryptHandler2(response.data);
        setOther(res.Others1);
        setCert(res.CertificateOfRegistration);
        setBus(res.BusinessResolution);
        setEvid(res.EvidenceOfMembership);
        setApp(res.ApplicationForBusinessName);
        setReff(res.Others2);
      })
      .catch((error) => {
		  message.error(error);
      });
  };
  useEffect(() => {
    check();
  }, []);

  return (
		<div className="container">
			<form action="">
				<div className="mb-4">
					<h5 className="sec-header text-body text-center">Upload documents</h5>
					<h6 className="mb-0 font-weight-normal text-body text-center ftsz-12">
						Upload pdf file formats. Asterisked items (**) are mandatory.{" "}
					</h6>
				</div>

				<div className="form-row pb-2">
					{cert === false && (
						<div className="col-12 col-md-6 mb-3 ftsz-12 ">
							<div>
								<h6 className="ftwt-500 ftsz-12 mb-1 text-body">
									Certificate of registration**
								</h6>
								{regCert ? <p>{regCert.path}</p> : <p>No file uploaded</p>}
								<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
									<Dropzone
										onDrop={onDrop}
										multiple={false}
										accept="image/jpeg, image/png, .pdf"
									>
										{({ getRootProps, getInputProps }) => (
											<section className="doc-uploads">
												<div {...getRootProps()}>
													<input {...getInputProps()} placeholder="" />
													{!!regCert.length &&
														regCert.map((file) => (
															<p className="doc-texts">{file.path}</p>
														))}

													{!regCert.length && (
														<section className="doc-texts">
															Drag 'n' drop , or click to select <br />
														</section>
													)}
												</div>
											</section>
										)}
									</Dropzone>
								</div>
							</div>
						</div>
					)}

					{app === false && (
						<div className="col-12 col-md-6 mb-3 ftsz-12 ">
							<div>
								<h6 className="ftwt-500 ftsz-12 mb-1 text-body">
									Application for business name**
								</h6>
								{appBus ? <p>{appBus.path}</p> : <p>No file uploaded</p>}
								<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
									<Dropzone
										onDrop={onDrops}
										multiple={false}
										accept="image/jpeg, image/png, .pdf"
									>
										{({ getRootProps, getInputProps }) => (
											<section className="doc-uploads">
												<div {...getRootProps()}>
													<input {...getInputProps()} placeholder="" />
													{!!appBus.length &&
														appBus.map((file) => (
															<p className="doc-texts">{file.path}</p>
														))}

													{!appBus.length && (
														<section className="doc-texts">
															Drag 'n' drop , or click to select <br />
														</section>
													)}
												</div>
											</section>
										)}
									</Dropzone>
								</div>
							</div>
						</div>
					)}

					{bus === false && (
						<div className="col-12 col-md-6 mb-3 ftsz-12 ">
							<div>
								<h6 className="ftwt-500 ftsz-12 mb-1 text-body">
									Business resolution**
								</h6>
								{busRes ? <p>{busRes.path}</p> : <p>No file uploaded</p>}
								<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
									<Dropzone
										onDrop={onDropss}
										multiple={false}
										accept="image/jpeg, image/png, .pdf"
									>
										{({ getRootProps, getInputProps }) => (
											<section className="doc-uploads">
												<div {...getRootProps()}>
													<input {...getInputProps()} placeholder="" />
													{!!busRes.length &&
														busRes.map((file) => (
															<p className="doc-texts">{file.path}</p>
														))}

													{!busRes.length && (
														<section className="doc-texts">
															Drag 'n' drop , or click to select <br />
														</section>
													)}
												</div>
											</section>
										)}
									</Dropzone>
								</div>
							</div>
						</div>
					)}

					{reff === false && (
						<div className="col-12 col-md-6 mb-3 ftsz-12 ">
							<div>
								<h6 className="ftwt-500 ftsz-12 mb-1 text-body">References</h6>
								{refSent ? <p>{refSent.path}</p> : <p>No file uploaded</p>}
								<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
									<Dropzone
										onDrop={onDropsss}
										multiple={false}
										accept="image/jpeg, image/png, .pdf"
									>
										{({ getRootProps, getInputProps }) => (
											<section className="doc-uploads">
												<div {...getRootProps()}>
													<input {...getInputProps()} placeholder="" />
													{!!refSent.length &&
														refSent.map((file) => (
															<p className="doc-texts">{file.path}</p>
														))}

													{!refSent.length && (
														<section className="doc-texts">
															Drag 'n' drop , or click to select <br />
														</section>
													)}
												</div>
											</section>
										)}
									</Dropzone>
								</div>
							</div>
						</div>
					)}

					{other === false && (
						<div className="col-12 col-md-6 mb-3 ftsz-12 ">
							<div>
								<h6 className="ftwt-500 ftsz-12 mb-1 text-body">Others</h6>
								{others ? <p>{others.path}</p> : <p>No file uploaded</p>}
								<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
									<Dropzone
										onDrop={onDropssss}
										multiple={false}
										accept="image/jpeg, image/png, .pdf"
									>
										{({ getRootProps, getInputProps }) => (
											<section className="doc-uploads">
												<div {...getRootProps()}>
													<input {...getInputProps()} placeholder="" />
													{!!others.length &&
														others.map((file) => (
															<p className="doc-texts">{file.path}</p>
														))}

													{!others.length && (
														<section className="doc-texts">
															Drag 'n' drop , or click to select <br />
														</section>
													)}
												</div>
											</section>
										)}
									</Dropzone>
								</div>
							</div>
						</div>
					)}

					{evid === false && (
						<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
							<div>
								<div className="form-row">
									<label className="form-label ftsz-12 ftwt-500 mb-1">
										Select Sector
									</label>
									<select
										className="custom-select pry-input-border"
										onChange={handleSecChanges("sector_id")}
										required
										value={sec.sector_id}
									>
										{sectorList.map((sectors, index) => (
											<option value={sectors.Sector_T24Code} key={index}>
												{sectors.Sector}
											</option>
										))}
									</select>
								</div>
							</div>
						</div>
					)}
					<div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
						{evid === false && (
							<div>
								<div className="form-row">
									<label className="form-label ftsz-12 ftwt-500 mb-1">
										Select Industry
									</label>
									<select
										className="custom-select pry-input-border"
										onChange={handleSecChanges("industry_id")}
										required
										value={sec.industry_id}
										disabled={isLoading}
									>
										<option value="">Select industry</option>
										{industriesList.map((industry, index) => (
											<option value={industry.Industry_T24Code} key={index}>
												{industry.Industry}
											</option>
										))}
									</select>
								</div>
							</div>
						)}
					</div>

					<div className="col-12 col-md-6 mb-3 ftsz-12 ">
						{showDoc && (
							<div>
								<h6 className="ftwt-500 ftsz-12 mb-1 text-body">
									<p>{docText} **</p>
								</h6>
								{evidence ? <p>{evidence.path}</p> : <p>No file uploaded</p>}
								<div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
									<Dropzone
										onDrop={onDropsssss}
										multiple={false}
										accept="image/jpeg, image/png, .pdf"
									>
										{({ getRootProps, getInputProps }) => (
											<section className="doc-uploads">
												<div {...getRootProps()}>
													<input {...getInputProps()} placeholder="" />
													{!!evidence.length &&
														evidence.map((file) => (
															<p className="doc-texts">{file.path}</p>
														))}

													{!evidence.length && (
														<section className="doc-texts">
															Drag 'n' drop , or click to select <br />
														</section>
													)}
												</div>
											</section>
										)}
									</Dropzone>
								</div>
							</div>
						)}
					</div>
				</div>
			</form>
		</div>
	);
};

export default BusinessForm;
