import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { landingAction, welcomeAction } from "../../../../redux/actions";
import SliderComponent from "./slider";
import SwitchButton from "./switchButton";
import SwitchButtonEx from "./switchEx";
import AccountOrBvn from "../ForgotAccount/components/modal/AccountOrBvn";
import { decryptHandler2 } from "../../../../dec";
import { encrypt2 } from "../../../../encr";

const NewMainContent = () => {
  const { show } = useSelector(({ landing }) => landing);
  const dispatch = useDispatch();
  const history = useHistory();


  const [showOptions, setShowOptions] = useState(false);
  const handleCloseOptions = () => setShowOptions(false);
  const handleShowOptions = () => setShowOptions(true);

  const switchBtn = () => {
    dispatch(landingAction.setLanding("switch"));
  };

  const switchSlider = () => {
    dispatch(landingAction.setLanding("slider"));
  };

  const switchBtnEx = () => {
    dispatch(landingAction.setLanding("switchEx"));
  };

  const switchBranch = () => {
    dispatch(welcomeAction.setWelcome("branch"));
  };

  const switchAtm = () => {
    // dispatch(welcomeAction.setWelcome("atm"));
    history.push(`/atm-locator`);
  };

  const switchForgotAcct = () => {
    history.push(`/retrieve-account`)
  }

  const switchForgotBvn = () => {
    history.push(`/retrieve-bvn`)
  }

  return (
    <div>
      <section className="form-section text-centers m-4 mNone">
        <div className="mb-5 pb-5 customersContainers px-3">
          <div className="">
            <div className="d-flex justify-content-between ">
              <div className="accountIntro">
                <h1>Easy account opening</h1>
              </div>
              <div></div>
            </div>
          </div>
          <p className="accountP">
            We are excited to meet you. Let's get started...
          </p>

          {show === "slider" && (
            <SliderComponent
              switchBtn={switchBtn}
              switchBtnEx={switchBtnEx}
              switchBranch={switchBranch}
              switchAtm={switchAtm}
              handleShowOptions={handleShowOptions}
              // switchForgotAcct={switchForgotAcct}
            />
          )}
          {show === "switch" && <SwitchButton switchSlider={switchSlider} />}
          {show === "switchEx" && (
            <SwitchButtonEx switchSlider={switchSlider} />
          )}
        </div>
        <AccountOrBvn
          showOptions={showOptions}
          handleCloseOptions={handleCloseOptions}
          switchForgotAcct={switchForgotAcct}
          switchForgotBvn={switchForgotBvn}
        />
      </section>
    </div>
  );
};

export default NewMainContent;
