import React from "react";
import "./content.css";

const ModalContent = () => {
  return (
    <div>
      <section className="content">
        <div className="formload">
          <h4>Welcome to TBH Community</h4>

          <p className="terms">
            The Business Hub is a platform carefully designed to provide
            solutions that will support small businesses from start-up to scale.
          </p>

          <p className="teams">
            Our services include:
            <p>
              <li>
                <span class="font-bold">Business Registrations:</span> All
                Business registration types (comes with Tax Identification
                Number, free search report, up to 3 years Zero AMF on your
                business account)
              </li>
            </p>
            <p>
              <li>
                <span class="font-bold">Business Loans:</span> Get the
                much-needed financial support to grow your business with our
                digital loan. This loan gives you access to about 5 million
                Naira (without collateral) at a highly competitive rate.
              </li>
            </p>
            <p>
              <li>
                <span class="font-bold"> Business tools:</span>
                Discounted business tools to support your business operations
                and and ease the cost of doing business. Our tools include
                resources and applications for marketing and customer
                engagement, logistics and delivery, warehousing, invoicing and
                book-keeping, inventory management, business phone numbers,
                digital marketplace, data and so much more.
              </li>
            </p>
            <p>
              <li>
                <span span class="font-bold">
                  Post Incorporation services:
                </span>{" "}
                Do you need to make any changes to your business/company
                registration with CAC or file Annual returns? We’ve got you
                covered at The Business Hub - from generating Tax Identification
                Number to upgrading your business registration category to
                changing your shareholders details to filing annual returns to
                keep your business as Active with the CAC. We can help you
                handle them while you focus on the core of your business. The
                best part?
              </li>
            </p>
            <ul>I. No paper work – just details</ul>
            <ul>II. Quick processing time</ul>
            <li>
              <span span class="font-bold">
                Business Advisory:
              </span>{" "}
              Stay informed and get ahead with vital information for business
              growth via our newsletters, webinars and customer forums.
            </li>
            <li>
              <span span class="font-bold">
                Co-shared spaces for business:
              </span>{" "}
              Book or rent a space to work or schedule our meeting room for your
              next meeting or training. Our workspace at The Business is
              equipped for you to work alone or with others.
            </li>
          </p>
        </div>
      </section>
    </div>
  );
};
					{/* <p className="terms">
            These Terms and Conditions is a contract between you and Sterling
            Bank Plc (“Sterling”) governing your use of your OneBank account and
            the services made available to you on OneBank.
          </p>
          <p className="terms">
            OneBank is a digital banking solution (“OneBank” or “Platform”),
            developed and operated by Sterling Bank Plc (“Sterling”) and offered
            via web (“Website”) and mobile Application (“App”).
          </p>
          <p className="terms">
            Please read these Terms and Conditions ("Terms") carefully before
            accessing or using the Platform. These terms govern and apply to
            your access and use of the suite of services (“Services”) on
            OneBank. By accessing and using the Platform, you agree to be bound
            by these Terms and all other terms and policies that apply to any
            account or service you can access using the Platform (collectively
            referred to as “Terms”). If you do not wish to be bound by any of
            these Terms, you may not use the Platform or its services.
          </p>

          <h4>DEFINITION OF TERMS</h4>
          <p className="terms">In this document:</p>
          <p className="terms">
            "<b>We</b>","<b>Us</b>" and "<b>Our</b>" means Sterling Bank Plc
          </p>
          <p className="terms">
            "<b>You</b>","<b>Your</b>","<b>Yours</b>" or "<b>User</b>" means the
            person who has downloaded the Platform and is using the Platform.
          </p>
          <p className="terms">
            "<b>ATM</b>" means Automated Teller Machine
          </p>
          <p className="terms">
            "<b>Card</b>" means a virtual, Credit or Debit card issued to you by
            us which is linked to an account and can be used to undertake
            payment transactions.
          </p>
          <p className="terms">
            "<b>Account</b>" means a Sterling Bank Plc personal account that can
            be accessed on the Platform.
          </p>
          <p className="terms">
            "<b>CBN</b>" means Central Bank of Nigeria
          </p>
          <p className="terms">
            "<b>Electronic Access Device</b>" means a personal computer,
            telephone, mobile phone, personal digital assistant or any other
            electronic device, including wireless devices that will allow you to
            access to the Platform.
          </p>
          <p className="terms">
            "<b>Electronic Access Device</b>" means a personal computer,
            telephone, mobile phone, personal digital assistant or any other
            electronic device, including wireless devices that will allow you to
            access to the Platform.
          </p>
          <p className="terms">
            "<b>Fees</b>" You may pay fees when you make transactions/transfers,
            these fees are displayed on the Platform before any transaction is
            authenticated. You can also reach our call centre for details.
          </p>
          <p className="terms">
            "<b>Insufficient Funds</b>" If there are insufficient funds in an
            account, we will not act on your Payment instruction.
          </p>
          <p className="terms">
            "<b>Mobile number</b>" means the mobile phone number you have
            nominated to use on or with the device or devices you use to access
            the Platform.
          </p>
          <p className="terms">
            "<b>PIN/Password</b>" means the confidential personal identification
            number you select to identify yourself and to confirm Transactions
            on the Platform or conjunction with a card.
          </p>
          <p className="terms">
            "<b>Prohibitions on Use</b>" Activities which are not permitted on
            the Platform such as the use of your account/wallet for an illegal,
            fraudulent, malicious or defamatory purpose.
          </p>
          <p className="terms">
            "<b>Password and PIN Security</b>" Your Password, PIN and
            Identification Question must be unique and not easily guessed. You
            must always keep your Password, PIN and Identification Questions
            strictly confidential.
          </p>
          <p className="terms">
            "<b>Transaction</b>" means any loading of funds from a Funding
            Source to an account/wallet, any offloading of funds from an Account
            and any Payment.
          </p>
          <p className="terms">
            "<b>Transfer</b>" can be in the form of internal transfer of funds
            between your accounts, or to other beneficiaries with Sterling or
            other banks.
          </p>

          <h4>SERVICES ON ONEBANK</h4>
          <p className="terms">
            The following services and more are available on OneBank. The
            Platform allows you to:
          </p>
          <ul>
            <li>
              View all your Sterling accounts and related services in a single
              log in (including but not limited to your current account, savings
              account, investments accounts).
            </li>
            <li>
              Check the available balances on your accounts and view or download
              recent transactions
            </li>
            <li>Make bill payments, buy airtime </li>
            <li>
              Transfers funds between your accounts within and outside Sterling,
              and to third party bank accounts
            </li>
            <li>Make foreign transfers</li>
            <li>Withdraw cash at the ATM using the cardless option </li>
            <li>
              Request for debit card and activate the card on the Platform
            </li>
            <li>Create virtual cards for online shopping </li>
            <li>
              Give us payment and other instructions on your bank account,
              including setting controls on your payment cards{" "}
            </li>
            <li>Access Specta loans of up to N5 Million Naira</li>
            <li>Invest anytime and double your money with Doubble </li>
            <li>Open additional accounts </li>
            <li>Deposit cheques</li>
            <li>Set your transaction limits </li>
            <li>Hide Balance</li>
            <li>
              Additional services may be introduced periodically and same shall
              be accessible on the Platform to Users.
            </li>
          </ul>

          <h4>THE APP TERMS</h4>
          <p className="terms">
            We grant you a non-exclusive, non-transferable, royalty-free license
            to use the App and software embedded in it to open and manage your
            OneBank account. This license will terminate immediately when you
            delete the App.
          </p>
          <p className="terms">
            OneBank application is available on iOS, and Android OS devices.
          </p>
          <p className="terms">
            We may update the App to add and improve its functionality.
            Depending on the update, you may not be able to use the App until
            you have downloaded the latest version and accepted any new or
            additional terms. Also, if you do not (or you do not upgrade your
            phone’s operating system to the latest version available), certain
            features of the App may not work as intended. We are not responsible
            for the performance of the App on your phone if you do not update
            the App or your phone’s operating system to the latest version
            available.
          </p>
          <p className="terms">
            The App is provided for convenience. You acknowledge that the App
            (and the services/products provided through the App) are dependent
            upon third party services, which may impact its availability to you.
            We try to ensure that the App services are always available to you,
            we however do not promise that it will always be available or always
            work perfectly. Downtime may arise in cases of maintenance, fraud,
            or a fault in the systems used to provide the App. We do not
            guarantee the duration of a system downtime as these events are
            sometimes outside of our control.
          </p>

          <h4>ELIGIBILITY TO USE THE PLATFORM</h4>
          <p className="terms">
            We are legally required to get to know you before we can open an
            account for you. That’s why we ask you for your personal details and
            documents during our registration process.
          </p>

          <p className="paragraph">
            To be eligible to use the Platform you must be :
          </p>
          <ul>
            <li>
              18 years or above. If you are under 18 and you wish to access or
              use the OneBank suite of services via web or App, your parents or
              legal guardian must consent to our Terms, otherwise, you shall
              immediately discontinue its use. We also reserve the right to
              discontinue your use of the Service if you are less than 18 years
              and have not gotten the consent of your parents or legal guardian;
              and
            </li>
            <li>
              physically located or are a resident of the State or Country you
              have chosen as your current residency when creating your OneBank
              Account.
            </li>
          </ul>

          <h4>ACCOUNT ENROLLMENT</h4>
          <p className="terms">
            To access the Services on the Platform, firstly, you need to enroll
            either via web or mobile App to create an individual profile (“One
            Bank Account”), by providing certain information such as your name,
            email address, mobile device number, online credentials for your
            Bank Account, and such other information as we may request from time
            to time (collectively, "User Information").
          </p>
          <p className="terms-yellow-bg">
            “If a breach is associated with the operation of your
            account/wallet, you agree that we have the right to apply
            restrictions to your account/wallet and report to appropriate law
            enforcement agencies in line with extant laws”
          </p>
          <p className="terms">
            <b>• Web Account Opening</b>
          </p>
          <p className="terms">
            You may open an account through the web, by following the
            instructions and providing the information requested by us. The
            account opening will be subject to the Bank’s processes and reviews
            which may require you to provide further confirmation or documents.
            You agree to comply with the standard account opening documentation
            requirements and to meet KYC requirements as we may request.
          </p>
          <p className="terms">
            <b>• Opening an Account through the App</b>
          </p>
          <p className="terms">
            Download and install the OneBank app, follow the registration
            process and provide the information requested. The App is available
            on iOS and android.
          </p>
          <p className="terms">
            If you are a minor, your legal representative is legally responsible
            for your actions. That’s why we are required to ask him or her to
            approve your registration. To gain approval we will send an approval
            request to your legal representative.
          </p>

          <h4>Your obligation to provide correct information</h4>
          <p className="terms">
            In exchange for your use of the Platform. You agree to: (i) provide
            true, accurate, current and complete information about yourself as
            prompted by our Account enrolment form and (ii) each time you log
            on, maintain and promptly update such Account information to keep it
            true, accurate, current and complete. If you provide any information
            that is false, inaccurate, not current, or incomplete, or we have
            reasonable grounds to suspect that such Account information is
            false, inaccurate, not current or incomplete, we reserve the right
            to refuse any future request(s) to use the Platform.
          </p>

          <h4>Verification of your Information</h4>
          <p className="terms">
            We may confidentially check the information you give us or obtain
            information on you ourselves or through third parties from secure
            databases. By entering into this Agreement, you consent to us or a
            third party on our behalf carrying out these checks and obtaining
            any more information we might need to set up your OneBank Account.
          </p>

          <h4>Charges</h4>
          <p className="terms">
            We do not charge for accessing the Platform, however, your mobile
            network provider may charge you to access the Platform. You are
            responsible for the charges and to review any applicable fees
            chargeable by your mobile network provider to access and operate the
            Platform.
          </p>

          <h4>WE RESPECT YOUR PRIVACY</h4>
          <p className="terms">
            We take the privacy of our Users very seriously. You understand that
            by using the Services, you consent to the collection, use, storage,
            and disclosure of your information as set forth in our Privacy
            Policy.
          </p>

          <h4>CONDITIONS FOR USING THE PLATFORM</h4>
          <ul>
            <li>
              You may only use the Services for your own personal,
              non-commercial use and not on behalf of or for the benefit of any
              third party.
            </li>
            <li>
              Your use of the Services must comply with all applicable laws. If
              your use of this Service is prohibited by applicable law, then you
              are not authorized to use the Services. We are not responsible if
              you use the Services in any manner that violates applicable
              law(s).
            </li>
            <li>
              You agree not to authorize any other person or entity to use your
              username and password or electronic access device to access the
              Services.
            </li>
            <li>
              You are solely responsible for the maintenance, confidentiality,
              and security of your username, password, and other User
              Information.
            </li>
            <li>
              Except as otherwise required by applicable law, you are
              responsible for all transactions and other activities authorized
              or performed using your username and password or electronic access
              device, whether authorized or unauthorized by you.
            </li>
            <li>
              Except as otherwise expressly stated in these Terms or required by
              applicable law, we are not responsible for any losses arising out
              of the loss or theft of your User Information or your electronic
              access device or from unauthorized or fraudulent transactions
              associated with your OneBank Account or Bank Account(s).
            </li>
            <li>
              If you suspect or become aware of any unauthorized activity or
              access to your username, password, or electronic access device,
              you must contact us immediately at +234 07008220000 or
              customercare@sterling.ng
            </li>
          </ul>

          <h4>YOUR RESPONSIBILITIES</h4>
          <p className="terms">
            We have compiled a list of things you need to do to help us keep
            your OneBank account safe:
          </p>
          <ul>
            <li>
              It is your sole responsibility to keep your OneBank account safe:
              Security Details, PIN and any part of your account security should
              not be shared with anybody else or carelessly stored or disclosed
              under any circumstance.
            </li>
            <li>
              If you leave your Electronic Access Device without logging out,
              write your PIN down in a public space or share your details with
              anybody, We will not be liable for any errors, or results that may
              come of it.
            </li>
            <li>
              We will always use all reasonable efforts to keep the Platform and
              service safe, and your security settings will help us narrow it
              further down. However, if these issues arise due to carelessness
              with any part of your app or account security, there is very
              little we can do, and we will be relieved of any liability as
              regards the occurrence of a breach
            </li>
            <li>
              If you believe or reasonably suspect that there is a fraudulent
              act, we haven’t caught (which should be easy since you get all
              notifications), it is your duty to notify us immediately. We will
              then investigate and report back to you through the most secure
              channel you provide to us.
            </li>
            <li>
              You are required to change your security options if you believe
              any part of your security has been compromised.
            </li>
            <li>
              Where you notify us of your intention to change your password, PIN
              or security options, we will, with your approval, delete these
              details and allow you set up new ones.
            </li>
            <li>
              After initial registration, we will not contact you (or ask anyone
              to do so on our behalf) with a request to disclose your log in
              details or transaction password/PIN. If you receive any such
              request from anyone (even if they are using our name and logo and
              appear to be genuine) then it is likely to be fraudulent and you
              must not provide your log in details to them under any
              circumstances. Also, you should report any such request to us
              immediately.
            </li>
            <li>
              Upon your registration or approval of certain services, you give
              us permission to deduct any fees we communicate or notify you on
              any transaction or service. If these fees change, you will be
              notified before consummating any transaction and given the option
              to opt out of this service.
            </li>
          </ul>

          <h4>YOUR RESPONSIBILITY IN A PAYMENT TRANSACTION</h4>
          <p className="terms">
            When you initiate a payment by entering the required details on
            OneBank, you explicitly consent and authorize us to make the payment
            to the recipient you have provided. It is your responsibility to
            check all details are accurate as Sterling cannot validate or check
            these details. If you are initiating payments related to purchasing,
            then the relationship for that purchase remains between you and the
            relevant supplier – Sterling is in no way responsible for that
            purchase, or any terms set by that supplier. If you think that a
            payment initiated through OneBank may have been incorrect,
            unauthorized, or not properly executed (perhaps due to delay or
            other error), then you need to: contact us immediately on
            customercare@sterling.ng so that we can investigate and stop the
            payment if possible.
          </p>

          <h4>USE OF THE WEBSITE</h4>
          <p className="terms">
            The information provided on the OneBank website or in any
            communication containing a link to the site is for personal use and
            information purposes only and is not intended for distribution to,
            or use by, any person or entity in any jurisdiction where such
            distribution or use is contrary to the applicable laws of that
            jurisdiction.
          </p>
          <p className="terms">
            The OneBank web page is designed to be accessed through its
            principal home page or any our affiliate pages which we may from
            time determine, if you access the site through third party pages you
            agree and understand that we shall not be liable for the
            authenticity of the site accessed through such pages.
          </p>
          <p className="terms">
            The information contained on the website is subject to, and must be
            read in conjunction with, all other publicly available information,
            including, where relevant, any other document published by us. We
            make no guarantee as to the accuracy of any information available to
            you on the Platform.
          </p>

          <h4>UPLOADING CONTENT TO THE PLATFORM</h4>
          <p className="terms">
            We may have features on the Platform that allows you to upload
            content to either the App or website, you must comply with the
            content standards set out in these Terms.
          </p>
          <p className="terms">
            You warrant that any such contribution does comply with those
            standards, and you will be liable to us and indemnify us for any
            breach of that warranty.
          </p>
          <p className="terms">
            Any content you upload to our site will be considered
            non-confidential and non-proprietary. You retain all of your
            ownership rights in your content, but you are required to grant us
            and other users of our site a limited license to use, store and copy
            that content and to distribute and make it available to third
            parties.
          </p>

          <h4>NO FINANCIAL ADVICE</h4>
          <p className="terms">
            We do not purport to provide any financial, investment or
            professional advice and nothing on the pages of the OneBank website
            or the App nor shall any of the services available on OneBank be
            deemed to constitute the offer or provision of financial, investment
            or other professional advice in any way. In particular, you should
            obtain advice from a qualified financial advisor before making any
            investment decision.
          </p>

          <h4>PROHIBITIONS ON USE OF THE PLATFORM</h4>
          <p className="terms">
            Below are examples of User content and/or use of the Platform that
            is illegal or prohibited. This list is not exhaustive, and we
            reserve the right to restrict access to and/or investigate any User
            who, in our sole discretion, violates any of the terms or conditions
            of these Terms.
          </p>
          <p className="terms">
            In view of the foregoing, you understand and agree:
          </p>
          <ul>
            <li>
              not to violate any law, regulation, industry requirement, or
              third-party guidelines or agreements by which you are bound,
              including those of payment card providers and transaction
              processors that are utilized in connection with the Services;
            </li>
            <li>
              not to provide information or carry out transactions that are
              fraudulent, misleading, inaccurate, or dishonest;
            </li>
            <li>
              not to fund a ransom, human trafficking or exploitation,
              vigilantism, bribes or bounty;
            </li>
            <li>
              not to interfere with or disrupt servers or networks connected to
              the Services, or disobey any requirements, procedures, policies or
              regulations of networks connected to the Services;
            </li>
            <li>
              not to harvest, collect or publish personally identifiable
              information of others;
            </li>
            <li>
              not to use another User's account or URL without permission,
              impersonate any person or entity, falsely state or otherwise
              misrepresent your affiliation with a person or entity;
            </li>
            <li>
              not to use the Platform in a way that could damage it or stop it
              from working or affect our systems or other users – for example,
              by hacking into or inserting malicious code into the OneBank App
              (whether IOS, Android or other operating systems).
            </li>
          </ul>
          <p className="terms">
            Any breach of these Terms may result in the immediate revocation of
            the access granted to you without prior notice. Should we determine
            at our sole discretion that you are in breach, we reserve the right
            to deny you access to the Platform and Services, and this is without
            prejudice to any available remedies at law or otherwise.
          </p>

          <h4>AVAILABILITY OF THE PLATFORM </h4>
          <p className="terms">
            While we use our best endeavor to ensure the Platform and Services
            are available optimally, we make no warranty that any part of the
            Platform will operate uninterrupted or error free and we accept no
            liability for loss or damage caused from any interruption or error
            on any part of the Platform.
          </p>
          <p className="terms">
            We do not guarantee secure, continuous, uninterrupted access to any
            part of the OneBank services, web or mobile, including any networks
            and servers used to provide any of the services stated herein.
            Accordingly, we are not responsible for the matters, which include
            actions of hackers and other unauthorized third parties that breach
            our reasonable security procedure. No method of electronic
            transmission or storage is 100% secure. Therefore, we cannot
            guarantee absolute security of the Platform.
          </p>
          <p className="terms">
            We will also not be liable for any failure to provide services, in
            part or full, due to abnormal or unforeseen circumstances beyond our
            control, the consequences of which would have been unavoidable
            despite all efforts to the contrary. This includes but is not
            limited to phone network failures, or in the case of mobile
            networks, when you are in not in an area of mobile coverage.
          </p>
          <p className="terms">
            We reserve the right to remove a service from the Platform with no
            obligation to give advance notice, and we shall not be liable for
            losses, costs or expenses arising from any such refusal or removal.
            You assume the risks associated with the use of the Platform.
          </p>

          <h4>SUSPENSION OF SERVICES OR YOUR ONEBANK ACCOUNT</h4>
          <p className="terms">
            We reserve the right to suspend your usage of the Platform or any of
            the Services immediately and without advance notice;
          </p>
          <ul>
            <li>In order to maintain security</li>
            <li>You have breached the terms and conditions</li>
            <li>You give us false information at any time</li>
            <li>We suspect fraud or an attempt at fraud</li>
            <li>If there is suspicious activity on your account</li>
            <li>
              You have not satisfied any anti-money laundering requirements
            </li>
            <li>You are not eligible or entitled to download the Platform </li>
            <li>You have broken the law or attempt to break the law</li>
            <li>
              We receive notice of your mental incapacity, bankruptcy or death
            </li>
            <li>
              There are system maintenance issues which need to be addressed
              promptly
            </li>
            <li>New upgrades are being introduced to the Platform</li>
            <li>
              Your mobile banking app is withdrawn by the manufacturer of your
              mobile device operating system or if we stop supporting the
              Platform on your mobile device or operating system
            </li>
            <li>
              If you stop holding any account, product or service in respect of
              which the Platform maybe used
            </li>
            <li>We have legal obligations to do so</li>
          </ul>
          <p className="terms">
            We will attempt to notify you, except it would compromise our
            security measures, or it is unlawful to do so, or impracticable
            within the circumstances.
          </p>

          <h4>CLOSING YOUR ONEBANK ACCOUNT</h4>
          <p className="terms">
            You can deactivate or close your OneBank account any time you want.
            If you’d like to, please contact us (see how to contact us section).
            We will not charge you for cancellation and if you chose to stop
            using the Platform, you should ensure you uninstall the OneBank
            mobile application if you accessed the Platform via the mobile App.
          </p>

          <h4>INTELLECTUAL PROPERTY</h4>
          <ul>
            <li>
              We are the owner or the licensee (as applicable) of all the
              content available and intellectual property rights on the
              Platform, and any material published on it and same are protected
              by copyright, trademark, patent, trade secret and other
              intellectual property law. All such rights are reserved.
            </li>
            <li>
              You agree to retain all copyright and trademark notices, including
              any other proprietary notices, contained in the materials, and not
              to alter, obscure or obliterate any of such notices.
            </li>
            <li>
              You agree not to reproduce, re-transmit, distribute, disseminate,
              sell, publish, broadcast, or circulate the content received
              through the Service to any third party. We and/or our licensors
              own the copyright to all software and accompanying documentation
              made available for download from the Platform. Any copy made of
              information obtained from or through the Platform must include all
              applicable copyright notices.
            </li>
            <li>
              The license granted herein does not permit you to, and you agree
              not to: (i) modify, translate, reverse engineer, disassemble,
              decompile or create derivative works of the Service or allow a
              third party, whether directly or indirectly (including, but not
              limited to the direct or indirect use of wizards, agents, bots, or
              other utilities), to modify, translate, reverse engineer,
              disassemble, decompile or create derivative works of the Service;
              or (ii) transfer, distribute, sell, lease, rent, disclose or
              provide access to the Service to any third party or use the
              Service to provide service bureau, time sharing or other services
              to third parties. We reserve all rights not expressly granted to
              User.
            </li>
          </ul>

          <h4>DISCLAIMERS</h4>
          <ul>
            <li>
              The Platform may be under constant upgrades, and some functions
              and features may not be fully operational during periods of
              upgrade or software maintenance.
            </li>
            <li>
              We disclaim any liability arising due to the vagaries that can
              occur in the electronic distribution of information.
            </li>
            <li>
              You acknowledge that third party services are available on the
              Platform. We may have formed partnerships or alliances with some
              of these third parties from time to time in order to facilitate
              the provision of certain services to you. However, you acknowledge
              and agree that at no time are we making any representation or
              warranty regarding any third party's services nor will we be
              liable to you or any third party for any consequences or claims
              arising from or in connection with such third party including, and
              not limited to, any liability or responsibility for, death, injury
              or impairment experienced by you or any third party. You hereby
              disclaim and waive any rights and claims you may have against us
              with respect to third party’s services.
            </li>
            <li>
              We do not, in any way, endorse any information or service offered
              or described on the Platform. In no event shall we be liable to
              you or any third party for any decision made or action taken in
              reliance on such information
            </li>
            <li>
              You assume all responsibility and risk with respect to your use of
              the Platform. The services are provided and available “as is,” and
              “as available”. You understand and agree that, to the fullest
              extent permitted by law, we disclaim all warranties,
              representations and endorsements, express or implied, with regard
              to the Platform, including, without limitation, implied warranties
              of title, merchantability, non-infringement and fitness for a
              particular purpose
            </li>
            <li>
              We do not warrant use of the Platform will be uninterrupted or
              error-free or that errors will be detected or corrected. We do not
              assume any liability or responsibility for any computer viruses,
              bugs, malicious code or other harmful components, delays,
              inaccuracies, errors or omissions, or the accuracy, completeness,
              reliability or usefulness of the information disclosed or accessed
              through the services.
            </li>
            <li>
              In no event, under no legal or equitable theory (whether tort,
              contract, strict liability or otherwise), shall we or any of our
              respective employees, directors, officers, agents or affiliates,
              be liable hereunder or otherwise for any loss or damage of any
              kind, direct or indirect, in connection with or arising from the
              use of the Platform / services or our agreement with you
              concerning the services, including, but not limited to, (i) the
              use of or inability to use the Platform, the service, or the
              content, (ii) any transaction conducted through or facilitated by
              the Platform; (iii) any claim attributable to errors, omissions,
              or other inaccuracies in the Platform, the service and/or the
              content, (iv) unauthorized access to or alteration of your
              transmissions or data, or (v) any other matter relating to the
              Platform, the service, or the content, and any compensatory,
              direct, consequential, incidental, indirect, special or punitive
              damages, lost anticipated profits, loss of goodwill, loss of data,
              business interruption, accuracy of results, or computer failure or
              malfunction, even if we have been advised of or should have known
              of the possibility of such damages.
            </li>
          </ul>

          <h4>MODIFICATIONS TO THESE TERMS </h4>
          <ul>
            <li>
              We may change these terms from time to time. This is solely at our
              discretion and changes will be effective when posted on the
              Platform with no other notice provided. Please check the Terms
              regularly for updates as changes shall be effective immediately,
              and we may indicate the date of such revision.
            </li>
            <li>
              In addition, we may change, suspend or discontinue any aspect of
              the Services at any time, including the availability of any
              feature, or content. We may also impose limits on certain features
              and service or restrict your access to parts or all of the
              Platform (either on the web or via the App) or the Services
              provided without notice or liability.
            </li>
            <li>
              You are free to decide whether or not to accept a revised version
              of these Terms, but accepting these Terms, as revised, is required
              for you to continue accessing or using the Platform and Services.
              If you do not agree to these Terms or any revised version of these
              Terms, your sole recourse is to terminate your access or use of
              the Services. Except as otherwise expressly stated by us, your
              access and use of the Services are subject to, and constitute your
              acceptance of, the version of these Terms in effect at the time of
              your access or use.
            </li>
          </ul>

          <h4>OTHER THINGS YOU SHOULD KNOW ABOUT THE SERVICES</h4>
          <div>
            <h5>Additional Terms.</h5>
            <p className="terms">
              In conjunction with your access or use of the Platform, you may be
              subject to additional terms, rules, policies, and conditions that
              are posted on the Platform.
            </p>

            <h5>Acceptance of Electronic Documents</h5>
            <p className="terms">
              You agree that all agreements, notices, disclosures, records, and
              other communications for all services provided to you under these
              Terms and in connection with your relationship with us
              (collectively, "Communications") that we provide to you
              electronically satisfy any legal requirement that such
              communications be in writing.
            </p>

            <h5>Force Majeure</h5>
            <p className="terms">
              To the fullest extent permitted under applicable law, we will be
              excused from performance under these Terms for any period that we
              are prevented from or delayed in performing any obligations
              pursuant to this Terms, in whole or in part, as a result of a
              Force Majeure Event. For purposes of this section, “Force Majeure
              Event” means an event or series of events caused by or resulting
              from any of the following: (a) weather conditions or other
              elements of nature or acts of God; (b) acts of war, acts of
              terrorism, insurrection, riots, civil disorders or rebellion; (c)
              quarantines or embargoes, (d) labor strikes; (e); (f) unauthorized
              access to our information technology systems by third parties; or
              (g) other causes beyond our reasonable controland contemplation.
            </p>

            <h5>Assignment</h5>
            <p className="terms">
              You may not transfer, assign, or delegate these Terms or your
              rights or obligations hereunder or your OneBank Account in any way
              (by operation of law or otherwise). To the extent permitted by
              applicable law, we may transfer, assign, or delegate these Terms
              and our rights and obligations hereunder without your consent.
            </p>

            <h5>Indemnity</h5>
            <p className="terms">
              You agree to defend, indemnify, and hold harmless Sterling Bank
              plc, its employees, officers, directors, agents, its affiliates
              and third-party service providers from and against any and all
              claims, suits, liabilities, damages (actual and consequential),
              losses, fines, penalties, costs, and expenses (including
              reasonable attorneys’ fees) arising from or in any way related to
              any claims relating to your use of the Services, violation of
              these Terms, applicable law or any third-party rights or claims,
              or your fraud or willful misconduct. Such indemnified parties
              reserve the right to assume the exclusive defense and control of
              any matter subject to indemnification by you, in which event you
              will cooperate in asserting any available defenses.
            </p>

            <h5>Limitation of Liability</h5>
            <p className="terms">
              Under no circumstances shall we, our licensors or any third party
              content/ service provider be liable for any punitive, exemplary,
              consequential, incidental, indirect or special damages (including,
              without limitation, any personal injury, loss profits, business
              interruption, loss data or otherwise) arising from or in
              connection with the performance of the services, your use of or
              inability to use the services, whether by a breach of contract,
              negligence, strict liability, malpractice or otherwise, even if
              such party has been advised of the possibility of such damages.
            </p>

            <h5>Waiver</h5>
            <p className="terms">
              No waiver by us of any term or condition set forth in these Terms
              shall be deemed a further or continuing waiver of such term or
              condition or a waiver of any other term or condition, and any
              failure by us to assert a right or provision under these Terms
              shall not constitute a waiver of such right or provision.
            </p>

            <h5>Severability </h5>
            <p className="terms">
              If any of this terms is judicially determined to be illegal,
              unenforceable, or invalid in whole or in part for any reason, such
              illegal, unenforceable, or invalid provisions or part(s) of the
              same shall be stricken from this terms, and such provision shall
              not affect the legality, enforceability, or validity of the
              remainder of this terms. The stricken provision maybe replaced, to
              the extent possible, with a legal, enforceable, and valid
              provision that is as similar in term to the stricken provision as
              is legally possible.
            </p>

            <h5>Dispute Resolution</h5>
            <p className="terms">
              We will use our best efforts to resolve any issue that may arise
              from the use of the Platform. However, we realize that there may
              be rare cases where we may not be able to resolve an issue to your
              satisfaction. In the event we cannot resolve a dispute between us,
              you agree that all matters related to any use or access to the
              Platform or the services shall be referred to Mediation.
            </p>

            <h5>Governing Law</h5>
            <p className="terms">
              These Terms and your use of the Platform shall be governed by the
              laws of the Federal Republic of Nigeria, without giving effect to
              the principles of conflict of laws.
            </p>

            <h5>Contact Us</h5>
            <p className="terms">
              We appreciate it greatly if you notify us of any inquiries or
              issues regarding the Platform or these Terms, because this gives
              us the opportunity to seek a suitable solution. It also gives us
              the opportunity to improve the service/products offered to you and
              other customers. We also have an internal complaints procedure to
              effectively and promptly address any complaints.
            </p>
            <p className="terms">
              You may reach us by sending a mail to customercare@sterling.ng and
              include the following information: your name, telephone number and
              a description of your concern or calling us on +234 07008220000
            </p>
          </div>

          <div className="part-b">PART B</div>
          <div className="sub-title">
            SPECIFIC TERMS FOR SOME OF OUR PRODUCTS & SERVICES
          </div>
          <h4>MOBILE CHEQUE DEPOSIT</h4>
          <p className="terms">
            You can now use the Platform to deposit your cheques into your Bank
            account. This works by inserting the required cheque details on the
            cheque deposit page and scanning the physical cheques for the image
            to be captured on the Platform
          </p>

          <h4>Depositing Cheques using this service</h4>
          <p className="terms">By using this service, you agree that</p>
          <ul>
            <li>
              You’ll only submit valid cheques which you are legally entitled to
              cash and which meet the standards we tell you
            </li>
            <li>All information given is accurate and true</li>
          </ul>

          <h4>We are unable to accept and process;</h4>
          <ul>
            <li>
              Cheques that are payable to another person (i.e. cheques not in
              your name)
            </li>
            <li>Cheques that contain obvious alterations or are suspicious</li>
            <li>Cheques that were previously deposited using the Service</li>
            <li>Post dated cheques (not yet valid for payment)</li>
            <li>Cheques dated more than 6 months before that deposit date</li>
            <li>Cheques that breach the Service</li>
            <li>Cheques that exceed our deposit limits</li>
            <li>Any other cheques we inform you about periodically</li>
          </ul>

          <h4>Ensure the cheque has accurate details i.e.</h4>
          <ul>
            <li>
              Date – Not less than 6 months before the date of deposit and must
              not be postdated as at date of deposit
            </li>
            <li>Drawer’s name and signature</li>
            <li>The amount in words and figures </li>
          </ul>

          <h4>Image Quality</h4>
          <p className="terms">
            Please ensure you submit a legible image that meets the standards we
            inform you for use of our Service. The image should be in focus and
            the 4 corners of the cheque must be visible when image is captured.
          </p>

          <h4>Authority to debit your account</h4>
          <p className="terms">
            You authorize us to debit your account(s) to take a cheque payment
            or deposit back in any of the following circumstances
          </p>
          <p className="terms">
            If you are not the correct payee or the name does not match with
            your bank account
          </p>
          <p className="terms">
            If we become aware that a cheque was incorrectly paid into your bank
            account
          </p>
          <p className="terms">
            If we credit your bank account but the cheque is unpaid (bounces)
          </p>
          <p className="terms">
            If the cheque has material errors or is missing any of the essential
            features
          </p>
          <p className="terms">
            If the cheque has already been paid, stopped or blocked by the time
            you submitted it
          </p>
          <p className="terms">
            If you are unable or unwilling to produce the original cheque on
            request, within the agreed period
          </p>

          <h4>AJO (GROUP CONTRIBUTIONS)</h4>
          <div className="sub-title">What is Ajo?</div>
          <p className="terms">
            Ajo is a savings scheme that allows for family or group of friends
            to participate in an agreed monthly contribution of a specific
            amount. Participants in this scheme can be invited by an initiator
            of the Ajo, however all participants must be registered users on
            OneBank and must accept the invitation request.
          </p>

          <div className="sub-title">
            What is the process of debiting participants accounts?{" "}
          </div>
          <p className="terms">
            There is a background process that does the debiting, the processor
            runs daily and would debit a participant on the day the participant
            is selected.
          </p>

          <div className="sub-title">
            How does the Platform handle unfunded participant’s account and
            resulting contribution?{" "}
          </div>
          <p className="terms">
            For unfunded accounts, the processor retries throughout the entire
            day selected by the participant, if the contribution fails to come
            in, the scheme continues AS IS and the benefactor for that cycle
            will get only the contributions that came in for that month. Dispute
            relating to the unfunded account would not be handled by Sterling,
            participants will resolve the differences or dispute without our
            involvement.
          </p>
          <p className="terms">
            Under no circumstance will we be liable or responsible for unfunded
            accounts. Neither do we guarantee payments of any kind. Our role is
            limited to debiting funded accounts which Participants have
            consented to as and when agreed and crediting the benefactors.
          </p>

          <div className="sub-title">
            Can someone be added without his or her knowledge?{" "}
          </div>
          <p className="terms">
            Although the Service permits the addition of participants by the
            creator through the email address, such an invited person is
            required to consent and accept before he becomes a valid
            participant. If no consent is provided, the invited person will not
            become a participant in the scheme.
          </p>

          <div className="sub-title">
            What is the process for dissolution before the completion of the Ajo
            duration?{" "}
          </div>
          <p className="terms">
            Once Ajo scheme starts there is currently no dissolution until the
            Ajo cycle is completed and all participants are bound to complete
            the cycle.
          </p>

          <h4>Responsibility</h4>
          <p className="terms">
            You acknowledge that we do not have any involvement in any
            arrangements that Users, Participants or group of Users make with
            each other through this Service. Therefore, we do not accept any
            responsibility for the Ajo (Group Contribution), nor do we warrant
            that any User of the service, will perform their roles as promised.
            The arrangements you make are solely private and the contracts
            relating to Pledges, Contributions under the Ajo scheme are made
            directly between you and the individual parties concerned.
            Accordingly, you agree and acknowledge that you take full
            responsibility for your arrangements with the relevant
            Users/Participants whom you contact and the nature, terms and extent
            of your arrangements with them and obligations to them.
          </p>

          <h4>Direct debit/Scheduled payments:</h4>
          <p className="terms">
            To set up a direct debit from your account on the Platform, you must
            first authorize the organization taking Direct Debit payments from
            your account.
          </p>
          <p className="terms">
            If you ask us to pay direct debits/subscriptions on a specified
            date, your payment will go out on the day you have indicated. And we
            will register the date you've chosen as the first official payment.
          </p>
          <p className="terms">
            It is your responsibility to check that your account is adequately
            funded before any payment is due. If your account has insufficient
            funds, your direct debit payment will be rejected.
          </p>

          <h4>CONTACTING US</h4>
          <p className="terms">
            If you have a questions, complaints or enquiries about your OneBank
            Account or any of the services provided on OneBank, you can contact
            us through the OneBank App or email us at………………………………… or by calling
            +234…………………….
              </p>*/}
			

export default ModalContent;
