import React from 'react'
import { Table } from 'react-bootstrap';

function table(branches) {

    return (
        <Table striped bordered hover size="sm">
            <thead>
                <tr>
                    <th>S/N</th>
                    <th>Branch</th>
                    <th>Region</th>
                    <th>Accounts Opened</th>
                </tr>
            </thead>
            <tbody>
                {branches.branches.slice(0, 6).map((branch, index) => {
                    return (
                        <tr>
                            <td>{index + 1}</td>
                            <td>{branch.branchName}</td>
                            <td>{branch.regionName}</td>
                            <td>{branch.totalCount}</td>
                        </tr>

                    )
                })}
            </tbody>
        </Table>
    )
}

export default table