import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import ImageCapture from "react-image-data-capture";
import Spinner from "react-bootstrap/Spinner";
import { Link } from "react-router-dom";
import { startHyperverge } from "../../../../../../hpv";
import UploadIcon from "../../../../../../Images/upload-icon.svg";

const DocumentUpload = ({
  handleFormChange,
  formValues,
  onDrops,
  signatureUpload,
  onCapture,
  config,
  onError,
  imgSrc,
  retake,
  branchCode,
  tcHandler,
  handleShowTC,
  handler,
  isClicked,
  uploadPic,
  onDropss,
  startHyperverge,
  hpvImage,
  isLoadingHpvSdk,
}) => {
  const MAX_SIZE = 2097152;
  return (
    <div>
      <form action="">
        <div className="my-4 text-center text-body">
          <h5 className="mb-0 sec-header">Document upload</h5>
          <h6 className="mb-0 fw-regular ftsz-12">
            **The file format should be JPEG or PDF or PNG
          </h6>
        </div>
        <div className="row mb-3 mb-3">
          <div className="col-12 col-md-6 mb-3 ftsz-12 ">
            <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
              Upload signature{" "}
            </h6>
            <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
              <Dropzone
                onDrop={onDrops}
                multiple={false}
                accept="image/jpeg, image/png"
                maxSize={MAX_SIZE}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="doc-uploads">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} placeholder="" />

                      {!!signatureUpload.length &&
                        signatureUpload.map((file) => (
                          <p className="doc-texts">{file.path}</p>
                        ))}
                      {!signatureUpload.length && (
                        <section className="doc-texts">
                          Drag 'n' drop , or click to select <br />
                        </section>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {signatureUpload ? (
              <p className="my-3 font-weight-bold text-danger">
                {signatureUpload.path}
              </p>
            ) : (
              <p className="my-3">No file uploaded</p>
            )}
          </div>

          {/* {signatureUpload ? <textarea id="base64File" rows="30" cols="150" value={signatureUpload} readOnly></textarea> : null } */}

          <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="form-group">
              <label>Branch of account domiciliation.</label>
              <select
                className="form-control"
                onChange={handleFormChange("branch")}
                required
                value={formValues.branch}
              >
                {branchCode.map((branches) => (
                  <option value={branches.BranchCode} key={branches.BranchCode}>
                    {branches.BranchName}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="col-6 col-md-6 mb-3 ftsz-12 ">
            <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
              Take live picture
            </h6>
            <div className="col-6 col-md-6 mb-3 ftsz-12 ">
              <div
                style={{ width: "300px", cursor: "pointer" }}
                className="bb"
                onClick={() => {
                  startHyperverge(formValues.imgBvn);
                }}
              >
                {hpvImage !== "" && (
                  <img src={hpvImage} alt="avatar" style={{ width: "100%" }} />
                )}
                {hpvImage === "" && (
                  <div class="avatar-hpv">
                    <div class="avatar-text-hpv">
                      {isLoadingHpvSdk ? (
                        <>
                          Loading Sdk....{" "}
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </>
                      ) : (
                        "Please Click here to Take a Photo"
                      )}
                    </div>
                  </div>
                )}
                {/* <Link className="displayImage">Upload Image</Link> */}
              </div>
            </div>

            {/* <div className="col-6 col-md-6 mb-3 ftsz-12 ">
            <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
              Take live picture
            </h6>
            {imgSrc ? (
              ""
            ) : (
              <ImageCapture
                onCapture={onCapture}
                onError={onError}
                width={300}
                userMediaConfig={config}
              />
            )}
            {imgSrc && (
              <div>
                <div>Captured Image:</div>
                <img src={imgSrc} alt="captured-img" />
                <section className="text-right mt-2">
                  <Link className="" onClick={retake}>
                    Retake
                  </Link>
                </section>
              </div>
            )}
          </div> */}
            {/* {imgSrc ? (
              ""
            ) : (
              <ImageCapture
                onCapture={onCapture}
                onError={onError}
                width={300}
                userMediaConfig={config}
              />
            )}
            {imgSrc && (
              <div>
                <div>Captured Image:</div>
                <img src={imgSrc} alt="captured-img" />
                <section className="text-right mt-2">
                  <Link className="" onClick={retake}>
                    Retake
                  </Link>
                </section>
              </div>
            )} */}
          </div>
          <div className="col-lg-6 col-sm-6 col-md-6 col-xs-12 mb-3 ftsz-12">
            <h6 className="ftwt-500 ftsz-12 mb-1 text-body">Upload Picture </h6>
            <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
              <Dropzone
                onDrop={onDropss}
                multiple={false}
                accept="image/jpeg, image/png"
                maxSize={MAX_SIZE}
              >
                {({ getRootProps, getInputProps }) => (
                  <section className="doc-uploads">
                    <div {...getRootProps()}>
                      <input {...getInputProps()} placeholder="" />
                      {!!uploadPic.length &&
                        uploadPic.map((file) => (
                          <p className="doc-texts">{file.path}</p>
                        ))}

                      {!uploadPic.length && (
                        <section className="doc-texts">
                          Drag 'n' drop , or click to select <br />
                        </section>
                      )}
                    </div>
                  </section>
                )}
              </Dropzone>
            </div>
            {uploadPic ? (
              <p className="my-3 font-weight-bold text-danger">
                {uploadPic.path}
              </p>
            ) : (
              <p className="my-3">No file uploaded</p>
            )}
          </div>
        </div>

        <div className="form-row">
          <div className="col-12 my-2">
            <h6 className="ftwt-500 ftsz-12">Select value-added services</h6>
          </div>
          <div className="col-12 col-sm-5 mb-3">
            <div className="form-check">
              <input
                type="checkbox"
                className="form-check-input"
                onChange={(ev) => handler(ev.target.checked)}
              />
              <label className="form-check-label ftsz-regular text-body" for="">
                Debit Card (Verve)
              </label>
            </div>
          </div>
        </div>
        {/* {isClicked ? (
          <div>
            <p>Select your type of debit card</p>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="option1"
              />
              <label className="form-check-label" for="inlineRadio1">
                Verve
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="option2"
              />
              <label className="form-check-label" for="inlineRadio2">
                Mastercard
              </label>
            </div>
          </div>
        ) : (
          <div></div>
        )} */}
        <div className=" mb-3 mt-2">
          <div className="form-check">
            <input
              type="checkbox"
              className="form-check-input"
              onChange={(ev) => tcHandler(ev.target.checked)}
            />
            <label className="form-check-label ftsz-regular text-body" for="">
              By checking this box, you have accepted the{" "}
              <a
                className="text-red"
                style={{ color: "red", textDecoration: "underline" }}
                href="javascript:;"
                onClick={(e) => {
                  handleShowTC(e);
                }}
              >
                Terms and conditions
              </a>
            </label>
          </div>
        </div>
      </form>
    </div>
  );
};

export default DocumentUpload;
