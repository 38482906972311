import React, { useState } from "react";
import { Modal } from "antd";
import {
  GoogleMap,
  withGoogleMap,
  withScriptjs,
  Marker,
  InfoWindow,
} from "react-google-maps";
import { GiHamburgerMenu } from "react-icons/gi";

import styles from "../atmlocation.module.css";
import AtmStatus from "../atmStatus";

function Map({ atm, branch, atmLocations, branchLocations, defaultPosition }) {
  const [atmLocation, setAtmLocation] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  function renderMap() {
    if (Object.keys(defaultPosition).length !== 0) {
      return (
        <GoogleMap
          defaultZoom={13}
          defaultCenter={{
            lat: defaultPosition?.lat,
            lng: defaultPosition?.lng,
          }}
        >
          {branch && (
            <>
              {branchLocations.map((location) => (
                <Marker
                  key={location.id}
                  position={{ lat: location.latitude, lng: location.longitude }}
                  icon={{
                    scaledSize: new window.google.maps.Size(25, 30),
                    url: require("./google_marker_branch.png"),
                  }}
                  onClick={() => {
                    const loc = location;
                    setAtmLocation(loc);
                  }}
                />
              ))}
            </>
          )}
          {atm && (
            <>
              {atmLocations.map((location) => (
                <Marker
                  key={location.id}
                  position={{ lat: location.latitude, lng: location.longitude }}
                  icon={{
                    scaledSize: new window.google.maps.Size(40, 40),
                    url: require("./google_markers_icon.png"),
                  }}
                  onClick={() => {
                    const loc = location;
                    setAtmLocation(loc);
                  }}
                />
              ))}
            </>
          )}
          {atmLocation && (
            <InfoWindow
              position={{
                lat: atmLocation?.latitude,
                lng: atmLocation?.longitude,
              }}
              onCloseClick={() => setAtmLocation(null)}
            >
              <div>
                <h6>{atmLocation?.name}</h6>
                <p>{atmLocation?.address || atmLocation?.branchName}</p>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      );
    }
  }

  return (
    <>
      {renderMap()}
      <div className={styles.footerMap}>
        <GiHamburgerMenu onClick={showModal} />

        {atmLocation ? atmLocation?.name : "TAP A PIN"}
      </div>
      <Modal
        title="Search Atm's and Branches"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        style={{ padding: 0 }}
      >
        <AtmStatus
          branchLocations={branchLocations}
          atmLocations={atmLocations}
        />
      </Modal>
    </>
  );
}

const WrappedMap = withScriptjs(withGoogleMap(Map));

const MapsUI = (props) => {
  return (
    <div style={{ width: "100%", height: "100%" }}>
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCYpBr47FzkTG9_0O2VsbgibRpY12-M6B4`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `100%` }} />}
        mapElement={<div style={{ height: `100%` }} />}
        {...props}
      />
    </div>
  );
};
export default MapsUI;
