import { useState } from "react";

export default () => {
  const [showAgreeModal, setShowAgreeModal] = useState(false);
  const handleCloseAgreeModal = () => setShowAgreeModal(false);
  const handleShowAgreeModal = () => setShowAgreeModal(true);

  const [showDisAgreeModal, setShowDisAgreeModal] = useState(false);
  const handleCloseDisAgreeModal = () => setShowDisAgreeModal(false);
  const handleShowDisAgreeModal = () => setShowDisAgreeModal(true);

  const [showCommentModal, setShowCommentModal] = useState(false);
  const handleCloseCommentModal = () => setShowCommentModal(false);
  const handleShowCommentModal = () => setShowCommentModal(true);

  return [
    showAgreeModal,
    handleCloseAgreeModal,
    handleShowAgreeModal,

    showDisAgreeModal,
    handleCloseDisAgreeModal,
    handleShowDisAgreeModal,

    showCommentModal,
    handleShowCommentModal,
    handleCloseCommentModal
  ];
};
