import React from "react";
import "./styles.css"

const TrybeOne = () => {
  return (
    <div>
      <div id="newkiakia-tier1-req" className="">
        <div className="d-flex justify-content-between px-4 styling">
          <h5 className="sec-header mb-4">TrybeOne</h5>
          <div className="mb-4">
            <a href="https://trybeone.ng" target="_blank" className="pry-red-bg rounded-lg px-5 py-3 text-white">
              Proceed
            </a>
          </div>
        </div>
        <div className="row pry-grey-clr mb-4">
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Features</h6>
            <ul class="custom-req-marker pl-3">
              <li>Community access to a network of like-minded GenZs </li>
              <li>Zero balance account</li>
              <li>Free debit cards</li>
              <li>
                Access to the coolest events (block parties, career fairs etc.)
              </li>
              <li>
                Access to skill acquisition programs, business management and
                advisory sessions.
              </li>
              <li>Access to fun and cool content.</li>
              <li>Online statement.</li>
              <li>Email/SMS alert.</li>
              <li>Internet and mobile banking facility.</li>
              <li>International funds transfer allowed.</li>
            </ul>
          </div>
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Requirements</h6>
            <ul class="custom-req-marker pl-3">
              <li>
                Basic customer information. (i.e., Name, Place and date of
                birth, Gender, Address, Telephone number).
              </li>
              <li>BVN.</li>
              <li>Passport photograph.</li>
              <li>Signature.</li>
              <li>Valid ID/Admission letter/Student ID card</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TrybeOne;
