import { message } from "antd";
import { useState, useCallback, useMemo } from "react";

export default () => {
  const [imgSrc, setImgSrc] = useState(null);

  const [minorImage, setMinorImage] = useState("");
  const [livePhotoUpload, setLivePhotoUpload] = useState("");
  const [frontPage, setFrontPage] = useState("");
  const [frontPageURL, setFrontPageURL] = useState("");
  const [birthCert, setBirthCert] = useState("");
  const [birthCertURL, setBirthCertURL] = useState("");
  const [parentSignature, setParentSignature] = useState("");
  const [parentSignatureURL, setParentSignatureURL] = useState("");
  const [minorImageURL, setMinorImageURL] = useState("");
  const [uploadPic, setUploadPic] = useState("");
  const [uploadPicURL, setUploadPicURL] = useState("");

  //upload docs
  const onDropsss = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setUploadPic(file);
      const base64 = await convertBase64(file);
      setUploadPicURL(base64);
    }
  };

  const onDrops = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setMinorImage(file);
      const base64 = await convertBase64(file);
      setMinorImageURL(base64);
    }
  };

  const onDrop = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setBirthCert(file);
      const base64 = await convertBase64(file);
      setBirthCertURL(base64);
    }
  };

  const onDropp = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setFrontPage(file);
      const base64 = await convertBase64(file);
      setFrontPageURL(base64);
    }
  };

  const onDroppp = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setParentSignature(file);
      const base64 = await convertBase64(file);
      setParentSignatureURL(base64);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  //camera
  const onCapture = (imageData) => {
    setImgSrc(imageData.webP);
    setLivePhotoUpload(imageData.file);
  };

  // Use useCallback to avoid unexpected behaviour while rerendering
  const onError = useCallback((error) => {
    console.log(error);
  }, []);

  // Use useMemo to avoid unexpected behaviour while rerendering
  const config = useMemo(() => ({ video: true }), []);
  const livePic = new FormData();
  livePic.append("file", livePhotoUpload);

  //retake image
  const retake = () => {
    setImgSrc(null);
  };

  return [
    minorImage,
    livePhotoUpload,
    frontPage,
    onDrops,
    config,
    onError,
    imgSrc,
    retake,
    onDrop,
    onCapture,
    frontPageURL,
    minorImageURL,
    birthCert,
    birthCertURL,
    onDropp,
    parentSignature,
    parentSignatureURL,
    onDroppp,
    onDropsss,
    uploadPic,
    uploadPicURL,
  ];
};
