import { SET_WELCOME_TYPE } from "../types";
const INITIAL_STATE = {
  page: "main",
};

const WelcomeReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_WELCOME_TYPE:
      return {
        ...state,
        page: action.payload,
      };

    default:
      return state;
  }
};

export default WelcomeReducer;