import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import None from "./none";
import BusinessAccount from "./business/businessAccount";
import CompanyAccount from "./company/companyAccount";
import DangerLogo from "../../../../../Images/warning-icon.svg";
import { corporateAction } from "../../../../../redux/actions";

const MainContent = () => {
  const [drop, setDrop] = useState({
    account: "",
  });

  const dispatch = useDispatch();

  const handleChange = (name) => (event) => {
    setDrop({
      ...drop,
      [name]: event.target.value,
    });
    dispatch(corporateAction.set_account_type(event.target.value));
  };

  const { account_type } = useSelector(({ corporate }) => corporate);

  return (
    <div className="acct-req-selector-wrapper pb-5">
      <h1 className="pry-header mb-3">Corporate/Business Account</h1>
      <div className="pale-red-tone-bg pry-red-clr ftwt-600 ftsz-12 mb-5 px-3 py-2 mb-3 rounded-lg d-flex align-items-center">
        <img src={DangerLogo} className="mr-3" alt="" />
        <ul className="ml-3 mb-0 ftwt-600">
          <li className="custom-red-marker">
            For a seamless experience, please ensure your BVN information is up
            to date.
          </li>
          <li className="custom-red-marker">
            This service is available to only registered entities
          </li>
        </ul>
      </div>
      <h2 className="ftsz-12 mb-4 pry-grey-clr pb-3 border-bottom-pale2 ">
        Current
      </h2>
      <div className="mb-5">
        <label for="" className="ftsz-12 ftwt-500">
          Select account type
        </label>
        <select
          className="custom-select pale-red-tone-bg pry-input-border border-0"
          onChange={handleChange("account")}
          value={drop.account}
        >
          <option value="none">Select account type</option>
          <option value="business">
            Business account - (Registered Business (BNs))
          </option>
          <option value="company">
            Company account - (Limited companies (RCs))
          </option>
        </select>
      </div>
      <div className="">
        {account_type === "none" && <None />}
        {account_type === "business" && <BusinessAccount />}
        {account_type === "company" && <CompanyAccount />}
      </div>
    </div>
  );
};

export default MainContent;
