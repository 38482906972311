import React from "react";
import Header from "./Header/Header";
import MainContent from "./Content/MainContent";

const RefereesConsent = () => {
  return (
    <div>
      <div className="pale-red-part-bg position-fixed"></div>
      <div className="">
        <Header />
        <div className="container-fluid pb-5 mt-5">
          <div class="row mb-5">
            <div class="col-xl-7 col-lg-9 col-md-10 mx-auto">
              <div class="container">
                <div class="app-details-form-wrapper pry-grey-clr bg-white px-3 px-lg-5 py-5">
                  <div className="text-right"></div>
                  <div className="steps-action pb-5 mt-3 text-right">
                    <MainContent />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RefereesConsent;
