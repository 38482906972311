var CryptoJS = require("crypto-js");

export const key = "zAL7X5AVRm8l4Ifs";
export const IV = "BE/s3V0HtpPsE+1x";
export const secretKey = CryptoJS.enc.Utf8.parse(key);
export const intiVector = CryptoJS.enc.Utf8.parse(IV);

export const decryptHandler2 = (data) => {
  const dataHex = CryptoJS.enc.Hex.parse(data);
  const preDecrypted = CryptoJS.AES.decrypt(
    { ciphertext: dataHex },
    secretKey,
    { iv: intiVector, mode: CryptoJS.mode.CBC, keySize: 128 / 8 }
  );
  const encDecrypted = preDecrypted.toString(CryptoJS.enc.Utf8);
  const decrypted = JSON.parse(encDecrypted);
  return decrypted;
};