import { useState } from "react";

export default () => {
  //completed modal
  const [showComplete, setShowComplete] = useState(false);
  const handleCloseComplete = () => setShowComplete(false);
  const handleShowComplete = () => setShowComplete(true);

  //existing tin modal
  const [showExisting, setShowExisting] = useState(false);
  const handleCloseExisting = () => setShowExisting(false);
  const handleShowExisting = () => setShowExisting(true);

  //invalid tin modal
  const [showInvalid, setShowInvalid] = useState(false);
  const handleCloseInvalid = () => setShowInvalid(false);
  const handleShowInvalid = () => setShowInvalid(true);

  return [
    showComplete,
    handleCloseComplete,
    handleShowComplete,
    showExisting,
    handleCloseExisting,
    handleShowExisting,
    showInvalid,
    handleCloseInvalid,
    handleShowInvalid
  ];
};
