import React, { useState, useEffect } from 'react';
import { message, Steps } from 'antd';
import Header from './components/Header';
import TinCompany from './components/tin';
import UpdateDetails from './components/update-details';
import Directors from './components/directors';
import UploadDocsCom from './components/upload-doc';
import * as EmailValidator from 'email-validator';
import useModal from './hooks/useModal';
import {
  apiUrl,
  apiUrl1,
  apiUrl2,
} from '../../../../../../redux/actions/endpoint';
import axios from 'axios';
import { encrypt2 } from '../../../../../../encr';
import { decryptHandler2 } from '../../../../../../dec';
import moment from 'moment';
import useFiles from './hooks/useFiles';
import CompletedForm from './components/completed';
import TermsAndConditions from './components/modal/tc';
import useGoogle from './hooks/useGoogle';
import ReferenceCompany from './components/reference';
import AddRefOTP from './components/reference/Modals/AddRef';
import RefSuccessOTP from './components/reference/Modals/RefSucess';
import Spinner from 'react-bootstrap/Spinner';
import { encrypt1 } from '../../../../../../encc';
import PendingModal from '../../../../../NewCustomer/components/PendingModal';
import { apiAccount } from '../../../../../../service/apiAccount';
import { appUrls } from '../../../../../../service/urls';
import Tbh from './components/modal/tbh';
import { authOApi } from '../../../../../../service/authOApi';
const { Step } = Steps;

const CompanyAcc = () => {
  //stepper
  const [current, setCurrent] = React.useState(0);
  const prev = () => {
    setCurrent(current - 1);
  };

  const [isTinValidated, setIsTinValidated] = useState(false);

  const next = () => {
    setCurrent(current + 1);
    handleClosePendingModal();
  };

  const [dirList, setDirList] = useState([]);
  const [stateList, setStateList] = useState([]);

  const [dob, setDob] = useState(
    moment(new Date()).format('YYYY-MM-DDTHH:mm:ss.sssZ')
  );

  const [comboType, setComboType] = useState('combo-one');

  const [dataCustomerID, setDataCustomerID] = useState('');

  const [accountInfo, setAccountInfo] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [sectorList, setSectorList] = useState([]);
  const [industriesList, setIndustriesList] = useState([]);

  const [showDoc, setShowDoc] = useState(false);
  const [docText, setDocText] = useState('');

  const [showAddRefOTP, setShowAddRefOTP] = useState(false);
  const handleCloseAddRefOTP = () => setShowAddRefOTP(false);
  const handleShowAddRefOTP = () => setShowAddRefOTP(true);

  const [showRefSuccessOTP, setShowRefSuccessOTP] = useState(false);
  const handleCloseRefSuccessOTP = () => setShowRefSuccessOTP(false);
  const handleShowRefSuccessOTP = () => setShowRefSuccessOTP(true);

  const [showTC, setShowTC] = useState(false);
  const handleCloseTC = () => setShowTC(false);
  const handleShowTC = () => setShowTC(true);

  const [showTBH, setShowTBH] = useState(false);
  const handleCloseTBH = () => setShowTBH(false);
  const handleShowTBH = () => setShowTBH(true);

  const [isTCClicked, setIsTCClicked] = React.useState(false);
  const [isProceedClicked, setIsProceedClicked] = React.useState(false);
  const [fileUrl, setFileUrl] = useState('');
  const [tin, setTin] = useState('');

  const [isOpen, setIsOpen] = useState(false);
  const handleClosePendingModal = () => setIsOpen(false);
  const handleShowPendingModal = () => setIsOpen(true);

  const [address, setAddress, handleSelect] = useGoogle();

  const tcHandler = (value) => {
    setIsTCClicked(value);
  };

  // const [formValues, setFormValues] = useState({
  //   bvn: "",
  // });

  const [sec, setSec] = useState({
    sector_id: '',
    industry_id: '',
  });

  const handleSecChanges =
    (name) =>
    ({ target }) => {
      setSec({
        ...sec,
        [name]: target.value,
      });

      if (
        target.value == 6401 ||
        target.value == 6402 ||
        target.value == 6403 ||
        target.value == 5701 ||
        target.value == 4601 ||
        target.value == 4602 ||
        target.value == 4603
      ) {
        setShowDoc(true);
        if (target.value == 6401) {
          setDocText('Annual license to practice ');
        }
        if (target.value == 6402) {
          setDocText('Annual license to practice ');
        }
        if (target.value == 6403) {
          setDocText('Annual license to practice ');
        }
        if (target.value == 5701) {
          setDocText(
            'Evidence of membership to professional body(Call to bar cert., CITN, ICAN etc) '
          );
        }
        if (target.value == 4601) {
          setDocText(
            'State government approval/ National UNI commission approval '
          );
        }
        if (target.value == 4602) {
          setDocText(
            'State government approval/ National UNI commission approval '
          );
        }
        if (target.value == 4603) {
          setDocText(
            'State government approval/ National UNI commission approval '
          );
        }
      } else {
        setShowDoc(false);
      }
    };

  let secVal = sec.sector_id;
  // let industry_id = sec.industry_id;

  const [customerNumber, setCustomerNumber] = useState('');

  //handling form state change
  const handleFormChanges =
    (name) =>
    ({ target }) => {
      setFormValues({
        ...formValues,
        [name]: target.value,
      });
    };

  const onDateChange = (event) => {
    setDob(event.target.value);
  };
  const [branchCode, setBranchCode] = useState([]);

  // state to continue your account opening process
  const [savedProcessValues, setSavedProcessValues] = useState({});

  const [formValues, setFormValues] = useState({
    bvn: '',
    dobirth: '',
    branch: '',
    firstName: '',
    secondName: '',
    lastName: '',
    phone: '',
    email: '',
    houseNo: '',
    address: '',
    landmark: '',
    state: '',
    addressSummary: '',
    gender: '',
    imgBvn: '',
    cerpac: '',
    nationality: '',
    mandate: '',
    signature: '',
    scannedID: '',
    streetName: '',
    refAccount: '',
    imgBvn: '',
    refName: '',
    refMail: '',
    refSubject: '',
  });

  const [formValuess, setFormValuess] = useState({
    bvn: '',
    bvn_two: '',
    firstName: '',
    secondName: '',
    lastName: '',
    dobirth: '',
    phone: '',
    email: '',
    address: '',
    landmark: '',
    state: '',
    gender: '',
    imgBvn: '',
    nationality: '',
    cerpac: '',
  });

  const handleFormChangess =
    (name) =>
    ({ target }) => {
      setFormValuess({
        ...formValuess,
        [name]: target.value,
      });
    };

  const [refList, setRefList] = useState([]);

  const [cac, setCac] = useState('');
  const [cacURL, setCacURL] = useState('');
  const [co2, setCo2] = useState('');
  const [co2URL, setCo2URL] = useState('');
  const [co7, setCo7] = useState('');
  const [co7URL, setCo7URL] = useState('');
  const [meMart, setMeMart] = useState('');
  const [meMartURL, setMeMartURL] = useState('');
  const [board, setBoard] = useState('');
  const [boardURL, setBoardURL] = useState('');
  const [refr, setRef] = useState('');
  const [refURL, setRefURL] = useState('');
  const [others, setOthers] = useState('');
  const [othersURL, setOthersURL] = useState('');
  const [evidence, setEvidence] = useState('');
  const [evidenceURL, setEvidenceURL] = useState('');

  const [caCTwo, setCaCTwo] = useState('');
  const [caCTwoURL, setCaCTwoURL] = useState('');
  const [caC11, setCaC11] = useState('');
  const [caC11URL, setCaC11URL] = useState('');
  const [meMarTTwo, setMeMarTTwo] = useState('');
  const [meMarTTwoURL, setMeMarTTwoURL] = useState('');
  const [boarDTwo, setBoarDTwo] = useState('');
  const [boarDTwoURL, setBoarDTwoURL] = useState('');
  const [refRTwo, setReFTwo] = useState('');
  const [reFTwoURL, setReFTwoURL] = useState('');
  const [otherSTwo, setOtherSTwo] = useState('');
  const [otherSTwoURL, setOtherSTwoURL] = useState('');
  const [evidencETwo, setEvidencETwo] = useState('');
  const [evidencETwoURL, setEvidencETwoURL] = useState('');

  // const [cacExtension, setCacExtension] = useState("");
  // const [co2Extension, setCo2Extension] = useState("");
  // const [co7Extension, setCo7Extension] = useState("");
  // const [meMartExtension, setMeMartExtension] = useState("");
  // const [boardExtension, setBoardExtension] = useState("");
  // const [refrExtension, setRefrExtension] = useState("");
  // const [otherExtension, setOtherExtension] = useState("");
  // const [evidenceExtension, setEvidenceExtension] = useState("");
  // const [cacTwoExtension, setCacTwoExtension] = useState("");
  // const [cac11Extension, setCac11Extension] = useState("");
  // const [meMarTTwoExtension, setMeMarTTwoExtension] = useState("");
  // const [boarDTwoExtension, setBoarDTwoExtension] = useState("");
  // const [refRTwoExtension, setRefRTwoExtension] = useState("");
  // const [otherSTwoExtension, setOtherSTwoExtension] = useState("");
  // const [evidencETwoExtension, setEvidencETwoExtension] = useState("");

  const [documentArrayOne, setDocumentArrayOne] = useState([]);
  const [documentArrayTwo, setDocumentArrayTwo] = useState([]);
  const [uploadD, setUploadD] = useState(false);
  const [clientID, setClientID] = useState(null);
  const [isLoadingClientID, setIsLoadingClientID] = useState(false);

  const rawDateTime = new Date();
  const dateTime = moment(rawDateTime).format('MMMM d, YYYY');

  //upload docs
  const onDrop1 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setCac(file);
      const base64 = await convertBase64(file);
      setCacURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setCacExtension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-cac`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-cac`,
            docURL: result,
            documentCategory: 'Combo1',
            documentTagName: 'cac',
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop2 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setCo2(file);
      const base64 = await convertBase64(file);
      setCo2URL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setCo2Extension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-co2`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-co2`,
            docURL: result,
            documentCategory: 'Combo1',
            documentTagName: 'co2',
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop3 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setCo7(file);
      const base64 = await convertBase64(file);
      setCo7URL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setCo7Extension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-co7`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-co7`,
            docURL: result,
            documentCategory: 'Combo1',
            documentTagName: 'co7',
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop4 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setMeMart(file);
      const base64 = await convertBase64(file);
      setMeMartURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setMeMartExtension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-memart`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-memart`,
            docURL: result,
            documentCategory: 'Combo1',
            documentTagName: 'memart',
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop5 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setBoard(file);
      const base64 = await convertBase64(file);
      setBoardURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setBoardExtension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-boardResolution`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-boardResolution`,
            docURL: result,
            documentCategory: 'Combo1',
            documentTagName: 'boardresolutionsignedsealed',
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop6 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setRef(file);
      const base64 = await convertBase64(file);
      setRefURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setRefrExtension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-reference`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-reference`,
            docURL: result,
            documentCategory: 'Combo1',
            documentTagName: 'others1',
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop7 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setOthers(file);
      const base64 = await convertBase64(file);
      setOthersURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setOtherExtension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-others`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-others`,
            docURL: result,
            documentCategory: 'Combo1',
            documentTagName: 'others',
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop8 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setEvidence(file);
      const base64 = await convertBase64(file);
      setEvidenceURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setEvidenceExtension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-evidence`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-evidence`,
            docURL: result,
            documentCategory: 'Combo1',
            documentTagName: 'evidenceofmembership',
          };
          setDocumentArrayOne((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop9 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setCaCTwo(file);
      const base64 = await convertBase64(file);
      setCaCTwoURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setCacTwoExtension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-cacc`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-cacc`,
            docURL: result,
            documentCategory: 'Combo2',
            documentTagName: 'cac',
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop10 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setCaC11(file);
      const base64 = await convertBase64(file);
      setCaC11URL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setCac11Extension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-cac1.1`,
        extension: `.${extension}`,
      };

      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-cac1.1`,
            docURL: result,
            documentCategory: 'Combo2',
            documentTagName: 'cac11',
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop11 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setMeMarTTwo(file);
      const base64 = await convertBase64(file);
      setMeMarTTwoURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setMeMarTTwoExtension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-memart1`,
        extension: `.${extension}`,
      };

      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-memart1`,
            docURL: result,
            documentCategory: 'Combo2',
            documentTagName: 'memart',
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop12 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setBoarDTwo(file);
      const base64 = await convertBase64(file);
      setBoarDTwoURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setBoarDTwoExtension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-boardResolution1`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-boardResolution1`,
            docURL: result,
            documentCategory: 'Combo2',
            documentTagName: 'boardresolutionsignedsealed',
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop13 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setReFTwo(file);
      const base64 = await convertBase64(file);
      setReFTwoURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setRefRTwoExtension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-reference1`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-reference1`,
            docURL: result,
            documentCategory: 'Combo2',
            documentTagName: 'others1',
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop14 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setOtherSTwo(file);
      const base64 = await convertBase64(file);
      setOtherSTwoURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setOtherSTwoExtension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-others1`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-others1`,
            docURL: result,
            documentCategory: 'Combo2',
            documentTagName: 'others',
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrop15 = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === 'file-too-large') {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === 'file-invalid-type') {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setEvidencETwo(file);
      const base64 = await convertBase64(file);
      setEvidencETwoURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf('.')) || Infinity) + 1
      );
      // setEvidencETwoExtension(`.${extension}`);
      const imageArr = base64.split(',');
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-evidence1`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: '',
            documentName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-evidence1`,
            docURL: result,
            documentCategory: 'Combo2',
            documentTagName: 'evidenceofmembership',
          };
          setDocumentArrayTwo((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const [loading, setLoading] = useState(false);
  const [formVal, setFormVal] = useState({
    tin: '',
    address: '',
    phone: '',
    regNum: '',
    dateOfInc: '',
    businessName: '',
    email: '',
    branch: '',
    taxOfficeId: '',
    stateOfRes: '',
    dirEmail: '',
    refCode: '',
  });

  const handleFormChange =
    (name) =>
    ({ target }) => {
      setFormVal({
        ...formVal,
        [name]: target.value,
      });
    };

  const [showComplete, handleCloseComplete, handleShowComplete] = useModal();

  const confirmComplete = () => {
    handleCloseComplete();
    setCurrent(0);
  };

  const [tinDetails, setTinDetails] = useState({});

  const [errorMsg, setErrorMsg] = useState('');
  const [errorMsgs, setErrorMsgs] = useState('');

  const getSignatoriesByTin = () => {
    setLoading(true);
    const data = encrypt2(tin);

    authOApi
      .get(`${appUrls.GET_SIGNATORIESBY_TIN}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);
        setRefList(res);
        setFormValues({
          bvn: res[0].Bvn,
          firstName: res[0].FirstName,
          secondName: res[0].MiddleName,
          lastName: res[0].LastName,
          dobirth: res[0].DateOfBirth,
          phone: res[0].PhoneNumber,
          email: res[0].Email,
          landMark: res[0].LandMark,
          state: res[0].AreaOrState,
          nationality: res[0].Nationality,
          tin: res[0].TIN,
          signature: res[0].Signature,
          passport: res[0].Passport,
          scannedID: res[0].ScannedID,
          streetName: res[0].StreetName,
          mandate: res[0].MandateInstruction,
        });
        setErrorMsg(!404);
        setLoading(false);
      })
      .catch((error) => {
        // const res = decryptHandler2(error.response);

        setErrorMsg(404);
        setLoading(false);
      });
  };

  // get data needed with bvn
  const getSaveProcess = () => {
    setIsLoading(true);
    const data = encrypt2(formValues.bvn);
    authOApi
      .get(`${appUrls.SAVE_PROCESS}/${data}`)
      .then((res) => {
        const results = decryptHandler2(res.data);
        localStorage.setItem('saveddata', JSON.stringify(results));
        if (
          results.data.length !== 0 &&
          results &&
          results.responseCode === '00'
        ) {
          setSavedProcessValues(results);
          setFormValues({
            bvn: res[0].Bvn,
            firstName: res[0].FirstName,
            secondName: res[0].MiddleName,
            lastName: res[0].LastName,
            dobirth: res[0].DateOfBirth,
            phone: res[0].PhoneNumber,
            email: res[0].Email,
            landMark: res[0].LandMark,
            state: res[0].AreaOrState,
            nationality: res[0].Nationality,
            tin: res[0].TIN,
            signature: res[0].Signature,
            passport: res[0].Passport,
            scannedID: res[0].ScannedID,
            streetName: res[0].StreetName,
          });
          next();
          setIsLoading(false);
        } else {
          handleClosePendingModal();
          // message.error(
          //   "Oops!! You have no existing application."
          // );
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error('Unable to save data. Please try again later', 10);
        // setIsLoading(false);
      });
  };

  // user continues process if user is not done
  const saveProcess = () => {
    setIsLoading(true);
    let payload = {
      tin: tin,
      address: formValues.address,
      phone: formValues.phone,
      regNum: formValues.regNum,
      dateOfInc: formValues.dateOfInc,
      businessName: formValues.businessName,
      productCode: 'CLASSIC.CUR',
      currency: 'NGN',
      clientID: '52',
      sector: '4200',
      industry: '4202',
      nationality: 'NG',
      appId: '25',
      productName: 'CORPORATE ACCOUNT',
      source: 'Digital Onboarding',
      iSsignatory: 'true',
      sqNo: 0,
      email: formValues.email,
      branch: formValues.branch,
      taxOfficeId: formValues.taxOfficeId,
      stateOfRes: formValues.stateOfRes,
      dirEmail: formValues.dirEmail,
      refCode: formValues.refCode,
    };
    const data = encrypt2(payload);
    authOApi
      .post(`${appUrls.SAVE_PROCESS}`, data)
      .then(async (res) => {
        const results = await decryptHandler2(res.data);
        if (results && results.ResponseCode === '00') {
          setCurrent(current + 1);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error('Unable to save data. Please try again later', 10);
      });
  };

  const JTB = () => {
    setLoading(true);
    const TinValue = tin;
    const data = encrypt1(TinValue);
    authOApi
      .get(`${appUrls.VALIDATE_TIN}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);

        if (res.ResponseCode === '00' && res.Data.IsVerified === true) {
          setIsTinValidated(true);
          setFormVal({
            address: res.Data.Address,
            phone: res.Data.Phone,
            regNum: res.Data.RcNumber,
            email: res.Data.Email,
            businessName: res.Data.TaxPayerName,
          });
          // const savedData = JSON.parse(localStorage.getItem("saveddata"));
          // if (savedData.data.length !== 0) {
          //   handleShowPendingModal();
          // } else {
          //   setCurrent(current + 1);
          // }
          next();
          setLoading(false);
        } else if (res.ResponseCode !== '00' || res.Data.IsVerified === false) {
          // message.error(res.ResponseDescription);
          next();
          setIsTinValidated(false);
          message.warning(<p>TIN validation was not successful</p>, 20);
          setFormVal({
            address: '',
            phone: '',
            regNum: '',
            email: '',
            businessName: '',
          });
          // message.warning(
          //   <p>
          //     Dear customer, <br />
          //     We are unable to validate your TIN. <br /> Kindly visit the
          //     nearest FIRS/JTB office to activate your TIN
          //   </p>,
          //   20
          // );
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error('Unable to validate TIN. Please try again later', 10);
      });
  };

  const validateFirst = () => {
    if (current === 0) {
      if (!tin) {
        message.error('TIN not provided !');
        return;
      }
      // next();
      // validateTIN();
      JTB();
    }
  };

  const isProceed = () => {
    if (current === 0) {
      if (!tin) {
        message.error('TIN not provided !');
        return;
      }
    }
    setIsProceedClicked(true);
    setCurrent(current + 1);
  };

  const validateTIN = () => {
    setLoading(true);
    const TinValue = tin;
    const data = encrypt2(TinValue);
    // getSaveProcess();
    authOApi
      .get(`${appUrls.VERIFY_BUSINESS}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);

        if (res.ResponseCode === '00') {
          setIsTinValidated(true);
          setFormVal({
            address: res.Data.Address,
            phone: res.Data.Phone,
            regNum: res.Data.RcNumber,
            email: res.Data.Email,
            businessName: res.Data.TaxPayerName,
          });

          next();
          setLoading(false);
        } else if (response.data.responseCode !== '00') {
          message.warning(
            <p>
              Dear customer, <br /> We are unable to validate your TIN from the
              FIRS portal. <br /> Kindly follow the link{' '}
              <a href="https://taxpromax.firs.gov.ng/" target="_blank">
                HERE
              </a>{' '}
              to get your TIN active.
            </p>,
            // "Dear customer,  We are unable to validate your TIN from the FIRS portal.  Kindly follow the link below to get your TIN active : https://taxpromax.firs.gov.ng/"
            20
          );
          setIsTinValidated(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          'Unable to verify business details. Please try again later',
          10
        );
      });
  };

  const validateFormTwo = () => {
    if (current === 1) {
      if (!formVal.businessName) {
        message.error('Business name not provided !');
        return;
      }
      if (!formVal.regNum) {
        message.error('Registration number not provided !');
        return;
      }
      // if (!formVal.tin) {
      //   message.error("TIN not provided !");
      //   return;
      // }
      if (!address) {
        message.error('Address not provided !');
        return;
      }
      if (address.length >= 35) {
        message.error('Address must not be more than 35 characters !');
        return;
      }
      if (!formVal.email) {
        message.error('Email not provided !');
        return;
      }
      if (!EmailValidator.validate(formVal.email)) {
        message.error('Email not valid!');
        return;
      }
      if (!formVal.phone) {
        message.error('Phone number not provided !');
        return;
      }
      if (!formVal.dateOfInc) {
        message.error('Date of incorporation not provided !');
        return;
      }
      if (refList.length === 0) {
        message.error(
          'You need to provide at least a signatory before proceeding !'
        );
        return;
      }
    }
    // saveProcess();
    next();
  };

  const validateFormThree = () => {
    if (current === 2) {
      if (dirList.length === 0) {
        message.error(
          'You need to provide at least a director before proceeding !'
        );
        return;
      }
    }
    next();
  };

  const open = () => {
    if (comboType === 'combo-one') {
      openAccountOne();
    } else {
      openAccountTwo();
    }
  };

  const validateFormFour = () => {
    if (documentArrayOne.length === 0 && documentArrayTwo.length === 0) {
      message.error('Minimum of one document is required !');
      return;
    }
    if (!formVal.branch) {
      message.error('Branch not provided !');
      return;
    }
    open();
  };

  const directorsList = () => {
    setLoading(true);
    const data = encrypt2(tin);
    authOApi
      .get(`${appUrls.GET_DIRECTORIESBY_TIN}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);

        setDirList(res);
        setErrorMsgs(!404);
        setLoading(false);
      })
      .catch((error) => {
        // const res = decryptHandler2(error.response);

        setErrorMsgs(404);
        setLoading(false);
      });
  };

  const openAccountOne = async () => {
    setLoading(true);
    let payload = {
      rcNumber: formVal.regNum,
      incorpDate: moment(formVal.dateOfInc).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      tin: tin,
      businessName: formVal.businessName,
      branch: formVal.branch,
      emailAddress: formVal.email,
      productCode: 'CLASSIC.CUR',
      currency: 'NGN',
      clientID: customerNumber,
      // clientID: refList[0].id,
      firstName: formValues.firstName,
      surName: formValues.lastName,
      middleName: formValues.secondName,
      street: formValues.streetName,
      sector: '4200',
      industry: '4202',
      nationality: 'NG',
      bvn: formValues.bvn,
      gender: formValues.gender,
      dob: moment(formValues.dobirth).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      title: '',
      email: formVal.email,
      state: formVal.stateOfRes,
      phone: formValues.phone ?? '',
      sms: formValues.phone,
      passportPhoto: formValues.passport,
      validIdType: '',
      validIdNumber: '',
      signature: formValues.signature,
      validId: '',
      reference: '',
      jointAccountTransferLimit: '',
      sqNo: 0,
      accountLinkCode: '',
      residence: formValues.address,
      stateResidence: formVal.stateOfRes,
      appId: '25',
      shortTitle: '',
      scumlNum: '',
      requestRefId1: '',
      requestRefId2: '',
      countryOfBirth: formValues.nationality,
      otherNationality: '',
      taxId: formVal.taxOfficeId,
      dao: formVal.refCode,

      branchCode: formValues.branch,
      statusJobTitle: '',
      nickName: '',
      corpLegalForm: '',
      isDirector: '',
      urlMandate: '',
      urlSignature: formValues.signature,
      otherName: formValues.secondName,
      corporateAddress: address,
      branchName: formVal.branch,
      internalRef: '',
      productName: 'CORPORATE ACCOUNT',
      refereeAccountNumber1: '',
      refereeAccountNumber2: '',
      refereeBank1: '',
      refereeBank2: '',
      source: 'Digital Onboarding',
      existingAccount: '',
      internalReference: '',
      chequeReference: '',
      iSsignatory: true,
      mandateAuth: '',
      classOfSig: '',
      mandateInstruction: formValues.mandate ?? '',
      identificationType: '',
      idNo: '',
      passportPic: formValues.passport,
      mandate: formValues.mandate ?? '',
      urlPhoto: '',
      idName: '',
      urlid: '',
      placeOfBirth: '',
      motherMadienName: '',
      religion: '',
      stateOfOrigin: formVal.stateOfRes,
      lga: '',
      occupation: '',
      natureOfControl: '',
      countryTaxRes: '',
      nearestBusstop: formValues.landmark,
      city_Town: formVal.stateOfRes,
      documents: [],
      isRestriction: true,
    };
    const enc = encrypt2(payload);
    const dec = decryptHandler2(enc);
    await authOApi
      .post(`${appUrls.OPEN_CORPORATE_ACCT}`, { data: enc })
      .then(async (response) => {
        const res = decryptHandler2(response.data);

        if (res.responseCode === '1') {
          setAccountInfo(res.nuban);
          const docsPayload = {
            // isRestriction: true,
            nuban: res.nuban,
            dob: moment(formValues.dobirth).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
            bvn: formValues.bvn,
            surName: formValues.lastName,
            otherName: formValues.secondName,
            firstName: formValues.firstName,
            tin: tin,
            countryTaxRes: '',
            natureOfControl: '',
            phone: formValues.phone ?? '',
            iSsignatory: true,
            mandateAuth: '',
            classOfSig: '',
            identificationType: '',
            idNo: '',
            signature: formValues.signature,
            passportPic: formValues.passport,
            mandate: formValues.mandate ?? '',
            urlPhoto: '',
            urlSignature: '',
            urlMandate: '',
            idName: '',
            urlid: '',
            placeOfBirth: formValues.nationality,
            motherMadienName: '',
            religion: '',
            stateOfOrigin: '',
            lga: '',
            occupation: '',
            statusJobTitle: '',
            nationality: formValues.nationality,
            residence: address,
            state: formVal.stateOfRes,
            city_Town: formVal.stateOfRes,
            nearestBusstop: formValues.landmark,
            email: formVal.email,
            title: '',
            nickName: '',
            corpLegalForm: '',
            gender: '',
            isDirector: '',
            branchCode: formVal.branch,
            branchName: formVal.branch,
            customerId: res.customerId,
            incorpDate: moment(formVal.dateOfInc).format(
              'YYYY-MM-DDTHH:mm:ss.sssZ'
            ),
            industry: '',
            internalRef: '',
            productName: 'BUSINESS ACCOUNT',
            rcNumber: '',
            refereeAccountNumber1: '',
            refereeAccountNumber2: '',
            refereeBank1: '',
            refereeBank2: '',
            sector: '4202',
            source: 'Digital Onboarding',
            existingAccount: '',
            corporateAddress: '',
            internalReference: '',
            chequeReference: '',
            businessName: formVal.businessName,
            documents: documentArrayOne,
          };
          const encryptDocPayload = encrypt2(docsPayload);
          authOApi
            .post(`${appUrls.SENDDATA_TO_ONEXPRESS}`, {
              data: encryptDocPayload,
            })
            .then((res) => {})
            .catch((error) => {});
          const payload = {
            addRestriction: {
              branchcode: formVal.branch,
              account: res.nuban,
              accountsType: 'ACCOUNTS',
              restriction_code: '47',
            },
          };

          const enc = encrypt2(payload);
          if (isTinValidated === false) {
            authOApi
              .post(`${appUrls.FIORANO_ADD_RESTRICTION}`, { data: enc })
              .then((response) => {
                const res = decryptHandler2(response.data);
              })
              .catch((error) => {});
          }

          if (isTinValidated) {
            let payloadTIN = {
              email: formVal.email,
              name: `${formValues.firstName} ${formValues.lastName}`,
            };
            const encT = encrypt2(payloadTIN);
            authOApi
              .post(`${appUrls.UPDATE_TIN_EMAIL}`, { data: encT })
              .then((response) => {
                const res = response.data;
              })
              .catch((error) => {
                message.error(
                  'Unable to update email, please try again later',
                  10
                );
              });
          }
          handleShowComplete();
          setLoading(false);
        } else if (response.data.responseCode === '104') {
          message.error('You currently have this account type !');
        } else {
          message.error(res.responseText);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const openAccountTwo = async () => {
    setLoading(true);
    let payload = {
      rcNumber: formVal.regNum,
      incorpDate: moment(formVal.dateOfInc).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      tin: tin,
      businessName: formVal.businessName,
      branch: formVal.branch,
      emailAddress: formVal.email,
      productCode: 'CLASSIC.CUR',
      currency: 'NGN',
      clientID: customerNumber,
      // clientID: refList[0].id,
      firstName: formValues.firstName,
      surName: formValues.lastName,
      middleName: formValues.secondName,
      street: formValues.streetName,
      sector: '4200',
      industry: '4202',
      nationality: 'NG',
      bvn: formValues.bvn,
      gender: formValues.gender,
      dob: moment(formValues.dobirth).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      title: '',
      email: formVal.email,
      state: formVal.stateOfRes,
      phone: formValues.phone,
      sms: formValues.phone,
      passportPhoto: formValues.passport,
      validIdType: '',
      validIdNumber: '',
      signature: formValues.signature,
      validId: '',
      reference: '',
      jointAccountTransferLimit: '',
      sqNo: 0,
      accountLinkCode: '',
      residence: address,
      stateResidence: formVal.stateOfRes,
      appId: '25',
      shortTitle: '',
      scumlNum: '',
      requestRefId1: '',
      requestRefId2: '',
      countryOfBirth: formValues.nationality,
      otherNationality: '',
      taxId: formVal.taxOfficeId,
      dao: formVal.refCode,

      branchCode: formValues.branch,
      statusJobTitle: '',
      nickName: '',
      corpLegalForm: '',
      isDirector: '',
      urlMandate: '',
      urlSignature: formValues.firstName,
      otherName: formValues.secondName,
      corporateAddress: address,
      branchName: formVal.branch,
      internalRef: '',
      productName: 'CORPORATE ACCOUNT',
      refereeAccountNumber1: '',
      refereeAccountNumber2: '',
      refereeBank1: '',
      refereeBank2: '',
      source: 'Digital Onboarding',
      existingAccount: '',
      internalReference: '',
      chequeReference: '',
      iSsignatory: true,
      mandateAuth: '',
      classOfSig: '',
      mandateInstruction: '',
      identificationType: '',
      idNo: '',
      passportPic: formValues.passport,
      mandate: formValues.mandate ?? '',
      urlPhoto: '',
      idName: '',
      urlid: '',
      placeOfBirth: '',
      motherMadienName: '',
      religion: '',
      stateOfOrigin: formVal.stateOfRes,
      lga: '',
      occupation: '',
      natureOfControl: '',
      countryTaxRes: '',
      nearestBusstop: formValues.landmark,
      city_Town: formVal.stateOfRes,
      documents: [],
      isRestriction: true,
    };
    const enc = encrypt2(payload);
    const dec = decryptHandler2(enc);
    await authOApi
      .post(`${appUrls.OPEN_CORPORATE_ACCT}`, { data: enc })
      .then(async (response) => {
        const res = decryptHandler2(response.data);
        const docsPayload = {
          nuban: res.nuban,
          dob: moment(formValues.dobirth).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
          bvn: formValues.bvn,
          surName: formValues.lastName,
          otherName: formValues.secondName,
          firstName: formValues.firstName,
          tin: tin,
          countryTaxRes: '',
          natureOfControl: '',
          phone: formValues.phone ?? '',
          iSsignatory: true,
          mandateAuth: '',
          classOfSig: '',
          identificationType: '',
          idNo: '',
          signature: formValues.signature,
          passportPic: formValues.passport,
          mandate: '',
          urlPhoto: '',
          urlSignature: '',
          urlMandate: '',
          idName: '',
          urlid: '',
          placeOfBirth: formValues.nationality,
          motherMadienName: '',
          religion: '',
          stateOfOrigin: '',
          lga: '',
          occupation: '',
          statusJobTitle: '',
          nationality: formValues.nationality,
          residence: address,
          state: formVal.stateOfRes,
          city_Town: formVal.stateOfRes,
          nearestBusstop: formValues.landmark,
          email: formVal.email,
          title: '',
          nickName: '',
          corpLegalForm: '',
          gender: '',
          isDirector: '',
          branchCode: formVal.branch,
          branchName: formVal.branch,
          customerId: '',
          incorpDate: moment(formVal.dateOfInc).format(
            'YYYY-MM-DDTHH:mm:ss.sssZ'
          ),
          industry: '',
          internalRef: '',
          productName: 'BUSINESS ACCOUNT',
          rcNumber: '',
          refereeAccountNumber1: '',
          refereeAccountNumber2: '',
          refereeBank1: '',
          refereeBank2: '',
          sector: '4202',
          source: 'Digital Onboarding',
          existingAccount: '',
          corporateAddress: '',
          internalReference: '',
          chequeReference: '',
          businessName: formVal.businessName,
          documents: documentArrayTwo,
        };
        const encryptDocPayload = encrypt2(docsPayload);
        authOApi
          .post(`${appUrls.SENDDATA_TO_ONEXPRESS}`, {
            data: encryptDocPayload,
          })
          .then((res) => {})
          .catch((error) => {});
        if (res.responseCode === '1') {
          setAccountInfo(res.nuban);
          const payload = {
            addRestriction: {
              branchcode: formVal.branch,
              account: res.nuban,
              accountsType: 'ACCOUNTS',
              restriction_code: '47',
            },
          };

          const enc = encrypt2(payload);
          authOApi
            .post(`${appUrls.FIORANO_ADD_RESTRICTION}`, { data: enc })
            .then((response) => {
              const res = response.data;
            })
            .catch((error) => {});

          if (isTinValidated) {
            let payloadTIN = {
              email: formVal.email,
              name: `${formValues.firstName} ${formValues.lastName}`,
            };
            const encT = encrypt2(payloadTIN);
            authOApi
              .post(`${appUrls.UPDATE_TIN_EMAIL}`, { data: encT })
              .then((response) => {
                const res = response.data;
              })
              .catch((error) => {
                message.error(
                  'Unable to update email, please try again later',
                  10
                );
              });
          }

          handleShowComplete();
          setLoading(false);
        } else if (response.data.responseCode === '104') {
          message.error('You currently have this account type !', 10);
        } else {
          message.error(response.data.responseText);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const getBranches = () => {
    authOApi
      .get(
        `${appUrls.GET_STERLING_BRANCHES}`
        //   , {
        //   headers: {
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        //   },
        // }
      )
      .then((response) => {
        const res = decryptHandler2(response.data);

        setBranchCode(res.SterlingBranches);
        // getSectors();
      })
      .catch((err) => {});
  };

  const getSectors = () => {
    authOApi
      .get(
        `${appUrls.GET_SECTOR}`
        // , {
        // headers: {
        //   "Access-Control-Allow-Origin": "*",
        //   "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        // },
        // }
      )
      .then((response) => {
        const res = decryptHandler2(response.data);
        setSectorList(res.Sectors);
      })
      .catch((err) => {});
  };

  const getIndusties = () => {
    setIsLoading(true);
    let enc = encrypt2(secVal);
    authOApi
      .get(`${appUrls.GET_INDUSTRY}?Data=${enc}`, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        },
      })
      .then((response) => {
        const res = decryptHandler2(response.data);

        setIndustriesList(res.Industries);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const sendEmail = () => {
    setLoading(true);
    let payload = { accountNumber: accountInfo, email: formVal.dirEmail };
    const enc = encrypt2(payload);

    authOApi
      .post(`${appUrls.SEND_CORPORATE_CUSTOMER_EMAIL}`, { data: enc })
      .then((response) => {
        const res = decryptHandler2(response.data);

        setLoading(false);
        if (res === true) {
          message.success('Email sent');
          setFormVal({
            dirEmail: '',
          });
          setLoading(false);
        } else {
          message.error('Email could not be sent. Please try again');
          setLoading(false);
        }
        setLoading(false);
      });
  };

  const getCustomerID = (data) => {
    const payload = data;
    const enc = encrypt2(payload);
    authOApi
      .get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${enc}`)
      .then((res) => {
        const dec = decryptHandler2(res.data);
        setDataCustomerID(
          dec.GetAccountFullInfo.BankAccountFullInfo[0].CUS_NUM
        );
      })
      .catch((error) => {
        message.error(
          'Unable to get account details. Please try again later',
          10
        );
      });
  };

  const steps = [
    {
      title: 'TIN Validation',
      content: (
        <TinCompany
          formVal={formVal}
          handleFormChange={handleFormChange}
          tin={tin}
          setTin={setTin}
        />
      ),
    },
    {
      title: 'Upload details',
      content: (
        <UpdateDetails
          formVal={formVal}
          handleFormChange={handleFormChange}
          tinDetails={tinDetails}
          setCustomerNumber={setCustomerNumber}
          getSignatoriesByTin={getSignatoriesByTin}
          refList={refList}
          setRefList={setRefList}
          errorMsg={errorMsg}
          formValues={formValues}
          setFormVal={setFormVal}
          setFormValues={setFormValues}
          handleFormChanges={handleFormChanges}
          directorsList={directorsList}
          address={address}
          setAddress={setAddress}
          handleSelect={handleSelect}
          validateTIN={validateTIN}
          tin={tin}
          isTinValidated={isTinValidated}
          clientID={clientID}
          setClientID={setClientID}
        />
      ),
    },
    {
      title: 'Add directors',
      content: (
        <Directors
          dirList={dirList}
          formValues={formValues}
          handleFormChanges={handleFormChanges}
          dob={dob}
          onDateChange={onDateChange}
          setFormValues={setFormValues}
          formVal={formVal}
          handleFormChange={handleFormChange}
          directorsList={directorsList}
          formValuess={formValuess}
          setFormValuess={setFormValuess}
          handleFormChangess={handleFormChangess}
          loading={loading}
          setLoading={setLoading}
          errorMsgs={errorMsgs}
          setDirList={setDirList}
          tin={tin}
          getCustomerID={getCustomerID}
        />
      ),
    },
    {
      title: 'Document upload',
      content: (
        <UploadDocsCom
          setComboType={setComboType}
          onDrop1={onDrop1}
          onDrop2={onDrop2}
          onDrop3={onDrop3}
          onDrop4={onDrop4}
          onDrop5={onDrop5}
          onDrop6={onDrop6}
          onDrop7={onDrop7}
          onDrop8={onDrop8}
          onDrop9={onDrop9}
          onDrop10={onDrop10}
          onDrop11={onDrop11}
          onDrop12={onDrop12}
          onDrop13={onDrop13}
          onDrop14={onDrop14}
          onDrop15={onDrop15}
          cac={cac}
          caCTwo={caCTwo}
          caC11={caC11}
          meMarTTwo={meMarTTwo}
          boarDTwo={boarDTwo}
          refRTwo={refRTwo}
          otherSTwo={otherSTwo}
          evidencETwo={evidencETwo}
          co2={co2}
          co7={co7}
          meMart={meMart}
          board={board}
          refr={refr}
          others={others}
          evidence={evidence}
          branchCode={branchCode}
          formValues={formValues}
          handleFormChange={handleFormChange}
          handleSecChanges={handleSecChanges}
          sec={sec}
          sectorList={sectorList}
          industriesList={industriesList}
          isLoading={isLoading}
          showDoc={showDoc}
          docText={docText}
          tcHandler={tcHandler}
          isTCClicked={isTCClicked}
          handleShowTC={handleShowTC}
          handleShowTBH={handleShowTBH}
          stateList={stateList}
          formVal={formVal}
        />
      ),
    },
    {
      title: 'Add reference',
      content: (
        <ReferenceCompany
          formValues={formValues}
          handleFormChange={handleFormChange}
          accountInfo={accountInfo}
          current={current}
          setCurrent={setCurrent}
          fileUrl={fileUrl}
          showAddRefOTP={showAddRefOTP}
          handleCloseAddRefOTP={handleCloseAddRefOTP}
          handleShowAddRefOTP={handleShowAddRefOTP}
          handleShowRefSuccessOTP={handleShowRefSuccessOTP}
          handleFormChanges={handleFormChanges}
          formVal={formVal}
        />
      ),
    },
  ];

  const [refereesList, setReferrsList] = useState([]);

  const getReference = () => {
    const data = encrypt2(accountInfo);
    authOApi
      .get(`${appUrls.GET_CUSTOMER_REFERENCES}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);

        setReferrsList(res);
      });
  };

  const docUpload = () => {
    authOApi.get(`${appUrls.GET_DOC}`).then((response) => {
      setFileUrl(response.data);
      // getBranches();
      // getStates();
      // getSignatoriesByTin();
    });
  };

  const getStates = () => {
    authOApi
      .get(`${appUrls.GET_STATES}`)
      .then((response) => {
        setStateList(response.data);
      })
      .catch((error) => {});
  };

  const handleGenerateClientID = async () => {
    if (!formVal.businessName) {
      message.error('Business name not provided !', 10);
      return;
    }
    if (!formVal.businessName.length >= 36) {
      message.error('Business name cannot exceed 35 characters!', 10);
      return;
    }
    // if (!formVal.tin) {
    //   message.error("TIN not provided !");
    //   return;
    // }
    if (!formVal.regNum) {
      message.error('Registration number not provided !', 10);
      return;
    }
    if (!address) {
      message.error('Address not provided !', 10);
      return;
    }
    if (address.length >= 35) {
      message.error('Address must not be more than 35 characters !');
      return;
    }
    if (!formVal.email) {
      message.error('Email not provided !', 10);
      return;
    }
    if (!EmailValidator.validate(formVal.email)) {
      message.error('Email not valid!', 10);
      return;
    }
    if (!formVal.phone) {
      message.error('Phone number not provided !', 10);
      return;
    }
    if (!formVal.dateOfInc) {
      message.error('Date of incorporation not provided !', 10);
      return;
    }

    setIsLoadingClientID(true);

    let payload = {
      rcNumber: formVal.regNum,
      incorpDate: moment(formVal.dateOfInc).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      tin: tin,
      businessName: formVal.businessName,
      branch: formVal.branch,
      emailAddress: formVal.email,
      productCode: 'CLASSIC.CUR',
      currency: 'NGN',
      clientID: customerNumber,
      // clientID: refList[0].id,
      firstName: formValues.firstName,
      surName: formValues.lastName,
      middleName: formValues.secondName,
      street: formValues.streetName,
      sector: '4200',
      industry: '4202',
      nationality: 'NG',
      bvn: formValues.bvn,
      gender: formValues.gender,
      dob: moment(formValues.dobirth).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      title: '',
      email: formVal.email,
      state: formVal.stateOfRes,
      phone: formValues.phone,
      sms: formValues.phone,
      passportPhoto: formValues.passport,
      validIdType: '',
      validIdNumber: '',
      signature: formValues.signature,
      validId: '',
      reference: '',
      jointAccountTransferLimit: '',
      sqNo: 0,
      accountLinkCode: '',
      residence: address,
      stateResidence: formVal.stateOfRes,
      appId: '25',
      shortTitle: '',
      scumlNum: '',
      requestRefId1: '',
      requestRefId2: '',
      countryOfBirth: formValues.nationality,
      otherNationality: '',
      taxId: formVal.taxOfficeId,
      dao: formVal.refCode,

      branchCode: formValues.branch,
      statusJobTitle: '',
      nickName: '',
      corpLegalForm: '',
      isDirector: '',
      urlMandate: '',
      urlSignature: formValues.signature,
      otherName: formValues.secondName,
      corporateAddress: address,
      branchName: formVal.branch,
      internalRef: '',
      productName: 'BUSINESS ACCOUNT',
      refereeAccountNumber1: '',
      refereeAccountNumber2: '',
      refereeBank1: '',
      refereeBank2: '',
      source: 'Digital Onboarding',
      existingAccount: '',
      internalReference: '',
      chequeReference: '',
      iSsignatory: true,
      mandateAuth: '',
      classOfSig: '',
      mandateInstruction: formValues.mandate,
      identificationType: '',
      idNo: '',
      passportPic: formValues.passport,
      mandate: formValues.mandate,
      urlPhoto: '',
      idName: '',
      urlid: '',
      placeOfBirth: '',
      motherMadienName: '',
      religion: '',
      stateOfOrigin: formVal.stateOfRes,
      lga: '',
      occupation: '',
      natureOfControl: '',
      countryTaxRes: '',
      nearestBusstop: formValues.landmark,
      city_Town: formVal.stateOfRes,
      documents: [],
      isRestriction: true,
    };
    const enc = encrypt2(payload);

    await authOApi
      .post(`${appUrls.ADD_UNVERIFIED_TIN_USER}`, { data: enc })
      .then((res) => {
        const decryptedData = decryptHandler2(res.data);
        if (decryptedData.Data) {
          setClientID(decryptedData.Data.AccountId);
          const clientData = {
            clientID: decryptedData.Data.AccountId,
            clientName: decryptedData.Data.Details.BusinessName,
          };
          localStorage.setItem('clientIDInfo', JSON.stringify(clientData));
        }
        setIsLoadingClientID(false);
      })
      .catch(() => {
        setIsLoadingClientID(false);
        message.error('Unable to generate clientID', 10);
      });

    // api fetch then set clientID to data response
  };

  useEffect(() => {
    docUpload();
    getBranches();
    getStates();
    getIndusties();
    getSectors();
  }, []);

  useEffect(() => {
    if (secVal) {
      getIndusties();
    } else {
      getSectors();
    }
  }, [secVal]);

  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 displayNone">
          <div className="leftDiv stickyDiv"></div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <Header />
          <div className="container-fluid pb-5 mt-5">
            <div class="row mb-5">
              <div class="container mr-3">
                <div className="steps-content mb-5">
                  {steps[current].content}
                </div>
                <div className="text-right"></div>
                <div className="steps-action text-right">
                  {current === steps.length - 5 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 btn-block w-80"
                      onClick={validateFirst}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        'Validate'
                      )}
                    </button>
                  )}
                  {current > 0 && current <= 3 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger px-5 mr-2 mb-1"
                      onClick={prev}
                    >
                      Previous
                    </button>
                  )}
                  {current < steps.length - 1 && current === 1 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mr-sm-3 mb-1"
                      disabled={isLoadingClientID}
                      onClick={
                        isTinValidated
                          ? validateFormTwo
                          : handleGenerateClientID
                      }
                    >
                      {isTinValidated ? (
                        'Next'
                      ) : isLoadingClientID ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        'Submit'
                      )}
                    </button>
                  )}
                  {current < steps.length - 1 && current === 2 && (
                    <button
                      type="button"
                      className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-1"
                      onClick={validateFormThree}
                    >
                      Next
                    </button>
                  )}
                  {current === steps.length - 2 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-1"
                      disabled={loading || isTCClicked === false}
                      onClick={validateFormFour}
                    >
                      {uploadD && (
                        <span>
                          Uploading doc ...
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                      {loading && (
                        <span>
                          Processing ...
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                      {!loading && !uploadD && 'Done'}
                    </button>
                  )}
                </div>
                {current === 0 && (
                  <div>
                    <div class="my-4 text-centers text-dark">
                      <h3 class="mb-0 sec-headers">Company Account</h3>
                    </div>
                    <div className="row pry-grey-clr mb-4 w-80">
                      <div className="col-md-6 ftsz-12">
                        <h6 className="ftsz-12 ftwt-500">Features</h6>
                        <ul className="custom-req-marker pl-3">
                          <li>
                            Minimum account opening balance of =N=5,000 (Not
                            Restrictive).
                          </li>
                          <li>Access to overdraft facility.</li>
                          <li>
                            AMF at =N=1/mille (Negotiable with huge turnover).
                          </li>
                          <li>Access to business finance.</li>
                          <li>
                            Internet and mobile banking facility i.e. online
                            statement, SMS alert etc..
                          </li>
                          <li>Chequebook.</li>
                          <li>International funds transfer allowed.</li>
                          <li>Access to TBH community.</li>
                        </ul>
                      </div>
                      <div className="col-md-6 ftsz-12">
                        <h6 className="ftsz-12 ftwt-500">Requirements</h6>
                        <ul className="custom-req-marker pl-3">
                          <li>Certificate of incorporation.</li>
                          <li>
                            Certified true copy of Form CO2 and CO7 or Form CAC
                            1.1
                          </li>
                          <li>
                            Certified true copy of Memorandum and Articles of
                            Association.
                          </li>
                          <li>Taxpayer identification number TIN.</li>
                          <li>
                            Basic information about Company (company name,
                            company address, company email and phone number,
                            Registration number).
                          </li>
                          <li>BVN of each signatory.</li>
                          <li>
                            Valid and acceptable means of Identification for
                            each signatory.
                          </li>
                          <li>
                            Basic information of each signatory/proprietor.
                            (i.e., Name, Place and date of birth, Gender,
                            Address, Telephone number)
                          </li>
                          <li>
                            Board Resolution duly sealed and signed by two
                            directors or a director and Secretary on company
                            letter head.
                          </li>
                          <li>Two satisfactory corporate references.</li>
                          <li>
                            One recent passport photograph of each signatory.
                          </li>
                          <li>SCUML Certificate (where applicable).</li>
                          <li>
                            For professional bodies, evidence of duly registered
                            membership of the professional body is required.
                          </li>
                          <li>
                            CERPAC- Combined Expatriate Residence Permit and
                            Alien Card for directors of the company and
                            signatories to the account who are foreign
                            nationals.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="pale-red-part-bg corporate-bg position-fixed"></div>
      <Header />
      <div className="d-flex justify-content-between px-2">
        <div></div>
        <div className="w-50">
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
      </div>
      <div className="container-fluid pb-5 mt-5">
        <div class="row mb-1">
          <div class="col-xl-7 col-lg-9 col-md-10 mx-auto">
            <div class="container">
              <div class="app-details-form-wrapper pry-grey-clr bg-white px-3 px-lg-5 py-5">
                <div className="steps-content">{steps[current].content}</div>
                <div className="text-right"></div>
                <div className="steps-action text-right">
                  {current === steps.length - 5 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                      onClick={validateFirst}
                      disabled={loading}
                    >
                      {loading ? "Validating..." : "Validate"}
                    </button>
                  )}
                  {current > 0 && current <= 3 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger px-5 mr-2 mb-1"
                      onClick={prev}
                    >
                      Previous
                    </button>
                  )}
                  {current < steps.length - 1 && current === 1 && (
                    <button
                      type="button"
                      className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-1"
                      onClick={validateFormTwo}
                    >
                      Next
                    </button>
                  )}
                  {current < steps.length - 1 && current === 2 && (
                    <button
                      type="button"
                      className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-1"
                      onClick={validateFormThree}
                    >
                      Next
                    </button>
                  )}
                  {current === steps.length - 2 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-1"
                      disabled={loading || isTCClicked === false}
                      onClick={validateFormFour}
                    >
                      {uploadD && (
                        <span>
                          Uploading doc ...
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                      {loading && (
                        <span>
                          Processing ...
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                      {!loading && !uploadD && "Done"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <CompletedForm
        showComplete={showComplete}
        handleCloseComplete={handleCloseComplete}
        confirmComplete={confirmComplete}
        accountInfo={accountInfo}
        formVal={formVal}
        next={next}
        handleFormChange={handleFormChange}
        sendEmail={sendEmail}
        loading={loading}
      />
      <AddRefOTP
        showAddRefOTP={showAddRefOTP}
        handleCloseAddRefOTP={handleCloseAddRefOTP}
        handleShowRefSuccessOTP={handleShowRefSuccessOTP}
      />
      <RefSuccessOTP
        showRefSuccessOTP={showRefSuccessOTP}
        handleCloseRefSuccessOTP={handleCloseRefSuccessOTP}
        setCurrent={setCurrent}
        getReference={getReference}
      />

      <PendingModal
        showModal={isOpen}
        handleClosePendingModal={handleClosePendingModal}
        setNext={next}
        // getSaveProcess={getSaveProcess}
      />

      <TermsAndConditions showTC={showTC} handleCloseTC={handleCloseTC} />
      <Tbh showTBH={showTBH} handleCloseTBH={handleCloseTBH} />
    </div>
  );
};

export default CompanyAcc;
