import React, { useState, useEffect } from "react";
import { message, Steps } from "antd";
import SignatoryForm from "./details/details";
import SignatoryBvn from "./bvn/bvn";
import {
  apiUrl,
  referenceEndpoint,
} from "../../../../../../../../../../redux/actions/endpoint";
import axios from "axios";
import moment from "moment";
import MandateInstruction from "./document";
import useFiles from "../../../../hooks/useFiles";
import * as EmailValidator from "email-validator";
import { encrypt2 } from "../../../../../../../../../../encr";
import { decryptHandler2 } from "../../../../../../../../../../dec";
import useSigFiles from "../../../../hooks/useSigFiles";
import Spinner from "react-bootstrap/Spinner";
import { apiAccount } from "../../../../../../../../../../service/apiAccount";
import { appUrls } from "../../../../../../../../../../service/urls";
import { authOApi } from "../../../../../../../../../../service/authOApi";

const { Step } = Steps;

const SignatoriesSteps = ({
  onClose,
  formVal,
  formValues,
  setFormValues,
  handleFormChanges,
  getSignatoriesByTin,
  refList,
  tin
}) => {
  //stepper
  const [current, setCurrent] = React.useState(0);
  const prev = () => {
    setCurrent(current - 1);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const [dob, setDob] = useState(
    moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.sssZ")
  );

  const onDateChange = (event) => {
    setDob(event.target.value);
  };

  const [loading, setLoading] = useState(false);

  const [formV, setFormV] = useState({
    bvn: "",
    dobirth: "",
    branch: "",
    firstName: "",
    secondName: "",
    lastName: "",
    phone: "",
    email: "",
    houseNo: "",
    address: "",
    landmark: "",
    state: "",
    addressSummary: "",
    gender: "",
    imgBvn: "",
    cerpac: "",
    nationality: "",
    mandate: "",
    signature: "",
    scannedID: "",
    streetName: "",
    refAccount: "",
    imgBvn: "",
  });

  const handleFormC =
    (name) =>
    ({ target }) => {
      setFormV({
        ...formV,
        [name]: target.value,
      });
    };

  const [
    photoID,
    photoIDURL,
    onDrop,
    onDrops,
    signatureUpload,
    signatureURL,
    onDropss,
    passport,
    passportURL,
  ] = useSigFiles();

  const [bvnDetails, setBvnDetails] = useState({});

  // const isSignatoryExisting = () => {
  //   setLoading(true);
  //   getSignatoriesByTin();
  //   const checkSignatory = refList;
  //   if (checkSignatory) {
  //     const checkExistence = checkSignatory.some(
  //       (signatory) => signatory.Bvn === formValues.bvn
  //     );
  //     if (checkExistence === true) {
  //       message.error(
  //         "Oops!!! You already have a signatory with the provided BVN. Kindly provide another."
  //       );
  //       setLoading(false);
  //       return;
  //     } else {
  //       verifyBvn();
  //     }
  //   } else {
  //     verifyBvn();
  //   }
  // };

  const validateForm = () => {
    if (current === 0) {
      if (!formV.bvn) {
        message.error("BVN not provided !", 10);
        return;
      }
      if (!dob) {
        message.error("Date of birth not provided !", 10);
        return;
      }
      verifyBvn();
    }
  };

  const validateFormTwo = () => {
    if (!formV.firstName) {
      message.error("First name not provided !", 10);
      return;
    }
    if (!formV.lastName) {
      message.error("Last name not provided !", 10);
      return;
    }
    if (!formV.dobirth) {
      message.error("Date of birth not provided !", 10);
      return;
    }
    if (!formV.email) {
      message.error("Email not provided !", 10);
      return;
    }
    if (!EmailValidator.validate(formV.email)) {
      message.error("Email not valid!", 10);
      return;
    }
    if (!formV.address) {
      message.error("Address not provided !", 10);
      return;
    }
    if (!formV.landmark) {
      message.error("Landmark not provided !", 10);
      return;
    }
    next();
  };

  const verifyBvn = () => {
    setLoading(true);
    const payload = {
      bvn: formV.bvn,
      // dateOfBirth: moment(dob).format(
      //   "YYYY-MM-DDTHH:mm:ss.sssZ"
      // ),
      dateOfBirth: dob,
    };
    const enc = encrypt2(payload);
    
    authOApi
      .post(`${appUrls.VERIFY_BVN}`, { data: enc })
      .then((response) => {
        const res = decryptHandler2(response.data);

        setBvnDetails(res);
        if (res.ResponseCode === "00") {
          setFormV({
            bvn: res.BVN,
            firstName: res.FirstName,
            secondName: res.MiddleName,
            lastName: res.LastName,
            dobirth: res.DateOfBirth,
            phone: res.PhoneNumber,
            email: res.Email,
            address: res.ResidentialAddress,
            landmark: res.StateOfResidence,
            state: res.StateOfResidence,
            gender: res.Gender,
            imgBvn: res.Base64Image,
          });
          message.success("BVN validation was successful");
          setCurrent(current + 1);
          setLoading(false);
        } else {
          message.error(res.ResponseDesc);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          "Unable to verify BVN. Please try again later", 10
        )
      });
  };
  
  const dateTime = new Date();

  const uploadToAzure = async () => {
    setLoading(true);
    const imageArr = signatureURL.split(",");
    const imageArr2 = photoIDURL.split(",");
    const imageArr3 = passportURL.split(",");

    const imageSplit = imageArr[1];
    const imageSplit2 = imageArr2[1];
    const imageSplit3 = imageArr3[1];

    const result = {
      signatureSent: "",
      scannedID: "",
      passportSent: "",
      success: false,
    };

    let payload1 = {
      file: imageSplit,
      fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-signature`,
      extension: ".png",
    };
    // let payload1 = new FormData();
    // payload1.append("file", signatureURL);
    // const data1 = encrypt2(payload1);

    let payload2 = {
      file: imageSplit2,
      fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-photoID`,
      extension: ".png",
    };
    // let payload2 = new FormData();
    // payload2.append("file", photoIDURL);
    // const data2 = encrypt2(payload2);

    let payload3 = {
      file: imageSplit3,
      fileName: `digitalOnboarding/${formValues.bvn}/${formValues.bvn}-passport`,
      extension: ".png",
    };
    // let payload3 = new FormData();
    // payload3.append("file", passportURL);
    // const data3 = encrypt2(payload3);

    await authOApi
      .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
      .then(async (response) => {
        const res1 = response.data;
        result.signatureSent = res1;
        await authOApi
          .post(`${appUrls.UPLOAD_DOC_V2}`, payload2)
          .then(async (response) => {
            const res2 = response.data;
            result.scannedID = res2;
            await authOApi
              .post(`${appUrls.UPLOAD_DOC_V2}`, payload3)
              .then(async (response) => {
                const res3 = response.data;
                result.passportSent = await res3;
              });
          });
        result.success = true;
      })
      .catch((error) => {
        result.success = false;
        message.error(
          "Oops! Error Processing Documents", 10
        )
      });
    return result;
  };

  const addSignatory = async () => {
    setLoading(true);
    const results = await uploadToAzure();
    if (results.success) {
      let payload = {
        tin: tin,
        firstName: formV.firstName,
        lastName: formV.lastName,
        phoneNumber: formV.phone,
        dateOfBirth: moment(formV.dobirth).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
        email: formV.email,
        residentialAddress: formV.address,
        houseNumber: 0,
        streetName: formV.address,
        landMark: formV.landmark,
        areaOrState: formV.landmark,
        nationality: formV.nationality,
        cerPadId: formV.cerpac,
        mandateInstruction: formV.mandate,
        signature: results.signatureSent,
        passport: results.passportSent,
        scannedID: results.scannedID,
        bvn: formV.bvn,
      };
      const enc = encrypt2(payload);
    
      authOApi
        .post(`${appUrls.ADD_SIGNATORY}`, { data: enc })
        .then((response) => {
          const res = decryptHandler2(response.data);

          if (res.ResponseCode === "200") {
            message.success("Signatory added !");
            setFormV({
              bvn: "",
            });
            setFormValues({
              tin: res.tin,
              bvn: res.bvn,
              dobirth: res.dob,
              branch: res.branch,
              firstName: formValues.firstName,
              secondName: formValues.secondName,
              lastName: formValues.lastName,
              phone: formValues.phone,
              email: formValues.email,
              mandate: res.MandateInstruction,
            })
            setDob("");
            getSignatoriesByTin();
            setLoading(false);
            setCurrent(0);
            onClose();
          } else {
            message.error(res.ResponseDescription, 10);
            setLoading(false);
            setFormV({
              bvn: "",
            });
            setDob("");
            setCurrent(0);
            onClose();
          }
        })
        .catch((error) => {
          message.error("Could not add signatory. Please try again later", 10);
          setLoading(false);
        });
    }
  };

  const steps = [
    {
      title: "BVN Validation",
      content: (
        <SignatoryBvn
          formV={formV}
          handleFormC={handleFormC}
          onDateChange={onDateChange}
          dob={dob}
        />
      ),
    },
    {
      title: "Upload details",
      content: (
        <SignatoryForm
          formV={formV}
          handleFormC={handleFormC}
          bvnDetails={bvnDetails}
        />
      ),
    },
    {
      title: "Upload Document",
      content: (
        <MandateInstruction
          formV={formV}
          handleFormC={handleFormC}
          photoID={photoID}
          onDrop={onDrop}
          onDrops={onDrops}
          signatureUpload={signatureUpload}
          onDropss={onDropss}
          passport={passport}
        />
      ),
    },
  ];

  return (
    <div>
      <div className="d-flex justify-content-between px-2">
        <div></div>
        <div className="">
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
      </div>
      <div className="container-fluid pb-5 mt-5">
        <div class="mb-5">
          <div class="contasiner">
            <div className="steps-content">{steps[current].content}</div>
            <div className="text-right"></div>
            <div className="steps-action text-right">
              {current === steps.length - 3 && (
                <button
                  type="button"
                  className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                  onClick={validateForm}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Validate"
                  )}
                </button>
              )}
              {current > 0 && (
                <button
                  type="button"
                  className="btn btn-outline-danger px-5 mr-2 mb-1"
                  onClick={prev}
                >
                  Previous
                </button>
              )}
              {current < steps.length - 1 && current > 0 && (
                <button
                  type="button"
                  className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-1"
                  onClick={next}
                >
                  Next
                </button>
              )}
              {current === steps.length - 1 && (
                <button
                  type="button"
                  className="btn btn-outline-danger text-white pry-red-bg px-5 mb-1"
                  onClick={() => {
                    addSignatory();
                  }}
                  disabled={loading}
                >
                  {loading ? (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  ) : (
                    "Add signatory"
                  )}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignatoriesSteps;
