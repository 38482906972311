import { message } from "antd";
import { useState, useCallback, useMemo } from "react";

export default () => {
  const [imgSrc, setImgSrc] = useState(null);

  const [signatureUpload, setSignatureUpload] = useState("");
  const [livePhotoUpload, setLivePhotoUpload] = useState("");
  const [frontPage, setFrontPage] = useState("");
  const [frontPageURL, setFrontPageURL] = useState("");
  const [signatureURL, setSignatureURL] = useState("");
  const [utilBill, setUtilBill] = useState("");
  const [utilBillURL, setUtilBillURL] = useState("");
  const [uploadPic, setUploadPic] = useState("");
  const [uploadPicURL, setUploadPicURL] = useState("");
  const [signatureExtension, setSignatureExtension] = useState("");
  const [utilityBillExtension, setUtilityBillExtension] = useState("");
  const [frontPageExtension, setFrontPageExtension] = useState("");
  const [uploadedImgExtension, setUploadedImgExtension] = useState("");

  //upload docs
  const onDrops = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSignatureUpload(file);
      const base64 = await convertBase64(file);
      setSignatureURL(file);
      const extension = signatureUpload.path.slice(
        (Math.max(0, signatureUpload.path.lastIndexOf(".")) || Infinity) + 1
      );
      setSignatureExtension(`.${extension}`);
    }
  };

  const onDropsss = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setUploadPic(file);
      const base64 = await convertBase64(file);
      setUploadPicURL(file);
      const extension = uploadPic.path.slice(
        (Math.max(0, uploadPic.path.lastIndexOf(".")) || Infinity) + 1
      );
      setUploadedImgExtension(`.${extension}`);
    }
  };
  const onDropss = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setUtilBill(file);
      const base64 = await convertBase64(file);
      setUtilBillURL(file);
      const extension = utilBill.path.slice(
        (Math.max(0, utilBill.path.lastIndexOf(".")) || Infinity) + 1
      );
      setUtilityBillExtension(`.${extension}`);
    }
  };

  const onDrop = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setFrontPage(file);
      const base64 = await convertBase64(file);
      setFrontPageURL(file);
      const extension = frontPage.path.slice(
        (Math.max(0, frontPage.path.lastIndexOf(".")) || Infinity) + 1
      );
      setFrontPageExtension(`.${extension}`);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  //camera
  const onCapture = (imageData) => {
    setImgSrc(imageData.webP);
    setLivePhotoUpload(imageData.file);
  };

  // Use useCallback to avoid unexpected behaviour while rerendering
  const onError = useCallback((error) => {
    console.log(error);
  }, []);

  // Use useMemo to avoid unexpected behaviour while rerendering
  const config = useMemo(() => ({ video: true }), []);
  const livePic = new FormData();
  livePic.append("file", livePhotoUpload);

  //retake image
  const retake = () => {
    setImgSrc(null);
  };

  return [
    signatureUpload,
    livePhotoUpload,
    frontPage,
    onDrops,
    config,
    onError,
    imgSrc,
    retake,
    onDrop,
    onCapture,
    frontPageURL,
    signatureURL,
    onDropss,
    utilBill,
    utilBillURL,
    onDropsss,
    uploadPic,
    uploadPicURL,
    signatureExtension,
    utilityBillExtension,
    frontPageExtension,
    uploadedImgExtension,
  ];
};
