import React, { useState, useEffect } from "react";
import { Steps, message } from "antd";
import Spinner from "react-bootstrap/Spinner";
import DashboardLogin from "../components/login";
import Header from "../components/Header";
import { useHistory } from "react-router-dom";
// import "./index.css";

const DashboardMain = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(0);



  const validateUser = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, '20000');
    history.push("/admin/home");
  };

  const steps = [
    {
      title: "BVN Validation",
      content: <DashboardLogin />,
    },
  ];
  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 displayNone">
          <div className="leftDiv stickyDiv"></div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <Header />

          <div className="container-fluid pb-5 mt-5">
            <div class="row mb-5">
              <div class="container w-100">
                <div className="steps-content mb-1">
                  {steps[current].content}
                </div>
                <div className="text-right"></div>
                <div className="steps-action pb-1 text-right otherBtn shiftFromBottom">
                  {/* {current === steps.length - 1 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 validateBtn btn-block w-80"
                      onClick={validateUser}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Login"
                      )}
                    </button>
                  )} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardMain;
