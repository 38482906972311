import React from "react";
import { useHistory } from "react-router-dom";
import Info from "../../../../../../Images/Info_icon.svg";
import Warning from "../../../../../../Images/warning-icon.svg";

const BVN = ({ bvnValues, handleBvnChange, setDisableBtn }) => {
  const history = useHistory();
  if (bvnValues.accountNumber.length <= 9 && bvnValues.bvn.length <= 10) {
    setDisableBtn(true);
  } else {
    setDisableBtn(false);
  }
  return (
    <div className="w-80">
      <form action="">
        <div class="my-4 text-centers text-dark">
          <h3 class="mb-0 sec-headers">
            <span
              onClick={() => history.goBack()}
              style={{ cursor: "pointer" }}
            >
              <i className="fas fa-long-arrow-alt-left mr-2"></i>
            </span>
            BVN Validation
          </h3>
        </div>
        <div>
          <p className="pale-red-tone-bg pry-red-clr ftwt-600 ftsz-12 mb-0 px-1 py-2 mb-3 rounded-lg">
            <img src={Warning} className="mr-1" alt="warning icon" />
            {/* Kindly ensure that your BVN information is up to date */}
            For a seamless experience, please ensure your BVN information is up
            to date.
          </p>
        </div>

        <div class="form-row mb-3">
          <div class="form-group col-12">
            <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
              Existing account number
            </label>
            <input
              type="text"
              class="form-control pry-input-border"
              placeholder="Enter your existing sterling bank account number"
              value={bvnValues.accountNumber}
              onChange={handleBvnChange("accountNumber")}
            />
          </div>
          <div class="form-group col-12">
            <label
              for=""
              class="pry-grey-clr ftsz-12 ftwt-500 mb-1 d-flex justify-content-between align-items-center"
            >
              <span>Enter BVN</span>
              <div class="popover-wrapper position-relative">
                <div class="popover-title">
                  <img src={Info} alt="" />
                </div>
                <div class="popover-content ftsz-12 p-2">
                  <ul class="pl-3">
                    <li>
                      The Bank Verification Number (BVN) is an 11-digit number
                    </li>
                    <li>Dial *565*0# to check your BVN</li>
                  </ul>
                </div>
              </div>
            </label>
            <input
              type="text"
              class="form-control pry-input-border"
              id=""
              placeholder="Enter your BVN"
              value={bvnValues.bvn}
              onChange={handleBvnChange("bvn")}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default BVN;
