import React, { useEffect, useState } from 'react';
import { message, Steps } from 'antd';
import Header from './header';
import TinStep from './tin';
import UploadDocument from './upload/uploadDoc';
import CompletedForm from './completed';
import UpdateDetails from './update-details';
import TinExists from './tin/errors/exists';
import TinInvalid from './tin/errors/invalid';
import useModal from '../hooks/useModal';
import * as EmailValidator from 'email-validator';
import {
  apiUrl,
  apiUrl1,
  referenceEndpoint,
} from '../../../../../../../redux/actions/endpoint';
// import axios from "axios";
import useAddFiles from '../hooks/useAddFiles';
import moment from 'moment';
import { encrypt2 } from '../../../../../../../encr';
import { decryptHandler2 } from '../../../../../../../dec';
import TermsAndConditions from './modal/tc';
import useGoogle from '../hooks/useGoogle';
import ReferenceBusiness from './reference';
import AddRefOTP from './reference/Modals/AddRef';
import RefSuccessOTP from './reference/Modals/RefSucess';
import Spinner from 'react-bootstrap/Spinner';
import { encrypt1 } from '../../../../../../../encc';
import { appUrls } from '../../../../../../../service/urls';
// import { apiAccount } from "../../../../../../../service/apiAccount";
import { authOApi } from '../../../../../../../service/authOApi';
import Tbh from './modal/tbh';

const { Step } = Steps;

const ExistingSterlingCaresAccount = () => {
  const [
    showOtp,
    handleCloseOtp,
    handleShowOtp,
    showCompleted,
    handleCloseCompleted,
    handleShowCompleted,
    showExisting,
    handleCloseExisting,
    handleShowExisting,
    showInvalid,
    handleCloseInvalid,
    handleShowInvalid,

    // showTC,
    // handleCloseTC,
    // handleShowTC,

    // showAddRefOTP,
    // handleCloseAddRefOTP,
    // handleShowAddRefOTP,
    // showRefSuccessOTP,
    // handleCloseRefSuccessOTP,
    // handleShowRefSuccessOTP,
  ] = useModal();

  const [isTinValidated, setIsTinValidated] = useState(false);

  const [showAddRefOTP, setShowAddRefOTP] = useState(false);
  const handleCloseAddRefOTP = () => setShowAddRefOTP(false);
  const handleShowAddRefOTP = () => setShowAddRefOTP(true);

  const [showRefSuccessOTP, setShowRefSuccessOTP] = useState(false);
  const handleCloseRefSuccessOTP = () => setShowRefSuccessOTP(false);
  const handleShowRefSuccessOTP = () => setShowRefSuccessOTP(true);

  const [showTBH, setShowTBH] = useState(false);
  const handleCloseTBH = () => setShowTBH(false);
  const handleShowTBH = () => setShowTBH(true);

  const [showTC, setShowTC] = useState(false);
  const handleCloseTC = () => setShowTC(false);
  const handleShowTC = () => setShowTC(true);
  const [fileUrl, setFileUrl] = useState('');
  const [tin, setTin] = useState('');
  const [dataCustomerID, setDataCustomerID] = useState('');

  const [
    regCert,
    regCertURL,
    appBus,
    appBusURL,
    busRes,
    busResURL,
    refSent,
    refURL,
    others,
    othersURL,
    evidence,
    evidenceURL,
    onDrop,
    onDrops,
    onDropss,
    onDropsss,
    onDropssss,
    onDropsssss,
    documentArray,
    uploadD,
  ] = useAddFiles();

  const [address, setAddress, handleSelect] = useGoogle();

  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [branchCode, setBranchCode] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [customerNumber, setCustomerNumber] = useState('');
  const [accountInfo, setAccountInfo] = useState('');
  const [sectorList, setSectorList] = useState([]);
  const [industriesList, setIndustriesList] = useState([]);

  const [refList, setRefList] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

  const [showDoc, setShowDoc] = useState(false);
  const [docText, setDocText] = useState('');
  const [isTCClicked, setIsTCClicked] = React.useState(false);
  const [isProceedClicked, setIsProceedClicked] = React.useState(false);
  const [clientID, setClientID] = useState(null);
  const [isLoadingClientID, setIsLoadingClientID] = useState(false);

  const tcHandler = (value) => {
    setIsTCClicked(value);
  };

  const [formVal, setFormVal] = useState({
    bvn: '',
    tin: '',
    address: '',
    phone: '',
    regNum: '',
    dateOfInc: '',
    businessName: '',
    email: '',
    branch: '',
    taxOfficeId: '',
    stateOfRes: '',
    dirEmail: '',
    refCode: '',
  });

  const [sec, setSec] = useState({
    sector_id: '',
    industry_id: '',
  });

  const handleSecChanges =
    (name) =>
    ({ target }) => {
      setSec({
        ...sec,
        [name]: target.value,
      });

      if (
        target.value == 6401 ||
        target.value == 6402 ||
        target.value == 6403 ||
        target.value == 5701 ||
        target.value == 4601 ||
        target.value == 4602 ||
        target.value == 4603
      ) {
        setShowDoc(true);
        if (target.value == 6401) {
          setDocText('Annual license to practice ');
        }
        if (target.value == 6402) {
          setDocText('Annual license to practice ');
        }
        if (target.value == 6403) {
          setDocText('Annual license to practice ');
        }
        if (target.value == 5701) {
          setDocText(
            'Evidence of membership to professional body(Call to bar cert., CITN, ICAN etc) '
          );
        }
        if (target.value == 4601) {
          setDocText(
            'State government approval/ National UNI commission approval '
          );
        }
        if (target.value == 4602) {
          setDocText(
            'State government approval/ National UNI commission approval '
          );
        }
        if (target.value == 4603) {
          setDocText(
            'State government approval/ National UNI commission approval '
          );
        }
      } else {
        setShowDoc(false);
      }
    };

  let secVal = sec.sector_id;
  // let industry_id = sec.industry_id;

  const [formValues, setFormValues] = useState({
    tin: '',
    bvn: '',
    dobirth: '',
    branch: '',
    firstName: '',
    secondName: '',
    lastName: '',
    phone: '',
    email: '',
    houseNo: '',
    address: '',
    landmark: '',
    state: '',
    addressSummary: '',
    gender: '',
    imgBvn: '',
    cerpac: '',
    nationality: '',
    mandate: '',
    signature: '',
    scannedID: '',
    streetName: '',
    refAccount: '',
    imgBvn: '',
    refName: '',
    refMail: '',
    refSubject: '',
  });

  const handleFormChanges =
    (name) =>
    ({ target }) => {
      setFormValues({
        ...formValues,
        [name]: target.value,
      });
    };

  const handleFormChange =
    (name) =>
    ({ target }) => {
      setFormVal({
        ...formVal,
        [name]: target.value,
      });
    };

  const [tinDetails, setTinDetails] = useState({});

  //stepper
  const [current, setCurrent] = React.useState(0);
  const prev = () => {
    setCurrent(current - 1);
  };

  const next = () => {
    setCurrent(current + 1);
  };

  const JTB = () => {
    setLoading(true);
    const TinValue = tin;
    const data = encrypt1(TinValue);
    authOApi
      .get(`${appUrls.VALIDATE_TIN}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);

        if (res.ResponseCode === '00' && res.Data.IsVerified === true) {
          setIsTinValidated(true);
          setFormVal({
            address: res.Data.Address,
            phone: res.Data.Phone,
            regNum: res.Data.RcNumber,
            email: res.Data.Email,
            businessName: res.Data.TaxPayerName,
          });
          next();
          setLoading(false);
        } else if (res.ResponseCode !== '00' || res.Data.IsVerified === false) {
          next();
          setIsTinValidated(false);
          message.warning(<p>TIN validation was not successful</p>, 20);
          setFormVal({
            address: '',
            phone: '',
            regNum: '',
            email: '',
            businessName: '',
          });
          // message.error(res.ResponseDescription);
          // message.warning(
          //   <p>
          //     Dear customer, <br />
          //     We are unable to validate your TIN. <br /> Kindly visit the
          //     nearest FIRS/JTB office to activate your TIN
          //   </p>,
          //   20
          // );
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error('Unable to validate TIN. Please try again later', 10);
      });
  };

  const validateTIN = () => {
    setLoading(true);
    const TinValue = tin;
    const data = encrypt2(TinValue);
    authOApi
      .get(`${appUrls.VERIFY_BUSINESS}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);

        if (res.ResponseCode === '00') {
          setIsTinValidated(true);
          setFormVal({
            address: res.data.address,
            phone: res.data.phone,
            regNum: res.data.rcNumber,
            email: res.data.email,
            businessName: res.data.taxPayerName,
          });
          next();
          setLoading(false);
        } else if (res.ResponseCode !== '00') {
          // message.error(res.ResponseDescription)
          message.warning(
            <p>
              Dear customer, <br /> We are unable to validate your TIN from the
              FIRS portal. <br /> Kindly follow the link{' '}
              <a href="https://taxpromax.firs.gov.ng/" target="_blank">
                HERE
              </a>{' '}
              to get your TIN active.
            </p>,
            // "Dear customer,  We are unable to validate your TIN from the FIRS portal.  Kindly follow the link below to get your TIN active : https://taxpromax.firs.gov.ng/"
            20
          );
          setIsTinValidated(false);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        message.error(
          'Unable to verify business details. Please try again later',
          10
        );
      });
  };

  const getSignatoriesByTin = () => {
    setLoading(true);
    const data = encrypt2(tin);

    authOApi
      .get(`${appUrls.GET_SIGNATORIESBY_TIN}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);
        setRefList(res);
        localStorage.setItem('details', JSON.stringify(res[0]));
        setFormValues({
          bvn: res[0].Bvn,
          firstName: res[0].FirstName,
          secondName: res[0].MiddleName,
          lastName: res[0].LastName,
          dobirth: res[0].DateOfBirth,
          phone: res[0].PhoneNumber,
          email: res[0].Email,
          landMark: res[0].LandMark,
          state: res[0].AreaOrState,
          nationality: res[0].Nationality,
          tin: res[0].TIN,
          signature: res[0].Signature,
          passport: res[0].Passport,
          scannedID: res[0].ScannedID,
          streetName: res[0].StreetName,
          mandate: res[0].MandateInstruction,
        });
        setErrorMsg(!404);
        setLoading(false);
      })
      .catch((error) => {
        setErrorMsg(404);
        setLoading(false);
      });
  };

  const validateFirst = () => {
    if (current === 0) {
      if (!tin) {
        message.error('TIN not provided !');
        return;
      }
      // if (formVal.length === 0) {
      //   message.error("TIN not provided !");
      //   return;
      // }
      // next();
      // validateTIN();
      JTB();
    }
  };

  const isProceed = () => {
    if (current === 0) {
      if (!tin) {
        message.error('TIN not provided !');
        return;
      }
    }
    setIsProceedClicked(true);
    setCurrent(current + 1);
  };

  const validateFormTwo = () => {
    if (!formVal.businessName) {
      message.error('Business name not provided !', 10);
      return;
    }
    if (!formVal.businessName.length >= 36) {
      message.error('Business name cannot exceed 35 characters!', 10);
      return;
    }
    // if (!formVal.tin) {
    //   message.error("TIN not provided !");
    //   return;
    // }
    if (!formVal.regNum) {
      message.error('Registration number not provided !', 10);
      return;
    }
    if (!address) {
      message.error('Address not provided !', 10);
      return;
    }
    if (address.length >= 35) {
      message.error('Address must not be more than 35 characters !');
      return;
    }
    if (!formVal.email) {
      message.error('Email not provided !', 10);
      return;
    }
    if (!EmailValidator.validate(formVal.email)) {
      message.error('Email not valid!', 10);
      return;
    }
    if (!formVal.phone) {
      message.error('Phone number not provided !', 10);
      return;
    }
    if (!formVal.dateOfInc) {
      message.error('Date of incorporation not provided !', 10);
      return;
    }
    if (refList.length < 2) {
      message.error(
        'You need to provide at least 2 signatories before proceeding !',
        10
      );
      return;
    }
    next();
  };

  const validateFormThree = () => {
    if (documentArray.length === 0) {
      message.error('Minimum of one document is required !');
      return;
    }
    if (!formVal.branch) {
      message.error('Branch not provided !');
      return;
    }
    openAccount();
  };

  const openAccount = async () => {
    setLoading(true);
    let payload = {
      rcNumber: formVal.regNum,
      incorpDate: moment(formVal.dateOfInc).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      tin: tin,
      businessName: formVal.businessName,
      branch: formVal.branch,
      emailAddress: formVal.email,
      productCode: 'STERLING.CARES.NGO',
      currency: 'NGN',
      clientID: customerNumber,
      // clientID: refList[0].id,
      firstName: formValues.firstName,
      surName: formValues.lastName,
      middleName: formValues.secondName,
      street: formValues.streetName,
      sector: '4200',
      industry: '4202',
      nationality: 'NG',
      bvn: formValues.bvn,
      gender: formValues.gender,
      dob: moment(formValues.dobirth).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      title: '',
      email: formVal.email,
      state: formVal.stateOfRes,
      phone: formValues.phone ?? '',
      sms: formValues.phone,
      passportPhoto: formValues.passport,
      validIdType: '',
      validIdNumber: '',
      signature: formValues.signature,
      validId: '',
      reference: '',
      jointAccountTransferLimit: '',
      sqNo: 0,
      accountLinkCode: '',
      residence: address,
      stateResidence: formVal.stateOfRes,
      appId: '25',
      shortTitle: '',
      scumlNum: '',
      requestRefId1: '',
      requestRefId2: '',
      countryOfBirth: formValues.nationality,
      otherNationality: '',
      taxId: formVal.taxOfficeId,
      dao: formVal.refCode,
      branchCode: formValues.branch,
      statusJobTitle: '',
      nickName: '',
      corpLegalForm: '',
      isDirector: '',
      urlMandate: '',
      urlSignature: formValues.signature,
      otherName: formValues.secondName,
      corporateAddress: address,
      branchName: formVal.branch,
      internalRef: '',
      productName: 'BUSINESS ACCOUNT',
      refereeAccountNumber1: '',
      refereeAccountNumber2: '',
      refereeBank1: '',
      refereeBank2: '',
      source: 'Digital Onboarding',
      existingAccount: '',
      internalReference: '',
      chequeReference: '',
      iSsignatory: true,
      mandateAuth: '',
      classOfSig: '',
      mandateInstruction: formValues.mandate ?? '',
      identificationType: '',
      idNo: '',
      passportPic: formValues.passport,
      mandate: formValues.mandate ?? '',
      urlPhoto: '',
      idName: '',
      urlid: '',
      placeOfBirth: '',
      motherMadienName: '',
      religion: '',
      stateOfOrigin: formVal.stateOfRes,
      lga: '',
      occupation: '',
      natureOfControl: '',
      countryTaxRes: '',
      nearestBusstop: formValues.landmark,
      city_Town: formVal.stateOfRes,
      documents: [],
      isRestriction: true,
    };
    const enc = encrypt2(payload);
    await authOApi
      .post(`${appUrls.OPEN_CORPORATE_ACCT}`, { data: enc })
      .then(async (response) => {
        const res = decryptHandler2(response.data);

        if (res.responseCode === '1') {
          setAccountInfo(res.nuban);
          const docsPayload = {
            // isRestriction: true,
            nuban: res.nuban,
            dob: moment(formValues.dobirth).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
            bvn: formValues.bvn,
            surName: formValues.lastName,
            otherName: formValues.secondName,
            firstName: formValues.firstName,
            tin: tin,
            countryTaxRes: '',
            natureOfControl: '',
            phone: formValues.phone ?? '',
            iSsignatory: true,
            mandateAuth: '',
            classOfSig: '',
            identificationType: '',
            idNo: '',
            signature: formValues.signature,
            passportPic: formValues.passport,
            mandate: '',
            urlPhoto: '',
            urlSignature: '',
            urlMandate: '',
            idName: '',
            urlid: '',
            placeOfBirth: formValues.nationality,
            motherMadienName: '',
            religion: '',
            stateOfOrigin: '',
            lga: '',
            occupation: '',
            statusJobTitle: '',
            nationality: formValues.nationality,
            residence: address,
            state: formVal.stateOfRes,
            city_Town: formVal.stateOfRes,
            nearestBusstop: formValues.landmark,
            email: formVal.email,
            title: '',
            nickName: '',
            corpLegalForm: '',
            gender: '',
            isDirector: '',
            branchCode: formVal.branch,
            branchName: formVal.branch,
            customerId: res.customerId,
            incorpDate: moment(formVal.dateOfInc).format(
              'YYYY-MM-DDTHH:mm:ss.sssZ'
            ),
            industry: '',
            internalRef: '',
            productName: 'BUSINESS ACCOUNT',
            rcNumber: '',
            refereeAccountNumber1: '',
            refereeAccountNumber2: '',
            refereeBank1: '',
            refereeBank2: '',
            sector: '4202',
            source: 'Digital Onboarding',
            existingAccount: '',
            corporateAddress: '',
            internalReference: '',
            chequeReference: '',
            businessName: formVal.businessName,
            documents: documentArray,
          };
          const encryptDocPayload = encrypt2(docsPayload);
          authOApi
            .post(`${appUrls.SENDDATA_TO_ONEXPRESS}`, {
              data: encryptDocPayload,
            })
            .then((res) => {})
            .catch((error) => {});

          const payload = {
            addRestriction: {
              branchcode: formVal.branch,
              account: res.nuban,
              accountsType: 'ACCOUNTS',
              restriction_code: '47',
            },
          };

          const enc = encrypt2(payload);
          if (isTinValidated === false) {
            authOApi
              .post(`${appUrls.FIORANO_ADD_RESTRICTION}`, { data: enc })
              .then((response) => {
                const res = decryptHandler2(response.data);
              })
              .catch((error) => {});
          }

          if (isTinValidated) {
            let payloadTIN = {
              email: formVal.email,
              name: `${formValues.firstName} ${formValues.lastName}`,
            };
            const encT = encrypt2(payloadTIN);
            authOApi
              .post(`${appUrls.UPDATE_TIN_EMAIL}`, { data: encT })
              .then((response) => {
                const res = response.data;
              })
              .catch((error) => {});
          }
          handleShowCompleted();
          setLoading(false);
        } else if (res.responseCode === '104') {
          message.error('You currently have this account type !', 10);
        } else {
          message.error(res.responseText);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const confirmCompleted = () => {
    handleCloseCompleted();
    setCurrent(0);
  };

  const getSectors = () => {
    authOApi
      .get(
        `${appUrls.GET_SECTOR}`
        // , {
        //   headers: {
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        //   },
        // }
      )
      .then((response) => {
        const res = decryptHandler2(response.data);
        setSectorList(res.Sectors);
      })
      .catch((err) => {});
  };

  const getIndusties = () => {
    setIsLoading(true);
    let enc = encrypt2(secVal);
    authOApi
      .get(
        `${appUrls.GET_INDUSTRY}?Data=${enc}`
        // , {
        //   headers: {
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        //   },
        // }
      )
      .then((response) => {
        const res = decryptHandler2(response.data);

        setIndustriesList(res.Industries);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const sendEmail = () => {
    setLoading(true);
    let payload = { accountNumber: accountInfo, email: formVal.dirEmail };
    const enc = encrypt2(payload);
    //(decryptHandler2(enc));
    authOApi
      .post(`${appUrls.SEND_CORPORATE_CUSTOMER_EMAIL}`, { data: enc })
      .then((response) => {
        const res = decryptHandler2(response.data);

        setLoading(false);
        if (res === true) {
          message.success('Email sent');
          setFormVal({
            dirEmail: '',
          });
          setLoading(false);
        } else {
          message.error('Email could not be sent. Please try again');
          setLoading(false);
        }
        setLoading(false);
      });
  };

  const getBranches = () => {
    authOApi
      .get(
        `${appUrls.GET_STERLING_BRANCHES}`
        // , {
        //   headers: {
        //     "Access-Control-Allow-Origin": "*",
        //     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        //   },
        // }
      )
      .then((response) => {
        const res = decryptHandler2(response.data);
        //(res.SterlingBranches);
        setBranchCode(res.SterlingBranches);
        // getSectors();
      })
      .catch((err) => {});
  };

  const docUpload = () => {
    authOApi.get(`${appUrls.GET_DOC}`).then((response) => {
      setFileUrl(response.data);
      // getBranches();
      // getStates();
      // getSignatoriesByTin();
    });
  };

  const getStates = () => {
    authOApi
      .get(`${appUrls.GET_STATES}`)
      .then((response) => {
        setStateList(response.data);
      })
      .catch((error) => {});
  };

  const getCustomerID = (data) => {
    const payload = data;
    const enc = encrypt2(payload);
    authOApi
      .get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${enc}`)
      .then((res) => {
        const dec = decryptHandler2(res.data);
        setDataCustomerID(
          dec.GetAccountFullInfo.BankAccountFullInfo[0].CUS_NUM
        );
        //(dec.GetAccountFullInfo.BankAccountFullInfo[0].CUS_NUM);
      })
      .catch((error) => {
        message.error('Unable to get account details. Please try again later');
      });
  };

  const steps = [
    {
      title: 'TIN Validation',
      content: (
        <TinStep
          formVal={formVal}
          handleFormChange={handleFormChange}
          setTin={setTin}
          tin={tin}
        />
      ),
    },
    {
      title: 'Upload details',
      content: (
        <UpdateDetails
          formVal={formVal}
          handleFormChange={handleFormChange}
          tinDetails={tinDetails}
          setCustomerNumber={setCustomerNumber}
          getSignatoriesByTin={getSignatoriesByTin}
          refList={refList}
          setRefList={setRefList}
          errorMsg={errorMsg}
          formValues={formValues}
          setFormVal={setFormVal}
          setFormValues={setFormValues}
          handleFormChanges={handleFormChanges}
          address={address}
          setAddress={setAddress}
          handleSelect={handleSelect}
          validateTIN={validateTIN}
          JTB={JTB}
          tin={tin}
          isTinValidated={isTinValidated}
          clientID={clientID}
          setClientID={setClientID}
        />
      ),
    },
    {
      title: 'Document upload',
      content: (
        <UploadDocument
          regCert={regCert}
          appBus={appBus}
          busRes={busRes}
          refSent={refSent}
          others={others}
          evidence={evidence}
          onDrop={onDrop}
          onDrops={onDrops}
          onDropss={onDropss}
          onDropsss={onDropsss}
          onDropssss={onDropssss}
          onDropsssss={onDropsssss}
          branchCode={branchCode}
          formVal={formVal}
          handleFormChange={handleFormChange}
          getSignatoriesByTin={getSignatoriesByTin}
          stateList={stateList}
          handleSecChanges={handleSecChanges}
          sec={sec}
          sectorList={sectorList}
          industriesList={industriesList}
          isLoading={isLoading}
          showDoc={showDoc}
          docText={docText}
          tcHandler={tcHandler}
          isTCClicked={isTCClicked}
          handleShowTC={handleShowTC}
          handleShowTBH={handleShowTBH}
          formValues={formValues}
          getCustomerID={getCustomerID}
        />
      ),
    },
    {
      title: 'Add reference',
      content: (
        <ReferenceBusiness
          formValues={formValues}
          handleFormChange={handleFormChange}
          accountInfo={accountInfo}
          current={current}
          setCurrent={setCurrent}
          fileUrl={fileUrl}
          showAddRefOTP={showAddRefOTP}
          handleCloseAddRefOTP={handleCloseAddRefOTP}
          handleShowAddRefOTP={handleShowAddRefOTP}
          handleShowRefSuccessOTP={handleShowRefSuccessOTP}
          handleFormChanges={handleFormChanges}
          formVal={formVal}
        />
      ),
    },
  ];

  const [refereesList, setReferrsList] = useState([]);

  const getReference = () => {
    const data = encrypt2(accountInfo);
    authOApi
      .get(`${appUrls.GET_CUSTOMER_REFERENCES}?Data=${data}`)
      .then((response) => {
        const res = decryptHandler2(response.data);

        setReferrsList(res);
      });
  };

  const handleGenerateClientID = async () => {
    if (!formVal.businessName) {
      message.error('Business name not provided !', 10);
      return;
    }
    if (!formVal.businessName.length >= 36) {
      message.error('Business name cannot exceed 35 characters!', 10);
      return;
    }
    // if (!formVal.tin) {
    //   message.error("TIN not provided !");
    //   return;
    // }
    if (!formVal.regNum) {
      message.error('Registration number not provided !', 10);
      return;
    }
    if (!address) {
      message.error('Address not provided !', 10);
      return;
    }
    if (address.length >= 35) {
      message.error('Address must not be more than 35 characters !');
      return;
    }
    if (!formVal.email) {
      message.error('Email not provided !', 10);
      return;
    }
    if (!EmailValidator.validate(formVal.email)) {
      message.error('Email not valid!', 10);
      return;
    }
    if (!formVal.phone) {
      message.error('Phone number not provided !', 10);
      return;
    }
    if (!formVal.dateOfInc) {
      message.error('Date of incorporation not provided !', 10);
      return;
    }

    setIsLoadingClientID(true);

    let payload = {
      rcNumber: formVal.regNum,
      incorpDate: moment(formVal.dateOfInc).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      tin: tin,
      businessName: formVal.businessName,
      branch: formVal.branch,
      emailAddress: formVal.email,
      productCode: 'CLASSIC.CUR',
      currency: 'NGN',
      clientID: customerNumber,
      // clientID: refList[0].id,
      firstName: formValues.firstName,
      surName: formValues.lastName,
      middleName: formValues.secondName,
      street: formValues.streetName,
      sector: '4200',
      industry: '4202',
      nationality: 'NG',
      bvn: formValues.bvn,
      gender: formValues.gender,
      dob: moment(formValues.dobirth).format('YYYY-MM-DDTHH:mm:ss.sssZ'),
      title: '',
      email: formVal.email,
      state: formVal.stateOfRes,
      phone: formValues.phone,
      sms: formValues.phone,
      passportPhoto: formValues.passport,
      validIdType: '',
      validIdNumber: '',
      signature: formValues.signature,
      validId: '',
      reference: '',
      jointAccountTransferLimit: '',
      sqNo: 0,
      accountLinkCode: '',
      residence: address,
      stateResidence: formVal.stateOfRes,
      appId: '25',
      shortTitle: '',
      scumlNum: '',
      requestRefId1: '',
      requestRefId2: '',
      countryOfBirth: formValues.nationality,
      otherNationality: '',
      taxId: formVal.taxOfficeId,
      dao: formVal.refCode,

      branchCode: formValues.branch,
      statusJobTitle: '',
      nickName: '',
      corpLegalForm: '',
      isDirector: '',
      urlMandate: '',
      urlSignature: formValues.signature,
      otherName: formValues.secondName,
      corporateAddress: address,
      branchName: formVal.branch,
      internalRef: '',
      productName: 'BUSINESS ACCOUNT',
      refereeAccountNumber1: '',
      refereeAccountNumber2: '',
      refereeBank1: '',
      refereeBank2: '',
      source: 'Digital Onboarding',
      existingAccount: '',
      internalReference: '',
      chequeReference: '',
      iSsignatory: true,
      mandateAuth: '',
      classOfSig: '',
      mandateInstruction: formValues.mandate,
      identificationType: '',
      idNo: '',
      passportPic: formValues.passport,
      mandate: formValues.mandate,
      urlPhoto: '',
      idName: '',
      urlid: '',
      placeOfBirth: '',
      motherMadienName: '',
      religion: '',
      stateOfOrigin: formVal.stateOfRes,
      lga: '',
      occupation: '',
      natureOfControl: '',
      countryTaxRes: '',
      nearestBusstop: formValues.landmark,
      city_Town: formVal.stateOfRes,
      documents: [],
      isRestriction: true,
    };
    const enc = encrypt2(payload);

    await authOApi
      .post(`${appUrls.ADD_UNVERIFIED_TIN_USER}`, { data: enc })
      .then((res) => {
        const decryptedData = decryptHandler2(res.data);
        if (decryptedData.Data) {
          console.log('==> accountIDData!', decryptedData.Data.AccountId);
          setClientID(decryptedData.Data.AccountId);
          const clientData = {
            clientID: decryptedData.Data.AccountId,
            clientName: decryptedData.Data.Details.BusinessName,
          };
          localStorage.setItem('clientIDInfo', JSON.stringify(clientData));
        }
        setIsLoadingClientID(false);
      })
      .catch(() => {
        setIsLoadingClientID(false);
        message.error('Unable to generate clientID', 10);
      });

    // api fetch then set clientID to data response
  };

  useEffect(() => {
    docUpload();
    getBranches();
    getStates();
    getIndusties();
    getSectors();
  }, []);

  useEffect(() => {
    if (secVal) {
      getIndusties();
    } else {
      getSectors();
    }
  }, [secVal]);

  return (
    <div>
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 displayNone">
          <div className="leftDiv stickyDiv"></div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
          <Header />
          <div className="container-fluid pb-5 mt-5">
            <div class="row mb-5">
              <div class="container mr-3">
                <div className="steps-content mb-5">
                  {steps[current].content}
                </div>
                <div className="text-right"></div>
                <div className="steps-action text-right">
                  {current === steps.length - 4 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 btn-block w-80"
                      onClick={validateFirst}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        'Validate'
                      )}
                    </button>
                  )}
                  {current > 0 && current <= 3 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger px-5 mr-2 mb-1"
                      onClick={prev}
                    >
                      Previous
                    </button>
                  )}
                  {current < steps.length - 1 &&
                    current > 0 &&
                    current <= 1 && (
                      <button
                        type="button"
                        className="btn btn-outline-danger text-white pry-red-bg px-5 mr-sm-3 mb-1"
                        disabled={isLoadingClientID}
                        onClick={
                          isTinValidated
                            ? validateFormTwo
                            : handleGenerateClientID
                        }
                      >
                        {isTinValidated ? (
                          'Next'
                        ) : isLoadingClientID ? (
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        ) : (
                          'Submit'
                        )}
                      </button>
                    )}
                  {current === steps.length - 2 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-1"
                      onClick={validateFormThree}
                      disabled={loading || isTCClicked === false || uploadD}
                    >
                      {uploadD && (
                        <span>
                          Uploading doc ...
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                      {loading && (
                        <span>
                          Processing ...
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                      {!loading && !uploadD && 'Done'}
                    </button>
                  )}
                </div>
                {current === 0 && (
                  <div>
                    <div class="my-4 text-centers text-dark">
                      <h3 class="mb-0 sec-headers">NGOs Account</h3>
                    </div>
                    <div className="row pry-grey-clr mb-4 w-80">
                      <div className="col-md-6 ftsz-12">
                        <h6 className="ftsz-12 ftwt-500">Features</h6>
                        <ul className="custom-req-marker pl-3">
                          <li>
                            Minimum account opening balance of =N=10,000 (For
                            Legal Search).
                          </li>
                          <li>No transaction fees.</li>
                          <li>
                            Internet and mobile banking facility i.e., online
                            statement, SMS alert etc.
                          </li>
                          <li>Chequebook.</li>
                          <li>International funds transfer allowed.</li>
                          <li>Access to TBH community.</li>
                        </ul>
                      </div>
                      <div className="col-md-6 ftsz-12">
                        <h6 className="ftsz-12 ftwt-500">Requirements</h6>
                        <ul className="custom-req-marker pl-3">
                          <li>Taxpayer Identification Number (TIN).</li>
                          <li>Copy of Certificate of Registration.</li>
                          <li>
                            Copy of Charter / Memorandum and Articles of the
                            NGO.
                          </li>
                          <li>
                            Resolution signed by the Chairman and Secretary of
                            the NGO to open account with the bank. The
                            resolution should be sealed, specify the venue of
                            the meeting, officers present and the person who
                            presided, state the signatories and mandate on the
                            account.
                          </li>
                          <li>
                            Basic Information about business (business name,
                            business address, business email, Phone number,
                            Registration number and Date of incorporation).
                          </li>
                          <li>BVN & Date of Birth of each signatory.</li>
                          <li>
                            Basic Information of each signatory (Full Name,
                            Email Address, Gender, Residential Address,
                            Telephone number, Nationality)
                          </li>
                          <li>
                            Valid and acceptable means of Identification for
                            each signatory.
                          </li>
                          <li>Scanned signature for each signatory.</li>
                          <li>
                            Two satisfactory corporate references (Account
                            details)
                          </li>
                          <li>
                            One recent and clear passport photograph for each
                            signatory.
                          </li>
                          <li>
                            SCUML Certificate or proof to show you have
                            commenced the registration process for SCUML.
                          </li>
                          <li>
                            CERPAC- Combined Expatriate Residence Permit and
                            Alien Card for directors of the company and
                            signatories to the account who are foreign
                            nationals.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="pale-red-part-bg corporate-bg position-fixed"></div>
      <Header />
      <div className="d-flex justify-content-between px-2">
        <div></div>
        <div className="w-50">
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
      </div>
      <div className="container-fluid pb-5 mt-5">
        <div class="row mb-1">
          <div class="col-xl-7 col-lg-9 col-md-10 mx-auto">
            <div class="container">
              <div class="app-details-form-wrapper pry-grey-clr bg-white px-3 px-lg-5 py-5">
                <div className="steps-content">{steps[current].content}</div>
                <div className="text-right"></div>
                <div className="steps-action text-right">
                  {current === steps.length - 4 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                      onClick={validateFirst}
                      disabled={loading}
                    >
                      {loading ? (
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        />
                      ) : (
                        "Validate"
                      )}
                    </button>
                  )}
                  {current > 0 && current <= 3 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger px-5 mr-2 mb-1"
                      onClick={prev}
                    >
                      Previous
                    </button>
                  )}
                  {current < steps.length - 1 && current > 0 && current <= 1 && (
                    <button
                      type="button"
                      className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-1"
                      onClick={validateFormTwo}
                    >
                      Next
                    </button>
                  )}
                  {current === steps.length - 2 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-1"
                      onClick={validateFormThree}
                      disabled={loading || isTCClicked === false || uploadD}
                    >
                      {uploadD && (
                        <span>
                          Uploading doc ...
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                      {loading && (
                        <span>
                          Processing ...
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                      {!loading && !uploadD && "Done"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <CompletedForm
        showCompleted={showCompleted}
        handleCloseCompleted={handleCloseCompleted}
        confirmCompleted={confirmCompleted}
        accountInfo={accountInfo}
        formVal={formVal}
        next={next}
        handleFormChange={handleFormChange}
        sendEmail={sendEmail}
        loading={loading}
      />
      <TinExists
        showExisting={showExisting}
        handleCloseExisting={handleCloseExisting}
      />
      <TinInvalid
        showInvalid={showInvalid}
        handleCloseInvalid={handleCloseInvalid}
      />
      <AddRefOTP
        showAddRefOTP={showAddRefOTP}
        handleCloseAddRefOTP={handleCloseAddRefOTP}
        handleShowRefSuccessOTP={handleShowRefSuccessOTP}
      />
      <RefSuccessOTP
        showRefSuccessOTP={showRefSuccessOTP}
        handleCloseRefSuccessOTP={handleCloseRefSuccessOTP}
        setCurrent={setCurrent}
        getReference={getReference}
      />

      <TermsAndConditions showTC={showTC} handleCloseTC={handleCloseTC} />
      <Tbh showTBH={showTBH} handleCloseTBH={handleCloseTBH} />
    </div>
  );
};

export default ExistingSterlingCaresAccount;
