import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AtmLocator from './components/AtmLocator';
import BranchLocator from './components/BranchLocator';
import NewSideCarousel from './components/newPages/newSideCarousel';
import './newLanding.css';
import axios from 'axios';
import { decryptHandler2 } from '../../dec';
import { apiUrl, apiUrl3 } from '../../redux/actions/endpoint';
import NewMainContent from './components/newPages/NewMainContent';
import SterlingLogo from '../../Images/Sterling_Bank_Logo_Straight_grey.png';
import { landingAction, welcomeAction } from '../../redux/actions';
import { encrypt2 } from '../../encr';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
// import { apiAccount } from '../../service/apiAccount';
import { appUrls } from '../../service/urls';
import { message } from 'antd';
import { authOApi } from '../../service/authOApi';

const NewLandingPage = () => {
  const { page } = useSelector(({ welcome }) => welcome);
  const dispatch = useDispatch();
  const history = useHistory();

  const [branches, setBranches] = useState([]);

  const fetchBranch = () => {
    authOApi
      .get(`${appUrls.GET_STERLING_BRANCHES}`)
      .then((response) => {
        const res = decryptHandler2(response.data);
        setBranches(res.SterlingBranches);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const verifyToken = () => {
    const storedToken = localStorage.getItem('accessToken');
    let payload = {
      token: storedToken,
    };
    authOApi
      .post(`${appUrls.VERIFY_TOKEN}`, payload)
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const refreshToken = () => {
    const storedToken = localStorage.getItem('accessToken');
    const storedRefreshToken = localStorage.getItem('accessToken');
    let payload = {
      accessToken: storedToken,
      refreshToken: storedRefreshToken,
    };
    authOApi
      .post(`${appUrls.REFRESH_TOKEN}`, payload)
      .then((response) => {
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const login = () => {
    let payload = {
      email: 'adetayo.abiodun@sterling.ng',
      password: 'Admin123@',
    };
    authOApi
      .post(`${appUrls.LOGIN}`, payload)
      .then((response) => {
        localStorage.setItem('accessToken', response.data.data.accessToken);
        localStorage.setItem('refreshToken', response.data.data.refreshToken);
        refreshToken();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  async function getToken() {
    const formData = new URLSearchParams({
      grant_type: 'client_credentials',
      scope: 'camsapi',
      client_id: encrypt2(process.env.REACT_APP_AUTHO_CLIENT_ID),
      client_secret: encrypt2(process.env.REACT_APP_AUTHO_CLIENT_SECRET),
    });

    try {
      const { data } = await axios.post(
        'https://docamsapigatewayidentityserver-api.sterlingapps.p.azurewebsites.net/connect/token',
        formData,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
      if (data.access_token) {
        const encryptedToken = encrypt2(data.access_token);

        sessionStorage.setItem('OAuthToken', encryptedToken);
      }
    } catch (error) {
    }
  }

  const switchBtn = () => {
    dispatch(landingAction.setLanding('switch'));
  };

  const switchBtnEx = () => {
    dispatch(landingAction.setLanding('switchEx'));
  };

  const switchBranch = () => {
    dispatch(welcomeAction.setWelcome('branch'));
  };

  const switchAtm = () => {
    // // dispatch(welcomeAction.setWelcome("atm"));
    history.push(`/atm-locator`);
  };


  useEffect(() => {
    fetchBranch();
    login();
    getToken();
  }, []);

  // console.log(
  //   decryptHandler2(
  //     "c0616fc012be2baabbc333e9bff53aaf2444916b42800dcf9370d2477b0beb5d662bf5939fea090c1f58c5afacf7152d5db12c4d21eaaabe23438e1ba476370e45e28227ae6f3e1d20412ee190a0c495"
  //   )
  // );

  return (
    <>
      <div className="row landingContainer">
        <div className="col-lg-5">
          <NewSideCarousel />
        </div>
        <div className="col-lg-7 pt-5">
          {page === 'main' && <NewMainContent />}
          {page === 'atm' && <AtmLocator branches={branches} />}
          {page === 'branch' && <BranchLocator branches={branches} />}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-around',
          alignContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          bottom: '-10px',
          width: '100%',
        }}
        className="newH2 newH2Shadow"
      >
        <div className="sterlLogo">
          <img src={SterlingLogo} alt="sterling logo" />
        </div>
        <div className="menuI">
          <ul>
            <li onClick={switchBtn}>New Customers</li>
            <li onClick={switchBtnEx}>Existing Customers</li>
            <li onClick={switchAtm}>ATM & Branch Locator</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default NewLandingPage;
