import React from "react";
import { Link } from "react-router-dom";

const ExBusinessAccount = () => {
  return (
    <div>
      <div className="">
        <div className="d-flex justify-content-between px-4">
          <h5 className="sec-header mb-3">Business account</h5>
          <div className="mb-4">
            <Link
              to="/existing-businessAccount"
              className="pry-red-bg rounded-lg px-5 py-3 text-white"
            >
              Proceed
            </Link>
          </div>
        </div>
        <div className="row pry-grey-clr mb-4">
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Features</h6>
            <ul className="custom-req-marker pl-3">
              <li>
                Minimum account opening balance of =N=5,000 (Not Restrictive).
              </li>
              <li>Access to overdraft facility.</li>
              <li>AMF at =N=1/mille (Negotiable with huge turnover).</li>
              <li>Access to business finance.</li>
              <li>
                Internet and mobile banking facility i.e. online statement, SMS
                alert etc..
              </li>
              <li>Chequebook.</li>
              <li>International funds transfer allowed.</li>
            </ul>
          </div>
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Requirements</h6>
            <ul className="custom-req-marker pl-3">
              <li>Certificate of Registration.</li>
              <li>Application form for Registration of Business name.</li>
              <li>Taxpayer identification number TIN.</li>
              <li>Partnership Deed (where applicable).</li>
              <li>
                Basic information about business (business name, business
                address, business email and phone number, Registration number).
              </li>
              <li>BVN of each signatory.</li>
              <li>
                Valid and acceptable means of Identification for each signatory.
              </li>
              <li>
                Basic information of each signatory/proprietor. (i.e., Name,
                Place and date of birth, Gender, Address, Telephone number)
              </li>
              <li>Two satisfactory corporate references.</li>
              <li>One recent passport photograph of each signatory.</li>
              <li>
                Power of attorney duly executed by the registered business owner
                where the signatory is different from the listed proprietor.
              </li>
              <li>
                For professional bodies, evidence of duly registered membership
                of the professional body is required.
              </li>
              <li>
                CERPAC- Combined Expatriate Residence Permit and Alien Card for
                directors of the company and signatories to the account who are
                foreign nationals.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExBusinessAccount;
