import React, { useState } from "react";
import { message } from "antd";
import axios from "axios";
import Dropzone from "react-dropzone";
import { decryptHandler2 } from "../../../dec";
import { encrypt2 } from "../../../encr";
import { apiUrl } from "../../../redux/actions/endpoint";
import useFiles from "../Hooks/useFiles";
import useModal from "../Hooks/useModal";
import AgreeModal from "../Modals/Agree";
import DisAgreeModal from "../Modals/Disagree";
import CommentModal from "../Modals/Comment";
import { apiAccount } from "../../../service/apiAccount";
import { appUrls } from "../../../service/urls";

const NonSterlingContent = () => {
  const [onDrop, signature, signatureURL] = useFiles();
  const [isVerified, setIsVerified] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingTwo, setIsLoadingTwo] = useState(false);
  const [refData, setRefData] = useState({});
  const [signatureSent, setSignatureSent] = useState("");
  const useQuery = () => new URLSearchParams(window.location.search);
  let query = useQuery();
  const tokenNeeded = query.get("token");
  //forms state
  const [formValues, setFormValues] = useState({
    refereeNuban: "",
    accountName: "",
    accountNumber: "",
    relationship: "",
    comment: "",
  });
  //handling form state change
  const handleFormChange =
    (name) =>
    ({ target }) => {
      setFormValues({
        ...formValues,
        [name]: target.value,
      });
    };

  const [
    showAgreeModal,
    handleCloseAgreeModal,
    handleShowAgreeModal,

    showDisAgreeModal,
    handleCloseDisAgreeModal,
    handleShowDisAgreeModal,

    showCommentModal,
    handleShowCommentModal,
    handleCloseCommentModal,
  ] = useModal();

  const verifyToken = () => {
    const tokReg = tokenNeeded.replace(/\s+/g, "+");
    setLoading(true);
    apiAccount
      .post(`${appUrls.VERIFY_REFRENCE_TOKEN}?token=${tokReg}`)
      .then((response) => {
        setRefData(response.data);
        setFormValues({
          refereeNuban: response.data.refNuban,
          accountName: response.data.customerFullName,
          accountNumber: response.data.customerNuban,
          relationship: "",
        });
        setIsVerified(true);
        setLoading(false);
      })
      .catch((error) => {
        setIsVerified(false);
        setLoading(false);
      });
  };

  const consent = () => {
    setIsLoading(true);
    const imageArr = signatureURL.split(",");
    const imageSplit = imageArr[1];

    let payload = {
      appId: "25",
      folderName: `digitalOnboarding reference document - ${formValues.accountName} folder 1`,
      fileName: `digitalOnboarding reference document - ${formValues.firstName} folder 1`,
      base64String: imageSplit,
    };
    const data1 = encrypt2(payload);
    apiAccount
      .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
        data: data1,
      })
      .then((response) => {
        const res = decryptHandler2(response.data);
        setSignatureSent(res.URL);
        // signatureSent = res.URL;
        let realSignature = res.URL;
        let payload1 = {
          refId: refData.refId,
          refNuban: formValues.refereeNuban,
          customerNuban: formValues.accountNumber,
          customerFullName: formValues.accountName,
          lengthOfRelationship: formValues.relationship,
          documentUrl: realSignature,
          approve: true,
        };
        const enc = encrypt2(payload1);
        apiAccount
          .post(`${appUrls.PROVIDE_REFERENCE_CONSENT}`, { data: enc })
          .then((response) => {
            const res = decryptHandler2(response.data);

            handleShowAgreeModal();
            setIsLoading(false);
          })
          .catch((error) => {
            message.error("Unable to give consents now. Please try again");
            setIsLoading(false);
          });
      })
      .catch((error) => {
        setIsLoading(false);
      });
  };

  const noConsent = () => {
    setIsLoadingTwo(true);
    const imageArr = signatureURL.split(",");
    const imageSplit = imageArr[1];

    let payload = {
      appId: "25",
      folderName: `digitalOnboarding reference document - ${formValues.accountName} folder 1`,
      fileName: `digitalOnboarding reference document - ${formValues.firstName} folder 1`,
      base64String: imageSplit,
    };
    const data1 = encrypt2(payload);
    apiAccount
      .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
        data: data1,
      })
      .then((response) => {
        const res = decryptHandler2(response.data);
        setSignatureSent(res.URL);
        let realSignature = res.URL;
        // signatureSent = res.URL;
        let payload1 = {
          refId: refData.refId,
          refNuban: formValues.refereeNuban,
          customerNuban: formValues.accountNumber,
          customerFullName: formValues.accountName,
          lengthOfRelationship: formValues.relationship,
          documentUrl: realSignature,
          approve: false,
          comment: formValues.comment
        };
        const enc = encrypt2(payload1);
        apiAccount
          .post(`${appUrls.PROVIDE_REFERENCE_CONSENT}`, { data: enc })
          .then((response) => {
            const res = decryptHandler2(response.data);

            handleShowDisAgreeModal();
            setIsLoadingTwo(false);
          })
          .catch((error) => {
            message.error("Unable to give consents now. Please try again");
            setIsLoadingTwo(false);
          });
      })
      .catch((error) => {
        setIsLoadingTwo(false);
      });
  };

  const validateConsent = () => {
    if (!formValues.refereeNuban) {
      message.error(
        "Oops!!! You're missing something. Please provide your Account number"
      );
      return;
    }
    if (!formValues.relationship || formValues.relationship === "0") {
      message.error(
        "Oops!!! You're missing something. Please provide relationship length"
      );
      return;
    }
    consent();
  };

  const validateNoConsent = () => {
    if (!formValues.refereeNuban) {
      message.error(
        "Oops!!! You're missing something. Please provide your Account number"
      );
      return;
    }
    if (!formValues.relationship || formValues.relationship === "0") {
      message.error(
        "Oops!!! You're missing something. Please provide relationship length"
      );
      return;
    }
    handleShowCommentModal();
  };
  return (
    <div>
      {!isVerified && (
        <div className="text-center font-weight-bold">
          Please click on the button below to give consent <br />
          <button
            type="button"
            className="btn btn-outline-danger px-5 mr-2 mb-3 mt-5"
            onClick={verifyToken}
            disabled={loading}
          >
            {loading ? "Please wait ..." : "Give consent"}
          </button>
        </div>
      )}
      {isVerified && (
        <div>
          <form action="">
            <div className="my-4 text-center text-body">
              <h5 className="mb-0 sec-header">Confirm reference</h5>
              <h6 className="mb-0 fw-regular ftsz-12">
                The file format should be JPEG or PDF
              </h6>
            </div>
            <div className="col-12 mb-3">
              <div className="form-row">
                <div className="col-12 col-md-12 mb-3 text-left">
                  <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                    Name of account holder
                  </label>
                  <input
                    type="text"
                    className="form-control pry-input-border"
                    placeholder="Enter referee’s account number"
                    value={formValues.accountName}
                    onChange={handleFormChange("accountName")}
                    disabled
                  />
                </div>
                <div className="col-12 col-md-12 mb-3 text-left">
                  <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                    Account number of account holder
                  </label>
                  <input
                    type="text"
                    className="form-control pry-input-border"
                    placeholder="Enter referee’s account number"
                    value={formValues.accountNumber}
                    onChange={handleFormChange("accountNumber")}
                    disabled
                  />
                </div>
                <div className="col-12 col-md-12 mb-3 text-left">
                  <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                    Referee’s account number
                  </label>
                  <input
                    type="text"
                    className="form-control pry-input-border"
                    placeholder="Enter referee’s account number"
                    value={formValues.refereeNuban}
                    onChange={handleFormChange("refereeNuban")}
                    // disabled
                  />
                </div>
                <div className="col-12 col-md-6 mb-3 text-left">
                  <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                    Length of relationship with the account holder
                  </label>
                  <input
                    type="text"
                    className="form-control pry-input-border"
                    placeholder="Enter length of relationship"
                    value={formValues.relationship}
                    onChange={handleFormChange("relationship")}
                  />
                </div>
                <div className="col-12 col-md-6 mb-3 ftsz-12 text-left">
                  <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
                    Upload Reference form you received via email
                  </h6>

                  <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                    <Dropzone
                      onDrop={onDrop}
                      multiple={false}
                      accept="image/jpeg, image/png, .pdf, .doc"
                    >
                      {({ getRootProps, getInputProps }) => (
                        <section className="doc-uploads">
                          <div {...getRootProps()}>
                            <input {...getInputProps()} placeholder="" />
                            {!!signature.length &&
                              signature.map((file) => (
                                <p className="doc-texts">{file.path}</p>
                              ))}

                            {!signature.length && (
                              <section className="doc-texts">
                                Drag 'n' drop , or click to select <br />
                              </section>
                            )}
                          </div>
                        </section>
                      )}
                    </Dropzone>
                  </div>
                  {signature ? (
                    <p>{signature.path}</p>
                  ) : (
                    <p>No file uploaded</p>
                  )}
                </div>
              </div>
            </div>
            <div>
              <button
                type="button"
                className="btn btn-outline-danger px-5 mr-2 mb-3"
                onClick={validateNoConsent}
                disabled={isLoadingTwo}
              >
                {isLoadingTwo ? "Declining ..." : "I do not consent"}
              </button>
              <button
                type="button"
                className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-3"
                onClick={validateConsent}
                disabled={isLoading}
              >
                {isLoading ? "Approving ..." : "I consent"}
              </button>
            </div>
          </form>
          <AgreeModal
            showAgreeModal={showAgreeModal}
            handleCloseAgreeModal={handleCloseAgreeModal}
          />
          <DisAgreeModal
            showDisAgreeModal={showDisAgreeModal}
            handleCloseDisAgreeModal={handleCloseDisAgreeModal}
          />
          <CommentModal
            showCommentModal={showCommentModal}
            handleCloseCommentModal={handleCloseCommentModal}
            formValues={formValues}
            handleFormChange={handleFormChange}
            noConsent={noConsent}
          />
        </div>
      )}
    </div>
  );
};

export default NonSterlingContent;
