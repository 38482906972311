import React, { useState, useEffect } from "react";
import AtmLocator from "./components/AtmLocator";
import BranchLocator from "./components/BranchLocator";
import MainContent from "./components/MainContent";
import SideCarousel from "./components/SideCarousel";
import { useSelector } from "react-redux";
import { apiUrl, apiUrl3 } from "../../redux/actions/endpoint";
import axios from "axios";
import "./welcome.css";
import { decryptHandler2 } from "../../dec";
import { encrypt2 } from "../../encr";
import { apiAccount } from "../../service/apiAccount";
import { appUrls } from "../../service/urls";
import { authOApi } from "../../service/authOApi";

const HomePage = () => {
  const { page } = useSelector(({ welcome }) => welcome);

  const [branches, setBranches] = useState([]);
  const fetchBranch = () => {
    authOApi
      .get(`${appUrls.GET_STERLING_BRANCHES}`)
      .then((response) => {
        const res = decryptHandler2(response.data);
        setBranches(res.SterlingBranches);
      })
      .catch((error) => {
        console.log(error);
      });
  };



  useEffect(() => {
    fetchBranch();
  }, []);
  return (
    <div>
      <div className="container-fluid px-sm-0 newBg">
        <div className="row">
          <div className="col-md-4">
            <SideCarousel />
          </div>
          <div className="col-12 col-md-8 pt-5">
            {page === "main" && <MainContent />}
            {page === "atm" && <AtmLocator branches={branches} />}
            {page === "branch" && <BranchLocator branches={branches} />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
