import React from "react";
import Modal from "react-bootstrap/Modal";
import SuccessIcon from "../../../../../../Images/newImg/success-icon.svg";

const SuccessAcct = ({
    showSuccess,
    handleCloseSuccess,
    customerNumber,
    bvnResults,
    bvnRes
}) => {

    return (
        <div>
            <Modal
                show={showSuccess}
                onHide={handleCloseSuccess}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <div className="text-center">
                        <div className="mb-5">
                            <h6 className="sec-header text-body mb-3 px-sm-5 mx-sm-5">
                                <img src={SuccessIcon} alt="success icon" />
                            </h6>
                            {/* bvnRes is undefined and can't be used */}
                            <p className="ftsz-regular">
                                Your account number <span style={{ fontWeight: "700" }}>{`${customerNumber}`} </span> , has been sent to your registered email <span style={{ fontWeight: "700" }}>{`${bvnResults?.email}`}</span>. Thank you
                            </p>
                            <div className="d-flex justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-danger pry-red-bg px-5 mb-3"
                                    onClick={handleCloseSuccess}
                                >
                                    Okay
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default SuccessAcct;
