import { useState } from "react";

export default () => {
  //otp modal
  const [showOtp, setShowOtp] = useState(false);
  const handleCloseOtp = () => setShowOtp(false);
  const handleShowOtp = () => setShowOtp(true);

  const [showDeleted, setShowDeleted] = useState(false);
  const handleCloseDeleted = () => setShowDeleted(false);
  const handleShowDeleted = () => setShowDeleted(true);

  //completed modal
  const [showCompleted, setShowCompleted] = useState(false);
  const handleCloseCompleted = () => setShowCompleted(false);
  const handleShowCompleted = () => setShowCompleted(true);

  //existing tin modal
  const [showExisting, setShowExisting] = useState(false);
  const handleCloseExisting = () => setShowExisting(false);
  const handleShowExisting = () => setShowExisting(true);

  //invalid tin modal
  const [showInvalid, setShowInvalid] = useState(false);
  const handleCloseInvalid = () => setShowInvalid(false);
  const handleShowInvalid = () => setShowInvalid(true);

  const [showTC, setShowTC] = useState(false);
  const handleCloseTC = () => setShowTC(false);
  const handleShowTC = () => setShowTC(true);

  const [showAddRefOTP, setShowAddRefOTP] = useState(false);
  const handleCloseAddRefOTP = () => setShowAddRefOTP(false);
  const handleShowAddRefOTP = () => setShowAddRefOTP(true);

  const [showRefSuccessOTP, setShowRefSuccessOTP] = useState(false);
  const handleCloseRefSuccessOTP = () => setShowRefSuccessOTP(false);
  const handleShowRefSuccessOTP = () => setShowRefSuccessOTP(true);

  return [
    showOtp,
    handleCloseOtp,
    handleShowOtp,
    showCompleted,
    handleCloseCompleted,
    handleShowCompleted,
    showExisting,
    handleCloseExisting,
    handleShowExisting,
    showInvalid,
    handleCloseInvalid,
    handleShowInvalid,
    showDeleted,
    handleShowDeleted,
    handleCloseDeleted,
    showTC,
    handleCloseTC,
    handleShowTC,
    showAddRefOTP,
    handleCloseAddRefOTP,
    handleShowAddRefOTP,
    showRefSuccessOTP,
    handleCloseRefSuccessOTP,
    handleShowRefSuccessOTP,
  ];
};
