import React from "react";
import { Link } from "react-router-dom";
import SterlingBall from "../../../../../../../../Images/ball.png";

const Header = () => {
  return (
    <div>
      <div className="d-flex justify-content-between px-5 mt-4">
        <div></div>
        <div className="ballDiv">
          <Link to="/">
            <img
              src={SterlingBall}
              class="img-fluid"
              alt="Sterling Bank Logo"
            />
          </Link>
        </div>
      </div>
    </div>
    // <div>
    //   <div class="row my-5 align-items-end">
    //     <div class="col logo">
    //       <Link to="/">
    //         <img
    //           src={SterlingLogo}
    //           class="img-fluid"
    //           alt="Sterling Bank Logo"
    //         />
    //       </Link>
    //     </div>
    //     <div class="col">
    //       <div className="px-4">
    //         <h1 class="mb-0 pry-grey-clr pry-header text-right">
    //           Company Account
    //         </h1>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Header;
