import React, { useState } from "react";
import BlackIcon from "../../../../../../../Images/warning-icon.svg";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { message } from "antd";
import {
  apiUrl,
  referenceEndpoint,
} from "../../../../../../../redux/actions/endpoint";
import axios from "axios";
import { encrypt2 } from "../../../../../../../encr";
import { decryptHandler2 } from "../../../../../../../dec";
import { apiAccount } from "../../../../../../../service/apiAccount";
import { appUrls } from "../../../../../../../service/urls";
import { authOApi } from "../../../../../../../service/authOApi";

const VerifyReferee = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const { refInfo } = useSelector(({ ref }) => ref);

  const sendRefMessage = () => {
    setIsLoading(true);
    const payload = {
      email: refInfo.email,
      customerAccount: refInfo.customerAccount,
      refAccount: refInfo.refAccount,
      refId: refInfo.refId,
    };

    const data = encrypt2(payload);
    authOApi
      .post(`${appUrls.SEND_REFEREE_CONSENTBY_EMAIL}`, { data: data })
      .then((response) => {
        const res = decryptHandler2(response.data);
        message.success(
          "An email has been sent to your Referee, you will be notified of their response(s)"
        );
        setIsLoading(false);
      })
      .catch((error) => {
        message.error(error.data.responseDescription);
        setIsLoading(false);
      });
  };
  return (
    <div>
      <div class="col-xl-6 col-lg-8 col-md-10 mx-auto">
        <div class="app-details-form-wrapper pry-grey-clr bg-white px-3 px-lg-5 pt-4 pb-5">
          <div class="">
            <div class="mb-5">
              <h6 class="sec-header text-body text-center mb-3 px-sm-5 mx-sm-5">
                Add reference
              </h6>
              <div class="mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  Referee’s account number
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border bg-white"
                  placeholder="Enter your referee’s account number"
                  value={refInfo.CustomerAccount}
                  disabled
                />
              </div>
              <div class="mb-3">
                <label for="" class="form-label ftsz-12 ftwt-500 mb-1">
                  Referee’s Bank
                </label>
                <input
                  type="text"
                  class="form-control pry-input-border bg-white"
                  value="STERLING BANK"
                  disabled
                />
              </div>
              <div class="shadow d-flex align-items-center justify-content-between text-body ftsz-12 ftwt-600 mb-0 mt-3 px-3 py-2 mb-3 rounded-12">
                <span>
                  <img src={BlackIcon} class="mr-3" alt="" />
                  Please wait, Validating account...
                </span>
                {/* <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div> */}
              </div>
              <div class="mb-4">
                <ul class="pl-3 text-left">
                  <li class="custom-grey-marker mb-2">
                    Account has been in operation for more than 6 months.
                  </li>
                  <li class="custom-grey-marker mb-2">
                    Account is not dormant
                  </li>
                  <li class="custom-grey-marker mb-2">
                    Category criteria (References from a registered business can
                    only be used for an individual account or another registered
                    business but a reference from a registered business is
                    suitable for all forms of current account.)
                  </li>
                </ul>
              </div>

              <div class="text-right">
                <button
                  class="btn btn-outline-danger px-3 mb-3 mr-3"
                  onClick={() => history.goBack()}
                >
                  Add more referee
                </button>
                <button
                  type="button"
                  class="btn btn-danger pry-red-bg px-5 mb-3"
                  disabled={isLoading}
                  onClick={sendRefMessage}
                >
                  Send Mail to Referee
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyReferee;
