import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { useHistory } from "react-router-dom";
import Map from "./map";
import SterlingBall from "../../../../../Images/ball.png";
import arrow from "../../../../../Images/arrow_map.svg";
import arrow2 from "../../../../../Images/arrow_map_2.svg";
import atmMap from "../../../../../Images/atm_map_icon.svg";
import branchMap from "../../../../../Images/branch_map_icon.svg";
import styles from "./atmlocation.module.css";
import AtmStatus from "./atmStatus";
import axios from "axios";
import { apiUrl } from "../../../../../redux/actions/endpoint";
import { apiAccount } from "../../../../../service/apiAccount";
import { appUrls } from "../../../../../service/urls";
import { authOApi } from "../../../../../service/authOApi";

export default function Atm() {
  const [showAtmStatus, setShowAtmStatus] = useState(true);
  const [atm, setAtm] = useState(true);
  const [branch, setBranch] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [atmLocations, setAtmLocations] = useState([]);
  const [branchLocations, setBranchLocations] = useState([]);
  const [defaultPosition, setDefaultPosition] = useState({});

  const history = useHistory();

  useEffect(() => {
    let mounted = false;
    (async () => {
      mounted = true;
      if (mounted) {
        setIsLoading(true);
        navigator.geolocation.getCurrentPosition(showPosition);
      }
    })();
    return () => {
      mounted = false;
    };
  }, []);

  async function showAllLocations(location) {
    const [branchResponse, atmResponse] = await Promise.all([
			authOApi.get(
				`${appUrls.GET_NEAREST_BRANCHES}?Longitude=${location?.coords?.longitude}&Latitude=${location?.coords?.latitude}`
			),
			authOApi.get(
				`${appUrls.GET_NEAREST_ATMS}?Longitude=${location?.coords?.longitude}&Latitude=${location?.coords?.latitude}`
			),
		]);
    setAtmLocations(() => [...atmResponse.data.data]);
    setBranchLocations(() => [...branchResponse.data.data]);
    setIsLoading(false);
  }

  function showPosition(location) {
    setDefaultPosition({
      lng: location?.coords?.longitude,
      lat: location?.coords?.latitude,
    });
    showAllLocations(location);
  }

  return (
    <div className={styles.atmContainer}>
      <div className={styles.atmHeader}>
        <div className={styles.atmHeader_content}>
          <button
            type="button"
            className="bare-btn sec-header"
            onClick={() => history.push("/")}
          >
            <i className="fas fa-long-arrow-alt-left fontStyle"></i>
          </button>
          <h5 className={`${styles.headerText} sec-header mb-0`}>
            Locate a Branch or ATM
          </h5>
          <img
            src={SterlingBall}
            alt="Sterling_Bank_Logo"
            className={styles.atmHeader_logo}
            loading="lazy"
          />
        </div>
      </div>
      <div className={styles.mapContainer}>
        <div
          className={`${styles.atmMapContent} ${
            showAtmStatus
              ? styles.atmMapContentWidthOpen
              : styles.atmMapContentWidtClose
          }`}
        >
          <div
            onClick={() => setShowAtmStatus(!showAtmStatus)}
            className={styles.atmPlacesOpenStyle}
          >
            {showAtmStatus ? (
              <img
                src={arrow2}
                alt="arrow"
                style={{ width: "17px", height: "30px" }}
                loading="lazy"
              />
            ) : (
              <img
                src={arrow}
                alt="arrow"
                style={{ width: "17px", height: "30px" }}
                loading="lazy"
              />
            )}
          </div>
          <Map
            atm={atm}
            branch={branch}
            atmLocations={atmLocations}
            branchLocations={branchLocations}
            defaultPosition={defaultPosition}
          />
          <div className={styles.atmBranchSwitchBox}>
            <div className={styles.atmSwitchContainer}>
              <div className={styles.atmSwitch}>
                <img
                  src={atmMap}
                  alt="arrow"
                  style={{ width: "22px", height: "30px" }}
                  loading="lazy"
                />
                ATM
              </div>
              <Switch
                checked={atm}
                onChange={() => {
                  setBranch(!branch);
                  setAtm(!atm);
                }}
                onColor="#CB6C6F"
                onHandleColor="#EB1A21"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={5}
                width={30}
              />
            </div>
            <div style={{ width: "100%", borderBottom: "2px solid #d6d6d6" }} />
            <div
              style={{ marginTop: ".5rem" }}
              className={styles.atmSwitchContainer}
            >
              <div className={styles.atmSwitch}>
                <img
                  src={branchMap}
                  alt="arrow"
                  style={{ width: "22px", height: "30px" }}
                  loading="lazy"
                />
                Branch
              </div>
              <Switch
                checked={branch}
                onChange={() => {
                  setAtm(!atm);
                  setBranch(!branch);
                }}
                onColor="#CB6C6F"
                onHandleColor="#EB1A21"
                handleDiameter={20}
                uncheckedIcon={false}
                checkedIcon={false}
                boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                height={5}
                width={30}
                className="react-switch"
                id="material-switch"
              />
            </div>
          </div>
        </div>
        <div
          className={`${
            showAtmStatus
              ? styles.atmStatusContentWidthOpen
              : styles.atmStatusContentWidthClose
          }`}
        >
          <AtmStatus
            atmLocations={atmLocations}
            branchLocations={branchLocations}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
}
