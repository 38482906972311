import { combineReducers } from "redux";
import WelcomeReducer from "./welcomeReducer";
import SavingsReducer from "./savingsReducer";
import CurrentReducer from "./currentReducer";
import CorporateReducer from "./corporateReducer";
import KiakiaTwoReducer from "./kiakTwoReducer";
import BvnReducer from "./bvnReducer";
import RefReducer from "./refReducer";
import BankReducer from "./bankReducer";
import ComboReducer from "./comboReducer";
import LandingReducer from "./landing";

export default combineReducers({
    welcome: WelcomeReducer,
    savings: SavingsReducer,
    current: CurrentReducer,
    corporate: CorporateReducer,
    kiakiaTwo: KiakiaTwoReducer,
    bvn: BvnReducer,
    ref: RefReducer,
    bank: BankReducer,
    combo: ComboReducer,
    landing: LandingReducer
})