import React from "react";
import SterlingLogo from "../../../Images/Sterling_Bank_Logo.svg";
import { Link } from "react-router-dom";

const SideCarousel = () => {
  return (
    <div>
      <section className="welcome-banners px-2 py-4 px-5">
        <div className="logoContainer">
          <Link to="/">
            <img src={SterlingLogo} alt="Sterling Bank Logo" />
          </Link>
        </div>
        <div
          id="welcomeBannerCarousel"
          className="carousel welcome-banner-carousel slide position-absolute mt-5 px-3"
          data-ride="carousel"
          data-interval="false"
        >
          <div className="carousel-inner text-white text-center">
            <div className="carousel-item active car-div">
              <h5 className="sec-header carousel-style">
                Easy account opening
              </h5>
            </div>
          </div>
          <div className="red-line"></div>
        </div>
      </section>
    </div>
  );
};

export default SideCarousel;
