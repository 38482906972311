import React from "react";
import Modal from "react-bootstrap/Modal";
import { useHistory } from "react-router";

const RegisteredModal = ({ showRegistered, handleCloseRegistered }) => {
  const history = useHistory();
  return (
    <div>
      <Modal
        show={showRegistered}
        onHide={handleCloseRegistered}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="text-center">
            <div className="mb-5">
              <h6 className="sec-header text-body mb-3 px-sm-5 mx-sm-5">
                Existing Account
              </h6>
              <p className="ftsz-regular">
                User with this type of Account exists.
              </p>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-danger pry-red-bg px-5 mb-3"
                  onClick={() => history.push("/")}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default RegisteredModal;
