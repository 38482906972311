import React, { useState } from 'react'
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";
import Warning from "../../../../../src/Images/warning-icon.svg";
import { ForgotAcctMethodAction } from '../../../../redux/actions';


function BvnValidation({
    formValues,
    handleFormChange,
    setDisableBtn,
    dob,
    name,
    validateFirst,
    onDateChange,
    bvnVal,
    handleShowOtp,
    handleChange,
    handleBvnChange,
    loading,
    isLoading,
    disableBtn,
    bvnValues,
    supposedBvn
}) {
    const history = useHistory();

    if ((bvnValues.accountNumber.length <= 9) && (bvnValues.bvn.length <= 10)) {
        setDisableBtn(true);
    } else {
        setDisableBtn(false);
    }



    return (
        <div className="w-80">
            <form action="">
                <div class="mb-2 text-centers text-dark">
                    <h3 class="mb-0 sec-headers">
                        <span
                            onClick={() => history.goBack()}
                            style={{ cursor: "pointer" }}
                        >
                            <i className="fas fa-long-arrow-alt-left mr-2"></i>
                        </span>
                        BVN Validation
                    </h3>
                </div>
                <div>
                    <p className="pale-red-tone-bg pry-red-clr ftwt-600 ftsz-12 mb-0 px-1 py-2 mb-3 rounded-lg">
                        <img src={Warning} className="mr-1" alt="warning icon" />
                        Kindly ensure that your BVN information is up to date
                    </p>
                </div>
                <div class="form-row mb-3">
                    <div class="form-group col-12">
                        <label
                            for=""
                            class="pry-grey-clr ftsz-12 ftwt-500 mb-1 d-flex justify-content-between align-items-center"
                        >
                            <span>
                                Existing account number
                            </span>
                        </label>
                        <input
                            type="text"
                            class="form-control pry-input-border"
                            placeholder="Enter your existing sterling bank account number"
                            value={bvnValues.accountNumber}
                            onChange={handleBvnChange("accountNumber")}
                        />
                    </div>
                    <div class="form-group col-12 ftsz-12">
                        <h6 class="ftwt-500 ftsz-12 mb-1 text-dark">First Name</h6>
                        <input
                            className="input-pad form-control pry-input-border"
                            type="text"
                            value={formValues.firstName}
                            onChange={handleFormChange("firstName")}
                            placeholder="Enter your First Name"
                        />
                    </div>

                    <div class="form-group col-12 ftsz-12">
                        <h6 class="ftwt-500 ftsz-12 mb-1 text-dark">Last Name</h6>
                        <input
                            className="input-pad form-control pry-input-border"
                            type="text"
                            value={formValues.lastName}
                            onChange={handleFormChange("lastName")}
                            placeholder="Enter your Last Name"
                        />
                    </div>
                    <div class="form-group col-12 ftsz-12">
                        <h6 class="ftwt-500 ftsz-12 mb-1 text-dark">Date of birth</h6>
                        <input
                            className="input-pad form-control pry-input-border"
                            type="date"
                            value={dob}
                            onChange={onDateChange}
                            placeholder="DD / MM / YY"
                        />
                    </div>
                    {/* <div className="form-group col-12 ftsz-12">
                        <label for="" className="ftsz-12 ftwt-500">
                            Select method
                        </label>
                        <select
                            className="custom-select pale-red-tone-bg pry-input-border border-0"
                            onChange={handleChange("method")}
                        >
                            <option value="none">Select method</option>
                            <option value="liveness-check">
                                Liveness Check
                            </option>
                        </select>
                    </div> */}
                </div>

                <div className="form-group steps-action pb-1 mt-5 text-right otherBtn shiftFromBottom">
                    <button
                        type="button"
                        className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 validateBtn btn-block"
                        onClick={validateFirst}
                        disabled={disableBtn || isLoading || loading}
                    >
                        {isLoading || loading ? (
                            <Spinner
                                as="span"
                                animation="border"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                        ) : (
                            "Validate"
                        )}
                    </button>
                </div>
            </form>

            {/* <div>
                {forgot_acct_method === "trybe" && <ExistingTrybeOne />}
                {forgot_acct_method === "dom" && <ExistingDOM />}
            </div> */}
        </div>
    )
}

export default BvnValidation