import { message } from "antd";
import axios from "axios";
import moment from "moment";
import { useState, useCallback, useMemo, useEffect } from "react";
import { decryptHandler2 } from "../../../../../../../dec";
import { encrypt2 } from "../../../../../../../encr";
import { apiUrl } from "../../../../../../../redux/actions/endpoint";
import { appUrls } from "../../../../../../../service/urls";
// import { apiAccount } from "../../../../../../../service/apiAccount";
import { authOApi } from '../../../../../../../service/authOApi';



export default () => {
  const [regCert, setRegCert] = useState("");
  const [regCertURL, setRegCertURL] = useState("");
  const [appBus, setAppBus] = useState("");
  const [appBusURL, setAppBusURL] = useState("");
  const [busRes, setBusRes] = useState("");
  const [busResURL, setBusResURL] = useState("");
  const [refSent, setRefSent] = useState("");
  const [refURL, setRefURL] = useState("");
  const [others, setOthers] = useState("");
  const [othersURL, setOthersURL] = useState("");
  const [evidence, setEvidence] = useState("");
  const [evidenceURL, setEvidenceURL] = useState("");

  // const [regCertExtension, setRegCertExtension] = useState("");
  // const [appBusExtension, setAppBusExtension] = useState("");
  // const [busResExtension, setBusResExtension] = useState("");
  // const [refSentExtension, setRefSentExtension] = useState("");
  // const [othersExtension, setOthersExtension] = useState("");
  // const [evidenceExtension, setEvidenceExtension] = useState("");

  const [documentArray, setDocumentArray] = useState([]);
  const [uploadD, setUploadD] = useState(false);

  const details = JSON.parse(localStorage.getItem("details"));

  const rawDateTime = new Date();
  const dateTime = moment(rawDateTime).format("MMMM d, YYYY");

  //upload docs
  const onDrop = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setRegCert(file);
      const base64 = await convertBase64(file);
      setRegCertURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf(".")) || Infinity) + 1
      );
      // setRegCertExtension(`.${extension}`);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${details.Bvn}-${details.LastName}/${dateTime}/certificateOfRegistration`,
        extension: `.${extension}`,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
        .then(async (response) => {
          setUploadD(false);
          const res1 = response.data;
          const result = res1;

          let newObj = {
            accountNumber: "",
            documentName: `digitalOnboarding/${details.Bvn}-${details.LastName}/${dateTime}/certificateOfRegistration`,
            docURL: result,
            documentCategory: "BusinessDocument",
            documentTagName: "certificateofregistration",
          };
          // let newArray = documentArray.push(newObj);
          setDocumentArray((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDrops = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setAppBus(file);
      const base64 = await convertBase64(file);
      setAppBusURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf(".")) || Infinity) + 1
      );
      // setAppBusExtension(`.${extension}`);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload2 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${details.Bvn}-${details.LastName}/${dateTime}/applicationForBusiness`,
        extension: `.${extension}`,
      };
      const data2 = encrypt2(payload2);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload2)
        .then(async (response) => {
          setUploadD(false);
          const res2 = response.data;
          const result = res2;

          let newObj = {
            accountNumber: "",
            documentName: `digitalOnboarding/${details.Bvn}-${details.LastName}/${dateTime}/applicationForBusiness`,
            docURL: result,
            documentCategory: "BusinessDocument",
            documentTagName: "applicationforbusiness",
          };
          // let newArray = documentArray.push(newObj);
          setDocumentArray((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDropss = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setBusRes(file);
      const base64 = await convertBase64(file);
      setBusResURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf(".")) || Infinity) + 1
      );
      // setBusResExtension(`.${extension}`);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload3 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${details.Bvn}-${details.LastName}/${dateTime}/businessRegistration`,
        extension: `.${extension}`,
      };
      const data3 = encrypt2(payload3);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload3)
        .then(async (response) => {
          setUploadD(false);
          const res3 = response.data;
          const result = res3;

          let newObj = {
            accountNumber: "",
            documentName: `digitalOnboarding/${details.Bvn}-${details.LastName}/${dateTime}/businessRegistration`,
            docURL: result,
            documentCategory: "BusinessDocument",
            documentTagName: "businessresolution",
          };
          // let newArray = documentArray.push(newObj);
          setDocumentArray((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDropsss = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setRefSent(file);
      const base64 = await convertBase64(file);
      setRefURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf(".")) || Infinity) + 1
      );
      // setRefSentExtension(`.${extension}`);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload4 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${details.Bvn}-${details.LastName}/${dateTime}/reference`,
        extension: `.${extension}`,
      };
      const data4 = encrypt2(payload4);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload4)
        .then(async (response) => {
          setUploadD(false);
          const res4 = response.data;
          const result = res4;

          let newObj = {
            accountNumber: "",
            documentName: `digitalOnboarding/${details.Bvn}-${details.LastName}/${dateTime}/reference`,
            docURL: result,
            documentCategory: "BusinessDocument",
            documentTagName: "ref",
          };
          // let newArray = documentArray.push(newObj);
          setDocumentArray((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDropssss = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setOthers(file);
      const base64 = await convertBase64(file);
      setOthersURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf(".")) || Infinity) + 1
      );
      // setOthersExtension(`.${extension}`);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload5 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${details.Bvn}-${details.LastName}/${dateTime}/others`,
        extension: `.${extension}`,
      };
      const data5 = encrypt2(payload5);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload5)
        .then(async (response) => {
          setUploadD(false);
          const res4 = response.data;
          const result = res4;

          let newObj = {
            accountNumber: "",
            documentName: `digitalOnboarding/${details.Bvn}-${details.LastName}/${dateTime}/others`,
            docURL: result,
            documentCategory: "BusinessDocument",
            documentTagName: "others",
          };
          // let newArray = documentArray.push(newObj);
          setDocumentArray((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };

  const onDropsssss = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setEvidence(file);
      const base64 = await convertBase64(file);
      setEvidenceURL(base64);
      const extension = file.path.slice(
        (Math.max(0, file.path.lastIndexOf(".")) || Infinity) + 1
      );
      // setEvidenceExtension(`.${extension}`);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload6 = {
        file: imageSplit,
        fileName: `digitalOnboarding/${details.Bvn}-${details.LastName}/${dateTime}/evidence`,
        extension: `.${extension}`,
      };
      const data6 = encrypt2(payload6);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_DOC_V2}`, payload6)
        .then(async (response) => {
          setUploadD(false);
          const res6 = response.data;
          const result = res6;

          let newObj = {
            accountNumber: "",
            documentName: `digitalOnboarding/${details.Bvn}-${details.LastName}/${dateTime}/evidence`,
            docURL: result,
            documentCategory: "BusinessDocument",
            documentTagName: "evidenceofmembership",
          };
          // let newArray = documentArray.push(newObj);
          setDocumentArray((prev) => [...prev, newObj]);
        });
      setUploadD(false);
    }
  };


  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return [
    regCert,
    regCertURL,
    appBus,
    appBusURL,
    busRes,
    busResURL,
    refSent,
    refURL,
    others,
    othersURL,
    evidence,
    evidenceURL,
    onDrop,
    onDrops,
    onDropss,
    onDropsss,
    onDropssss,
    onDropsssss,
    documentArray,
    uploadD,
  ];
};
