import React from "react";
import { Link } from "react-router-dom";
import "./styles.css"

const ExistingClassic = () => {
  return (
    <div>
      <div id="newkiakia-tier1-req" className="">
        <div className="d-flex justify-content-between px-4 styling">
          <h5 className="sec-header mb-4">Classic Savings</h5>
          <div className="mb-4">
            <Link
              to="/existing-classicSavings"
              className="pry-red-bg rounded-lg px-5 py-3 text-white"
            >
              Proceed
            </Link>
          </div>
        </div>
        <div className="row pry-grey-clr mb-4">
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Features</h6>
            <ul class="custom-req-marker pl-3">
              <li>Zero initial opening balance is accepted.</li>
              <li>
                Maximum single deposit and cumulative balance/threshold is
                unlimited.
              </li>
              <li>Daily minimum balance of N1,000.</li>
              <li>Attractive interest rate i.e., 1.15% p.a. (30% of MPR)</li>
              <li>Issuance of Verve Card and Mastercard.</li>
              <li>Online statements</li>
              <li>SMS alert/Email.</li>
              <li>Internet &amp; Mobile banking facility.</li>
              <li>International funds transfer allowed.</li>
            </ul>
          </div>
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Requirements</h6>
            <ul class="custom-req-marker pl-3">
              <li>
                Basic customer information. (i.e., Name, Place and date of
                birth, Gender, Address, Telephone number) **Address would be
                verified.
              </li>
              <li>BVN.</li>
              <li>Passport photograph.</li>
              <li>Signature.</li>
              <li>Valid ID.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExistingClassic;
