import React, { useState, useEffect } from "react";
import axios from "axios";
import { message } from "antd";
import { decryptHandler2 } from "../../../../../../../../../../dec";
import { encrypt2 } from "../../../../../../../../../../encr";
import { apiUrl } from "../../../../../../../../../../redux/actions/endpoint";
import { appUrls } from "../../../../../../../../.../../../../service/urls";
// import { apiAccount } from "../../../../../../../../../../service/apiAccount";
import { authOApi } from '../../../../../../../../../../service/authOApi';



const OtherBanks = ({
  formValues,
  handleFormChange,
  fileUrl,
  handleShowRefSuccessOTP,
  accountInfo,
  handleFormChanges,
  formVal
}) => {
  const [disableRefBtn, setDisableRefBtn] = useState(false);
  const sendRef = () => {
    setDisableRefBtn(true);
    let payload = {
      customerAccount: accountInfo,
      subj: formValues.refSubject,
      destinationEmail: formValues.refMail,
      recieverFirstName: formValues.refName,
      base64Attachment: fileUrl,
      fileName: "Digital Onboarding Refernce",
      addressesToCopy: "",
      customerName: formValues.refName,
      fromEmail: formValues.email,
      body: formValues.refSubject,
    };
    const data = encrypt2(payload);
    authOApi
      .post(`${appUrls.SEND_MAIL_ATTACHMENT_NONSTERLING}`, { data: data })
      .then((response) => {
        const res = decryptHandler2(response.data);
        handleShowRefSuccessOTP();
        // message.success(res.Message);
        setDisableRefBtn(true);
      })
      .catch((error) => {
        setDisableRefBtn(false);
      });
  };

  return (
    <div>
      <div>
        <h2>From:</h2>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="form-group">
              <label>Your Name</label>
              <input
                className="form-control"
                type="text"
                value={`${formVal.businessName}`}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="form-group">
              <label>Your Email</label>
              <input
                className="form-control"
                type="email"
                value={formVal.email}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <h2>To:</h2>
        <div className="row">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="form-group">
              <label>Subject</label>
              <input
                className="form-control"
                type="text"
                value={formValues.refSubject}
                onChange={handleFormChanges("refSubject")}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="form-group">
              <label>Referee's Name</label>
              <input
                className="form-control"
                type="text"
                value={formValues.refName}
                onChange={handleFormChanges("refName")}
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
            <div className="form-group">
              <label>Referee's Email</label>
              <input
                className="form-control"
                type="email"
                value={formValues.refMail}
                onChange={handleFormChanges("refMail")}
              />
            </div>
          </div>

          {/* <a
            href={`data:application/pdf;base64,${fileUrl}`}
            download="Reference Form"
            style={{
              padding: "10px 20px",
              border: "1px solid green",
              textAlign: "center",
              color: "green",
            }}
          >Download Form</a> */}

          {/* <a href={`${fileUrl}`} target="_blank">
            Download Form
          </a> */}
          <button
            type="button"
            className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-3"
            onClick={sendRef}
            disabled={disableRefBtn}
          >
            {disableRefBtn ? "Sending ..." : "Send Mail"}
          </button>
        </div>
      </div>
    </div>
  );
};

export default OtherBanks;
