import React from "react";
import Modal from "react-bootstrap/Modal";
import CancelIcon from "../../../../../../Images/newImg/cancel.svg"

const RejectedBvn = ({
    showRejected,
    handleCloseRejected
}) => {
    return (
        <div>
            <Modal
                show={showRejected}
                onHide={handleCloseRejected}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Body>
                    <div className="text-center">
                        <div className="mb-5">
                            <h6 className="sec-header text-body mb-3 px-sm-5 mx-sm-5">
                                <img src={CancelIcon} alt="cancel icon" />
                            </h6>
                            <p className="ftsz-regular">
                                There was an error in retrieving your BVN. Kindly call customer care on XXXX to retrieve your BVN or send an email to customercare@sterling.ng.”
                            </p>
                            <div className="d-flex justify-content-center">
                                <button
                                    type="button"
                                    className="btn btn-danger pry-red-bg px-5 mb-3"
                                    onClick={handleCloseRejected}
                                >
                                    Okay
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default RejectedBvn;
