import React from "react";
import Completed from "../../../../../../Images/completed.png";
import Copy from "../../../../../../Images/copy-icon.png";
import Modal from "react-bootstrap/Modal";
import CopyToClipboard from "react-copy-to-clipboard";
import { message } from "antd";
import Progress from "./Progress/progress";
import "./Progress/progress.css";

const NewCompletedForm = ({
  showCompleted,
  handleCloseCompleted,
  confirmCompleted,
  accountInfo,
  bvnDetails,
  imgSrc,
  // next
}) => {
  const [Copied] = React.useState("");
  return (
    <div>
      <Modal
        show={showCompleted}
        onHide={handleCloseCompleted}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="text-center">
            <div className="d-flex align-items-center text-center justify-content-center">
              <div className="mb-3 mr-2">
                <img
                  src={Completed}
                  className="img-fluid"
                  alt=""
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
              <p>Congratulations, Your account opening was successful</p>
            </div>
            <div style={{ width: "100px" }} className="m-auto">
              <img
                src={imgSrc}
                alt="Team_image"
                style={{ width: "100%", borderRadius: "50%", height: "100px" }}
              />
            </div>
            <p className="mt-3 font-weight-bold">
              {bvnDetails.FirstName} {bvnDetails.LastName}
            </p>
            <div className="mb-5">
              <span className="new-acct-num pry-red-clr pale-red-tone-bg rounded-lg pry-header px-3 py-2">
                {accountInfo}{" "}
                <CopyToClipboard
                  text={Copied}
                  onCopy={() => {
                    message.success({
                      content: "Copied to clipboard",
                      style: {
                        position: "relative",
                        right: "0",
                        marginTop: "20px",
                      },
                    });
                  }}
                >
                  <button type="button" className="bare-btn">
                    <img src={Copy} alt="copy" />
                  </button>
                </CopyToClipboard>
              </span>
            </div>

            <Progress />
            <p className="mb-4">Account Opening Progress (50%)</p>
            
            <div class="text-center">
              <button
                class="btn btn-danger pry-red-bg px-5 py-2"
                onClick={() => {
                  confirmCompleted();
                }}
              >
                Okay
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default NewCompletedForm;
