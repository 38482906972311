import { message } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { decryptHandler2 } from "../../../dec";
import { encrypt2 } from "../../../encr";
import { apiUrl } from "../../../redux/actions/endpoint";
import useBusinessFiles from "../hooks/useBusinessFiles";
import BusinessForm from "./form";
import { apiAccount } from "../../../service/apiAccount";
import { appUrls } from "../../../service/urls";
import { authOApi } from "../../../service/authOApi";

const BusinessAccountScreen = ({
  showDoc,
  docText,
  sec,
  handleSecChanges,
  sectorList,
  industriesList,
  isLoading,
  // accNum,
}) => {
  const [loading, setLoading] = useState(false);

  const [regCert, setRegCert] = useState("");
  const [regCertURL, setRegCertURL] = useState("");
  const [appBus, setAppBus] = useState("");
  const [appBusURL, setAppBusURL] = useState("");
  const [busRes, setBusRes] = useState("");
  const [busResURL, setBusResURL] = useState("");
  const [refSent, setRefSent] = useState("");
  const [refURL, setRefURL] = useState("");
  const [others, setOthers] = useState("");
  const [othersURL, setOthersURL] = useState("");
  const [evidence, setEvidence] = useState("");
  const [evidenceURL, setEvidenceURL] = useState("");

  const [documentArray, setDocumentArray] = useState([]);
  const [uploadD, setUploadD] = useState(false);

  const onDrop = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setRegCert(file);
      const base64 = await convertBase64(file);
      setRegCertURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload1 = {
        appId: "25",
        folderName: `digitalOnboarding / ${acceptedFiles} / folder 1`,
        fileName: `digitalOnboarding / ${acceptedFiles} / file 1`,
        base64String: imageSplit,
      };
      const data1 = encrypt2(payload1);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data1,
        })
        .then(async (response) => {
          const res1 = decryptHandler2(response.data);
          const result = res1.URL;

          let newObj = {
            accountNumber: "",
            documentName: `digitalOnboarding / file 1`,
            docURL: result,
            documentCategory: "BusinessDocument",
            documentTagName: "certificateofregistration",
          }
          // let newArray = documentArray.push(newObj);
          setDocumentArray(prev => ([...prev,
            newObj]))
        });
        setUploadD(false);
    }
  };

  const onDrops = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setAppBus(file);
      const base64 = await convertBase64(file);
      setAppBusURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload2 = {
        appId: "25",
        folderName: `digitalOnboarding / ${acceptedFiles} / folder 2`,
        fileName: `digitalOnboarding / ${acceptedFiles} / file 2`,
        base64String: imageSplit,
      };
      const data2 = encrypt2(payload2);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data2,
        })
        .then(async (response) => {
          const res2 = decryptHandler2(response.data);
          const result = res2.URL;

          let newObj = {
            accountNumber: "",
            documentName: `digitalOnboarding / file 2`,
            docURL: result,
            documentCategory: "BusinessDocument",
            documentTagName: "applicationforbusiness",
          }
          // let newArray = documentArray.push(newObj);
          setDocumentArray(prev => ([...prev,
            newObj]))
        });
        setUploadD(false);
    }
  };

  const onDropss = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setBusRes(file);
      const base64 = await convertBase64(file);
      setBusResURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload3 = {
        appId: "25",
        folderName: `digitalOnboarding / ${acceptedFiles} / folder 3`,
        fileName: `digitalOnboarding / ${acceptedFiles} / file 3`,
        base64String: imageSplit,
      };
      const data3 = encrypt2(payload3);
      setUploadD(true)
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data3,
        })
        .then(async (response) => {
          const res3 = decryptHandler2(response.data);
          const result = res3.URL;

          let newObj = {
            accountNumber: "",
            documentName: `digitalOnboarding / file 3`,
            docURL: result,
            documentCategory: "BusinessDocument",
            documentTagName: "businessresolution",
          }
          // let newArray = documentArray.push(newObj);
          setDocumentArray(prev => ([...prev,
            newObj]))
        });
        setUploadD(false);
    }
  };

  const onDropsss = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setRefSent(file);
      const base64 = await convertBase64(file);
      setRefURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload4 = {
        appId: "25",
        folderName: `digitalOnboarding / ${acceptedFiles} / folder 4`,
        fileName: `digitalOnboarding / ${acceptedFiles} / file 4`,
        base64String: imageSplit,
      };
      const data4 = encrypt2(payload4);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data4,
        })
        .then(async (response) => {
          const res4 = decryptHandler2(response.data);
          const result = res4.URL;

          let newObj = {
            accountNumber: "",
            documentName: `digitalOnboarding / file 4`,
            docURL: result,
            documentCategory: "BusinessDocument",
            documentTagName: "others2",
          }
          // let newArray = documentArray.push(newObj);
          setDocumentArray(prev => ([...prev,
            newObj]))
        });
        setUploadD(false);
    }
  };

  const onDropssss = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setOthers(file);
      const base64 = await convertBase64(file);
      setOthersURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload5 = {
        appId: "25",
        folderName: `digitalOnboarding / ${acceptedFiles} / folder 5`,
        fileName: `digitalOnboarding / ${acceptedFiles} / file 5`,
        base64String: imageSplit,
      };
      const data5 = encrypt2(payload5);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data5,
        })
        .then(async (response) => {
          const res4 = decryptHandler2(response.data);
          const result = res4.URL;

          let newObj = {
            accountNumber: "",
            documentName: `digitalOnboarding / file 5`,
            docURL: result,
            documentCategory: "BusinessDocument",
            documentTagName: "others1",
          }
          // let newArray = documentArray.push(newObj);
          setDocumentArray(prev => ([...prev,
            newObj]))
        });
        setUploadD(false);
    }
  };

  const onDropsssss = async (acceptedFiles) => {
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setEvidence(file);
      const base64 = await convertBase64(file);
      setEvidenceURL(base64);
      const imageArr = base64.split(",");
      const imageSplit = imageArr[1];
      let payload6 = {
        appId: "25",
        folderName: `digitalOnboarding / ${acceptedFiles} / folder 6`,
        fileName: `digitalOnboarding / ${acceptedFiles} / file 6`,
        base64String: imageSplit,
      };
      const data6 = encrypt2(payload6);
      setUploadD(true);
      await authOApi
        .post(`${appUrls.UPLOAD_IMAGE_AZURE}`, {
          data: data6,
        })
        .then(async (response) => {
          const res6 = decryptHandler2(response.data);
          const result = res6.URL;

          let newObj = {
            accountNumber: "",
            documentName: `digitalOnboarding / file 6`,
            docURL: result,
            documentCategory: "BusinessDocument",
            documentTagName: "evidenceofmembership",
          }
          // let newArray = documentArray.push(newObj);
          setDocumentArray(prev => ([...prev,
            newObj]))
        });
        setUploadD(false);
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };


  return (
    <div>
      <div>
        <BusinessForm
          showDoc={showDoc}
          docText={docText}
          sec={sec}
          handleSecChanges={handleSecChanges}
          sectorList={sectorList}
          industriesList={industriesList}
          isLoading={isLoading}
          regCert={regCert}
          appBus={appBus}
          busRes={busRes}
          refSent={refSent}
          others={others}
          evidence={evidence}
          onDrop={onDrop}
          onDrops={onDrops}
          onDropss={onDropss}
          onDropsss={onDropsss}
          onDropssss={onDropssss}
          onDropsssss={onDropsssss}
          // accNum={accNum}
        />
        <div className="d-flex justify-content-center align-items-center">
          <button
            type="button"
            className="btn btn-outline-danger text-white pry-red-bg px-5 mb-1"
            disabled={loading || uploadD || documentArray.length === 0}
            // onClick={uploadDocs}
          >
            {loading && "Sending ..." }
            {uploadD && "Uploading ..."}
            {(!loading || !uploadD) && "Done" }
          </button>
        </div>
      </div>
    </div>
  );
};

export default BusinessAccountScreen;
