import React from "react";
import Modal from "react-bootstrap/Modal";

const InvalidOTP = ({ showInvalidOTP, handleCloseInvalidOTP }) => {
  return (
    <div>
      <Modal
        show={showInvalidOTP}
        onHide={handleCloseInvalidOTP}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="text-center">
            <div className="mb-5">
              <h6 className="sec-header text-body mb-3 px-sm-5 mx-sm-5">
                Invalid OTP
              </h6>
              <p className="ftsz-regular">
                Uh-oh! The OTP is incorrect/invalid. Please check and try again.
                Did not get OTP? Resend OTP
              </p>
              <div className="d-flex justify-content-center">
                <button
                  type="button"
                  className="btn btn-outline-danger px-5 mr-sm-3 mb-3"
                  onClick={handleCloseInvalidOTP}
                >
                  Retry
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default InvalidOTP;
