import React from "react";
import { Link } from "react-router-dom";
import SterlingLogo from "../../../../Images/Sterling_Bank_Logo.svg";
import "./index.css"

const SideBar = () => {
  return (
    <div>
      <section className="sidebar px-2 py-4 px-5">
        <div>
          <Link to="/">
            <img src={SterlingLogo} alt="Sterling Bank Logo" />
          </Link>
        </div>
      </section>
    </div>
  );
};

export default SideBar;
