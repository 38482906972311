import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalContent from "./tbhContent";

const Tbh = ({ showTBH, handleCloseTBH }) => {
  return (
    <div>
      <Modal
        show={showTBH}
        onHide={handleCloseTBH}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          {/* <Modal.Title>
            <h2>Terms And Conditions</h2>
          </Modal.Title> */}
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <ModalContent />
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Tbh;
