import React, { useEffect } from "react";
import Warning from "../../../../../../Images/warning-icon.svg";
import Upload from "../../../../../../Images/upload-icon.svg";
import InternationalPassport from "./idType/intPassport";
import NationalId from "./idType/nationalId";
import PermanentVotersCard from "./idType/pvc";
import NigerianDriversLicense from "./idType/nigerianDL";
import { useSelector, useDispatch } from "react-redux";
import { kiakTwoAction } from "../../../../../../redux/actions";
import { apiUrl, test } from "../../../../../../redux/actions/endpoint";
import PlacesAutocomplete from "react-places-autocomplete";
import moment from "moment";
import { encrypt2 } from "../../../../../../encr";
import { decryptHandler2 } from "../../../../../../dec";
import { appUrls } from "../../../../../../service/urls";
// import { apiAccount } from "../../../../../../service/apiAccount";
import { authOApi } from '../../../../../../service/authOApi';


const BvnValidation = ({
  formValues,
  handleFormChange,
  bvnDetails,
  identificationType,
  setIdentificationType,
  setAccountCount,
  setAccNum,
  setCustomerNumber,
  address,
  setAddress,
  handleSelect,
  bvnValues,
  setLoading,
  setFormValues,
  bvnInfo,
  fullInfo,
}) => {
  const dispatch = useDispatch();
  const { id_type } = useSelector(({ kiakiaTwo }) => kiakiaTwo);

  const switchIntl = () => {
    dispatch(kiakTwoAction.set_id_type("intl"));
  };

  const switchPvc = () => {
    dispatch(kiakTwoAction.set_id_type("pvc"));
  };

  const switchNat = () => {
    dispatch(kiakTwoAction.set_id_type("natID"));
  };

  const switchNdl = () => {
    dispatch(kiakTwoAction.set_id_type("ndl"));
  };

  const getAccountCount = () => {
    const data = encrypt2(bvnDetails.bvn);
    authOApi
      .get(`${appUrls.FIORANO_GET_ACCT_COUNTBY_BVN}?Data=${data}`)
      .then((response) => {
      
        const res = decryptHandler2(response.data);
        setAccountCount(res.Count);
      })
      .catch((error) => {
      
      });
  };

  // const fullInfo = () => {
  //   const data = encrypt2(bvnValues.bvn);
  //   axios
  //     .get(`${apiUrl}/FioranoGetAccountByOnlyBVN?Data=${data}`)
  //     .then((response) => {
  //       const res = decryptHandler2(response.data);
  //        
  //       
  //     });
  // };

  const setData = () => {
    let payload = {
      bvn: bvnInfo.bvn,
      dateOfBirth: moment(new Date()).format("YYYY-MM-DDTHH:mm:ss.sssZ"),
    };
    const enc = encrypt2(payload);
    // (enc);
    setLoading(true);
    // axios.post(`${apiUrl}/VerifyBVN`, payload).then((response) => {
      authOApi.post(`${appUrls.VERIFY_BVN_V2}`, { data: enc }).then((response) => {
      const res = decryptHandler2(response.data);

      setFormValues({
        bvn: res.BVN,
        firstName: res.FirstName,
        secondName: res.MiddleName,
        lastName: res.LastName,
        dobirth: res.DateOfBirth,
        phone: res.PhoneNumber,
        email: res.Email,
        address: res.ResidentialAddress,
        landmark: res.StateOfResidence,
        state: res.StateOfResidence,
      });
      setLoading(false);
    });
  };

  // fullInfo();

  useEffect(() => {
    setFormValues({
      bvn: bvnDetails.BVN,
      firstName: bvnDetails.FirstName,
      secondName: bvnDetails.MiddleName,
      lastName: bvnDetails.LastName,
      dobirth: bvnDetails.DateOfBirth,
      phone: bvnDetails.PhoneNumber,
      email: bvnDetails.Email,
      address: bvnDetails.ResidentialAddress,
      landmark: bvnDetails.StateOfResidence,
      state: bvnDetails.StateOfResidence,
    });
    getAccountCount();
    // setData();
    fullInfo();
  }, []);
  return (
    <div>
      <form action="">
        <h5 className="my-4 sec-header text-body text-center">
          Update Details
        </h5>
        <p className="ftsz-regular text-body text-center">
          Hello <span className="ftwt-600">{bvnDetails.FirstName}</span>,
          Welcome to Sterling bank
        </p>
        <div className="col-12 mb-3">
          <div className="form-row">
            <div className="col-12">
              <h6 className="ftwt-600 ftsz-12 mb-1">Minor’s Details</h6>
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                First name
              </label>
              <input
                type="text"
                className="form-control pry-input-border"
                placeholder="Enter minor’s first name"
                value={formValues.ChildFirstName}
                onChange={handleFormChange("ChildFirstName")}
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                Middle name
              </label>
              <input
                type="text"
                className="form-control pry-input-border"
                placeholder="Enter minor’s second name"
                value={formValues.ChildMiddleName}
                onChange={handleFormChange("ChildMiddleName")}
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                Last name
              </label>
              <input
                type="text"
                className="form-control pry-input-border"
                placeholder="Enter minor’s last name"
                value={formValues.ChildLastName}
                onChange={handleFormChange("ChildLastName")}
              />
            </div>
            <div className="col-12 col-md-6 mb-3">
              <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                Date of Birth
              </label>
              <input
                type="date"
                className="form-control pry-input-border"
                value={formValues.ChildDob}
                onChange={handleFormChange("ChildDob")}
              />
            </div>
          </div>
        </div>
        <p className="pale-red-tone-bg pry-red-clr ftwt-600 ftsz-12 mb-0 px-3 py-2 mb-3 rounded-lg">
          <img src={Warning} className="mr-3" alt="" />
          Update your address and email if it has changed
        </p>
        <h6 className="ftwt-600 ftsz-12 mb-1">Parent/Guardians details</h6>
        <div className="form-row mb-3">
          <div className="col-12 col-md-6 mb-3">
            <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
              Phone number
            </label>
            <input
              type="text"
              className="form-control pry-input-border bg-white"
              placeholder="Enter phone number"
              value={formValues.phone}
              onchange={handleFormChange("phone")}
              disabled
            />
          </div>
          <div className="col-12 col-md-6 mb-3">
            <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
              Email address
            </label>
            <input
              type="email"
              className="form-control pry-input-border"
              placeholder="Enter email adddress"
              value={formValues.email}
              onChange={handleFormChange("email")}
            />
          </div>
          <div className="col-12 mb-3">
            <div className="form-row">
              <div className="col-12">
                <h6 className="ftwt-600 ftsz-12">Residential address</h6>
              </div>
              <div className="col-12 col-md-12 mb-3">
                <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                  House address
                </label>
                <PlacesAutocomplete
                  value={address}
                  onChange={setAddress}
                  onSelect={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div key={suggestions.description}>
                      <input
                        {...getInputProps({
                          placeholder: "Search Places ...",
                          className:
                            "form-control pry-input-border location-search-input",
                        })}
                      />
                      <div className="autocomplete-dropdown-container">
                        {loading && <div>Loading...</div>}
                        {suggestions.map((suggestion) => {
                          const className = suggestion.active
                            ? "suggestion-item--active"
                            : "suggestion-item";
                          // inline style for demonstration purpose
                          const style = suggestion.active
                            ? { backgroundColor: "#fafafa", cursor: "pointer" }
                            : { backgroundColor: "#ffffff", cursor: "pointer" };
                          return (
                            <div
                              {...getSuggestionItemProps(suggestion, {
                                className,
                                style,
                              })}
                            >
                              <span>{suggestion.description}</span>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <div className="col-12 mb-3">
                <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                  Nearest bus-stop/landmark
                </label>
                <input
                  type="text"
                  className="form-control pry-input-border"
                  placeholder="Nearest bus-stop/landmark"
                  value={formValues.landmark}
                  onChange={handleFormChange("landmark")}
                />
              </div>
              <div className="col-12 mb-3">
                <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                  Area/State
                </label>
                <input
                  type="text"
                  className="form-control pry-input-border"
                  placeholder="Area/State"
                  value={formValues.state}
                  onChange={handleFormChange("state")}
                />
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 mb-3">
            <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
              Select minor’s employment information{" "}
            </label>
            <select
              id=""
              className="form-control pry-input-border"
              onChange={handleFormChange("employment")}
              value={formValues.employment}
            >
              <option value="student">Student</option>
              <option value="employed">Employed</option>
              <option value="self-employed">Self-employed</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className="col-12 col-md-6 mb-3">
            <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
              Select minor’s gender{" "}
            </label>
            <select
              id=""
              className="form-control pry-input-border"
              value={formValues.ChildGender}
              onChange={handleFormChange("ChildGender")}
            >
              <option value="">Select gender</option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="other">Other</option>
            </select>
          </div>
          <div className=" col-12 mb-3">
            <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
              Name of next of kin of the Minor
            </label>
            <input
              type="text"
              className="form-control pry-input-border"
              value={formValues.nok}
              onChange={handleFormChange("nok")}
              placeholder="Enter name of next of kin of the Minor"
            />
          </div>
          <div className="col-12 mb-3">
            <h6 className="ftsz-12 ftwt-500 mb-3">
              Select Parent/Guardian’s identity card type
            </h6>
            <div className="col-12 mb-3">
              <div className="form-row ftsz-10 pale-red-tone-bg pry-red-clr ftwt-500 py-2">
                <div className="col-md-auto">
                  <div className="custom-radio-controls position-relative">
                    <input
                      type="radio"
                      id="intlPassportRadio"
                      name="idCardType"
                      className="invisible position-absolute"
                      checked={identificationType === "International Passport"}
                      value="International Passport"
                      onClick={() => {
                        setIdentificationType("International Passport");
                        
                      }}
                    />
                    <label
                      className=""
                      onClick={switchIntl}
                      for="intlPassportRadio"
                    >
                      International passport
                    </label>
                  </div>
                </div>
                <div className="col-md-auto">
                  <div className="custom-radio-controls position-relative">
                    <input
                      type="radio"
                      id="nationalIDRadio"
                      name="idCardType"
                      className="invisible position-absolute"
                      checked={identificationType === "National ID"}
                      value="National ID"
                      onClick={() => {
                        setIdentificationType("National ID");
                       
                      }}
                    />
                    <label
                      className=""
                      onClick={switchNat}
                      for="nationalIDRadio"
                    >
                      Virtual NIN
                    </label>
                  </div>
                </div>
                <div className="col-md-auto">
                  <div className="custom-radio-controls position-relative">
                    <input
                      type="radio"
                      id="pVCRadio"
                      name="idCardType"
                      className="invisible position-absolute"
                      checked={identificationType === "Permanent Voters Card"}
                      value="Permanent Voters Card"
                      onClick={() => {
                        setIdentificationType("Permanent Voters Card");

                      }}
                    />
                    <label className="" onClick={switchPvc} for="pVCRadio">
                      Permanent Voter's card
                    </label>
                  </div>
                </div>
                <div className="col-md-auto">
                  <div className="custom-radio-controls position-relative">
                    <input
                      type="radio"
                      id="driverLicenceRadio"
                      name="idCardType"
                      className="invisible position-absolute"
                      checked={identificationType === "Drivers License"}
                      value="Drivers License"
                      onClick={() => {
                        setIdentificationType("Drivers License");

                      }}
                    />
                    <label
                      className=""
                      onClick={switchNdl}
                      for="driverLicenceRadio"
                    >
                      Nigerian Driver's licence
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12">
            {id_type === "intl" && (
              <InternationalPassport
                formValues={formValues}
                handleFormChange={handleFormChange}
              />
            )}
            {id_type === "natID" && (
              <NationalId
                formValues={formValues}
                handleFormChange={handleFormChange}
              />
            )}
            {id_type === "pvc" && (
              <PermanentVotersCard
                formValues={formValues}
                handleFormChange={handleFormChange}
              />
            )}
            {id_type === "ndl" && (
              <NigerianDriversLicense
                formValues={formValues}
                handleFormChange={handleFormChange}
              />
            )}
          </div>
          <div className=" col-12 mb-3">
            <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
              DAO Code(Referral ID) - *Optional
            </label>
            <input
              type="text"
              className="form-control pry-input-border"
              placeholder="Enter referral code"
              value={formValues.refCode}
              onChange={handleFormChange("refCode")}
            />
          </div>
        </div>
      </form>
    </div>
  );
};

export default BvnValidation;
