import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { savingsAction } from "../../../../../../redux/actions";
import ExistingClassic from "./components/classic";
import ExistingDOM from "./components/dom";
import ExistingKids from "./components/kids";
import ExistingNone from "./components/none";
import ExistingTrybeOne from "./components/trybeOne";


const SavingsTab = () => {
  const [drop, setDrop] = useState({
    account: "",
  });
  const { account_type } = useSelector(({ savings }) => savings);
  const dispatch = useDispatch();
  const handleChange = (name) => (event) => {
    setDrop({
      ...drop,
      [name]: event.target.value,
    });
    dispatch(savingsAction.set_account_type(event.target.value));
  };

  return (
    <div>
      <div className="mb-5">
        <label htmlFor="" className="ftsz-12 ftwt-500">
          Select account type
        </label>
        <select
          className="custom-select pale-red-tone-bg pry-input-border border-0"
          onChange={handleChange("account")}
          value={drop.account}
        >
          <option value="none">Select account type</option>
          <option value="classic">Classic Savings</option>
          <option value="kids">Kid’s Savings</option>
          {/* <option value="trybe">TrybeOne</option> */}
          <option value="dom">Domiciliary savings</option>
        </select>
      </div>
      <div>
        {account_type === "none" && <ExistingNone />}
        {account_type === "classic" && <ExistingClassic />}
        {account_type === "kids" && <ExistingKids />}
        {account_type === "trybe" && <ExistingTrybeOne />}
        {account_type === "dom" && <ExistingDOM />}
      </div>
    </div>
  );
};

export default SavingsTab;
