import { useState, useCallback, useMemo, useEffect } from "react";
import { message } from "antd";

export default () => {
  const [photoID, setPhotoID] = useState("");
  const [photoIDURL, setPhotoIDURL] = useState("");
  const [signatureUpload, setSignatureUpload] = useState("");
  const [signatureURL, setSignatureURL] = useState("");
  const [passport, setPassport] = useState("");
  const [passportURL, setPassportURL] = useState("");

  //upload docs
  const onDrop = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setPhotoID(file);
      const base64 = await convertBase64(file);
      setPhotoIDURL(base64);
    }
  };

  const onDrops = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setSignatureUpload(file);
      const base64 = await convertBase64(file);
      setSignatureURL(base64);
    }
  };

  const onDropss = async (acceptedFiles, fileRejections) => {
    fileRejections.forEach((file) => {
      file.errors.forEach((err) => {
        if (err.code === "file-too-large") {
          message.error(`File should not be more than 2MB`, 5);
          return;
        }
        if (err.code === "file-invalid-type") {
          message.error(`Error: ${err.message}`);
          return;
        }
      });
    });
    if (acceptedFiles.length) {
      const file = acceptedFiles[0];
      setPassport(file);
      const base64 = await convertBase64(file);
      setPassportURL(base64);
    }
  };

  
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  return [
    photoID,
    photoIDURL,
    onDrop,
    onDrops,
    signatureUpload,
    signatureURL,
    onDropss,
    passport,
    passportURL,
  ];
};
