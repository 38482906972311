import { message } from "antd";
import axios from "axios";
import React, { useState, useEffect } from "react";
import Dropzone from "react-dropzone";
import { decryptHandler2 } from "../../../dec";
import { encrypt2 } from "../../../encr";
import { apiUrl } from "../../../redux/actions/endpoint";
import { apiAccount } from "../../../service/apiAccount";
import { appUrls } from "../../../service/urls";
import { authOApi } from "../../../service/authOApi";

const FormOne = ({
  showDoc,
  docText,
  sec,
  handleSecChanges,
  sectorList,
  industriesList,
  isLoading,
  accNum,
  cac,
  co2,
  co7,
  meMart,
  board,
  refr,
  others,
  evidence,
  onDrop1,
  onDrop2,
  onDrop3,
  onDrop4,
  onDrop5,
  onDrop6,
  onDrop7,
  onDrop8,
}) => {
  const [cacc, setCacc] = useState(false);
  const [co22, setCo22] = useState(false);
  const [co77, setCo77] = useState(false);
  const [memartt, setMemartt] = useState(false);
  const [boardR, setBoardR] = useState(false);
  const [other, setOther] = useState(false);
  const [lic, setLic] = useState(false);
  const [reff, setReff] = useState(false);

  const check = () => {
    let pay = accNum;
    let enc = encrypt2(pay);
    authOApi
      .get(`${appUrls.COMBOONE_DOC_UPLOAD_STATUS}?Data=${enc}`)
      .then((response) => {
        const res = decryptHandler2(response.data);
        setCacc(res.CAC);
        setCo22(res.CO2);
        setCo77(res.CO7);
        setMemartt(res.MEMART);
        setBoardR(res.BoardResolutionSignedSealed);
        setOther(res.Others1);
        setLic(res.EvidenceOfMembership);
        setReff(res.Others2);
      })
      .catch((error) => {
        message.error(error);
      });
  };
  useEffect(() => {
    check();
  }, []);

  return (
    <div className="container">
      <form action="">
        <h6 className="mb-2 sec-header font-weight-normal text-body text-center ftsz-12">
          Upload pdf file formats. Asterisked items (**) are mandatory
        </h6>
        <div className="form-row mb-5 pb-5 mt-3">
          {cacc === false && (
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">CAC**</h6>
              {cac ? <p>{cac.path}</p> : <p>No file uploaded</p>}
              <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                <Dropzone
                  onDrop={onDrop1}
                  multiple={false}
                  accept="image/jpeg, image/png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="doc-uploads">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} placeholder="" />
                        {!!cac.length &&
                          cac.map((file) => (
                            <p className="doc-texts">{file.path}</p>
                          ))}

                        {!cac.length && (
                          <section className="doc-texts">
                            Drag 'n' drop , or click to select <br />
                          </section>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}

          {co22 === false && (
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">Co2**</h6>
              {co2 ? <p>{co2.path}</p> : <p>No file uploaded</p>}
              <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                <Dropzone
                  onDrop={onDrop2}
                  multiple={false}
                  accept="image/jpeg, image/png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="doc-uploads">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} placeholder="" />
                        {!!co2.length &&
                          co2.map((file) => (
                            <p className="doc-texts">{file.path}</p>
                          ))}

                        {!co2.length && (
                          <section className="doc-texts">
                            Drag 'n' drop , or click to select <br />
                          </section>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}

          {co77 === false && (
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">co7**</h6>
              {co7 ? <p>{co7.path}</p> : <p>No file uploaded</p>}
              <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                <Dropzone
                  onDrop={onDrop3}
                  multiple={false}
                  accept="image/jpeg, image/png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="doc-uploads">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} placeholder="" />
                        {!!co7.length &&
                          co7.map((file) => (
                            <p className="doc-texts">{file.path}</p>
                          ))}

                        {!co7.length && (
                          <section className="doc-texts">
                            Drag 'n' drop , or click to select <br />
                          </section>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}

          {memartt === false && (
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">MeMart**</h6>
              {meMart ? <p>{meMart.path}</p> : <p>No file uploaded</p>}
              <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                <Dropzone
                  onDrop={onDrop4}
                  multiple={false}
                  accept="image/jpeg, image/png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="doc-uploads">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} placeholder="" />
                        {!!meMart.length &&
                          meMart.map((file) => (
                            <p className="doc-texts">{file.path}</p>
                          ))}

                        {!meMart.length && (
                          <section className="doc-texts">
                            Drag 'n' drop , or click to select <br />
                          </section>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}

          {boardR === false && (
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
                Board resolution signed and sealed**
              </h6>
              {board ? <p>{board.path}</p> : <p>No file uploaded</p>}
              <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                <Dropzone
                  onDrop={onDrop5}
                  multiple={false}
                  accept="image/jpeg, image/png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="doc-uploads">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} placeholder="" />
                        {!!board.length &&
                          board.map((file) => (
                            <p className="doc-texts">{file.path}</p>
                          ))}

                        {!board.length && (
                          <section className="doc-texts">
                            Drag 'n' drop , or click to select <br />
                          </section>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}

          {reff && (
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">References</h6>
              {refr ? <p>{refr.path}</p> : <p>No file uploaded</p>}
              <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                <Dropzone
                  onDrop={onDrop6}
                  multiple={false}
                  accept="image/jpeg, image/png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="doc-uploads">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} placeholder="" />
                        {!!refr.length &&
                          refr.map((file) => (
                            <p className="doc-texts">{file.path}</p>
                          ))}

                        {!refr.length && (
                          <section className="doc-texts">
                            Drag 'n' drop , or click to select <br />
                          </section>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}

          {other === false && (
            <div className="col-12 col-md-6 mb-3 ftsz-12 ">
              <h6 className="ftwt-500 ftsz-12 mb-1 text-body">Others</h6>
              {others ? <p>{others.path}</p> : <p>No file uploaded</p>}
              <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                <Dropzone
                  onDrop={onDrop7}
                  multiple={false}
                  accept="image/jpeg, image/png"
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="doc-uploads">
                      <div {...getRootProps()}>
                        <input {...getInputProps()} placeholder="" />
                        {!!others.length &&
                          others.map((file) => (
                            <p className="doc-texts">{file.path}</p>
                          ))}

                        {!others.length && (
                          <section className="doc-texts">
                            Drag 'n' drop , or click to select <br />
                          </section>
                        )}
                      </div>
                    </section>
                  )}
                </Dropzone>
              </div>
            </div>
          )}

          {lic === false && (
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <div>
                <div className="form-row">
                  <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                    Select Sector
                  </label>
                  <select
                    className="custom-select pry-input-border"
                    onChange={handleSecChanges("sector_id")}
                    required
                    value={sec.sector_id}
                  >
                    {sectorList.map((sectors, index) => (
                      <option value={sectors.Sector_T24Code} key={index}>
                        {sectors.Sector}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
            {lic === false && (
              <div>
                <div className="form-row">
                  <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
                    Select Industry
                  </label>
                  <select
                    className="custom-select pry-input-border"
                    onChange={handleSecChanges("industry_id")}
                    required
                    value={sec.industry_id}
                    disabled={isLoading}
                  >
                    <option value="">Select industry</option>
                    {industriesList.map((industry, index) => (
                      <option value={industry.Industry_T24Code} key={index}>
                        {industry.Industry}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            )}
          </div>

          <div className="col-12 col-md-6 mb-3 ftsz-12 ">
            {showDoc && (
              <div>
                <h6 className="ftwt-500 ftsz-12 mb-1 text-body">
                  <p>{docText} **</p>
                </h6>
                {evidence ? <p>{evidence.path}</p> : <p>No file uploaded</p>}
                <div className="form-label pry-grey-clr upload-doc-label-fmt d-flex flex-nowrap justify-content-between pale-red-tone-bg px-4 py-3 position-relative">
                  <Dropzone
                    onDrop={onDrop8}
                    multiple={false}
                    accept="image/jpeg, image/png"
                  >
                    {({ getRootProps, getInputProps }) => (
                      <section className="doc-uploads">
                        <div {...getRootProps()}>
                          <input {...getInputProps()} placeholder="" />
                          {!!evidence.length &&
                            evidence.map((file) => (
                              <p className="doc-texts">{file.path}</p>
                            ))}

                          {!evidence.length && (
                            <section className="doc-texts">
                              Drag 'n' drop , or click to select <br />
                            </section>
                          )}
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </div>
              </div>
            )}
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormOne;
