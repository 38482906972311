import { SET_LANDING_TYPE } from "../types";
const INITIAL_STATE = {
  show: "slider",
};

const LandingReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_LANDING_TYPE:
      return {
        ...state,
        show: action.payload,
      };

    default:
      return state;
  }
};

export default LandingReducer;
