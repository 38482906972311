import { message } from 'antd';
import React, { useState } from 'react';
import { Modal, Spinner } from 'react-bootstrap';
import Info from '../../../../../Images/Info_icon.svg';
import { decryptHandler2 } from '../../../../../dec';
import { encrypt2 } from '../../../../../encr';
import { authOApi } from '../../../../../service/authOApi';
import { appUrls } from '../../../../../service/urls';

const ValidateClientID = ({
  showClientIDModal,
  setShowClientIDModal,
  setClientIDSuccessModal,
}) => {
  const [clientID, setClientID] = useState('');
  const [isLoadingClientIDValidation, setIsLoadingClientIDValidation] =
    useState(false);

  const clientIDInfo = JSON.parse(localStorage.getItem('clientIDInfo'));
  const validate = async () => {
    if (!clientID) {
      message.error('Please enter your client ID');
      return;
    }
    if (clientID !== clientIDInfo.clientID) {
      message.error('Please enter the correct ClientID');
      return;
    }
    setIsLoadingClientIDValidation(true);
    const enc = encrypt2(clientID);
    await authOApi
      .get(`${appUrls.GET_UNVERIFIED_TIN_DETAILS}`, {
        params: {
          encryptedaccountId: enc,
        },
      })
      .then((res) => {
        console.log('getData ==>', res.data, decryptHandler2(res.data));
        setIsLoadingClientIDValidation(false);
        setShowClientIDModal(false);
        setClientIDSuccessModal(true);
      })
      .catch(() => {
        setIsLoadingClientIDValidation(false);
        message.error('unable to validate client ID', 10);
      });
  };

  return (
    <Modal
      show={showClientIDModal}
      onHide={() => setShowClientIDModal(false)}
      centered
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <form action="">
          <div class="my-4 text-centers text-dark">
            <h3 class="mb-0 sec-headers">ClientID Validation</h3>
          </div>
          <div className="form-row mb-3">
            <div className="form-group col-12">
              <label
                for=""
                className="pry-grey-clr ftsz-12 ftwt-500 mb-1 d-flex justify-content-between align-items-center"
              >
                <span>Enter ClientID</span>
                <div className="popover-wrapper position-relative">
                  <div className="popover-title">
                    <img src={Info} alt="" style={{ cursor: 'pointer' }} />
                  </div>
                  <div className="popover-content ftsz-12 p-2">
                    <ul className="pl-3">
                      <li>Please see format here A578903</li>
                    </ul>
                  </div>
                </div>
              </label>
              <input
                type="text"
                className="form-control pry-input-border"
                id=""
                placeholder="Enter your ClientID"
                value={clientID}
                onChange={(e) => setClientID(e.target.value)}
              />
              {clientIDInfo && clientIDInfo.clientID === clientID ? (
                <p className="mt-2">
                  Your Business Name is{' '}
                  <span className="font-weight-bold text-uppercase">
                    {clientIDInfo.clientName}
                  </span>
                </p>
              ) : null}
              <button
                type="button"
                className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 btn-block w-100 mt-5"
                disabled={!clientID || isLoadingClientIDValidation}
                onClick={validate}
              >
                {' '}
                {isLoadingClientIDValidation ? (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                ) : (
                  'Validate'
                )}
              </button>
            </div>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ValidateClientID;
