import React from "react";
import { Link } from "react-router-dom";
import "../../savings/components/styles.css"

const DomiciliaryCurrent = () => {
  return (
    <div>
      <div id="newdom-sav-req" className="">
        <div className="d-flex justify-content-between px-4 styling">
          <h5 className="sec-header mb-4">Domiciliary current</h5>
          <div className="mb-4">
            <Link
              to="/domCurrent"
              className="pry-red-bg rounded-lg px-5 py-3 text-white"
            >
              Proceed
            </Link>
          </div>
        </div>
        <div className="row pry-grey-clr mb-4">
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Features</h6>
            <ul className="custom-req-marker pl-3">
              <li>
                Minimum account opening balance of: - USD 100 - GBP 100 - EURO
                100.
              </li>
              <li>Attractive interest rate on tenured deposit.</li>
              <li>Interest bearing.</li>
              <li>Foreign cheque lodgement allowed (21 working days).</li>
              <li>Allows for offshore remittances.</li>
              <li>Internet Banking Facility & SMS Alert.</li>
              <li>Online statement.</li>
            </ul>
          </div>
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Requirements</h6>
            <ul className="custom-req-marker pl-3">
              <li>
                Basic customer information. (i.e., Name, Place and date of
                birth, Gender, Address, Telephone number) **Address would be
                verified.
              </li>
              <li>BVN.</li>
              <li>Passport photograph.</li>
              <li>Signature.</li>
              <li>Valid ID.</li>
              <li>2 Suitable references.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DomiciliaryCurrent;
