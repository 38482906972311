import React from "react";

const InternationalPassport = ({ formValues, handleFormChange }) => {
  return (
    <div>
      <div className="form-row">
      <div className="col-12 col-md-4 mb-3">
          <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
            ID card number
          </label>
          <input
            type="text"
            className="form-control pry-input-border"
            placeholder="Enter ID card number"
            value={formValues.idCardNo}
            onChange={handleFormChange("idCardNo")}
          />
        </div>
        <div className="col-sm-6 col-md-4 mb-3">
          <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
            Issue Date
          </label>
          <input
            type="date"
            className="form-control pry-input-border"
            value={formValues.issueDate}
            onChange={handleFormChange("issueDate")}
          />
        </div>
        <div className="col-sm-6 col-md-4 mb-3 id-card-expiry-date">
          <label for="" className="form-label ftsz-12 ftwt-500 mb-1">
            Expiry Date
          </label>
          <input
            type="date"
            className="form-control pry-input-border"
            value={formValues.expiryDate}
            onChange={handleFormChange("expiryDate")}
          />
        </div>
      </div>
    </div>
  );
};

export default InternationalPassport;
