import React from "react";
import { Link } from "react-router-dom";
import "./styles.css"

const KiakiaTwo = () => {
  return (
    <div>
      <div id="newkiakia-tier1-req" className="">
        <div className="d-flex justify-content-between px-4 styling">
          <h5 className="sec-header mb-4">Kiakia (Tier 2)</h5>
          <div className="mb-4">
            <Link
              to="/kiakiaTwo"
              className="pry-red-bg rounded-lg px-5 py-3 text-white"
            >
              Proceed
            </Link>
          </div>
        </div>
        <div className="row pry-grey-clr mb-4">
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Features</h6>
            <ul className="custom-req-marker pl-3">
              <li>Maximum single deposit of N100,000.</li>
              <li>Maximum cumulative balance/threshold of N500,000.</li>
              <li>Instant account opening.</li>
              <li>Email/SMS alert.</li>
              <li>Online statements</li>
              <li>One account signatory.</li>
              {/* <li>Mobile banking limit of N10,000</li> */}
              <li>Verve card only.</li>
              <li>No International Funds Transfer.</li>
            </ul>
          </div>
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Requirements</h6>
            <ul className="custom-req-marker pl-3">
              <li>
                Basic customer information. (i.e., Name, Place and date of
                birth, Gender, Address, Telephone number)
              </li>
              <li>BVN.</li>
              <li>Passport photograph.</li>
              <li>Signature.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KiakiaTwo;
