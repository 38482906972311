import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useCallback } from 'react';
import DoYouHaveClientID from './businessModals/DoYouHaveClientID';
import ValidateClientID from './businessModals/ValidateClientID';
import ValidationSuccessful from './businessModals/ValidationSuccessful';

const SwitchButton = ({ switchSlider }) => {
  const history = useHistory();
  const [drop, setDrop] = useState(true);
  //active state was changed from boolean to string
  const [active, setActive] = useState('');
  const [btnValue, setBtnValue] = useState(null);
  const [dropValue, setDropValue] = useState({
    account: '',
  });
  const handleChange = (name) => (event) => {
    setDropValue({
      ...dropValue,
      [name]: event.target.value,
    });
    // setBtnValue(event.target.value);
  };
  const [showClientIDModal, setShowClientIDModal] = useState(false);
  const [clientIDSuccessModal, setClientIDSuccessModal] = useState(false);

  const handleClickYes = useCallback(() => {
    setBtnValue(null);
    setShowClientIDModal(true);
  }, []);

  const handleClickNo = useCallback(() => {
    if (dropValue.account === 'business') {
      history.push('/businessAccount');
    } else if (dropValue.account === 'company') {
      history.push('/companyAccount');
    } else if (dropValue.account === 'cares') {
      history.push('/ngo');
    } else if (dropValue.account === 'zero') {
      history.push('/zeroAMF');
    }
    setBtnValue(null);
  }, [dropValue.account, history]);

  return (
    <div className="formSection__switchBtn">
      <div className="border-0 d-flex align-items-center mb-3">
        <button
          type="button"
          className="bare-btn sec-header"
          onClick={switchSlider}
        >
          <i className="fas fa-long-arrow-alt-left fontStyle"></i>
        </button>
        <h5 className="sec-header mb-0" id="ATMLocatorLabel">
          New Customer
        </h5>
      </div>
      <div>
        {/* Set active status  to status name itself */}
        <button
          className={active === 'individual' ? 'switchBtn-active' : 'switchBtn'}
          onClick={() => {
            setDrop(true);
            setActive('individual');
          }}
        >
          Individual
        </button>
        {/* Set active status  to status name itself */}
        <button
          className={`${
            active === 'corporate' ? 'switchBtn-active' : 'switchBtn'
          } ml-2`}
          onClick={() => {
            setDrop(false);
            setActive('corporate');
          }}
        >
          Corporate
        </button>
      </div>
      <div>
        {drop && (
          <div className="mt-3">
            {/* <label htmlFor="" className="ftsz-12 ftwt-500">
              Select account type
            </label> */}
            <select
              className="custom-select pale-red-tone-bg pry-input-border border-0"
              onChange={handleChange('account')}
              value={dropValue.account}
            >
              <option value="none">Select account type</option>
              <option value="one">Kiakia (Tier 1)</option>
              <option value="two">Kiakia (Tier 2)</option>
              <option value="classic">Classic Savings</option>
              <option value="kids">Kid’s Savings</option>
              <option value="trybe">TrybeOne</option>
              <option value="dom">Domiciliary savings</option>
              <option value="cclassic">Classic current</option>
              <option value="cdom">Domiciliary current</option>
            </select>

            <div className="ml-0 pl-0 mt-3">
              <button
                className="cardBtn ml-0"
                onClick={() => {
                  if (btnValue === 'one') {
                    history.push('/kiakiaOne');
                  } else if (dropValue.account === 'two') {
                    history.push('/kiakiaTwo');
                  } else if (dropValue.account === 'classic') {
                    history.push('/classicSavings');
                  } else if (dropValue.account === 'kids') {
                    history.push('/kidsAccount');
                  } else if (dropValue.account === 'dom') {
                    history.push('/domSavings');
                  } else if (dropValue.account === 'cdom') {
                    history.push('/domCurrent');
                  } else if (dropValue.account === 'cclassic') {
                    history.push('/classicCurrent');
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}
        {!drop && (
          <div className="mt-3">
            {/* <label htmlFor="" className="ftsz-12 ftwt-500">
              Select account type
            </label> */}
            <select
              className="custom-select pale-red-tone-bg pry-input-border border-0"
              onChange={handleChange('account')}
              value={dropValue.account}
            >
              <option value="">Select account type</option>
              <option value="business">
                Business account - (Registered Business (BNs))
              </option>
              <option value="company">
                Company account - (Limited companies (RCs))
              </option>
              <option value="cares">NGOs</option>
              <option value="zero">Zero AMF</option>
            </select>

            <div className="ml-0 pl-0 mt-4">
              <button
                className="cardBtn ml-2"
                onClick={() => {
                  if (dropValue.account) {
                    setBtnValue(dropValue.account);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
        )}
      </div>

      <DoYouHaveClientID
        btnValue={btnValue}
        setBtnValue={setBtnValue}
        handleClickNo={handleClickNo}
        handleClickYes={handleClickYes}
      />

      {/* Validate ClientID Modal  */}
      <ValidateClientID
        showClientIDModal={showClientIDModal}
        setClientIDSuccessModal={setClientIDSuccessModal}
        setShowClientIDModal={setShowClientIDModal}
      />

      {/*  SuccessFul Validation Modal  */}
      <ValidationSuccessful
        clientIDSuccessModal={clientIDSuccessModal}
        setClientIDSuccessModal={setClientIDSuccessModal}
        handleProceed={handleClickNo}
      />
    </div>
  );
};

export default SwitchButton;
