import React from "react";
import Completed from "../../../../../../Images/completed.png";
import Copy from "../../../../../../Images/copy-icon.png";
import Modal from "react-bootstrap/Modal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { message } from "antd";

const CompletedForm = ({
  showCompleted,
  handleCloseCompleted,
  confirmCompleted,
  accountInfo,
  bvnDetails,
}) => {
  const [Copied] = React.useState("");
  return (
    <div>
      <Modal
        show={showCompleted}
        onHide={handleCloseCompleted}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Body>
          <div className="text-center">
            <div className="mb-3">
              <img src={Completed} className="img-fluid" alt="" />
            </div>
            <div className="mb-5">
              <h6 className="sec-header text-body mb-3 px-sm-5 mx-sm-5">
                Congratulations{" "}
                <span className="font-weight-bold">{bvnDetails.FirstName}</span>
                , Your account has been successfully opened.
              </h6>
              <p className="ftsz-regular">Your account number is</p>
              <span className="new-acct-num pry-red-clr pale-red-tone-bg rounded-lg pry-header px-3 py-2">
                {accountInfo}{" "}
                <CopyToClipboard
                  text={Copied}
                  onCopy={() => {
                    message.success({
                      content: "Copied to clipboard",
                      style: {
                        position: "relative",
                        right: "0",
                        marginTop: "20px",
                      },
                    });
                  }}
                >
                  <button type="button" className="bare-btn">
                    <img src={Copy} alt="copy" />
                  </button>
                </CopyToClipboard>
              </span>
            </div>
            <button
              className="btn btn-outline-danger px-5 py-2"
              onClick={confirmCompleted}
            >
              Okay
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default CompletedForm;
