import { useState } from "react";

export default () => {
  const [showAddRefOTP, setShowAddRefOTP] = useState(false);
  const handleCloseAddRefOTP = () => setShowAddRefOTP(false);
  const handleShowAddRefOTP = () => setShowAddRefOTP(true);

  const [showRefSuccessOTP, setShowRefSuccessOTP] = useState(false);
  const handleCloseRefSuccessOTP = () => setShowRefSuccessOTP(false);
  const handleShowRefSuccessOTP = () => setShowRefSuccessOTP(true);


  return [
    showAddRefOTP,
    handleCloseAddRefOTP,
    handleShowAddRefOTP,
    showRefSuccessOTP,
    handleCloseRefSuccessOTP,
    handleShowRefSuccessOTP,
  ]
};
