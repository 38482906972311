import React from "react";
import { Carousel } from "antd";
import img1 from "../../../../Images/slider_img_1.png";
import img2 from "../../../../Images/slider_img_2.png";
import styles from "./newSideCarousel.module.css";

const NewSideCarousel = () => {
  return (
    <div className={styles.carousel_banners}>
      <section className="pt-4">
        <Carousel effect="fade" dots={false} autoplay={true}>
          <div>
            <img src={img1} className={styles.carousel_image_style} />
          </div>
          <div>
            <img src={img2} className={styles.carousel_image_style} />
          </div>
        </Carousel>
      </section>
    </div>
  );
};

export default NewSideCarousel;
