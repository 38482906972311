import React from "react";
import { Link } from "react-router-dom";
import "./styles.css"

const ExistingKids = () => {
  return (
    <div>
      <div id="newkiakia-tier1-req" className="">
        <div className="d-flex justify-content-between px-4 styling">
          <h5 className="sec-header mb-4">Kid's Savings</h5>
          <div className="mb-4">
            <Link
              to="/existing-kids"
              className="pry-red-bg rounded-lg px-5 py-3 text-white"
            >
              Proceed
            </Link>
          </div>
        </div>
        <div className="row pry-grey-clr mb-4">
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Features</h6>
            <ul className="custom-req-marker pl-3">
              <li>
                Minimum account opening balance of N2,000. (Not Restrictive)
              </li>
              <li>Daily minimum balance of N1,000.</li>
              <li>Attractive interest rate i.e., 1.15% p.a. (30% of MPR).</li>
              <li>Allow lodgement of cheques and dividend warrants.</li>
              <li>Issuance of Verve card</li>
              <li>SMS/Email alert.</li>
              <li>
                Incentive of N250,000 to beneficiary that have scores of all A's
                in SSCE/Cambridge O'level in at least 8 subjects at one sitting
              </li>
            </ul>
          </div>
          <div className="col-md-6 ftsz-12">
            <h6 className="ftsz-12 ftwt-500">Requirements</h6>
            <ul className="custom-req-marker pl-3">
              <li>
                Basic information of Minor and Parent/guardian. (i.e., Name,
                Place and date of birth, Gender, Address, Telephone number)
                **Address would be verified
              </li>
              <li>
                Birth certificate of the minor/International passport / NIN
                Slip.
              </li>
              <li>
                Parent’s/Guardian’s Valid and acceptable means of
                Identification.
              </li>
              <li>Passport photograph of minor and parent/ guardian.</li>
              <li>BVN of Parent/Guardian.</li>
              <li>Signature of Parent/Guardian.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExistingKids;
