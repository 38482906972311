import React from 'react';

import { Modal } from 'react-bootstrap';

const DoYouHaveClientID = ({
  btnValue,
  handleClickYes,
  handleClickNo,
  setBtnValue,
}) => {
  return (
    <Modal show={btnValue} onHide={() => setBtnValue(null)} centered>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="py-5 px-5 rounded w-100">
        <h6 className="text-center">Do You Have A ClientID?</h6>
        <div className="d-flex justify-content-around pt-5">
          <button
            className="btn btn-outline-danger px-5 mr-2"
            onClick={handleClickYes}
          >
            Yes
          </button>
          <button
            className="btn btn-outline-danger px-5"
            onClick={handleClickNo}
          >
            No
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default DoYouHaveClientID;
