import React, { useState, useEffect } from "react";
import { Steps, message } from "antd";
import {
	apiUrl,
	apiUrl1,
	apiUrl2,
	apiUrl3,
} from "../../../../redux/actions/endpoint";
// import axios from "axios";
import moment from "moment";
import * as EmailValidator from "email-validator";
import Header from "./components/Header";
import UpdateDetailsCurrent from "./components/update-details";
import DocsCurrent from "./components/documentss";
import BVN from "./components/bvn";
import useModal from "./hooks/useModal";
import useFiles from "./hooks/useFiless";
import useGoogle from "../cSavings/hooks/useGoogle";
import { useSelector, useDispatch } from "react-redux";
import { set_bvn_info } from "../../../../redux/actions/bvnAction";
import ReferenceCurrent from "./components/reference";
import OTP from "./components/otp";
import InvalidOTP from "./components/otp/errors/invalid";
import CompletedForm from "./components/completed";
import ExistingOTP from "./components/otp/errors/existing";
import InvalidBVN from "./components/bvn/errors/invalid";
import { encrypt2 } from "../../../../encr";
import { decryptHandler2 } from "../../../../dec";
import AddRefOTP from "./components/reference/Modals/AddRef";
import RefSuccessOTP from "./components/reference/Modals/RefSucess";
import TermsAndConditions from "./components/modal/tc";
import NewCompletedForm from "./components/newCompleted";
import { Spinner } from "react-bootstrap";
import PendingModal from "../../../ExistingCustomer/components/PendingModal";
import { appUrls } from "../../../../service/urls";
// import { apiAccount } from "../../../../service/apiAccount";
import { authOApi } from '../../../../service/authOApi';

const { Step } = Steps;

const ClassicCurrentEx = () => {
	const dispatch = useDispatch();
	const [current, setCurrent] = useState(0);
	const [loading, setLoading] = useState(false);
	const [loadingID, setLoadingID] = useState(false);
	const { bvnInfo } = useSelector(({ bvn }) => bvn);
	const [accountCount, setAccountCount] = useState(0);
	const [supposedBvn, setSupposedBvn] = useState("");
	const [mob, setMob] = useState("");
	const [bvnDetails, setBvnDetails] = useState({});
	const [accNum, setAccNum] = useState("");
	//state to hold bvn details
	const [identificationType, setIdentificationType] = useState(
		"International Passport"
	);
	const [customerNumber, setCustomerNumber] = useState("");
	const [accountInfo, setAccountInfo] = useState("");

	const [branchCode, setBranchCode] = useState([]);

	const [disableBtn, setDisableBtn] = useState(true);

	const [address, setAddress, handleSelect] = useGoogle();

	const [isVerified, setIsVerified] = useState(false);
	const [isRestriction, setIsRestriction] = useState(false);

	const [otp, setOtp] = useState("");

	const [hpvImage, setHpvImage] = useState("");
	const [valid, setValid] = useState(false);
	const [isLoadingHpvSdk, setIsLoadingHpvSdk] = useState(false);
	const [emailInfo, setEmailInfo] = useState("");

	const [formValues, setFormValues] = useState({
		bvn: "",
		dobirth: "",
		branch: "",
		firstName: "",
		secondName: "",
		lastName: "",
		phone: "",
		email: "",
		houseNo: "",
		address: "",
		landmark: "",
		state: "",
		addressSummary: "",
		employment: "",
		idCardNo: "",
		issueDate: "",
		expiryDate: "",
		refCode: "",
		nok: "",
		otp: "",
		id: "",
		gender: "",
		dao: null,
		imgBvn: "",
	});

	// state to continue your account opening process
	const [savedProcessValues, setSavedProcessValues] = useState([]);

	// const [
	//   showOtp,
	//   handleCloseOtp,
	//   handleShowOtp,
	//   // showInvalid,
	//   handleCloseInvalid,
	//   handleShowInvalid,
	//   showCompleted,
	//   handleCloseCompleted,
	//   handleShowCompleted,
	//   showInvalidOTP,
	//   handleCloseInvalidOTP,
	//   handleShowInvalidOTP,
	//   showExistingOTP,
	//   handleCloseExistingOTP,
	//   handleShowExistingOTP
	// ] = useModal();

	const [
		showInvalid,
		handleCloseInvalid,
		handleShowInvalid,
		handleShowInvalidOTP,
		showInvalidOTP,
		handleCloseOtp,
		handleCloseInvalidOTP,
		showOtp,
		handleShowOtp,
		showCompleted,
		handleShowCompleted,
		handleCloseCompleted,
		showExistingOTP,
		handleCloseExistingOTP,
		handleShowExistingOTP,

		showAddRefOTP,
		handleCloseAddRefOTP,
		handleShowAddRefOTP,

		showRefSuccessOTP,
		handleCloseRefSuccessOTP,
		handleShowRefSuccessOTP,

		showTC,
		handleCloseTC,
		handleShowTC,

		isOpen,
		setIsOpen,
		handleClosePendingModal,
		handleShowPendingModal,
	] = useModal();

	const [
		signatureUpload,
		livePhotoUpload,
		frontPage,
		onDrops,
		config,
		onError,
		imgSrc,
		retake,
		onDrop,
		onCapture,
		frontPageURL,
		signatureURL,
		utilBill,
		utilBillURL,
		onDropss,

		onDropsss,
		uploadPic,
		uploadPicURL,
		signatureExtension,
		utilityBillExtension,
		frontPageExtension,
		uploadedImgExtension,
	] = useFiles();

	//handling form state change
	const handleFormChange =
		(name) =>
		({ target }) => {
			setFormValues({
				...formValues,
				[name]: target.value,
			});
		};

	const [bvnValues, setBvnValues] = useState({
		accountNumber: "",
		bvn: "",
		otp: "",
	});

	//handling form state change
	const handleBvnChange =
		(name) =>
		({ target }) => {
			setBvnValues({
				...bvnValues,
				[name]: target.value,
			});
		};

	const next = () => {
		setCurrent(current + 1);
		handleClosePendingModal();
		
	};

	const prev = () => {
		setCurrent(current - 1);
		
	};
	const [isClicked, setIsClicked] = React.useState(false);

	const [isDebitClicked, setIsDebitClicked] = React.useState(false);

	const handler = (value) => {
		
		setIsClicked(value);
	};

	const [isTCClicked, setIsTCClicked] = React.useState(false);

	const tcHandler = (value) => {
		
		setIsTCClicked(value);
	};

	const debitHandler = (value) => {
		
		setIsDebitClicked(value);
	};

	const validateFirst = () => {
		getAccountCount();
	};

	const getAccountCount = () => {
		setLoading(true);
		const data = encrypt2(bvnValues.bvn);
		authOApi
			.get(`${appUrls.FIORANO_GET_ACCT_COUNTBY_BVN}?Data=${data}`)
			.then((res) => {
				const response = decryptHandler2(res.data);
				
				if (response.Count === 0) {
					message.error(
						"Oops!!! You do not have an existing Sterling account. Please Use the New customers' module"
					);
					setLoading(false);
					return;
				} else {
					isExisting();
					// setLoading(false);
				}
				setAccountCount(response.Count);
			})
			.catch((error) => {
				setLoading(false);
				message.error(
					"Unable to get account details. Please try again later",
					10
				);
			});
	};

	const fullInfo = () => {
		// setLoading(true);
		const data = encrypt2(bvnValues.bvn);
		authOApi
			.get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${data}`)
			.then((response) => {
				const res = decryptHandler2(response.data);
				let accountInfos = res.GetAccountFullInfo.BankAccountFullInfo;
				let accounts = accountInfos.filter(
					(x) => x.CustomerStatus !== "2" && x.BVN !== null
				);
				setEmailInfo(res.GetAccountFullInfo.BankAccountFullInfo[0]);
				
				if (accounts.length > 0) setCustomerNumber(accounts[0].CUS_NUM);
				else
					setCustomerNumber(
						res.GetAccountFullInfo.BankAccountFullInfo[0].CUS_NUM
					);
				setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				message.error(
					"Unable to get account details. Please try again later",
					10
				);
			});
	};

	// get data needed with bvn
	const getSaveProcess = () => {
		setLoading(true);
		const data = encrypt2(bvnValues.bvn);
		authOApi
			.get(`${appUrls.SAVE_PROCESS_DATABY_BVN}?Data=${data}`)
			.then((res) => {
				const results = decryptHandler2(res.data);
				localStorage.setItem("saveddata", JSON.stringify(results));
				if (
					results.Data.length !== 0 &&
					results &&
					results.responseCode === "00"
				) {
					setSavedProcessValues(results);
					setFormValues({
						bvn: results.data[0].Bvn,
						dobirth: results.data[0].dateOfBirth,
						firstName: results.data[0].firstName,
						lastName: results.data[0].lastName,
						phone: results.data[0].phone,
						email: results.data[0].email,
						address: results.data[0].address,
						landmark: results.data[0].landmark,
						state: results.data[0].state,
						gender: results.data[0].gender,
					});
					// next()
					setLoading(false);
				} else {
					handleClosePendingModal();
					// message.error(
					//   "Oops!! You have no existing application."
					// );
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	// user continues process if user is not done
	const saveProcess = () => {
		setLoading(true);
		let payload = {
			Bvn: formValues.bvn,
			firstName: formValues.firstName,
			email: formValues.email,
			surname: formValues.surname,
			middleName: formValues.middleName,
			productCode: "CLASSIC.ACCT",
			currencyCode: "NGN",
			branch: formValues.branch,
			appId: "1",
			state: formValues.state,
			dateOfBirth: formValues.dateOfBirth,
			phoneNumber: formValues.phoneNumber,
			address: formValues.address,
			sector: "4200",
			industry: "4202",
			nationality: "NG",
			clientID: "52",
			mode: "1",
			channelId: "1",
			customerID: customerNumber,
			residence: formValues.residence,
			shortTitle: "",
			childGender: "",
			preferredName: formValues.preferredName,
			dao: formValues.dao,
		};
		const data = encrypt2(payload);
		authOApi
			.post(`${appUrls.SAVE_PROCESS}`, { data: data })
			.then(async (res) => {
				const results = await decryptHandler2(res.data);
				if (results && results.ResponseCode === "00") {
					setCurrent(current + 1);
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
				message.error("Unable to save data. Please try again later", 10);
			});
		setLoading(false);
	};

	const isExisting = () => {
		// setLoading(true);
		// getAccountCount();
		const data = encrypt2(bvnValues.bvn);
		authOApi
			.get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${data}`)
			.then(async (response) => {
				const results = await decryptHandler2(response.data);
				if (results && results.ResponseCode === "00") {
					const accountArray = results.GetAccountFullInfo.BankAccountFullInfo;
					
					if (accountArray) {
						const checkAccount = accountArray.some(
							(accounts) => accounts.ACCT_TYPE === "Individual-Current"
						);
						if (checkAccount === true) {
							message.error(
								<span>
									<p>
										Oops!!! You already have this type of account. Open another
										account or call 0700 822 0000 to retrieve your existing
										account.
									</p>
									<p>
										Forgot Account Number?
										<a style={{ color: "#db353a" }} href="/retrieve-account">
											{" "}
											Retrieve account number
										</a>
									</p>
								</span>
							);
							setLoading(false);
							return;
						} else {
							validateBVN();
							// setLoading(false);
						}
					} else {
						validateBVN();
						// setLoading(false);
					}
				} else {
					message.error(
						"Unable to verify account details. Please try again later"
					);
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
				message.error(
					"Unable to get account details. Please try again later.",
					10
				);
			});
	};

	const getFullInfo = () => {
		setLoading(true);
		const enc = encrypt2(bvnValues.accountNumber);
		authOApi
			.get(`${appUrls.FIORANO_GET_ACCT_FULLINFO}?Data=${enc}`)
			.then((response) => {
				const res = decryptHandler2(response.data);

				if (res.ResponseCode === "00") {
					let respp = res.BankAccountFullInfo.BVN;
					setSupposedBvn(respp);
					setLoading(false);
				} else {
					setLoading(false);
				}
				// setLoading(false);
			})
			.catch((error) => {
				setLoading(false);
				message.error(
					"Unable to get account details. Please try again later.",
					10
				);
			});
	};

	const validateBVN = () => {
		if (!bvnValues.accountNumber) {
			message.error(
				"Oops!!! You're missing something. Please provide your Account number"
			);
			return;
		}
		if (!bvnValues.bvn) {
			message.error(
				"Oops!!! You're missing something. Please provide your BVN"
			);
			return;
		}
		if (supposedBvn !== bvnValues.bvn) {
			message.error(
				"Oops! There's a mismatch with your BVN and Account information, please check and enter the correct details"
			);
			return;
		} else {
			let payload = {
				bvn: bvnValues.bvn,
				dateOfBirth: null,
				// dateOfBirth: "",
			};
			const enc = encrypt2(payload);
			setLoading(true);
			getSaveProcess();
			authOApi
				.post(`${appUrls.VERIFY_BVN_V2}`, { data: enc })
				.then(async (response) => {
					const res = await decryptHandler2(response.data);

					if (res && res.ResponseCode === "00") {
						handleShowOtp();
						setBvnDetails(res);
						dispatch(set_bvn_info(res));
						let ot = {
							mobile: res.PhoneNumber,
							email: emailInfo?.email || res?.Email,
							clientID: "52",
						};
						const encOt = encrypt2(ot);
						authOApi
							.post(`${appUrls.GENERATE_OTP}`, { data: encOt })
							.then((response) => {
								const res = decryptHandler2(response.data);

								setMob(res.PhoneNumber);
								setLoading(false);
							})
							.catch((error) => {
								setLoading(false);
								message.error(
									"Unable to generate OTP. Please try again later",
									10
								);
							});
					} else {
						setLoading(false);
						message.error(res.ResponseDesc);
					}
				});
		}
	};

	//validateOTP
	const validateOTP = () => {
		setLoading(true);
		const payload = {
			mobile: mob,
			otp: otp,
		};
		const data = encrypt2(payload);
		authOApi
			.post(`${appUrls.VALIDATE_OTP}`, { data: data })
			.then((response) => {
				const res = decryptHandler2(response.data);

				if (res.ResponseCode === "1") {
					handleCloseOtp();
					message.success("Great! Your BVN verification was successful!");
					const savedData = JSON.parse(localStorage.getItem("saveddata"));
					if (savedData.Data.length !== 0) {
						handleShowPendingModal();
					} else {
						// if user is creating application for the first time
						setCurrent(current + 1);
					}
					setLoading(false);
					let bvnPay = encrypt2(bvnValues.bvn);
					authOApi
						.get(`${appUrls.FIORANO_GET_ACCTBY_BVN}?Data=${bvnPay}`)
						.then((response) => {
							const res = decryptHandler2(response.data);

							if (res.ResponseCode === "00") {
								message.success("Great! Your BVN verification was successful!");
								// setCurrent(current + 1);
							}
						});
					setLoading(false);
				} else {
					handleShowInvalidOTP();
					setLoading(false);
				}
			})
			.catch((error) => {
				setLoading(false);
				message.error("Unable to validate OTP. Please try again later", 10);
			});
	};

	const validateFormTwo = () => {
		if (!formValues.firstName) {
			message.error(
				"Oops!!! You're missing something. Please input your first name"
			);
			return;
		}
		if (!formValues.lastName) {
			message.error(
				"Oops!!! You're missing something. Please input your last name"
			);
			return;
		}
		if (!formValues.dobirth) {
			message.error(
				"Oops!!! You're missing something. Please input your date of birth"
			);
			return;
		}
		if (!formValues.email) {
			message.error(
				"Oops!!! You're missing something. Please provide your Email to proceed"
			);
			return;
		}
		if (!EmailValidator.validate(formValues.email)) {
			message.error("This email is not valid, please update and try again ");
			return;
		}
		if (!address) {
			message.error(
				"Oops!!! You're missing something. Please input your address"
			);
			return;
		}
		if (!formValues.landmark) {
			message.error(
				"Oops!!! You're missing something. Please input your landmark"
			);
			return;
		}
		if (!formValues.state) {
			message.error(
				"Oops!!! You're missing something. Please input your state of residence"
			);
			return;
		}

		if (!formValues.nok) {
			message.error(
				"Oops!!! You're missing something. Please input your Next of kin"
			);
			return;
		}
		if (!identificationType) {
			message.error(
				"Oops!!! You're missing something. Please input your ID type"
			);
			return;
		}
		if (identificationType === "National ID") {
			if (!formValues.idCardNo) {
				message.error(
					"Oops!!! You're missing something. Please input your ID number"
				);
				return;
			}
		}
		if (identificationType === "Permanent Voters Card") {
			if (!formValues.idCardNo) {
				message.error(
					"Oops!!! You're missing something. Please input your ID number"
				);
				return;
			}
		}
		if (identificationType === "Drivers License") {
			if (!formValues.idCardNo) {
				message.error(
					"Oops!!! You're missing something. Please input your ID number"
				);
				return;
			}
			if (!formValues.issueDate) {
				message.error(
					"Oops!!! You're missing something. Please input your Issue date"
				);
				return;
			}
			if (!formValues.expiryDate) {
				message.error(
					"Oops!!! You're missing something. Please input your Expiry date"
				);
				return;
			}
		}
		if (identificationType === "International Passport") {
			if (!formValues.idCardNo) {
				message.error(
					"Oops!!! You're missing something. Please input your ID number"
				);
				return;
			}
			if (!formValues.issueDate) {
				message.error(
					"Oops!!! You're missing something. Please input your Issue date"
				);
				return;
			}
			if (!formValues.expiryDate) {
				message.error(
					"Oops!!! You're missing something. Please input your Expiry date"
				);
				return;
			}
		}
		saveProcess();
		// next();
		verifyy();
	};

	const validateFormThree = () => {
		if (!signatureUpload) {
			message.error(
				"Oops!!! You're missing something. Please upload your signature"
			);
			return;
		}
		if (!hpvImage && uploadPic === "") {
			message.error(
				"Oops!!! You're missing something. Please take a upload or take live picture"
			);
			return;
		}
		// if (!livePhotoUpload) {
		//   message.error(
		//     "Oops!!! You're missing something. Please upload a live picture"
		//   );
		//   return;
		// }
		if (!frontPage) {
			message.error(
				"Oops!!! You're missing something. Please select your ID front page"
			);
			return;
		}
		if (!utilBill) {
			message.error(
				"Oops!!! You're missing something. Please upload your Utility bill"
			);
			return;
		}
		if (!formValues.branch) {
			message.error(
				"Oops!!! You're missing something. Please select your preferred branch to proceed"
			);
			return;
		}
		openAccount();
	};

	const dateTime = new Date();

	const uploadToAzure = async () => {
		setLoading(true);
		if (!hpvImage) {
			const imageArr = signatureURL.split(",");
			const imageArr2 = uploadPicURL.split(",");
			const imageArr3 = frontPageURL.split(",");
			const imageArr4 = utilBillURL.split(",");

			const imageSplit = imageArr[1];
			const imageSplit2 = imageArr2[1];
			const imageSplit3 = imageArr3[1];
			const imageSplit4 = imageArr4[1];

			const result = {
				imgSent: "",
				signatureSent: "",
				bvnImgSent: "",
				frontIDSent: "",
				utilBillSent: "",
				success: false,
			};

			let payload1 = {
				file: imageSplit,
				fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/signature`,
				extension: signatureExtension,
			};
			const data1 = encrypt2(payload1);

			let payload2 = {
				file: imageSplit2,
				fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/uploadedImage`,
				extension: uploadedImgExtension,
			};
			const data2 = encrypt2(payload2);

			let payload3 = {
				file: imageSplit3,
				fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/ID`,
				extension: frontPageExtension,
			};
			const data3 = encrypt2(payload3);

			let payload4 = {
				file: imageSplit4,
				fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/utilityBill`,
				extension: utilityBillExtension,
			};
			const data4 = encrypt2(payload4);

			let payload5 = {
				file: bvnDetails.Base64Image,
				fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/bvnImage`,
				extension: ".png",
			};
			const data5 = encrypt2(payload5);

			await authOApi
				.post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
				.then(async (response) => {
					const res1 = response.data;
					result.signatureSent = res1;
					await authOApi
						.post(`${appUrls.UPLOAD_DOC_V2}`, payload2)
						.then(async (response) => {
							const res2 = response.data;
							result.imgSent = res2;
							await authOApi
								.post(`${appUrls.UPLOAD_DOC_V2}`, payload3)
								.then(async (response) => {
									const res3 = response.data;
									result.bvnImgSent = res3;
									await authOApi
										.post(`${appUrls.UPLOAD_DOC_V2}`, payload4)
										.then(async (response) => {
											const res4 = response.data;
											result.frontIDSent = res4;
											await authOApi
												.post(`${appUrls.UPLOAD_DOC_V2}`, payload5)
												.then(async (response) => {
													const res5 = response.data;
													result.utilBillSent = await res5;
												})
												.catch((error) => {
													setLoading(false);
													message.error("Oops! Error Processing Documents", 10);
												});
										});
								});
						});
					result.success = true;
				})
				.catch((error) => {
					setLoading(false);
					message.error("Oops! Error Processing Documents", 10);
					result.success = false;
				});
			return result;
		} else {
			const imageArr = signatureURL.split(",");
			const imageArr2 = hpvImage.split(",");
			const imageArr3 = frontPageURL.split(",");
			const imageArr4 = utilBillURL.split(",");

			const imageSplit = imageArr[1];
			const imageSplit2 = imageArr2[1];
			const imageSplit3 = imageArr3[1];
			const imageSplit4 = imageArr4[1];

			const result = {
				imgSent: "",
				signatureSent: "",
				bvnImgSent: "",
				frontIDSent: "",
				utilBillSent: "",
				success: false,
			};

			let payload1 = {
				file: imageSplit,
				fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/signature`,
				extension: signatureExtension,
			};
			const data1 = encrypt2(payload1);

			let payload2 = {
				file: imageSplit2,
				fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/selfie`,
				extension: ".jpeg",
			};
			const data2 = encrypt2(payload2);

			let payload3 = {
				file: imageSplit3,
				fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/ID`,
				extension: frontPageExtension,
			};
			const data3 = encrypt2(payload3);

			let payload4 = {
				file: imageSplit4,
				fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/utilityBill`,
				extension: utilityBillExtension,
			};
			const data4 = encrypt2(payload4);

			let payload5 = {
				file: bvnDetails.Base64Image,
				fileName: `digitalOnboarding/${formValues.bvn}-${formValues.lastName}/${dateTime}/bvnImage`,
				extension: ".png",
			};
			const data5 = encrypt2(payload5);

			await authOApi
				.post(`${appUrls.UPLOAD_DOC_V2}`, payload1)
				.then(async (response) => {
					const res1 = response.data;
					result.signatureSent = res1;
					await authOApi
						.post(`${appUrls.UPLOAD_DOC_V2}`, payload2)
						.then(async (response) => {
							const res2 = response.data;
							result.imgSent = res2;
							await authOApi
								.post(`${appUrls.UPLOAD_DOC_V2}`, payload3)
								.then(async (response) => {
									const res3 = response.data;
									result.bvnImgSent = res3;
									await authOApi
										.post(`${appUrls.UPLOAD_DOC_V2}`, payload4)
										.then(async (response) => {
											const res4 = response.data;
											result.frontIDSent = res4;
											await authOApi
												.post(`${appUrls.UPLOAD_DOC_V2}`, payload5)
												.then(async (response) => {
													const res5 = response.data;
													result.utilBillSent = await res5;
												})
												.catch((error) => {
													result.success = false;
													setLoading(false);
													message.error("Oops! Error Processing Documents", 10);
												});
										});
								});
						});
					result.success = true;
				})
				.catch((error) => {
					setLoading(false);
					message.error("Oops! Error Processing Documents", 10);
					result.success = false;
					setLoading(false);
				});
			return result;
		}
	};

	const confirmCompleted = () => {
		handleCloseCompleted();
		setCurrent(0);
	};

	const addRestrictions = (accountNN) => {
		let restrictionPayload = {
			addRestriction: {
				branchcode: formValues.branch,
				account: accountNN,
				accountsType: "ACCOUNTS",
				restriction_code: "23",
			},
		};
		authOApi
			.post(`${appUrls.FIORANO_ADD_RESTRICTION}`, restrictionPayload)
			.then((response) => {
				// message.success(response.data.responseDescription);
				
			})
			.catch((error) => {});
	};

	const verifyy = () => {
		if (identificationType === "International Passport") {
			youVerifyInternational();
		}

		if (identificationType === "Permanent Voters Card") {
			youVerifyPvc();
		}

		if (identificationType === "National ID") {
			youVerifyNationalID();
		}

		if (identificationType === "Drivers License") {
			youVerifyDriversLic();
		}
	};

	const youVerifyInternational = () => {
		setLoadingID(true);
		let payloadd = {
			id: formValues.idCardNo,
			lastName: formValues.lastName,
			isSubjectConsent: true,
			validations: {
				data: {
					firstName: formValues.firstName,
					dateOfBirth: moment(formValues.dobirth).format("YYYY-MM-DD"),
				},
			},
		};
		
		authOApi
			.post(`${appUrls.ADD_INTL_PASSPORT}`, payloadd)
			.then((response) => {
				let res = response.data;
				if (response.data.statusCode == "200") {
					next();
					setLoadingID(false);
					setIsVerified(true);
				} else {
					// message.error(response.data.message, 10);
					next();
					setLoadingID(false);
				}
			})
			.catch((error) => {
				setLoadingID(false);
				next();
			});
	};

	const youVerifyNationalID = () => {
		let payload = {
			id: formValues.idCardNo,
			isSubjectConsent: true,
		};
		//  
		authOApi
			.post(`${appUrls.VERIFY_NIN}`, payload)
			.then((response) => {
				let res = response.data;
				if (response.data.statusCode == "200") {
					next();
					setLoadingID(false);
					setIsVerified(true);
				} else {
					// message.error(response.data.message, 10);
					next();
					setLoadingID(false);
				}
			})
			.catch((error) => {
				next();
				setLoadingID(false);
			});
	};

	const youVerifyPvc = () => {
		setLoadingID(true);
		let payload = {
			id: formValues.idCardNo,
			isSubjectConsent: true,
		};
		//  
		authOApi
			.post(`${appUrls.VERIFY_PVC}`, payload)
			.then((response) => {
				let res = response.data;
				if (response.data.statusCode == "200") {
					next();
					setLoadingID(false);
					setIsVerified(true);
				} else {
					// message.error(response.data.message, 10);
					next();
					setLoadingID(false);
				}
			})
			.catch((error) => {
				setLoadingID(false);
				next();
			});
	};

	const youVerifyDriversLic = () => {
		setLoadingID(true);
		let payload = {
			id: formValues.idCardNo,
			isSubjectConsent: true,
		};
		authOApi
			.post(`${appUrls.VERIFY_DRIVER_LICENSE}`, payload)
			.then((response) => {
				let res = response.data;
				if (response.data.statusCode == "200") {
					next();
					setLoadingID(false);
					setIsVerified(true);
				} else {
					// message.error(response.data.message);
					next();
					setLoadingID(false);
				}
			})
			.catch((error) => {
				next();
			});
	};

	const openAccount = async () => {
		setLoading(true);
		const results = await uploadToAzure();
		
		if (results.success) {
			// does not have valid facial recognition
			let payloadTwo = {
				firstName: formValues.firstName,
				email: formValues.email,
				surName: formValues.lastName,
				middleName: formValues.secondName,
				productCode: "CLASSIC.ACCT",
				currencyCode: "NGN",
				branch: formValues.branch,
				appId: "1",
				state: formValues.state,
				bvn: formValues.bvn,
				dateOfBirth: moment(formValues.dobirth).format(
					"YYYY-MM-DDTHH:mm:ss.sssZ"
				),
				phoneNumber: formValues.phone,
				address: address,
				sector: "4200",
				industry: "4202",
				nationality: "NG",
				clientID: "52",
				mode: "1",
				channelId: "1",
				customerID: customerNumber,
				residence: "NG",
				shortTitle: "",
				childGender: "",
				preferredName: formValues.firstName,
				dao: formValues.dao,
				IsVerified: isVerified && valid,
				isRestriction: isRestriction,
			};
			
			const payloadEnc = encrypt2(payloadTwo);
			await authOApi
				.post(`${appUrls.CREATE_ACCT_INAPP}`, { data: payloadEnc })
				.then(async (response) => {
					const res = decryptHandler2(response.data);

					if (res.responseCode === "00") {
						setAccountInfo(res.nuban);
						let rest = res.nuban;
						addRestrictions(rest);

						let payload1 = {
							accountNumber: rest,
							phoneNumber: formValues.phone,
						};
						let enc = encrypt2(payload1);
						authOApi
							.post(`${appUrls.SIM_SWAP_STATUS}`, { data: enc })
							.then((response) => {
								let decRes = decryptHandler2(response.data);

								if (decRes.SimSwapMode === "SWAPPED") {
									let payload = {
										branchCode: formValues.branch,
										accountNumber: res.nuban,
										accontType: "CLASSIC.ACCT",
									};
									const encRestr = encrypt2(payload);
									authOApi
										.post(`${appUrls.ADD_RESTRICTION}`, {
											data: encRestr,
										})
										.then((response) => {
											let encRes = decryptHandler2(response.data);
											
											if (encRes.RestrictionResponse.ResponseCode === "1") {
												customerCare();
											}
										});
								}
							});

						const customerCare = () => {
							let payload = {
								accountNumber: res.nuban,
								phoneNumber: formValues.phone,
							};
							authOApi
								.post(`${appUrls.EMAIL_CUSTOMER_CARE}`, {
									data: payload,
								})
								.then((response) => {
									let encRes = encrypt2(response.data);
									if (encRes === 1) {
									}
								});
						};

						let payload = {
							accountNumber: res.nuban,
							folio: "Digital Onboarding",
							source: "Digital Onboarding",
							photoIDBase64: results.imgSent,
							signatureMandateBase64: results.signatureSent,
							bvnImageURL: results.bvnImgSent,
							otherBase64: results.utilBillSent,
							photoMandateBase64: results.frontIDSent,
							idtype: identificationType,
							idno: formValues.idCardNo,
							issuedate: formValues.issueDate,
							isexpirydate: formValues.expiryDate,
						};
						const docsEnc = encrypt2(payload);
						await authOApi
							.post(`${appUrls.UPLOAD_DOC_ONLINEACCOUNT}`, {
								data: docsEnc,
							})
							.then((response) => {
								const doc = decryptHandler2(response.data);
								message.success(doc.responseDescription);
							})
							.catch((error) => {});
						handleShowCompleted();
						setLoading(false);
					} else {
						message.error(res.responseText);
						setLoading(false);
					}
				})
				.catch((error) => {
					setLoading(false);
					message.error(
						"UOops! Error uploading document. Please try again later",
						10
					);
				});
		}
	};

	const [fileUrl, setFileUrl] = useState("");

	const initializeSdk = (token) => {
		window.HyperSnapSDK.init(token, window.HyperSnapParams.Region.Africa);
		window.HyperSnapSDK.startUserSession();
	};

	const runLiveness = () => {
		const hvFaceConfig = new window.HVFaceConfig();
		hvFaceConfig.setShouldShowInstructionPage(true);
		hvFaceConfig.setLivenessAPIParameters({
			rejectFaceMask: "yes",
			allowEyesClosed: "no",
			allowMultipleFaces: "no",
		});
		customizeFaceCaptureInstructionsScreen(hvFaceConfig);
		window.HVFaceModule.start(hvFaceConfig, livelinessCallback);
	};

	const customizeFaceCaptureInstructionsScreen = (hvFaceConfig) => {
		const { faceTextConfig } = hvFaceConfig;
		faceTextConfig.setFaceInstructionsTitle("Selfie capture Tips");
		// remove Top1, Top2
	};

	const livelinessCallback = (HVError, HVResponse) => {
		if (HVError) {
			const errorCode = HVError.getErrorCode();
			const errorMessage = HVError.getErrorMessage();
			message.error(errorMessage);

		} else {
			const faceImageBase64 = HVResponse.imgBase64;
			setHpvImage(faceImageBase64);
			checkMatch(faceImageBase64);
		}
	};

	const checkMatch = (faceImageBase64) => {
		const imgg = localStorage.getItem("params");
		const hyp = faceImageBase64.split(",");
		const hypSplit = hyp[1];
		let payload = {
			Selfie: hypSplit,
			IdFaceString: imgg,
		};
		authOApi
			.post(`${appUrls.HYPERVERGE_FACE_MATCH}`, payload)
			.then((response) => {

				if (response.data.status === "failure") {
					message.error("Unable to verify Hyperverge !");
					setValid(false);
					setIsRestriction(true);
				} else {
					setValid(true);
					setIsRestriction(false);
				}
			})
			.catch((error) => {
				setLoading(false);
				message.error(
					"Unable to verify Face Match. Please try again later",
					10
				);
			});
	};

	const startHyperverge = (firstParam) => {
		localStorage.setItem("params", firstParam);
		setIsLoadingHpvSdk(true);
		authOApi
			.get(
				`${appUrls.GET_HYPERVERGE_TOKEN}`
				// , {
				//   headers: {
				//     "Access-Control-Allow-Origin": "*",
				//     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
				//   },
				// }
			)
			.then(async (response) => {
				if (response.data.statusCode === "200") {
					const token = response.data.result.token;
					initializeSdk(token);
					runLiveness();
					setIsLoadingHpvSdk(false);
				}
			})
			.catch((err) => {
				setIsLoadingHpvSdk(false);
				message.error("Error please try again later", 10);
			});
	};

	const steps = [
		{
			title: "BVN Validation",
			content: (
				<BVN
					bvnValues={bvnValues}
					handleBvnChange={handleBvnChange}
					setDisableBtn={setDisableBtn}
				/>
			),
		},
		{
			title: "Upload details",
			content: (
				<UpdateDetailsCurrent
					formValues={formValues}
					handleFormChange={handleFormChange}
					bvnDetails={bvnDetails}
					identificationType={identificationType}
					setIdentificationType={setIdentificationType}
					setAccNum={setAccNum}
					setCustomerNumber={setCustomerNumber}
					setAccountCount={setAccountCount}
					address={address}
					setAddress={setAddress}
					handleSelect={handleSelect}
					bvnInfo={bvnInfo}
					setFormValues={setFormValues}
					setLoading={setLoading}
					fullInfo={fullInfo}
				/>
			),
		},
		{
			title: "Document upload",
			content: (
				<DocsCurrent
					formValues={formValues}
					handleFormChange={handleFormChange}
					signatureUpload={signatureUpload}
					onDrops={onDrops}
					onCapture={onCapture}
					config={config}
					onError={onError}
					imgSrc={imgSrc}
					retake={retake}
					onDrop={onDrop}
					frontPage={frontPage}
					branchCode={branchCode}
					accountInfo={accountInfo}
					utilBill={utilBill}
					onDropss={onDropss}
					handler={handler}
					tcHandler={tcHandler}
					isTCClicked={isTCClicked}
					handleShowTC={handleShowTC}
					isDebitClicked={isDebitClicked}
					debitHandler={debitHandler}
					onDropsss={onDropsss}
					uploadPic={uploadPic}
					hpvImage={hpvImage}
					isLoadingHpvSdk={isLoadingHpvSdk}
					startHyperverge={startHyperverge}
					bvnDetails={bvnDetails}
				/>
			),
		},
		{
			title: "Add rederence",
			content: (
				<ReferenceCurrent
					formValues={formValues}
					handleFormChange={handleFormChange}
					accountInfo={accountInfo}
					current={current}
					setCurrent={setCurrent}
					fileUrl={fileUrl}
					showAddRefOTP={showAddRefOTP}
					handleCloseAddRefOTP={handleCloseAddRefOTP}
					handleShowAddRefOTP={handleShowAddRefOTP}
					handleShowRefSuccessOTP={handleShowRefSuccessOTP}
				/>
			),
		},
	];

	const getBranches = () => {
		authOApi
			.get(
				`${appUrls.GET_STERLING_BRANCHES}`
				// , {
				//   headers: {
				//     "Access-Control-Allow-Origin": "*",
				//     "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
				//   },
				// }
			)
			.then((response) => {
				const res = decryptHandler2(response.data);
				setBranchCode(res.SterlingBranches);
				docUpload();
			})
			.catch((err) => {});
	};

	const docUpload = () => {
		authOApi.get(`${appUrls.GET_DOC}`).then((response) => {
			setFileUrl(response.data);
		});
	};

	useEffect(() => {
		getBranches();
	}, []);

	useEffect(() => {
		getFullInfo();
		fullInfo();
	}, [bvnValues.accountNumber.length === 10]);

	return (
		<div>
			<div className="row">
				<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 displayNone">
					<div className="leftDiv stickyDiv"></div>
				</div>
				<div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
					<Header />
					<div className="container-fluid pb-5 mt-5">
						<div class="row mb-5">
							<div class="container mr-3">
								<div className="steps-content mb-5">
									{steps[current].content}
								</div>
								<div className="text-right"></div>
								<div className="steps-action pb-1 mt-3 text-right">
									{current === steps.length - 4 && (
										<button
											type="button"
											className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3 btn-block w-80"
											onClick={validateFirst}
											disabled={disableBtn || loading}
										>
											{loading ? (
												<Spinner
													as="span"
													animation="border"
													size="sm"
													role="status"
													aria-hidden="true"
												/>
											) : (
												"Validate"
											)}
										</button>
									)}
									{current > 0 && steps.length === 4 && (
										<button
											type="button"
											className="btn btn-outline-danger px-5 mr-2 mb-3"
											onClick={prev}
										>
											Previous
										</button>
									)}
									{current < steps.length - 2 && current > 0 && (
										<button
											type="button"
											className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-3"
											onClick={validateFormTwo}
										>
											{/* {loadingID && "Validating ..."}
                      {!loadingID && "Next"} */}
											Next
										</button>
									)}
									{current < steps.length - 1 && current === 2 && (
										<button
											type="button"
											className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
											onClick={validateFormThree}
											disabled={loading === true || isTCClicked === false}
										>
											{loading ? "Please wait" : "Next"}
										</button>
									)}
								</div>
								{current === 0 && (
									<div>
										<div class="my-4 text-centers text-dark">
											<h3 class="mb-0 sec-headers">Classic Current</h3>
										</div>
										<div className="row pry-grey-clr mb-4 w-80">
											<div className="col-md-6 ftsz-12">
												<h6 className="ftsz-12 ftwt-500">Features</h6>
												<ul className="custom-req-marker pl-3">
													<li>
														Minimum account opening balance of =N=5,000 (Not
														Restrictive)
													</li>
													<li>Access to overdraft facility.</li>
													<li>
														AMF at =N=1/mille (Negotiable with huge turnover)
													</li>
													<li>
														Internet and mobile banking facility i.e., online
														statement, SMS alert etc
													</li>
													<li>Chequebook.</li>
													<li>International funds transfer allowed.</li>
													<li>Issuance of Verve Card and Mastercard.</li>
												</ul>
											</div>
											<div className="col-md-6 ftsz-12">
												<h6 className="ftsz-12 ftwt-500">Requirements</h6>
												<ul className="custom-req-marker pl-3">
													<li>
														Basic customer information. (i.e., Name, Place and
														date of birth, Gender, Address, Telephone number)
														**Address would be verified.
													</li>
													<li>BVN.</li>
													<li>Passport photograph.</li>
													<li>Signature.</li>
													<li>Valid ID.</li>
													<li>2 Suitable references.</li>
												</ul>
											</div>
										</div>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <Header />
      <div className="d-flex justify-content-between px-2">
        <div></div>
        <div className="w-50">
          <Steps current={current}>
            {steps.map((item) => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>
        </div>
      </div>
      <div className="container-fluid pb-5 mt-5">
        <div class="row mb-5">
          <div class="col-xl-7 col-lg-9 col-md-10 mx-auto">
            <div class="container">
              <div class="app-details-form-wrapper pry-grey-clr bg-white px-3 px-lg-5 py-5">
                <div className="steps-content">{steps[current].content}</div>
                <div className="text-right"></div>
                <div className="steps-action pb-5 mt-3 text-right">
                  {current === steps.length - 4 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger text-white pry-red-bg px-5 mb-3"
                      onClick={validateFirst}
                      disabled={disableBtn || loading}
                    >
                      {loading ? "Validating..." : "Validate"}
                    </button>
                  )}
                  {current > 0 && steps.length === 4 && (
                    <button
                      type="button"
                      className="btn btn-outline-danger px-5 mr-2 mb-3"
                      onClick={prev}
                    >
                      Previous
                    </button>
                  )}
                  {current < steps.length - 2 && current > 0 && (
                    <button
                      type="button"
                      className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-3"
                      onClick={validateFormTwo}
                    >
                      Next
                    </button>
                  )}
                  {current < steps.length - 1 && current === 2 && (
                    <button
                      type="button"
                      className="btn btn-danger btn-outline-danger text-white px-5 mr-sm-3 mb-3"
                      onClick={validateFormThree}
                      disabled={loading === true || isTCClicked === false}
                    >
                      {loading ? "Please wait" : "Next"}
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
			<OTP
				showOtp={showOtp}
				handleCloseOtp={handleCloseOtp}
				formValues={formValues}
				handleFormChange={handleFormChange}
				validateOTP={validateOTP}
				loading={loading}
				validateBVN={validateBVN}
				otp={otp}
				setOtp={setOtp}
			/>
			{/* <CompletedForm
        showCompleted={showCompleted}
        handleCloseCompleted={handleCloseCompleted}
        confirmCompleted={confirmCompleted}
        accountInfo={accountInfo}
        bvnDetails={bvnDetails}
        accNum={accNum}
        next={next}
      /> */}
			<NewCompletedForm
				showCompleted={showCompleted}
				handleCloseCompleted={handleCloseCompleted}
				confirmCompleted={confirmCompleted}
				accountInfo={accountInfo}
				bvnDetails={bvnDetails}
				accNum={accNum}
				next={next}
				imgSrc={imgSrc}
			/>
			<InvalidBVN
				showInvalid={showInvalid}
				handleCloseInvalid={handleCloseInvalid}
			/>
			<InvalidOTP
				showInvalidOTP={showInvalidOTP}
				handleCloseInvalidOTP={handleCloseInvalidOTP}
			/>

			<ExistingOTP
				showExistingOTP={showExistingOTP}
				handleCloseExistingOTP={handleCloseExistingOTP}
			/>
			<AddRefOTP
				showAddRefOTP={showAddRefOTP}
				handleCloseAddRefOTP={handleCloseAddRefOTP}
				handleShowRefSuccessOTP={handleShowRefSuccessOTP}
			/>
			<RefSuccessOTP
				showRefSuccessOTP={showRefSuccessOTP}
				handleCloseRefSuccessOTP={handleCloseRefSuccessOTP}
				setCurrent={setCurrent}
			/>

			<PendingModal
				showPendingModal={isOpen}
				handleClosePendingModal={handleClosePendingModal}
				setNext={next}
				getSaveProcess={getSaveProcess}
			/>

			<TermsAndConditions showTC={showTC} handleCloseTC={handleCloseTC} />
		</div>
	);
};

export default ClassicCurrentEx;
