import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import styles from "./apidashboard.module.css";
import SterlingLogo from "../../../Images/ball.png";
import AddMerchants from "../pages/merchants/addMerchants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Merchants from "../pages/merchants/merchants";
import { MdOutlineDashboard } from "react-icons/md";
import { AiOutlineUserAdd } from "react-icons/ai";
import Modal from "react-bootstrap/Modal";
import ProtectedRoutes from "../../protectedRoutes";

function ApidashboardHeader() {
  const history = useHistory();
  const path = history.location.pathname;
  const [showLogout, setShowLogout] = useState(false);

  return (
    <>
      <div className={styles.main_body}>
        <div className={styles.header_content}>
          <div className={styles.logo_content}>
            <img
              src={SterlingLogo}
              alt="sterling logo"
              className={styles.logo}
            />
            Api dashboard
          </div>
        </div>
        <div className={styles.routes}>
          <div
            style={{
              display: "flex",
              gap: "30px",
              cursor: "pointer",
              alignItems: "center",
              marginTop: "30px",
            }}
          >
            <div
              style={{ display: "flex", gap: "10px" }}
              onClick={() => {
                history.push(`/api-dashboard/merchants`);
              }}
              className={
                path == "/api-dashboard/merchants" ? `${styles.activeLink}` : ""
              }
            >
              <MdOutlineDashboard className={styles.icon__img} />
              <p style={{ fontSize: "24px", fontWeight: "600" }}>Merchants</p>
            </div>
            <div
              style={{ display: "flex", gap: "10px", cursor: "pointer" }}
              onClick={() => {
                history.push(`/api-dashboard/addmerchants`);
              }}
              className={
                path == "/api-dashboard/addmerchants"
                  ? `${styles.activeLink}`
                  : ""
              }
            >
              <AiOutlineUserAdd className={styles.icon__img} />
              <p style={{ fontSize: "24px", fontWeight: "600" }}>
                Add Merchant
              </p>
            </div>
          </div>
          <div
            onClick={() => {
              setShowLogout(true);
              sessionStorage.clear();
            }}
            className={styles.logout}
          >
            Log out
          </div>
        </div>
        <div className={styles.main_content}>
          <Switch>
            <ProtectedRoutes
              path="/api-dashboard/merchants"
              component={Merchants}
            />
            <ProtectedRoutes
              path="/api-dashboard/addMerchants"
              component={AddMerchants}
            />
          </Switch>
        </div>
      </div>
      <Modal
        show={showLogout}
        backdrop="static"
        fullscreen="md-down"
        keyboard={false}
        centered={true}
      >
        <Modal.Body>
          <p className={styles.logoutModal_confirm_text}>
            Do you want to Log Out?
          </p>
          <div className="btn-flex">
            <button
              onClick={() => history.push("/api-dashboard/login")}
              className={styles.logout_yes}
              type="button"
            >
              Yes
            </button>
            <button
              onClick={() => setShowLogout(false)}
              className={styles.logout_no}
              type="button"
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}
export default ApidashboardHeader;
