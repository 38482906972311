import React, { useState } from "react";
import KiakiaOne from "./components/kiakiaOne";
import { useSelector, useDispatch } from "react-redux";
import KiakiaTwo from "./components/kiakiaTwo";
import { savingsAction } from "../../../../../../redux/actions";
import None from "./components/none";
import KidsAccount from "./components/kids";
import ClassicAccount from "./components/classic";
import TrybeOne from "./components/trybeOne";
import DomiciliaryAccount from "./components/dom";

const SavingsTab = () => {
  const [drop, setDrop] = useState({
    account: "",
  });
  const { account_type } = useSelector(({ savings }) => savings);
  const dispatch = useDispatch();
  const handleChange = (name) => (event) => {
    setDrop({
      ...drop,
      [name]: event.target.value,
    });
    dispatch(savingsAction.set_account_type(event.target.value));
  };

  return (
    <div>
      <div className="mb-5">
        <label htmlFor="" className="ftsz-12 ftwt-500">
          Select account type
        </label>
        <select
          className="custom-select pale-red-tone-bg pry-input-border border-0"
          onChange={handleChange("account")}
          value={drop.account}
        >
          <option value="none">Select account type</option>
          <option value="one">Kiakia (Tier 1)</option>
          <option value="two">Kiakia (Tier 2)</option>
          <option value="classic">Classic Savings</option>
          <option value="kids">Kid’s Savings</option>
          {/* <option value="trybe">TrybeOne</option> */}
          <option value="dom">Domiciliary savings</option>
        </select>
      </div>
      <div>
        {account_type === "none" && <None />}
        {account_type === "one" && <KiakiaOne />}
        {account_type === "two" && <KiakiaTwo />}
        {account_type === "classic" && <ClassicAccount />}
        {account_type === "kids" && <KidsAccount />}
        {account_type === "trybe" && <TrybeOne />}
        {account_type === "dom" && <DomiciliaryAccount />}
      </div>
    </div>
  );
};

export default SavingsTab;
