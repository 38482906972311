import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { RxAvatar } from "react-icons/rx"
import { BsArrowDown, BsArrowUp } from "react-icons/bs"
import { MdOutlinedFlag, MdTune } from "react-icons/md"
import { AiOutlineInfoCircle } from "react-icons/ai"
import { MdRestartAlt } from "react-icons/md"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import Table from '../components/Tables/table'
import dashboard from '../../../service/dashboard'
import Spinner from "react-bootstrap/Spinner";



function Home() {
    const [branches, setBranches] = useState([])
    const [loading, setLoading] = useState(false);


    const branchesRequest = async () => {
        setLoading(true);
        await dashboard
            .get("account-top-branches")
            .then((res) => {
                if (res.status === 200) {
                    setBranches(res.data)
                    setLoading(false);
                }

            })
            .catch((err) => {
                
            });
    }

    useEffect(() => {
        branchesRequest()
    }, [])

    return (
        <div>
            {loading ?
                (<Spinner
                    as="span"
                    animation="border"
                    role="status"
                    aria-hidden="true"
                />) : (

                    <div className="main__container">
                        <div className="header">
                            <ul>
                                <h1>Summary</h1>
                                <div>
                                    <label><MdTune /></label>
                                    <select className="date-select" name="" id="">
                                        <option>Last 30 days</option>
                                        <option>Last 60 days</option>
                                    </select>
                                </div>
                            </ul>
                        </div>
                        <div className="separatingDiv">
                            <div className="main__cards">
                                <div className="card-big">
                                    <div className="cards">
                                        <div className="card_inners">
                                            <div className='card-title'>
                                                <div className="cards_icon">
                                                    <RxAvatar className='card-img' />
                                                </div>
                                                <h3 className="title-h">Accounts Opened</h3>
                                            </div>
                                            <div className='card-sub-1'>
                                                <h3 className="card-value">530</h3>
                                                <BsArrowUp className="card-arrow-green" />
                                            </div>

                                            <div className='card-sub-3'>
                                                <div style={{ width: 70, height: 70 }}>
                                                    <CircularProgressbar
                                                        value="30"
                                                        text={`30%`}
                                                        styles={buildStyles({
                                                            pathColor: `rgba(45, 179, 80, ${70 / 100})`,
                                                            textColor: '#2DB350',
                                                            trailColor: '#d6d6d6',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                    />
                                                </div>
                                                <h3 className="title-h">Web &nbsp; &nbsp;</h3>

                                            </div>
                                            <div className='card-sub-3'>
                                                <div style={{ width: 70, height: 70 }}>
                                                    <CircularProgressbar
                                                        value="70"
                                                        text={`70%`}
                                                        styles={buildStyles({
                                                            pathColor: `rgba(43, 152, 181, ${70 / 100})`,
                                                            textColor: '#2B98B5',
                                                            trailColor: '#d6d6d6',
                                                            backgroundColor: '#3e98c7',
                                                        })}
                                                    />
                                                </div>
                                                <h3 className="title-h">Mobile</h3>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="multi-cards">

                                    <div className="cards-1">
                                        <div className="card_inners">
                                            <div className='card-title'>
                                                <div className="cards_icon">
                                                    <MdOutlinedFlag className='card-img' />
                                                </div>
                                                <h3 className="title-h">Complete Documents</h3>
                                            </div>
                                            <div className='card-sub-1'>
                                                <h3 className="card-value">100</h3>
                                                <BsArrowDown className="card-arrow-red" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cards-1">
                                        <div className="card_inners">
                                            <div className='card-title'>
                                                <div className="cards_icon">
                                                    <span>N</span>
                                                </div>
                                                <h3 className="title-h">Transacting</h3>
                                            </div>
                                            <div className='card-sub-1'>
                                                <h3 className="card-value">20</h3>
                                                <BsArrowUp className="card-arrow-green" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cards-1">
                                        <div className="card_inners">
                                            <div className='card-title'>
                                                <div className="cards_icon">
                                                    <MdRestartAlt
                                                        className='card-img' />
                                                </div>
                                                <h3 className="title-h">Value Add Requests</h3>
                                            </div>
                                            <div className='card-sub-1'>
                                                <h3 className="card-value">150</h3>
                                                <BsArrowDown className="card-arrow-green" />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="cards-1">
                                        <div className="card_inners">
                                            <div className='card-title'>
                                                <div className="cards_icon">
                                                    <span>N</span>
                                                </div>
                                                <h3 className="title-h">Total Funding</h3>
                                            </div>
                                            <div className='card-sub-1'>
                                                <h3 className="card-value">1.5B</h3>
                                                <BsArrowDown className="card-arrow-green" />
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div className="main__cards">
                                <div className="card-2-big">
                                    <div className="cards">
                                        <div className="card-table-header">
                                            <h3 className='card-table-title'>Requests from Branches</h3>
                                            <Link
                                                to={{
                                                    pathname: "/admin/request-braches",
                                                    state: {
                                                        fromHome: { branches },
                                                    },
                                                }}
                                            >
                                                View All
                                            </Link>
                                            {/* <a href='/admin/request-braches'>View All</a> */}
                                        </div>
                                        <Table branches={branches} />
                                    </div>
                                </div>
                                <div className="multi-cards multi-cards-column">


                                    <div className="cards-1 cards-2">
                                        <a href='/admin/incomplete-docs'>
                                            <div className="card_inners">
                                                <div className="cards_icon_2">
                                                    <AiOutlineInfoCircle className='card-img-2' />
                                                </div>

                                                <div className='card-sub-2'>
                                                    <h3 className="card-value-2">120</h3>
                                                    <p>Incomplete Documents</p>
                                                </div>
                                            </div>

                                        </a>
                                    </div>
                                    <div className="cards-1 cards-2">
                                        <a href='/admin/restricted-accts'>
                                            <div className="card_inners">
                                                <div className="cards_icon_2">
                                                    <AiOutlineInfoCircle className='card-img-2' />
                                                </div>
                                                {/* <div className='card-title'>
                                                </div> */}
                                                <div className='card-sub-2'>
                                                    <h3 className="card-value-2 value-red">10</h3>
                                                    <p>Restricted Accounts</p>
                                                </div>
                                            </div>

                                        </a>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                )
            }

        </div>
    )
}

export default Home